<button class="button_date-change" (click)="changeDate('prev')">
    <fa-icon [icon]="faChevronLeft"></fa-icon>
</button>
<div class="widget_date">
    <app-date-input class="grid__child_col_1" [group]="formGroup" name="currentDate" [range]="graphConfigSrv.periodUnitMap.get(graphConfig.period)"></app-date-input>
</div>
<button class="button_date-change" (click)="changeDate('next')">
    <fa-icon [icon]="faChevronRight"></fa-icon>
</button>

<!-- 2 main Columns -->
<div style="width: 100%; height: 100%; position: relative;" class="grid grid_col_3 co2-grid">
    
        <!-- first column -->
        <div class="grid__child_col-sm_1 grid__child_col-md_1 grid__child_col-lg_1" style="text-align: center;">
            <fa-icon class=" leaf-icon" [icon]="faLeaf" size="3x"></fa-icon>
            <div class="leaf-title" > {{co2Datas && co2Datas.kwh? co2Datas.kwh > 1 ? co2Datas.kwh.toFixed(0) : co2Datas.kwh.toFixed(2) : '?' }} Kwh produit<br>soit<br>{{co2Datas && co2Datas.co2? co2Datas.co2 > 1 ? co2Datas.co2.toFixed(0) : co2Datas.co2.toFixed(2) : '?' }} kg de CO² {{ "dodged" | translate }} </div>
        </div>
        
        <!-- second column && 2 lines -->
        <div class="grid grid_row_2 grid__child_col-sm_2 grid__child_col-md_2 grid__child_col-lg_2">

            <div class="grid grid_col_2 grid__child_row_1">
                <div class="grid__child_col_1">
                    <div class="co2-infos-box">
                        <div >{{ "diesel" | translate }}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.diesel? co2Datas.diesel.toFixed(0) + " km" : ' - ' }}</div>
                    </div>

                    <div class="co2-infos-box">
                        <div>{{ "gasoline" | translate }}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.gasoline? co2Datas.gasoline.toFixed(0) + " km" : ' - ' }} </div>
                    </div>
                    <div class="co2-infos-box">
                        <div>{{ "electricity" | translate }}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.electricity? co2Datas.electricity.toFixed(0) + " km" : ' - ' }} </div>
                    </div>
                    <div class="co2-infos-box">
                        <div>{{ "hybrid" | translate }}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.hybrid? co2Datas.hybrid.toFixed(0)  + " km": ' - ' }} </div>
                    </div>
                    <div class="co2-infos-box" *ngIf="graphConfig.displayBike">
                        <div>{{ "bike" | translate }}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.bike? co2Datas.bike.toFixed(0) + " km" : ' - ' }} </div>
                    </div>
                </div>
                <div class="grid__child_col_1">
                    <div class="co2-infos-box">
                        <div>{{"cng" | translate}}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.cng? co2Datas.cng.toFixed(0) + " km" : ' - ' }} </div>
                    </div>
                    <div class="co2-infos-box" *ngIf="graphConfig.displayWalk">
                        <div>{{ "walk" | translate }}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.walk? co2Datas.walk.toFixed(0) + " km" : ' - ' }} </div>
                    </div>
                    <div class="co2-infos-box" *ngIf="graphConfig.displayTrain">
                        <div>{{ "train" | translate }}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.train? co2Datas.train.toFixed(0) + " km" : ' - ' }} </div>
                    </div>
                    <div class="co2-infos-box" *ngIf="graphConfig.displayBus">
                        <div>{{ "bus" | translate }}:</div> 
                        <div class="co2-infos">{{co2Datas && co2Datas.bus? co2Datas.bus.toFixed(0) + " km" : ' - ' }} </div>
                    </div>
                </div>
            </div>
        </div>

</div>
<div *ngIf="information" class="graph-informations">
    <!-- <div>
        {{"totalProductionForPeriod" | translate}}: {{information.productionTotal? information.productionTotal.toFixed(3) : (0).toFixed(0)}} {{"kWh" | translate}} - {{"totalConsumptionForPeriod" | translate}}: {{information.consumptionTotal? information.consumptionTotal.toFixed(3) : (0).toFixed(0)}} {{"kWh" | translate}}
    </div> -->
</div>

<div class="loader" *ngIf="loading">
    <div class="loader-item"></div>
</div>