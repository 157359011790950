import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { ISelectOption } from 'src/app/libs/input/facades';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { IPaginationInput } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { TranslationService } from '../../../translation/services/translation.service';
import { MyOverlayRef } from '../../overlay/myoverlay-ref';
import { ICreateCountWidget } from '../facades/interfaces/createWidget.interface';

/**
 * Config component to create Count Widget
 */
@Component({
  selector: 'app-count-config',
  templateUrl: './count-config.component.html',
  styleUrls: ['./count-config.component.scss']
})
export class CountConfigComponent implements OnInit {

  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** Datas from edit modal */ @Input() public editDatas: any;
  /** FormGroup of count */ public countFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;
  /** Options of the select top */ periodOptions: ISelectOption[] = [];
  /** Installation to select */ public installationsToSelect: ISearchSelectItem[] = [];
  /** Subject to listen for search with compare string */ public searchInstallationsSubject = new Subject<string>();
  /** Pagination used on search installations */ public pagination: IPaginationInput = { page: 1, limit: 20 };
  /** getter for controls of count form group */ get formControls() { return this.countFormGroup.controls; }
  /** Pagination used on table */ public paginationInput: IPaginationInput = { page: 1, limit: 100 };
  /** Icon for closing modal */ public faTimes: IconDefinition = faTimes;
  /** selcted installation */ public installationSelected: any = [];

  /**
   * constructor
   * @param _translationSrv the translation service
   * @param _fb the form builder
   * @param _errorMessageSrv the error message service
   * @param _installationQueriesSrv the installation queries service
   */
  constructor(
    private _translationSrv: TranslationService,
    private _fb: FormBuilder,
    protected _errorMessageSrv: ErrorMessageService,
    private _installationQueriesSrv: InstallationQueriesService,
  ) {
    this.inputErrorsLabelMap = new Map<string, string>([
      ["title", this._translationSrv.getInstant("installation_power-input")],
      ["period", this._translationSrv.getInstant("installation_power-input")],
      ["cols", this._translationSrv.getInstant("installation_power-input")],
      ["rows", this._translationSrv.getInstant("installation_power-input")],
      ["offset", this._translationSrv.getInstant("installation_power-input")],
      ["installationItem", this._translationSrv.getInstant("installation_power-input")],
      ["correctionMultiplier", this._translationSrv.getInstant("installation_power-input")],
    ]);
  }

  /**
   * On Init method
   */
  ngOnInit(): void {
    this.initFormGroup();
  }

  /**
   * Init form group of count config
   */
  public initFormGroup(): void {
    var d = new Date();
    d.setHours(23,59,59,59);

    const presetDatas = this.editDatas && this.editDatas.widgetDataConfig? this.editDatas.widgetDataConfig : null;
    this.countFormGroup = this._fb.group({
      title: [presetDatas? presetDatas.title : "", Validators.required],
      period: [presetDatas? presetDatas.date : d.getTime(), Validators.required],
      periodHour: [presetDatas? presetDatas.hour : null, Validators.required],
      cols: [this.editDatas && this.editDatas.cols != null? this.editDatas.cols : 2, Validators.required],
      rows: [this.editDatas && this.editDatas.rows != null? this.editDatas.rows : 2, Validators.required],
      counterNumber: [presetDatas? presetDatas.counterNumber : null],
      offset: [presetDatas && presetDatas.offset !== 0 ? presetDatas.offset : 0],
      correctionMultiplier: [presetDatas && presetDatas.correctionMultiplier !== 0 ? presetDatas.correctionMultiplier : 1],
      installationItem: [[], Validators.required],
    });

    if (presetDatas) {
      this._presetInstallationsDatas(presetDatas.installationsIds)
    }

    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this.periodOptions.push({
          label: "0" + i + ":00",
          value: i * 3600000
        })
      } else {
        this.periodOptions.push({
          label: i + ":00",
          value: i * 3600000
        })
      }
    }
    this.instanciatelistener()
  }

  /**
   * preset the installations datas
   * @param installationsIds the installations ids
   */
  private _presetInstallationsDatas(installationsIds: number[]){
    this._installationQueriesSrv.getInstallationsByIds(installationsIds).subscribe((res: any) => {
      const { getInstallationsByIds } = res.data;
      if (getInstallationsByIds) {
        this.installationSelected = getInstallationsByIds.map(installation => {
          return {
            id: installation.id,
            label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
            data: installation
          }
        })
        this.countFormGroup.get("installationItem").patchValue(this.installationSelected);
      }
    }, error => {
      console.log(error);
    })
  }

  /**
   * Validate form and do query to get computed datas from form values
   * @returns
   */
  public validateForm(duplicate: boolean = false): void {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.countFormGroup, this.inputErrorsLabelMap);

    if (this.countFormGroup.invalid) {
      console.log("count-config.component.ts: countFormGroup is invalid.");
      return;
    }
    const countToCreate: ICreateCountWidget = {
      cols: this.countFormGroup.value.cols,
      rows: this.countFormGroup.value.rows,
      widgetDataConfig: {
        title: this.countFormGroup.get("title").value ? this.countFormGroup.get("title").value : "Default title of count",
        finalTimestamp: this.countFormGroup.get("period").value + this.countFormGroup.get("periodHour").value - 86399000,
        date: this.countFormGroup.get("period").value,
        hour: this.countFormGroup.get("periodHour").value,
        installationsIds: this.installationSelected.map(elem => elem.id),
        counterNumber: this.countFormGroup.get("counterNumber").value,
        offset: this.countFormGroup.get("offset").value ? this.countFormGroup.get("offset").value : 0,
        correctionMultiplier: this.countFormGroup.get("correctionMultiplier").value !== 0 ? this.countFormGroup.get("correctionMultiplier").value : 0
      }
    }
    console.log("countToCreate", countToCreate);
    if(this.editDatas && this.editDatas.id && !duplicate) countToCreate['id'] = this.editDatas.id;
    this.close(countToCreate);
  }

  /**
   * Close the modal and send a value to listeners
   * @param value
   */
  public close(value: any) {
    this.ref.close(value);
  }

  /**
   * instanciate listener
   */
  private instanciatelistener() {
    this.countFormGroup.get("installationItem").valueChanges.subscribe(res => {
      this.installationSelected = res && res[0]? res : [];
    });
  }

  /**
   * Search a palamede installation based on search params (q)
   * @param q the search string to find the installation
   * @param idsSelected the installation ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the installation results
   */
  public searchInstallations = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._installationQueriesSrv.searchInstallations({ page: 1, limit: 1000 }, q).toPromise();
    let installations: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getInstallations.installations && (<any>resultQuery.data).getInstallations.installations)
      installations = (<any>resultQuery.data).getInstallations.installations

    installations = installations.map((installation: any) => {
      return {
        id: installation.id,
        label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
        data: installation
      };
    })
    return installations;
  }
}
