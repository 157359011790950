<div class="search-select-parent search-select" [class.multiSelect]="!maxSelected || maxSelected > 1"  (appClickOutside)="closeDropdown()">
    
    <div class="selectedItems" *ngIf="optionsSelected.length > 0 && (!maxSelected || maxSelected > 1) ">
        <ng-container *ngFor="let item of optionsSelected">
            <div class="selectedItems__item" data-value="item.id" >
                {{item.label}}
                <fa-icon *ngIf=canEdit class="selectedItems__item-icon" [icon]="faTimes" (click)="deleteSelectedItem(item)"></fa-icon>
            </div>
        </ng-container>
    </div>

    <div class="selectedItems" *ngIf="currentItem && maxSelected && maxSelected == 1">
        <div class="selectedItems__item" data-value="item.id" >
            {{currentItem.label}}
            <fa-icon *ngIf=canEdit class="selectedItems__item-icon" [icon]="faTimes" (click)="deleteSelectedItem(currentItem)"></fa-icon>
        </div>
    </div> 

    <div *ngIf="(!maxSelected || optionsSelected.length < maxSelected || (maxSelected == 1 && !this.currentItem)) && canEdit " class="search-select" [ngClass]="{'search-select__open': isOpen }">
        <fa-icon class="dropdownIcon" [icon]="faDropdown" [ngClass]="{'dropdown_open': isOpen }" (click)="toggleDropdown()"></fa-icon>
        <app-text-input formControlName="searchName" [group]="searchFormGroup" [name]="'searchName'" [id]="id" [placeholder]="'searchSelectFormInput' | translate" [autocomplete]="'off'" class="searchInput" (click)="toggleDropdown(true)"></app-text-input>
    </div>

    <div *ngIf="optionsDisplayed.length > 0" class="menu" [ngClass]="{'menu__open': isOpen }">
        <ng-container *ngFor="let item of optionsDisplayed">
            <div class="item" data-value="item.id" (click)="onItemClick(item)">{{item.label}}</div>
        </ng-container>
    </div> 
    <div *ngIf="optionsDisplayed.length === 0" class="menu" [ngClass]="{'menu__open': isOpen }">
        <div class="item">{{ "searchSelectFormNoOption" | translate }}</div>
    </div>     

</div>
