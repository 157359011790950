<div class="companies-add-edit">
  <div class="side-panel" id="edit-box">
    <h1 class="side-panel__header">
      {{ titleKey | translate }}
    </h1>
    <div class="side-panel__content">
      <nav class="nav">
        <div class="nav-item" (click)="navChanged('install')">Production</div>
        <div *ngIf="currentInstallationConverters" class="nav-item" style="margin-left: 1rem;" (click)="navChanged('alarms')">Alarmes</div>
      </nav>
      <div [ngClass]="{'visible': selectedPart === 'install', 'hidden': selectedPart === 'alarms'}">
        <div class="grid grid_col_1 box gap_top">
          <h4 class="title">{{ 'installationPalamedeSearchTitle' | translate }}</h4>
          <div class="grid gid_col_2">
            <div>
              <app-label>{{
                "addInstallationPalamedeFormLabel" | translate
              }}</app-label>
              <app-search-select
                [query]="searchInstallations"
                formControlName="installationItem"
                [group]="installationsFormGroup"
                [name]="'installationItem'"
                id="installationItem"
                [selected]="selectedInstallations"
                [maxSelected]="1"
                [currentItem]="currentInstallation"
                [canEdit]=canEdit
                [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.installationItem }">
              </app-search-select>
              <div *ngIf=" isSubmitted && inputErrorsLabel && inputErrorsLabel.installationItem " class="invalid-label">
                {{ inputErrorsLabel.installationItem.msg | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid_col_1" class="box gap_top">
          <h4 class="title">{{ "generalInfosTitle" | translate }}</h4>
          
          <div *ngIf=" installationsFormGroup.get('installationItem').value && installationsFormGroup.get('installationItem').value[0]" class="label-gap-top">
            <app-label>{{ "installationAddressTitle" | translate }}</app-label>
            <span class="addressinfos">{{ getInstallationAddress(installationsFormGroup.get("installationItem").value[0].data ) }}</span>
          </div>
          
          <div class="grid grid_col_2">
            <!-- First column -->
            <div> 

              <div class="label-gap-top">
                <app-label>{{ "name" | translate }} *</app-label>
                <app-text-input formControlName="name" [group]="installationsFormGroup" [name]="'name'" id="name" 
                  [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.name }">
                </app-text-input>
                <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.name" class="invalid-label">
                  {{ inputErrorsLabel.name.msg | translate }}
                </div>
              </div>

              <div class="label-gap-top">
                <app-label>{{ "location" | translate }}</app-label>
                <app-text-input formControlName="location" [group]="installationsFormGroup" [name]="'location'" id="location" 
                  [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.location }">
                </app-text-input>
                <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.location" class="invalid-label">
                  {{ inputErrorsLabel.location.msg | translate }}
                </div>
              </div>

              <div class="label-gap-top">
                <app-label>{{ "contact_one" | translate }}</app-label>
                <app-text-input formControlName="contact_one" [group]="installationsFormGroup" [name]="'contact_one'" id="contact_one" 
                  [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.contact_one }">
                </app-text-input>
                <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.contact_one" class="invalid-label">
                  {{ inputErrorsLabel.contact_one.msg | translate }}
                </div>
              </div>

              <div class="label-gap-top">
                <app-label>{{ "counter_brand" | translate }}</app-label>
                <app-text-input formControlName="counter_brand" [group]="installationsFormGroup"
                  [name]="'counter_brand'" id="counter_brand" [ngClass]="{ 'invalid-input': isSubmitted && formControls.counter_brand.errors }"></app-text-input>
                <div *ngIf=" isSubmitted && inputErrorsLabel && inputErrorsLabel.counter_brand " class="invalid-label">
                  {{ inputErrorsLabel.counter_brand.msg | translate }}
                </div>
              </div>
            </div>
            
            <!-- Second column -->
            <div> 

              <div class="grid grid_col_2">
                <div>
                  <div class="label-gap-top">
                    <app-label>{{ "positionY" | translate }} *</app-label>
                    <app-number-input [min]="0" [step]="0.01" formControlName="positionY" [group]="installationsFormGroup"
                      [name]="'positionY'" id="positionY" [ngClass]="{'invalid-input':isSubmitted && formControls.positionY.errors}"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.positionY " class="invalid-label">
                      {{ inputErrorsLabel.positionY.msg | translate }}
                    </div>
                  </div>

                  <div class="label-gap-top">
                    <app-label>{{ "installation_date" | translate }}</app-label>
                    <app-calendar-input
                      (onDateChanges)="dateChanges($event)"
                      formControlName="installation_date"
                      [group]="installationsFormGroup"
                      [name]="'installation_date'"
                      [value]="installationsFormGroup.get('installation_date').value"
                      id="installation_date"
                      [ngClass]="{
                        'invalid-input':
                          isSubmitted && formControls.installation_date.errors
                      }"
                      [canEdit]=canEdit
                    ></app-calendar-input>
                    <div
                      *ngIf="
                        isSubmitted &&
                        inputErrorsLabel &&
                        inputErrorsLabel.installation_date
                      "
                      class="invalid-label"
                    >
                      {{ inputErrorsLabel.installation_date.msg | translate }}
                    </div>
                  </div>
                </div>

                <div>
                  <div class="label-gap-top">
                    <app-label>{{ "positionX" | translate }} *</app-label>
                    <app-number-input [min]="0" [step]="0.01" formControlName="positionX" [group]="installationsFormGroup"
                      [name]="'positionX'" id="positionX" [ngClass]="{'invalid-input':isSubmitted && formControls.positionX.errors}"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.positionX " class="invalid-label">
                      {{ inputErrorsLabel.positionX.msg | translate }}
                    </div>
                  </div>
                  <div class="label-gap-top">
                    <app-label>{{ "power" | translate }} (kWc)*</app-label>
                    <app-number-input [min]="0" [step]="0.01" formControlName="power" [group]="installationsFormGroup"
                      [name]="'power'" id="power" [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.power }"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.power" class="invalid-label">
                      {{ inputErrorsLabel.power.msg | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="label-gap-top">
                <app-label>{{ "contact_two" | translate }}</app-label>
                <app-text-input formControlName="contact_two" [group]="installationsFormGroup" [name]="'contact_two'" id="contact_two" 
                  [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.contact_two }">
                </app-text-input>
                <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.contact_two" class="invalid-label">
                  {{ inputErrorsLabel.contact_two.msg | translate }}
                </div>
              </div>

              <div class="label-gap-top">
                <app-label>{{ "serialNumberInstallation" | translate }} *</app-label>
                <app-text-input formControlName="serialNumber" [group]="installationsFormGroup" [name]="'serialNumber'"
                  id="serialNumber" [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.serialNumber }">
                </app-text-input>
                <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.serialNumber" class="invalid-label">
                  {{ inputErrorsLabel.serialNumber.msg | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="label-gap-top">
            <app-label>{{ "information" | translate }}</app-label>
            <app-textarea-input
              [group]="installationsFormGroup"
              [name]="'information'"
              id="information"
            ></app-textarea-input>
            <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.information" class="invalid-label">
              {{ inputErrorsLabel.information.msg | translate }}
            </div>
          </div>
        </div>

        <!-- <div *ngIf=" installationsFormGroup.get('installationItem').value && installationsFormGroup.get('installationItem').value[0] "
          class="grid grid_col_1 box gap_top">
          <h4 style="margin-bottom: 1rem"> {{ "installationAddressTitle" | translate }} </h4>
          <span class="addressinfos">{{ getInstallationAddress(installationsFormGroup.get("installationItem").value[0].data ) }}</span>
        </div> -->

        <div
          *ngIf="
            installationsFormGroup.get('installationItem').value &&
            installationsFormGroup.get('installationItem').value[0]
          "
          class="grid grid_col_1 box gap_top"
        >
          <app-converters-list
            *ngIf="currentInstallationConverters"
            [isSubmitted]="isSubmitted"
            [converters]="currentInstallationConverters"
            (convertersListChanged)="convertersListChanged($event)"
            (isValid)="converterListValidityChanged($event)"
            [validitySubject]="validityStatusSubject"
            [canEdit]=canEdit
          ></app-converters-list>
        </div>
        <app-linked-users *ngIf="canEdit" (usersChanged)="linkedUsersChanged($event)" [selectedUsers]="formattedSelectedUsers" [canEdit]=canEdit></app-linked-users>
        <app-linked-comment *ngIf="(canEdit || comments.length > 0) && installationId" [commentList]="comments" (commentChanged)="commentChanged($event)" [canEdit]=canEdit></app-linked-comment>
        <app-linked-files *ngIf="(canEdit || files.length > 0) && installationId" [filesList]="files" (filesChanged)="filesChanged($event)" [canEdit]=canEdit></app-linked-files>
        <div
          *ngIf="error !== ''"
          class="grid grid_col_1"
          style="margin-bottom: 2em"
        >
          <div>
            <app-label>{{ "errorLabel" | translate }} *</app-label>
            <h3>{{ error }}</h3>
          </div>
        </div>
      </div>

      <app-alarms
        [ngClass]="{'visible': selectedPart === 'alarms', 'hidden': selectedPart === 'install'}"
        [installationId]="installationId"
        [alarms]="currentInstallationAlarms"
        [converters]="currentInstallationConverters"
        [isDisplayed]="selectedPart === 'alarms'"
        (alarmsListChanged)="alarmsListChanged($event)"
        (isValid)="alarmListValidityChanged($event)"
        [validitySubject]="validityStatusSubject"
        [isSubmitted]="isSubmitted"
      >
      </app-alarms>
      
      
      <div class="grid grid_col_2" style="padding: 2rem">
        <app-button
          [action]="__TYPE_BUTTON.DELETE"
          (click)="goTo(pagesEnum.MAIN)"
          >{{ "cancel" | translate }}</app-button
        >
        <app-button *ngIf="canEdit" [action]="__TYPE_BUTTON.VALID" (click)="validateForm()">{{
          "save" | translate
        }}</app-button>
      </div>
    </div>
  </div>
</div>