<div class="box gap_top">
    <h4 class="title">{{ "linkedConsumptions-title" | translate }}</h4>
    <div class="grid grid_col_2" *ngIf="allowNewConsumption == true">
        <div class="add-consumptions__select">
            <app-label>{{ "addConsumptionFormLabel" | translate }}</app-label>
            <app-search-select [group]="formGroup" formControlName="consumptionItem"
                [query]="searchConsumptions"  
                [name]="'consumptionItem'" 
                id="consumptionItem" 
                [selected]="selectedForSearch" 
                [maxSelected]="1"
                [resetSelected]="resetSelected"></app-search-select>
            <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.consumptionItem" class="invalid-label">
                {{ inputErrorsLabel.consumptionItem.msg | translate }}
            </div>
        </div>
        <div class="add-consumptions__validate">
            <div class="add-consumptions__validate_icon">
                <fa-icon [icon]="faPlusCircle" (click)="addConsumption()"></fa-icon>
            </div>
        </div>
    </div>

    <div *ngIf="selectedConsumptions.length > 0" class="grid grid_col_1" style="margin-top: 2em">
        <app-table style="margin-top: -2em" 
            [columns]="columns" 
            [contents]="contents" 
            [smallPadding]="true"
            [withPagination]="false" 
            [paginationResult]="paginationResult" 
            (tableAction)="tableActionClicked($event)"
            (pageChangeEmitter)="onPageChange($event)"></app-table>
    </div>
</div>

<ng-template #tplDeleteConsumptionToLink>
    <app-modal-item [ref]="ref" [config]="modalConfig"></app-modal-item>
</ng-template>