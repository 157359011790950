import { Component, OnInit, TemplateRef, Type } from '@angular/core';
import { MODAL_IN_ANIMATION } from 'src/app/libs/animations/modalIn.animation';
import { MyOverlayRef } from '../myoverlay-ref';

/**
 * Overlay component for modal component
 */
@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  animations: [MODAL_IN_ANIMATION],
  host: { '[@modalInAnimation]': ''}
})
export class OverlayComponent implements OnInit {
  /** Type of the content that will be displayed in the body of the modal */ public contentType: 'template' | 'component' | 'string';
  /** Content that will be displayed in the body of the modal */ public content: TemplateRef<any> | Type<any> | string | any;
  /** Context on which will be bind the close method */ public context: any;

  /**
   * Constructor method.
   * @param _ref 
   */
  constructor(private _ref: MyOverlayRef) { }

  /**
   * Close the modal
   */
  close() {
    this._ref.close(null);
  }

  /**
   * On init method that set the content type using content
   */
  ngOnInit(): void {
    this.content = this._ref.content;

    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this._ref.close.bind(this._ref)
      }
    } else {
      this.contentType = 'component';
    }
  }

}
