<div class="modal_first">
  <div class="modal-card">
    <section class="modal-card-head">
      <ng-container>
        <div>{{ "note-title" | translate}}</div>
        <fa-icon class="modal-card-head__close" [icon]="faTimes" (click)="close('close')"></fa-icon>
      </ng-container>
    </section>
    <section class="modal-card-body">
      <app-label>{{ "title" | translate}}</app-label>
      <app-text-input
      [group]="commentFormGroup"
      [name]="'title'"
      id="title"
      ></app-text-input>
      <app-label>{{ "description-comment" | translate}}</app-label>
      <app-textarea-input
      [group]="commentFormGroup"
      [name]="'description'"
      id="description"
      ></app-textarea-input>
      <app-label>{{ "pictures-comment" | translate}}</app-label>
      <div style="display: flex; gap: 1rem;">
        <app-img-input (sendImg)="onNewFile($event)"></app-img-input>
        <div *ngFor="let file of fileArray">
          <app-img-block [file]=file (fileTouched)=onFileAction($event) >
          </app-img-block>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div>
        <ng-container>
          <app-button (click)="validateForm()">{{ validateTranslateKey | translate}}</app-button>
        </ng-container>
      </div>
    </footer>
  </div>
</div>
