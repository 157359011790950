import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsumptionsComponent } from './consumptions/consumptions.component';
import { ConsumptionsRoutingModule } from './consumptions.routing.module';
import { InputModule } from 'src/app/libs/input/input.module';
import { ButtonsModule } from 'src/app/libs/buttons/buttons.module';
import { TableModule } from 'src/app/libs/table/table.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'src/app/libs/modal/modal.module';
import { SearchSelectModule } from 'src/app/libs/search-select/search-select.module';
import { TranslationModule } from 'src/app/libs/translation/translation.module';
import { AddEditConsumptionsComponent } from './add-edit-consumptions/add-edit-consumptions.component';
import { ConsumptionProfilesPalamedeQueriesService } from 'src/app/queries/consumptionProfilePalamede/consumption-profile-palamede-queries.service';
import { LinkedModule } from '../linked/linked.module';

@NgModule({
  imports: [
    CommonModule,
    ConsumptionsRoutingModule,
    InputModule,
    ButtonsModule,
    TableModule,
    FontAwesomeModule,
    ModalModule,
    SearchSelectModule,
    TranslationModule,
    LinkedModule
  ],
  declarations: [
    ConsumptionsComponent,
    AddEditConsumptionsComponent
  ],
  exports: [
    ConsumptionsComponent,
    AddEditConsumptionsComponent
  ],
  providers: [
    ConsumptionProfilesPalamedeQueriesService
  ]
})
export class ConsumptionsModule {}
