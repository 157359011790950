import { Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltersComponent } from './filters/filters.component';
import { FilterCalendarComponent } from './filter-calendar/filter-calendar.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { FilterRangeDateComponent } from './filter-range-date/filter-range-date.component';
import { InputModule } from '../input/input.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    FiltersComponent,
    FilterCalendarComponent,
    FilterSelectComponent,
    FilterRangeDateComponent,
    
  ],
  imports: [
    InputModule,
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    FiltersComponent
  ]
})
export class FiltersModule { }
