<div class="login_page"> 
  <div class="login_page__form">
    <img
      src="../../../../assets/img/login/login_logo.png"
      alt="Logo IDETA"
      class="login_page__form-logo"
    />
    <p>{{ "ideta_description" | translate }}</p>

    <app-label>{{ "email" | translate }}</app-label>
    <app-text-input
      [placeholder]="'placeholder_mail' | translate"
      formControlName="mail"
      [group]="loginFormGroup"
      [name]="'mail'"
      id="mail"
      [ngClass]="{ 'invalid-input': isSubmitted && formControls.mail.errors }"
    ></app-text-input>
    <app-label>{{ "password" | translate }}</app-label>
    <app-text-input
      [placeholder]="'placeholder_password' | translate"
      formControlName="password"
      [group]="loginFormGroup"
      [name]="'password'"
      id="password"
      [ngClass]="{
        'invalid-input': isSubmitted && formControls.password.errors
      }"
      [isPassword]="true"
    ></app-text-input>
    <h5 *ngIf="error.isError">{{ error.message | translate }}</h5>
    <app-button
      [action]="TYPE_BUTTON.DARK_PRIMARY"
      size="regular"
      (keyup.enter)="validateForm()"
      (click)="validateForm()"
      >Connexion</app-button
    >

    <a href="/login/recover">Mot de passe oublié ?</a>
    <div class="login_page__partner">
      <img class="login_page__partner-logo" src="../../../../assets/img/DW-Smartregion.png" alt="Logo Digital Wallonia smartregion"  />
      <img class="login_page__partner-logo" src="../../../../assets/img/soutien_r_fr.png" alt="Logo Avec le soutien de la Wallonie"  />
    </div>
  </div>
  <div class="login_page__bg">
    
  </div>
</div>
