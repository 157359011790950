<div class="modal-card">
    <section class="modal-card-head">
        <div>{{ 'widgetConfig' | translate }}</div>
        <fa-icon class="modal-card-head__close" [icon]="faTimes" (click)="close(false)"></fa-icon>
    </section>
    <section class="modal-card-body" style="min-height: 30rem;">
        <div>
            <div class="grid grid_col_1">
                <div>
                    <app-label>{{ "chooseProductionProfile" | translate}} *</app-label>
                    <app-search-select [group]="graphFormGroup" formControlName="installationItem"
                        [query]="searchInstallations" [name]="'installationItem'" id="installationItem"
                        [selected]="installationSelected"></app-search-select>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.installationItem"
                        class="invalid-label">
                        {{ inputErrorsLabel.installationItem.msg | translate}}
                    </div>
                </div>
            </div>
            <div class="grid grid_col_2 gap_top">
                <div>
                    <app-label>{{ "title" | translate}} *</app-label>
                    <app-text-input formControlName="title" [group]="graphFormGroup" [name]="'title'" id="title"
                        [ngClass]="{ 'invalid-input': isSubmitted && formControls.title.errors}"></app-text-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.title" class="invalid-label">
                        {{ inputErrorsLabel.title.msg | translate}}
                    </div>
                </div>
                <div>
                    <app-label>{{ "period" | translate}} *</app-label>
                    <app-select-input [options]="graphConfigSrv.periodOptions" [autoSelectFirst]="true" formControlName="period"
                        [group]="graphFormGroup" [name]="'period'" id="period"
                        [ngClass]="{ 'invalid-input': isSubmitted && formControls.period.errors}">
                    </app-select-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.period" class="invalid-label">
                        {{ inputErrorsLabel.period.msg | translate}}
                    </div>
                </div>
            </div>
            <div class="grid grid_col_2 gap_top">
                <div>
                    <app-label>{{ "cols" | translate}} *</app-label>
                    <app-number-input [min]="4" [step]="1" formControlName="cols" [group]="graphFormGroup"
                        [name]="'cols'" id="cols"
                        [ngClass]="{ 'invalid-input': isSubmitted && formControls.cols.errors}"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.cols" class="invalid-label">
                        {{ inputErrorsLabel.cols.msg | translate}}
                    </div>
                </div>
                <div>
                    <app-label>{{ "rows" | translate}} *</app-label>
                    <app-number-input [min]="4" [step]="1" formControlName="rows" [group]="graphFormGroup"
                        [name]="'rows'" id="rows"
                        [ngClass]="{ 'invalid-input': isSubmitted && formControls.rows.errors}"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.rows" class="invalid-label">
                        {{ inputErrorsLabel.rows.msg | translate}}
                    </div>
                </div>
            </div>
            <div>
                <app-label>{{ "co2PerKwH" | translate}} *</app-label>
                <app-number-input [min]="1" [step]="1" formControlName="co2Level" [group]="graphFormGroup"
                    [name]="'co2Level'" id="co2Level"
                    [ngClass]="{ 'invalid-input': isSubmitted && formControls.co2Level.errors}"></app-number-input>
                <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.co2Level" class="invalid-label">
                    {{ inputErrorsLabel.cols.msg | translate}}
                </div>
            </div>
            <div class="gap_top">
                <app-label>{{ "TransportConversion" | translate }} *</app-label>
                <div class="grid grid_col_4" style="padding-left: 2rem;">
                    <div>
                        <app-label>{{ "bike" | translate }}</app-label>
                        <app-checkbox-input formControlName="displayBike" [group]="graphFormGroup" [name]="'displayBike'"
                            id="displayBike"
                            [ngClass]="{ 'invalid-input': isSubmitted && formControls.displayBike.errors}"></app-checkbox-input>
                    </div>
                    <div>
                        <app-label>{{ "walk" | translate }}</app-label>
                        <app-checkbox-input formControlName="displayWalk" [group]="graphFormGroup" [name]="'displayWalk'"
                            id="displayWalk"
                            [ngClass]="{ 'invalid-input': isSubmitted && formControls.displayWalk.errors}"></app-checkbox-input>
                    </div>
                    <div>
                        <app-label>{{ "train" | translate }}</app-label>
                        <app-checkbox-input formControlName="displayTrain" [group]="graphFormGroup" [name]="'displayTrain'"
                            id="displayTrain"
                            [ngClass]="{ 'invalid-input': isSubmitted && formControls.displayTrain.errors}"></app-checkbox-input>
                    </div>
                    <div>
                        <app-label>{{ "bus" | translate }}</app-label>
                        <app-checkbox-input formControlName="displayBus" [group]="graphFormGroup" [name]="'displayBus'"
                            id="displayBus"
                            [ngClass]="{ 'invalid-input': isSubmitted && formControls.displayBus.errors}"></app-checkbox-input>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="modal-card-foot">
        <div>
            <button class="button button_valid" (click)="validateForm()" type="button">{{ "save" | translate }}</button>
            <button class="button button_delete" (click)="close(false)" type="button">{{ "cancel" | translate }}</button>
            <button *ngIf="this.editDatas && this.editDatas.id" class="button button_primary" (click)="validateForm(true)" type="button">{{ "Duplicate" | translate }}</button>
        </div>
    </footer>
</div>