import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditDashboardsComponent } from './add-edit-dashboard/add-edit-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ListDashboardComponent } from './list-dashboard/list-dashboard.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: ListDashboardComponent,
    children: [
      { path: 'add', component: AddEditDashboardsComponent },
      { path: 'edit/:id', component: AddEditDashboardsComponent },
    ],
  },
  {
    path: 'dashboard/show/:id',
    component: DashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
