import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ProfileModule } from '../profile/profile.module';
import { MenuModule } from '../menu/menu.module';
import { ExportModule } from '../export/export.module';

@NgModule({
  declarations: [
    SidenavComponent
  ],
  imports: [
    CommonModule,
    ProfileModule,
    ExportModule,
    MenuModule
  ],
  exports: [
    SidenavComponent
  ]
})
export class SidenavModule { }
