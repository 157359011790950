import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportComponent } from './export/export.component';
import { TranslationModule } from '../translation/translation.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { ExportConfigComponent } from './export-config/export-config.component';
import { InputModule } from '../input/input.module';
import { SearchSelectModule } from '../search-select/search-select.module';

@NgModule({
  declarations: [
    ExportComponent,
    ExportConfigComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    ButtonsModule,
    InputModule,
    SearchSelectModule
  ],
  exports: [
    ExportComponent
  ]
})
export class ExportModule { }
