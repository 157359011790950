import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { faCircle, faSort, faTrashAlt, faEdit, IconDefinition, faPlusCircle, faUndo } from "@fortawesome/free-solid-svg-icons";
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { EnumCommentAction } from 'src/app/libs/comment/facades/enums/commentAction.enum';
import { ICommentAction } from 'src/app/libs/comment/facades/interfaces/commentAction.interface';
import { IComment } from '../../comment/facades/interfaces/comment.interface';
import { FormGroup } from '@angular/forms';
import { AlertService } from 'ngx-alerts';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { ICommentConfig } from './interfaces/comment.interface';
import { environment } from '../../../../environments/environment';
import { InstallationQueriesService } from '../../../queries/installation/installation-queries.service';
import { DateTime, DurationObject } from 'luxon';

/**
 * linked comment
 */
@Component({
  selector: 'app-linked-comment',
  templateUrl: './linked-comment.component.html',
  styleUrls: ['./linked-comment.component.scss']
})
export class LinkedCommentComponent implements OnInit {
    /** FormGroup for add a linked comment */ public formGroup: FormGroup;
    /** title (note) */ public titleNote: string = "note-title";
    /** Add button icon */ public faPlusCircle: IconDefinition = faPlusCircle;
    /** Font awesome icon for delete */ public faTrashAlt: IconDefinition = faTrashAlt;
    /** Font awesome icon for edit */ public faUndo: IconDefinition = faUndo;
    /** Template of modal */ @ViewChild('tplAddEditComment') modalAddEditTemplate: TemplateRef<any>;
    /** Template of modal */ @ViewChild('tplDeleteComment') modalDeleteTemplate: TemplateRef<any>;
    /** Modal configuration */ public modalConfig: IModalItemConfig = {}
    /** Modal Delete configuration */ public modalConfigDelete: IModalItemConfig = {}
    /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
    /** boolean true if the user can edit the installation otherwise false */ @Input() public canEdit: boolean = true

    /** EventEmitter when the array of comments linked changed */ @Output("commentChanged") public commentChanged: EventEmitter<ICommentConfig[]> = new EventEmitter();

    /** Comment to open into the modal (edit or delete) */ commentToOpen: ICommentConfig = null;
    /** Array of all comments */ @Input() public commentList: ICommentConfig[] = [];
    /** map of note taking as key a note and as value an array or image url */ public imgsUrl: Map<Object, string[]> = new Map<Object, string[]>()


  /**
   * constructor
   * @param _overlayService the overlay service
   */
  constructor(
    private _overlayService: OverlayService,
  ) { }

  /**
   * on init
   */
  ngOnInit(): void {
    this.getNotesFiles(this.commentList, this)
    this.commentChanged.emit(this.commentList)
  }

  /**
   * on change
   * @param changes the changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.commentList && changes.commentList.currentValue && changes.commentList.currentValue.length > 0 && changes.commentList.currentValue !== changes.commentList.previousValue) {
      this.commentList = changes.commentList.currentValue;
      this.changeCommentList()
      this.commentList.forEach(elem => {
        let onlyDate = (<any>elem).createdAt.slice(0, 10)
        let splitted = onlyDate.split("-", 3);
        elem.created_at = splitted[2] + '-' + splitted[1] + '-' + splitted[0]
      });
    }
  }

  /**
   * Handle the action triggered by a user click on a comment component
   */
  public commentActionClicked(): void{
    this.commentToOpen = null;
    this.open(this.modalAddEditTemplate)
  }

  /**
   * Open modal with content
   * @param content
   */
  public open(content: TemplateRef<any>){
    this.modalConfig = {
      title: "",
      body: ""
    }
    this.ref = this._overlayService.open(content, null)
    this.ref.afterClosed.subscribe(async res => {
      if(res && res.data && res.data.valid){
        switch (res.data.type) {
          case "edit":
            this.commentList[this.commentList.indexOf(this.commentToOpen)] = {
              title: res.data.value.title,
              description: res.data.value.description,
              id : this.commentToOpen.id,
              created_at: this.commentToOpen.created_at,
              updated_at: this.commentToOpen.updated_at,
              deleted_at: this.commentToOpen.deleted_at,
              itemId: this.commentToOpen.itemId,
            }
            break;
          case "add":
            const date = DateTime.now()
            res.data.value.created_at = date.day + "-" + date.month + "-" + date.year
            this.commentList.push(res.data.value)
            this.changeCommentList();
            this.commentChanged.emit(this.commentList)
            break;
        }

        // Ajouter la note dans le tableau / editer la note dans le tableau / supprimer la note du tableau
        // Utiliser le Output Emmiter pour renvoyer le tableau complet au composant add-edit-installation
        // Dans add-edit-installation ajouter le tableau des notes dans la sauvegarde de l'installation
        // toEdit : boolean

      }
    })
  }

  /**
   * Called when there is changes on comment.
   */
  changeCommentList(){
    this.imgsUrl = new Map<Object, string[]>();
    this.commentList.forEach(async comment => {

      if (typeof comment.files === "string" && comment.files !== "") {
        await this.getNotesFiles(comment, this);
      } else if (Array.isArray(comment.files)) {
        await this.readUrl(comment, this);
      }
    });
  }

  /**
   * on delete clicked
   * @param comment the comment to delete
   * @returns void
   */
   commentDeleteClicked(comment: ICommentConfig){
     if (!comment.id) {
      const index = this.commentList.indexOf(comment);
      if (index > -1) {
        this.commentList.splice(index, 1);
      }
      this.commentChanged.emit(this.commentList);
      return;
     }
    if (comment.deleted_at) {
      delete comment.deleted_at;
    } else {
      const date = new Date();
      this.commentList[this.commentList.indexOf(comment)].deleted_at = date.toLocaleDateString();
    }
    this.commentChanged.emit(this.commentList)
  }

  /**
   * get the note files
   * @param note the note
   * @param instance to display
   * @returns 
   */
  public async getNotesFiles(note: any, instance: any) {
    let myInit: RequestInit =
    {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'cors',
      cache: 'default'
    };

    if (!note.files) {
      return;
    };

    const paths = await JSON.parse(note.files);
    if (!paths) {
      return
    }

    return await Promise.all(paths.map(async (path) => {
      return new Promise(async (resolve) => {
        const filepath = environment.API_URL_CONTROLLER + "getFile/" + "notes/" + path.substr(environment.UPLOAD_NOTE_IMAGE_PATH.length);

        await fetch(filepath, myInit)
        .then(function(response) {
          if (!response || !response.url) {
            console.error("can't find url of file")
          }
          resolve(response.url)
        })
      })
    }))
    .then(res => {
      instance.imgsUrl.set(note,res)
    })
  }

  /**
   * read an url
   * @param note the note
   * @param instance the instance
   */
  public async readUrl(note: any, instance: any) {
    if (note.files) {
      await Promise.all(note.files.map(async (file) => {
        return new Promise((resolve) => {
          var reader = new FileReader();
          reader.onloadend = function (e) {
            resolve(e.target.result);
          }
          reader.readAsDataURL(file);
        })
      }))
      .then(res => {
        instance.imgsUrl.set(note,res)
      })
    }
  }

  /**
   * open the image in a new tab
   * @param url the image url
   */
  public openImg(url: string){
    window.open(url, '_blank');
  }
}
