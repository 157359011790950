import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditConsumptionsComponent } from './add-edit-consumptions/add-edit-consumptions.component';
import { ConsumptionsComponent } from './consumptions/consumptions.component';

const routes: Routes = [
  {
    path: 'consumptions',
    component: ConsumptionsComponent,
    children: [
      { path: 'add', component: AddEditConsumptionsComponent},
      { path: 'detail/:id', component: AddEditConsumptionsComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ConsumptionsRoutingModule { }
