import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { EnumTableAction, ITableAction, ITableColumn } from 'src/app/libs/table/facades';
import { ISearchConfig } from 'src/app/libs/table/facades/interfaces/tableSearchConfig.interface';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { IPaginationInput, IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { RecQueriesService } from 'src/app/queries/rec/rec-queries.service';
import { USER_ROLES_ENUM } from '../../users/facades/enums/usersRoles.enum';
import { IUser } from '../../users/facades/interfaces/user.interface';
import { IRec } from '../facades/interfaces/rec.interface';

/**
 * Display a list of users
 * 
 * @example 
 * Basic usage 
 * <app-list-rec></app-list-rec>
 */
@Component({
  selector: 'app-list-rec',
  templateUrl: './list-rec.component.html',
  styleUrls: ['./list-rec.component.scss']
})
export class ListRecComponent implements OnInit {
  /** Template of modal to validate deleting of a rec */ @ViewChild('tplDeleteRec', { static: true }) modalTemplate: TemplateRef<any>;

  /** Array of columns for table */ public columns: ITableColumn[] = [
    { key: 'name', type: "text", title: 'Name', sortable: true },
    { key: 'mail', type: "text", title: 'Mail de contact', sortable: true },
    { key: 'actions', type: "action", title: "Actions" }
  ]
  /** Content to display on table */ public content: any[] = [];
  /** Pagination result base for table */
  public paginationResult: IPaginationResult = {
    ...this._recQueriesSrv.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1
  };
  /** Rec to delete */ public recToDelete: any = {};
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
  /** Modal configuration */ public modalConfig: IModalItemConfig = {}
  /** Configuration of search input */ public searchConfig: ISearchConfig = { searchPlaceholderKey: "rec-search-placeholder-list-label" }
  /** Column key which one is sorted by (if sorting is used) */ public sortedBy: string = null;

  /**
   * Constructor method
   * @param {Router} _router 
   * @param {ActivatedRoute} _activedRoute 
   */
  constructor(private _router: Router,
    private _activedRoute: ActivatedRoute,
    private _recQueriesSrv: RecQueriesService,
    private _translateSrv: TranslationService,
    private _overlayService: OverlayService,
    private _alertSrv: AlertService) { }

  /**
   * Called on init
   */
  ngOnInit(): void {
    // this.getRecs(); // onSearch('') is used in order to reset the filter when page is loaded
    this.onSearch('');
  }

  /**
   * Called to navigate to another page
   */
  goTo(page: string) {
    this._router.navigate([page], { relativeTo: this._activedRoute })
  }

  /**
   * Handle the action triggered by a user click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   */
  public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.recToDelete = event.content;
        this.open(this.modalTemplate);
        break;
      case EnumTableAction.EDIT:
        this._router.navigate(['/rec/detail/' + event.content.id], { relativeTo: this._activedRoute });
        break;
      case EnumTableAction.SHOW:
        break;
      case EnumTableAction.SORT:
        if (event && event.content && event.content.key) {
          this._recQueriesSrv.order = {
            key: event.content.key,
            direction: this._recQueriesSrv.order && this._recQueriesSrv.order.key === event.content.key ? (
              this._recQueriesSrv.order.direction === "ASC" ? "DESC" : "ASC") :
              "ASC"
          }
          this.getRecs()
        }
        break;
      default:
        break;
    }
  }

  /**
   * Open modal using content
   * @param content 
   */
  public open(content: TemplateRef<any>) {
    this.modalConfig = {
      title: this._translateSrv.getInstant('modalDeleteRec'),
      body: `${this._translateSrv.getInstant('modalDeleteRecBody', { name: this.recToDelete.name })}`,
      buttons: [
        {
          message: this._translateSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translateSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res.data.value === true) {
        this.onDelete();
      }
    });
  }

  /**
 * Called by table child when page is changed
 * @param event 
 */
  public onPageChange(event: number): void {
    this._recQueriesSrv.pagination = { ...this._recQueriesSrv.pagination, page: event }
    this.getRecs();
  }

  /**
   * Called when a rec is delete.
   */
  public onDelete(): void {
    this._recQueriesSrv
      .deleteRec(this.recToDelete.id)
      .subscribe((result) => {
        if (result.errors && result.errors.length > 0) {
          console.log('error', result.errors);
          this._alertSrv.danger(this._translateSrv.getInstant("errorDeleteRec"));
        } else {
          if ((this.content.length - 1) == 0) {
            if (this.paginationResult.hasPrevious) this._recQueriesSrv.pagination.page = this.paginationResult.page - 1;
            else this._recQueriesSrv.pagination.page = 1;
          }
          this._alertSrv.success(this._translateSrv.getInstant("successDeleteRec"));
        }
      });
  }

  /**
   * Query getting users using pagination of table
   */
  public getRecs(): void {
    this.sortedBy = null;
    this._recQueriesSrv.getRecs().valueChanges.subscribe(async (result) => {
      if (result.errors && result.errors.length > 0) {
        console.log('error', result.errors);
        throw result.errors;
      } else {
        const data: any = <any>result.data;
        if (data && data.getRecs && data.getRecs.recs) {
          this.content = await data.getRecs.recs.map((rec: any) => {
            return {
              id: rec.id,
              name: rec.name,
              mail: rec.mail,
            };
          });
        }
        if (data && data.getRecs && data.getRecs.pagination) {
          this.paginationResult = data.getRecs.pagination;
        }
      }
    });
  }

  
  /**
   * onSearch callback handle when user type in the search field to filter the list
   * @param {string} q the search param type by the user to filter the list
   * @returns {void}
   */
   public onSearch(q: string): void {
    this._recQueriesSrv.pagination = { ...this._recQueriesSrv.pagination, page: 1 }
    this._recQueriesSrv.queryString = q;
    this.getRecs();
  }
}
