import { Injectable } from "@angular/core"
import { Apollo } from "apollo-angular";
import { IPaginationInput } from "../pagination/interfaces/paginationInput.interface";

import GET_PALAMEDE_CONSUMPTION_PROFILES_BY_COMPARE from "./graphql/get-palamede-consumption-profiles-by-compare.graphql"

/**
 * Palamede Consumption Profiles queries service
 */
@Injectable({ providedIn: "root" })
export class ConsumptionProfilesPalamedeQueriesService {

    /**
     * constructor method
     * @param _apollo
     */
    constructor(private _apollo: Apollo) { }

    /**
     * Get installation with a compare string
     * @param name 
     * @returns 
     */
    public async getPalamedeConsumptionProfilesByCompare(compareString?: string, pagination?: IPaginationInput, filter?: any) {
        return await this._apollo.query({
            query: GET_PALAMEDE_CONSUMPTION_PROFILES_BY_COMPARE,
            variables: {
                compareString,
                pagination,
                filter
            },
            fetchPolicy: "no-cache"
        }).toPromise();
    }
}