import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditInstallationsComponent } from './add-edit-installations/add-edit-installations.component';
import { InstallationsComponent } from './installations/installations.component';

const routes: Routes = [
  {
    path: 'installations',
    component: InstallationsComponent,
    children: [
      { path: 'add', component: AddEditInstallationsComponent},
      { path: 'detail/:id', component: AddEditInstallationsComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class InstallationsRoutingModule { }
