<div [formGroup]="group" class="container-input">
  <textarea
      [name]="name"
      [id]="id"
      [attr.disabled]="disable ? '' : null"
      rows={{rows}}
      cols={{cols}}
      [formControlName]="name"
  >
  </textarea>
</div>
