import { Component, OnInit, Renderer2, OnChanges, SimpleChanges, Input } from "@angular/core";
import * as moment from "moment";
import { TranslationService } from "../../translation/services/translation.service";
import { AInputs } from "../facades/input";
import { ErrorMessageService } from "../facades/services/error-message.service";


/**
 * Date input component
 */
@Component({
  selector: "app-date-input",
  templateUrl: "./date-input.component.html",
  styleUrls: ["./date-input.component.scss"]
})
export class DateInputComponent extends AInputs implements OnInit, OnChanges {
  /** true if the date should be hidden */ public isHidden: boolean = true;
  /** the date selected */ public valueDate: string;
  /** the range */ @Input() public range: string = "date";
  /** true if the input should be placed relatively otherwise it's absolute*/ @Input() public relative: boolean = false;

  /**
   * constructor
   * @param renderer the renderer
   * @param _errorMessageSrv the error message service
   * @param _translationSrv the translation service
   */
  constructor(public renderer: Renderer2,
    protected _errorMessageSrv: ErrorMessageService,
    private _translationSrv: TranslationService) {
    super(renderer, _errorMessageSrv);
  }

  /**ngOnInit */
  ngOnInit() {
    if (this.group.get(this.name).value) {
     this.getFormattedDate();
     this.group.get(this.name).valueChanges.subscribe(res => {
      this.getFormattedDate();
    })
    } else {
      this.valueDate = "";
    }
  }

  /**
   * open the date picker
   */
  public open() {
    if (!this.disable) {
      this.isHidden = !this.isHidden;
    }
  }

  /** close the date picker */
  public close(){
    this.isHidden = true;
  }

  /** show the info of the input */
  public showInfo() {
    if (!this.isHidden) { this.isHidden = true; }
  }

  /**
   * update the date
   * @param event the event
   */
  public updateDate(event) {
    // this.valueDate = moment(event * 1000).format("DD/MM/YYYY");
    console.log("moment event", event)
    this.group.get(this.name).patchValue(event * 1000);
    this.getFormattedDate();
    this.showInfo();
  }

  /**
   * check if the input is valid
   */
  protected validationCheck() {
    this.group.get(this.name).valueChanges.subscribe(result => {
      if (result == null) {
        this.valueDate = "";
      }
    });
  }

  /**
   * get the formatted date
   */
  getFormattedDate(){
    let date = moment(this.group.get(this.name).value)
    switch(this.range){
      case "date":
        this.valueDate = date.format("DD-MM-YYYY");
        break;
      case "month":
        this.valueDate = date.format("MMMM YYYY");
        break;
      case "year":
        this.valueDate = date.format("YYYY");
        break;
      case "week":
        this.valueDate = `${this._translationSrv.getInstant("week")} ${date.format("WW")} (${date.startOf('isoWeek').format('DD-MM-YYYY')} - ${date.endOf('isoWeek').format('DD-MM-YYYY')})`;
        break;
      default:
        this.valueDate = date.format("DD-MM-YYYY");
        break;
    }
    this.valueDate
  }
}
