import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalItemComponent } from './modal-item/modal-item.component';
import { OverlayComponent } from './overlay/overlay/overlay.component';
import { OverlayService } from './overlay/overlay.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WidgetsConfigModule } from './widgets-config/widgets-config.module';

@NgModule({
  declarations: [ModalItemComponent, OverlayComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    WidgetsConfigModule,
  ],
  providers: [OverlayService],
  exports: [ModalItemComponent, OverlayComponent]
})
export class ModalModule { }
