import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { ISelectOption } from 'src/app/libs/input/facades';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { EnumGraphType } from 'src/app/libs/widgets/facades/enums';
import { ConsumptionQueriesService } from 'src/app/queries/consumption/consumption-queries.service';
import { IPaginationInput } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { TranslationService } from '../../../translation/services/translation.service';
import { MyOverlayRef } from '../../overlay/myoverlay-ref';
import { EnumPeriod } from '../facades/enums/period.enum';
import { ICreateEditGraphWidget } from '../facades/interfaces/createWidget.interface';


/**
 * Config for graph creation form (modal)
 */
@Component({
  selector: 'app-consumption-graph-config',
  templateUrl: './consumption-graph-config.component.html',
  styleUrls: ['./consumption-graph-config.component.scss']
})
export class ConsumptionGraphConfigComponent implements OnInit {
  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** Datas from edit modal */ @Input() public editDatas: any;
  /** FormGroup of graph */ public graphFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;
  /** Options of the select top */ periodOptions: ISelectOption[] = [
    { label: this._translationSrv.getInstant("perDay"), value: EnumPeriod.DAY },
    { label: this._translationSrv.getInstant("perWeek"), value: EnumPeriod.WEEK },
    { label: this._translationSrv.getInstant("perMonth"), value: EnumPeriod.MONTH },
    { label: this._translationSrv.getInstant("perYear"), value: EnumPeriod.YEAR },
    // { label: this._translationSrv.getInstant("perRange"), value: EnumPeriod.RANGE },
  ];
  /** Consumption to select */ public consumptionsToSelect: ISearchSelectItem[] = [];
  /** Subject to listen for search with compare string */ public searchConsumptionsSubject = new Subject<string>();
  /** Pagination used on search consumptions */ public pagination: IPaginationInput = { page: 1, limit: 20 };
  /** getter for controls of graph form group */ get formControls() { return this.graphFormGroup.controls; }
  /** Pagination used on table */ public paginationInput: IPaginationInput = { page: 1, limit: 100 };
  /** Icon for closing modal */ public faTimes: IconDefinition = faTimes;
  /** selected consumption id*/ private _consumptionId: number = null;
  /** selected consumption */ public consumptionSelected: any = null;

  /**
   * constructor
   * @param _translationSrv the translation service
   * @param _fb the form builder
   * @param _errorMessageSrv the error message service
   * @param _consumptionQueriesSrv the consumption queries service
   */
  constructor(
    private _translationSrv: TranslationService,
    private _fb: FormBuilder,
    protected _errorMessageSrv: ErrorMessageService,
    private _consumptionQueriesSrv: ConsumptionQueriesService) {
    this.inputErrorsLabelMap = new Map<string, string>([
      ["title", this._translationSrv.getInstant("consumption_power-input")],
      ["period", this._translationSrv.getInstant("consumption_power-input")],
      ["cols", this._translationSrv.getInstant("consumption_power-input")],
      ["rows", this._translationSrv.getInstant("consumption_power-input")],
      ["consumptionItem", this._translationSrv.getInstant("consumption_power-input")],
    ]);
  }

  /**
   * On Init method
   */
  ngOnInit(): void {
    this.initFormGroup();
  }

  /**
   * Init form group of graph config
   */
  public initFormGroup(): void {
    const presetDatas = this.editDatas && this.editDatas.widgetDataConfig? this.editDatas.widgetDataConfig : null;
    this.graphFormGroup = this._fb.group({
      title: [presetDatas? presetDatas.title : "", Validators.required],
      period: [presetDatas? presetDatas.period : {}, Validators.required],
      cols: [this.editDatas && this.editDatas.cols != null? this.editDatas.cols : 4, Validators.required],
      rows: [this.editDatas && this.editDatas.rows != null? this.editDatas.rows : 4, Validators.required],
      consumptionItem: [null, Validators.required],
    });

    if(presetDatas){
      this._consumptionQueriesSrv.getConsumptionById(presetDatas.consumptionId).subscribe((res: any) => {
        if(res && res.data && res.data.consumption){
          const consumption = res.data.consumption;

          this.consumptionSelected = {
            id: consumption.id,
            label: `${consumption.name} (${this._translationSrv.getInstant("serialNumber")}: ${consumption.serialNumber})`,
            data: consumption
          };
          this.graphFormGroup.get("consumptionItem").patchValue([this.consumptionSelected])
        }
      }, error => {
        console.log(error);
      })
    }

    this.graphFormGroup.get("consumptionItem").valueChanges.subscribe(res => {
      if (res && res[0] && res[0].data) this._consumptionId = res[0].data.id;
      else this._consumptionId = null;
    })
  }

  /**
   * Validate form and do query to get computed datas from form values
   * @returns
   */
  public validateForm(duplicate: boolean = false) {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.graphFormGroup, this.inputErrorsLabelMap);

    if (this.graphFormGroup.invalid) {
      console.log("graph-config.component.ts: graphFormGroup is invalid.");
      return;
    }

    const graphToCreate: ICreateEditGraphWidget = {
      cols: this.graphFormGroup.value.cols,
      rows: this.graphFormGroup.value.rows,
      widgetDataConfig: {
        type: EnumGraphType.AREA,
        title: this.graphFormGroup.get("title").value ? this.graphFormGroup.get("title").value : "Default title of graphic",
        period: this.graphFormGroup.get("period").value,
        consumptionId: this._consumptionId,
      }
    }
    if(this.editDatas && this.editDatas.id && !duplicate) graphToCreate['id'] = this.editDatas.id;
    this.close(graphToCreate);
  }

  /**
   * Close the modal and send a value to listeners
   * @param value
   */
  public close(value: any) {
    this.ref.close(value);
  }

  /**
   * Search a palamede consumption based on search params (q)
   * @param q the search string to find the consumption
   * @param idsSelected the consumption ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the consumption results
   */
  public searchConsumptions = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._consumptionQueriesSrv.searchConsumptions({ page: 1, limit: 1000 }, q).toPromise();
    let consumptions: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getConsumptions.consumptions && (<any>resultQuery.data).getConsumptions.consumptions)
      consumptions = (<any>resultQuery.data).getConsumptions.consumptions

    consumptions = consumptions.map((consumption: any) => {
      return {
        id: consumption.id,
        label: `${consumption.name} (${this._translationSrv.getInstant("serialNumber")}: ${consumption.serialNumber})`,
        data: consumption
      };
    })
    return consumptions;
  }
}
