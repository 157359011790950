import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';

/**
 * linked filed component
 */
@Component({
  selector: 'app-linked-files',
  templateUrl: './linked-files.component.html',
  styleUrls: ['./linked-files.component.scss']
})
export class LinkedFilesComponent implements OnInit {

  /** Array of all files */ @Input() public filesList: any[] = [];
  /** FormGroup of files */ public filesFormGroup: FormGroup;
  /** boolean true if the user can edit the installation otherwise false */ @Input() public canEdit: boolean = true
  /** EventEmitter when the array of files linked changed */ @Output("filesChanged") public filesChanged: EventEmitter<any> = new EventEmitter();

  /**
   * constructor
   * @param _fb the form builder
   */
  constructor(
    private _fb: FormBuilder,
  ) { }

  /**
   * On init method
   */
  ngOnInit(): void {
    this.initFormGroup();
  }

  /**
   * on change event of files
   * @param changes the changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.filesList) {
      this.setPreviewUrl();
    }
  }

  /**
   * Init form group of comments adding
   */
  public initFormGroup(): void {
    this.setPreviewUrl();

    this.filesFormGroup = this._fb.group({
      files: [this.filesList ? this.filesList : ""]
    });
  }

  /**
   * Set preview url of files
   */
  public setPreviewUrl(): void {
    if (this.filesList) {
      this.filesList.forEach(async file => {
        if (!file.previewUrl) {
          if (!file.type.startsWith('image'))
            file.previewUrl = '/assets/img/filePreview.png'
          else
            file.previewUrl = await this.getFile(file)
        }
      });
    }
  }

  /**
   * get the file from backend
   * @param file the file
   * @returns void
   */
  public async getFile(file: any) {
    let myInit: RequestInit =
    {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'cors',
      cache: 'default'
    };
    const path = file.path;

    return new Promise(async (resolve) => {
      const filepath = environment.API_URL_CONTROLLER + "getFile/" + "files/" + path.substr(environment.UPLOAD_INSTALLATION_FILE_PATH.length);

      await fetch(filepath, myInit)
      .then(function(response) {
        if (!response || !response.url) {
          console.error("can't find url of file")
        }
        resolve(response.url)
      })
    })
  }

  /**
   * this function remove a file that have been added
   * @param file the file that should be removed
   */
  private removeFile(file: File)
  {
    if (!this.canEdit) { return }
    let result = {
      toDelete: null,
      total: [],
    }
    this.filesList = this.filesList.filter((obj) => obj != file)
    if (file && !file.size) {
      result.toDelete = file;
    }

    result.total = this.filesList;
    this.filesChanged.emit(result)
    //  this.filesFormGroup.get('files').patchValue(this.filesList);
  }
 
   /**
    * this function will be called by one of the app-img-block containing an image
    * @param event the event that contain {action: Delete or Zoom, file: the File that have triggerd the event}
    * @returns nothing
    */
  onFileAction(event: any)
  {
    if (event && event.action && event.file)
    {
      switch(event.action) {
        case "Delete":
          return this.removeFile(event.file);
        case "Zoom": // todo: display the image in full screen
          if(event.file.path){
            const filepath = environment.API_URL_CONTROLLER + "getFile/files/" + event.file.path.substr(environment.UPLOAD_INSTALLATION_FILE_PATH.length);
            window.open(filepath, "_blank");
          }
          break;
      }
    }
  }
  /**
  * this function will be triggerd when the user add a new image
  * @param file the file that have been added
  */
  onNewFile(file: any) {
    let result = {
      toAdd: null,
      total: [],
    }
    if (file) {
      if (!file.type.startsWith('image'))
        file.previewUrl = '/assets/img/filePreview.png'
      result.toAdd = file
      if (!this.filesList || this.filesList.length <= 0)
        this.filesList = [];
      this.filesList.push(file);
      result.total = this.filesList
      this.filesChanged.emit(result)
    //  this.filesFormGroup.get('files').patchValue(this.filesList);
    }
  }

}
