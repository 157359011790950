// tslint:disable: variable-name
// tslint:disable: no-string-literal
import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

/**
 * Directive use to close an element when click outside of it
 * 
 * @example
 * Basic Usage
 * <div (appClickOutside)="close()"> </div>
 */
@Directive({
  selector: "[appClickOutside]"
})
export class ClickOutsideDirective {

  /** The event to handle when click outside of the element */ @Output() appClickOutside: EventEmitter<void> = new EventEmitter();
  
  /**
   * Constructor Method
   * @param {ElementRef} _elementRef The angular ElementRef to access the DOM 
   */
  constructor(private _elementRef: ElementRef) { }

  /**
   * The HostListener to listen the click event on the document
   * @param targetElement 
   */
  @HostListener("document:click", ["$event.target"])
    public onClick(targetElement) {
      const clickedInside = this._elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) { this.appClickOutside.emit() }
    }
}
