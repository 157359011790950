import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSelectComponent } from './search-select/search-select.component';
import { InputModule } from '../input/input.module';
import { TranslationModule } from '../translation/translation.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedDirectivesModule } from '../directives/directives.modules';



@NgModule({
  declarations: [
    SearchSelectComponent
  ],
  imports: [
    CommonModule,
    InputModule,
    TranslationModule,
    FontAwesomeModule,
    SharedDirectivesModule
  ],
  exports: [
    SearchSelectComponent
  ]
})
export class SearchSelectModule { }
