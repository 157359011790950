import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditRecComponent } from './add-edit-rec/add-edit-rec.component';
import { ListRecComponent } from './list-rec/list-rec.component';

const routes: Routes = [
  {
    path: 'rec',
    component: ListRecComponent,
    children: [
      { path: 'add', component: AddEditRecComponent },
      { path: 'detail/:id', component: AddEditRecComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RecRoutingModule { }
