import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WidgetCardItem } from 'src/app/libs/widgets/facades/interfaces/widget-card-item.interface';
import { AuthService } from 'src/app/services/auth.service';
import { DashboardQueriesService } from '../../../queries/dashboard/dashboard-queries.service';

/**
 * Display board of cards
 *
 * @example
 * Basic usage
 * <app-dashboard></app-dashboard>
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  /** Array of Cards to be display */ public boardConfig: Array<WidgetCardItem> = []; 
  /** save initial Array of Cards */ public boardConfigInit: Array<WidgetCardItem> = []; 
  /** Id of Dashboard to show */ public dashboardId: number; 
  /** The title of the dashboard to display */ public title: string = "dashboard";

  /**
   * Constructor method
   * @param _router
   * @param _activatedRoute
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _dashboardQueriesSrv: DashboardQueriesService,
    private _router: Router,
    private _authService: AuthService,

  ) {
    this._activatedRoute.params.subscribe((param) => {
      this.dashboardId = JSON.parse(param['id']);
    });
  }

  /**
   * Called when a row config card is clicked
   */
  public query = () => {

  };

  /**
   * Called on init
   */
  ngOnInit(): void {

    if (!this.dashboardId) {
      throw 'Dashboard id was not found.';
    } else {
      this._dashboardQueriesSrv
        .getDashboard(this.dashboardId)
        .subscribe(async (result) => {
          console.log("result", result);
          if (!result || !result.data) {
            // try to access to a dashboard from another user
            if (this._authService.userLocalStorage.role === 'USER') {
              this.goTo("/dashboard");
              return;
            }
          }
          if (result.errors && result.errors.length > 0) {
            throw result.errors;
          } else {
            const data: any = <any>result.data;
            this.title = data?.getDashboard?.name? data.getDashboard.name : "Dashboard";
            if (data?.getDashboard?.items) {
              this.boardConfig = JSON.parse(data.getDashboard.items);
              this.boardConfigInit = JSON.parse(data.getDashboard.items);
            }
          }
        });
    }
  }

  /**
   * Called when save dashboard is clicked
   */
  onSaveDataHandler(data: any) {
    this._dashboardQueriesSrv
      .updateDashboard({
        id: this.dashboardId,
        items: data,
      })
      .subscribe(async (result) => {
        if (result.errors && result.errors.length > 0) {
          throw result.errors;
        } else {
          if (data && data.getDashboard && data.getDashboard.items) {
            this.boardConfig.push(JSON.parse(data.getDashboard.items));
            this.boardConfigInit = JSON.parse(data.getDashboard.items);
          }
        }
      });
  }

  /**
   * Called to navigate to another page
   * @param page URL
   */
     goTo(page: string) {
      this._router.navigate([page]);
    }

  /**
   * Called when save dashboard is clicked
   */
  onResetDataHandler() {
    this.boardConfig = [...this.boardConfigInit];
  }
}
