<div class="modal-card">
    <section class="modal-card-head">
        <div>{{ 'widgetConfig' | translate }}</div>
        <fa-icon class="modal-card-head__close" [icon]="faTimes" (click)="close(false)"></fa-icon>
    </section>
    <section class="modal-card-body" style="min-height: 30rem;">
        <div>
            <div class="grid grid_col_1">
                <div>
                    <app-label>{{ "chooseCer" | translate}} *</app-label>
                    <app-search-select [group]="graphFormGroup" formControlName="cerItem"
                        [query]="searchCer" [name]="'cerItem'" id="cerItem" [maxSelected]="1"
                        [selected]="cerSelected"
                    ></app-search-select>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.cerItem"
                        class="invalid-label">
                        {{ inputErrorsLabel.cerItem.msg | translate}}
                    </div>
                </div>
            </div>
            <div class="grid grid_col_1 gap_top">
                <app-label>{{ "chooseConsumptionProfile" | translate}} *</app-label>
                <app-search-select [group]="graphFormGroup" formControlName="consumptionItem"
                    [query]="searchConsumptions" [name]="'consumptionItem'" id="consumptionItem" 
                    [selected]="consumptionSelected" [resetSelected]=shouldResetSelected></app-search-select>
                <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.consumptionItem"
                    class="invalid-label">
                    {{ inputErrorsLabel.consumptionItem.msg | translate}}
                </div>
            </div>
            <div class="grid grid_col_2 gap_top">
                <div>
                    <app-label>{{ "title" | translate}} *</app-label>
                    <app-text-input formControlName="title" [group]="graphFormGroup" [name]="'title'" id="title"
                        [ngClass]="{ 'invalid-input': isSubmitted && formControls.title.errors}"></app-text-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.title" class="invalid-label">
                        {{ inputErrorsLabel.title.msg | translate}}
                    </div>
                </div>
                <div>
                    <app-label>{{ "period" | translate}} *</app-label>
                    <app-select-input [options]="graphConfigSrv.periodOptions" [autoSelectFirst]="true" formControlName="period"
                        [group]="graphFormGroup" [name]="'period'" id="period"
                        [ngClass]="{ 'invalid-input': isSubmitted && formControls.period.errors}">
                    </app-select-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.period" class="invalid-label">
                        {{ inputErrorsLabel.period.msg | translate}}
                    </div>
                </div>
             
            </div>
            <div class="grid grid_col_2 gap_top">
                <div>
                    <app-label>{{ "cols" | translate}} *</app-label>
                    <app-number-input [min]="3" [step]="1" formControlName="cols" [group]="graphFormGroup"
                        [name]="'cols'" id="cols"
                        [ngClass]="{ 'invalid-input': isSubmitted && formControls.cols.errors}"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.cols" class="invalid-label">
                        {{ inputErrorsLabel.cols.msg | translate}}
                    </div>
                </div>
                <div>
                    <app-label>{{ "rows" | translate}} *</app-label>
                    <app-number-input [min]="3" [step]="1" formControlName="rows" [group]="graphFormGroup"
                        [name]="'rows'" id="rows"
                        [ngClass]="{ 'invalid-input': isSubmitted && formControls.rows.errors}"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.rows" class="invalid-label">
                        {{ inputErrorsLabel.rows.msg | translate}}
                    </div>
                </div>
            </div>

        </div>
    </section>
    <footer class="modal-card-foot">
        <div>
            <button class="button button_valid" (click)="validateForm()" type="button">{{ "save" | translate }}</button>
            <button class="button button_delete" (click)="close(false)" type="button">{{ "cancel" | translate}}</button>
            <button *ngIf="this.editDatas && this.editDatas.id" class="button button_primary" (click)="validateForm(true)" type="button">{{ "Duplicate" | translate }}</button>

        </div>
    </footer>
</div>