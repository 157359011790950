import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import PREDICTION from "./graphql/prediction.graphql"

/**
 * Service of prediction queries
 */
@Injectable({
  providedIn: "root"
})
export class PredictionQueriesService {

  /**
   * constructor method
   * @param _apollo 
   */
  constructor(private _apollo: Apollo) { }

  /**
   * run the prediction query
   * @param date date of the prediction
   * @param selectedYear the selected year
   * @param installationId the installation id
   * @returns 
   */
  public prediction(date: number, selectedYear: string, installationId: number) {
    return this._apollo.query({
      query: PREDICTION,
      variables: {
        date,
        selectedYear,
        installationId
      },
      fetchPolicy: "network-only"
    })
  }
}