import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { DateTime } from "luxon";
import { faChevronLeft, faChevronRight, faLeaf, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendarInputComponent } from '../../input/calendar-input/calendar-input.component';
import { GraphConfigService } from '../../modal/widgets-config/facades/services/graph-config.service';

/**
 * Dioxyde graph widget component
 */
@Component({
  selector: 'app-dioxyde-graph-widget',
  templateUrl: './dioxyde-graph-widget.component.html',
  styleUrls: ['./dioxyde-graph-widget.component.scss']
})
export class DioxydeGraphWidgetComponent {

  /** Config for graph */ @Input() public graphConfig: any;
  /** id for graph */ @Input() public id: any;
  /** The FontAwesome Icon for previous period */ public faChevronLeft: IconDefinition = faChevronLeft;
  /** The FontAwesome Icon for next period */ public faChevronRight: IconDefinition = faChevronRight;
  /** The FontAwesome Icon CO2 economy */ public faLeaf: IconDefinition = faLeaf;
  /** FormGroup */ public formGroup: FormGroup;
  /** co2 datas from backend */ public co2Datas: any;

  /** true if the widget is loading */ public loading: boolean = false;

  /** input date view */ @ViewChild('inputDate', { static: true }) inputDate: ElementRef<CalendarInputComponent>;

  
  /** data */ public information: any = null;
  /** data from backend */ public formattedDatasForGraph: any = null;
  /** dataset */ public totalDataset: any = null;

  /**
   * constructor
   * @param _installationQueriesSrv the installation queries service
   * @param _fb the form builder
   * @param graphConfigSrv the graph config service
   */
  constructor(private _installationQueriesSrv: InstallationQueriesService,
    private _fb: FormBuilder,
    public graphConfigSrv: GraphConfigService) { }



  /**
   * On init method.
   */
  ngOnInit() {
    this.initFormGroup();
    this._getWidgetDatas();
  }

  /**
   * init form group
   */
  public initFormGroup() {
    this.formGroup = this._fb.group({
      currentDate: [DateTime.now().setLocale("fr").toMillis()],
    });
  }

  /**
   * get widget datas
   */
  private _getWidgetDatas() {

    const range = this.graphConfigSrv.calculateRange(this.graphConfig.period, this.formGroup.get("currentDate").value);
    this.loading = true;
    this._installationQueriesSrv.getWidgetCo2(this.graphConfig.installationsIds, range, this.graphConfig.co2Level).subscribe((resultDatas: any) => {
      this.loading = false;
      if (resultDatas && resultDatas.data) {
        const { getWidgetCo2 } = resultDatas.data;
        this.co2Datas = getWidgetCo2;

      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    })
  }
  
  /**
   * change the date
   * @param orientation next or prev
   */
  public changeDate(orientation: string = "next") {
    const currentDate = DateTime.fromMillis(this.formGroup.get("currentDate").value);
    if (orientation == "next") this.formGroup.get("currentDate").patchValue(currentDate.plus({ [this.graphConfig.period]: 1 }).toMillis());
    else this.formGroup.get("currentDate").patchValue(currentDate.minus({ [this.graphConfig.period]: 1 }).toMillis());
    this._getWidgetDatas();
  }
}
