import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidenavModule } from './libs/sidenav/sidenav.module';
import { DevelopersModule } from './libs/developers/developers.module';
import { TranslationModule } from './libs/translation/translation.module';
import { RecModule } from './pages/rec/rec.module';
import { WidgetsModule } from './libs/widgets/widgets.module';
import { TableModule } from './libs/table/table.module';
import { FiltersModule } from './libs/filters/filters.module';
import { PaginationModule } from './libs/pagination/pagination.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { UsersModule } from './pages/users/users.module';
import { CounterModule } from './libs/counter/counter.module';

import {HttpClientModule} from '@angular/common/http';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';

import {environment} from '../environments/environment';
import { AuthModule } from './pages/auth/auth.module';
import { GraphQLModule } from './libs/graphql/graphql.module';
import { InstallationsModule } from './pages/installations/installations.module';
import { OverlayComponent } from './libs/modal/overlay/overlay/overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { SearchSelectModule } from './libs/search-select/search-select.module';

import { AlertModule } from 'ngx-alerts';
import { ConsumptionsModule } from './pages/consumptions/consumptions.module';
import { LinkedModule } from './pages/linked/linked.module';
// import { NgChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslationModule,
    DevelopersModule,
    SidenavModule,
    WidgetsModule,
    RecModule,
    PaginationModule,
    TableModule,
    FiltersModule,
    DashboardModule,
    UsersModule,
    LinkedModule,
    CounterModule,
    AuthModule,
    GraphQLModule,
    InstallationsModule,
    ConsumptionsModule,
    OverlayModule,
    SearchSelectModule,
    AlertModule.forRoot({maxMessages: 5, timeout: 3000, positionX: 'right', positionY: 'bottom'}),
    // NgChartsModule
  ],
  providers: [
    GraphQLModule,
  ],
  bootstrap: [AppComponent],
  entryComponents: [OverlayComponent]
})
export class AppModule { }
