
export const environment = {
   production: true,
   APP_TITLE: "IDETA MODULE C",
   API_URL: "https://api-solaris.ideta.be/graphql",
   ACCESS_TOKEN_SECRET: "undefined",
   UPLOAD_NOTE_IMAGE_PATH: "./uploads/notes/",
   UPLOAD_AVATAR_IMAGE_PATH: "./uploads/avatar/",
   UPLOAD_INSTALLATION_FILE_PATH: "./uploads/files/",
   API_URL_CONTROLLER: "https://api-solaris.ideta.be/",
   MAX_BODY_SIZE_BYTE: "104857600"
};
