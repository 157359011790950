import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import * as moment from "moment";
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';

/**
 * date picker component
 */
@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.css"]
})
export class DatePickerComponent implements OnInit {
  /** navigation date */ public navigationDate: any;
  /** array of day of the week */ public weekDaysHeaderArr: Array<string> = [];
  /** array of day */ public calendarDays: Array<any> = [];
  /** the format to display */ private _dateFormat: string = "DD/MM/YYYY";
  /** the date of today */ private _todayDate: any;
  /** the selected date */ private _selectedDay: any;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronLeft: IconDefinition = faChevronLeft;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronRight: IconDefinition = faChevronRight;
  /** triggerd when the date change */ @Output() OnDateSelected = new EventEmitter<string>();
  /** true if the position of the widget should be relative otherwise it's absolute */ @Input() public relative: boolean = false;

  /**
   * constructor
   */
  constructor() {
    moment.locale("fr");
  }

  /**
   * ngOnInit
   */
  ngOnInit() {
    this.setDatas();
    this._todayDate = moment();
    this._todayDate = moment();
    this.calendarWeekName();
    this.buildCalendarDays();
  }

  /**
   * setDatas
   */
  private setDatas() {
    this.navigationDate = moment();
  }

  /**
   * change the month of the calendar
   * @param num the number of month to add
   */
  public changeNavMonth(num: number) {
    this.navigationDate.add(num, "month");
    this.buildCalendarDays();
  }

  /**
   * fill the day of the week
   */
  public calendarWeekName() {
    const weekDaysArr: Array<number> = [0, 1, 2, 3, 4, 5, 6];
    weekDaysArr.forEach(day => this.weekDaysHeaderArr.push(moment().weekday(day).format("ddd")));
  }

  /**
   * build the calendar days
   */
  public buildCalendarDays() {
    this.calendarDays = [];
    const startCalendarDate = moment({
      y: this.navigationDate.year(),
      M: this.navigationDate.month(),
      d: 1
    }).startOf("week"),
      endCalendarDate = moment({
        y: this.navigationDate.year(),
        M: this.navigationDate.month(),
        d: this.navigationDate.daysInMonth()
      }).endOf("week").add(1, "days");

    while (startCalendarDate.valueOf() < endCalendarDate.valueOf()) {
      const obj: any = {
        "value": startCalendarDate.format("D"),
        "pastDay": startCalendarDate.endOf("day").valueOf() < this._todayDate.valueOf() && startCalendarDate.format("M") === this.navigationDate.format("M"),
        "disabled": startCalendarDate.format("M") != this.navigationDate.format("M"),
        "today": startCalendarDate.format(this._dateFormat) === this._todayDate.format(this._dateFormat),
        "selected": startCalendarDate.format(this._dateFormat) === this._selectedDay,
        "dateValue": startCalendarDate.unix()
      };
      this.calendarDays.push(obj);
      startCalendarDate.add(1, "days");
    }
  }

  /**
   * triggerd when the user select a date
   * @param daySelected the selected day
   */
  public selectDay(daySelected) {
    this.calendarDays.forEach(day => {
      day.selected = day.dateValue === daySelected.dateValue;
    });
    this.OnDateSelected.emit(daySelected.dateValue);
  }


}
