import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
// import { Color, Label } from 'ng2-charts';
import { EnumGraphType } from 'src/app/libs/widgets/facades/enums';
import { ConsumptionQueriesService } from 'src/app/queries/consumption/consumption-queries.service';
import { DateTime, DurationObject } from "luxon";
import { EnumPeriod } from '../../modal/widgets-config/facades/enums/period.enum';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphBackgroundColor, GraphBorderColor } from '../facades/constants/graph-color.constant';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendarInputComponent } from '../../input/calendar-input/calendar-input.component';

Chart.register(...registerables);
/**
 * Component that display a bar
 * Basic Usage 
 * <app-graph-widget [graphConfig]="item.widgetData"></app-graph-widget>
 */
@Component({
  selector: 'app-consumption-graph-widget',
  templateUrl: './consumption-graph-widget.component.html',
  styleUrls: ['./consumption-graph-widget.component.scss']
})
export class ConsumptionGraphWidgetComponent {

  /** Config for graph */ @Input() public graphConfig: any;
  /** id for graph */ @Input() public id: any;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronLeft: IconDefinition = faChevronLeft;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronRight: IconDefinition = faChevronRight;
  /** FormGroup of consumption */ public formGroup: FormGroup;

  /** true if the widget is loiading */ public loading: boolean = false;
  /** graph view */ @ViewChild('myCanvas', { static: true }) myCanvas: ElementRef<HTMLCanvasElement>;
  /** input date view */ @ViewChild('inputDate', { static: true }) inputDate: ElementRef<CalendarInputComponent>;
  /** Enum to est type by enumType for graph */ private typeByEnum = {
    [EnumGraphType.AREA]: 'line',
    [EnumGraphType.MIXED]: 'line',
    [EnumGraphType.BAR]: 'bar',
    [EnumGraphType.STACKEDBAR]: 'bar'
  }

  /** current start date */ public currentStartDate: DateTime = null;
  /** current end date */ public currentEndDate: DateTime = null;

  /** period unit map */ public periodUnitMap: Map<string, keyof DurationObject> = null;
  /** enum period */ public __EnumPeriod = EnumPeriod;
  /** data */ public information: any = null;

  /** the chart */ public myChart: Chart = null;
  /** dataset */ public totalDataset: any = null;
  /** data from backend */ public formattedDatasForGraph: any = null;

  /**
   * constructor
   * @param _consumptionQueriesSrv the consumption queries service
   * @param _fb the form builder
   */
  constructor(private _consumptionQueriesSrv: ConsumptionQueriesService,
              private _fb: FormBuilder) {
    this.periodUnitMap = new Map<string, keyof DurationObject>([
      [EnumPeriod.DAY, "day"],
      [EnumPeriod.WEEK, "week"],
      [EnumPeriod.MONTH, "month"],
      [EnumPeriod.YEAR, "year"]
    ]);
  }

  /**
   * On init method.
   */
  ngOnInit() {
    this.initFormGroup();
    this._initGraphOptions();
    this._getWidgetDatas();
  }

  /**
   * Init form group.
   */
  public initFormGroup(){
    this.formGroup = this._fb.group({
      currentDate: [DateTime.now().setLocale("fr").toMillis()],
    });

    this.formGroup.get("currentDate").valueChanges.subscribe(res => {
      this._getWidgetDatas();
    })
  }

  /**
   * Init graph options.
   */
  private _initGraphOptions() {
    if (this.myCanvas) {
      const ctx = this.myCanvas.nativeElement.getContext("2d");
      const chartConfig = {
        type: this.typeByEnum[this.graphConfig.type],
        data: null,
        options: {
          scales: {
            x: {
              stacked: this.graphConfig.type === EnumGraphType.STACKEDBAR ? true : false
            },
            y: {
              beginAtZero: true,
              stacked: this.graphConfig.type === EnumGraphType.STACKEDBAR ? true : false,
              title: {
                display: true,
                text: 'kWh',
                font: {
                  size: 10,
                  lineHeight: 0.5
                }
              }
            }
          },
          plugins: {},
          interaction: {
            intersect: false,
            mode: 'index',
          },
          maintainAspectRatio: false,
        }
      };

      chartConfig.options.plugins['tooltip'] = {
        callbacks: {
          label: function (tooltipItems) {
            return `${tooltipItems.dataset.label}: ${tooltipItems.parsed.y.toFixed(2)} kWh`;
          },
        }
      }
      if (this.graphConfig.type == EnumGraphType.STACKEDBAR) {
        chartConfig.options.plugins['tooltip'].callbacks['footer'] =
          (tooltipItems) => {
            let sum = 0;
            tooltipItems.forEach(function (tooltipItem) {
              sum += tooltipItem.parsed.y;
            });
            return 'Total: ' + sum.toFixed(2) + ' kWh';
          }
      }
      this.myChart = new Chart(ctx, chartConfig)
    }
  }

  /**
   * Get widget datas.
   */
  private _getWidgetDatas() {
    let convertersFormattedData = [];
    let convertersLabels = [];
    const range = this._calculateRange(this.graphConfig.period);
    this.loading = true;
    this._consumptionQueriesSrv.getFormattedDataForConsumption(this.graphConfig.consumptionId, range).subscribe((resultDatas: any) => {
      this.loading = false;
      if (resultDatas && resultDatas.data) {
        const { getFormattedDataForConsumption } = resultDatas.data;
        let consumptionDatas = getFormattedDataForConsumption.data;
        const label = getFormattedDataForConsumption.label;

        this.information = getFormattedDataForConsumption.information;

        this.totalDataset = {
          label: "Total",
          data: []
        }

        convertersFormattedData = consumptionDatas ? consumptionDatas : [];
        convertersLabels = label ? label : [];

        this.formattedDatasForGraph = convertersFormattedData.map((item, index) => {
          return {
            ...item,
            backgroundColor: GraphBackgroundColor[index],
            borderColor: GraphBorderColor[index],
            pointRadius: 1,
            borderWidth: 1,
            stack: "Test",
            fill: true
          }
        });

        this.myChart.data.datasets = this.formattedDatasForGraph;
        this.myChart.data.labels = convertersLabels;
        this.myChart.update();

      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    })
  }

  /**
   * calculate the range
   * @param period the period
   * @returns void
   */
  private _calculateRange(period: string) {
   
    let result = null
    this.currentStartDate = DateTime.fromMillis(this.formGroup.get("currentDate").value).setLocale("fr").startOf(this.periodUnitMap.get(this.graphConfig.period));
    this.currentEndDate = DateTime.fromMillis(this.formGroup.get("currentDate").value).setLocale("fr").endOf(this.periodUnitMap.get(this.graphConfig.period));
    result = {
      gte: this.currentStartDate.toMillis(),
      lte: this.currentEndDate.toMillis(),
      period
    }
    return result;
  }
  
  /**
   * change the date
   * @param orientation next or prev
   */
  public changeDate(orientation: string = "next") {
    const currentDate = DateTime.fromMillis(this.formGroup.get("currentDate").value);
    if (orientation == "next") this.formGroup.get("currentDate").patchValue(currentDate.plus({[this.graphConfig.period]: 1}).toMillis());
    else this.formGroup.get("currentDate").patchValue(currentDate.minus({[this.graphConfig.period]: 1}).toMillis());
    this._getWidgetDatas();
  }
}
