import { Injectable } from '@angular/core';
import { DateTime, DurationObject } from 'luxon';
import { ISelectOption } from 'src/app/libs/input/facades';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { EnumPeriod } from '../enums/period.enum';

/**
 * graph config service
 */
@Injectable({ providedIn: 'root' })
export class GraphConfigService {

    /**
     * Get the period options
     */
    private _periodOptions: ISelectOption[] = [
        { label: this._translationSrv.getInstant("perDay"), value: EnumPeriod.DAY },
        { label: this._translationSrv.getInstant("perWeek"), value: EnumPeriod.WEEK },
        { label: this._translationSrv.getInstant("perMonth"), value: EnumPeriod.MONTH },
        { label: this._translationSrv.getInstant("perYear"), value: EnumPeriod.YEAR },
        // { label: this._translationSrv.getInstant("perRange"), value: EnumPeriod.RANGE },
    ];

    /**
     * list of period unit
     */
    private _periodUnitMap = new Map<string, keyof DurationObject>([
        [EnumPeriod.DAY, "day"],
        [EnumPeriod.WEEK, "week"],
        [EnumPeriod.MONTH, "month"],
        [EnumPeriod.YEAR, "year"]
    ]);

    /** getter of periodOption */
    public get periodOptions(): ISelectOption[] { return this._periodOptions };
    /** setter of periodOption */
    public set periodOptions(periodOptions: ISelectOption[]) { this._periodOptions = periodOptions };
    /** getter of UnitMap */
    public get periodUnitMap(): Map<string, keyof DurationObject> { return this._periodUnitMap };

    /**
     * constructor
     * @param _translationSrv translation service
     */
    constructor(private _translationSrv: TranslationService) { }

    /**
     * compute the range of period
     * @param period the period
     * @param currentDate the current date
     * @returns an object with start and end date and the period
     */
    public calculateRange(period: string, currentDate: number) {
        let result = null
        const currentStartDate = DateTime.fromMillis(currentDate).setLocale("fr").startOf(this.periodUnitMap.get(period));
        const currentEndDate = DateTime.fromMillis(currentDate).setLocale("fr").endOf(this.periodUnitMap.get(period));
        result = {
            gte: currentStartDate.toMillis(),
            lte: currentEndDate.toMillis(),
            period
        }
        return result;
    }
}
