<div class="users-add-edit">
  <div class="side-panel" id="edit-box">
    <h1 class="side-panel__header">
      {{ titleKey | translate }}
    </h1>
    <div class="side-panel__content">
      <div class="grid grid_col_1" class="box">
        <h4 class="title">{{ "generalInfosTitle" | translate }}</h4>
        <div class="grid grid_col_2 gap_top">
          <div>
            <app-label>{{ "name" | translate }} *</app-label>
            <app-text-input 
              formControlName="name" 
              [group]="usersFormGroup" 
              [name]="'name'" 
              id="name" 
              [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.name }">
            </app-text-input>
            <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.name" class="invalid-label">
              {{ inputErrorsLabel.name.msg | translate }}
            </div>
          </div>
          <div>
            <app-label>{{ "lastName" | translate }} *</app-label>
            <app-text-input 
              formControlName="lastName" 
              [group]="usersFormGroup" 
              [name]="'lastName'" 
              id="lastName"
              [ngClass]="{'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.lastName}">
            </app-text-input>
            <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.lastName" class="invalid-label">
              {{ inputErrorsLabel.lastName.msg | translate }}
            </div>
          </div>
        </div>
        <div class="grid grid_col_2 gap_top">
          <div>
            <app-label>{{ "mail" | translate }} *</app-label>
            <app-text-input 
              formControlName="mail" 
              [group]="usersFormGroup" 
              [name]="'mail'" 
              id="mail"
              [ngClass]="{'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.mail}">
            </app-text-input>
            <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.mail" class="invalid-label">
              {{ inputErrorsLabel.mail.msg | translate }}
            </div>
          </div>
          <div>
            <app-label>{{ "phone" | translate }} *</app-label>
            <app-text-input 
              formControlName="phone" 
              [group]="usersFormGroup" 
              [name]="'phone'" 
              id="phone"
              [ngClass]="{'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.phone}"></app-text-input>
            <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.phone" class="invalid-label">
              {{ inputErrorsLabel.phone.msg | translate }}
            </div>
          </div>
        </div>
        <div class="grid grid_col_2 gap_top">
          <div>
            <app-label>{{ "role" | translate }} *</app-label>
            <app-radio-input 
              formControlName="role" 
              [group]="usersFormGroup" 
              [name]="'role'" 
              id="role"
              [options]="rolesOptions" 
              [isInline]="true"></app-radio-input>
          </div>
          <div class="grid grid_col_2">
            <div>
              <app-label>{{ "addAvatar" | translate }}</app-label>
              <app-img-input (sendImg)="onNewFile($event)"></app-img-input>
            </div>
            <div *ngIf="file">
              <app-label>{{ "selectedAvatar" | translate }}</app-label>
              <app-img-block [file]=file (fileTouched)=onFileAction($event)></app-img-block>
            </div>
          </div>
        </div>
        <div *ngIf="error !== ''" class="grid grid_col_1" style="margin-bottom: 2em">
          <div>
            <app-label>{{ "errorLabel" | translate }} *</app-label>
            <h3>{{ error }}</h3>
          </div>
        </div>
      </div>

      <app-linked-installations (installationsChanged)="linkedInstallationsChanged($event)" [selectedInstallations]="formattedSelectedInstallations"></app-linked-installations>
      <app-linked-consumptions (consumptionsChanged)="linkedConsumptionsChanged($event)" [selectedConsumptions]="formattedSelectedConsumptions"></app-linked-consumptions>


      <div class="grid grid_col_2" style="padding: 2rem">
        <app-button [action]="__TYPE_BUTTON.DELETE" (click)="goTo(pagesEnum.MAIN)">{{ "cancel" | translate }}</app-button>
        <app-button [action]="__TYPE_BUTTON.VALID" (click)="validateForm()">{{
          "save" | translate
          }}</app-button>
      </div>
    </div>
  </div>
</div>

<!-- <ng-template #tplDeleteInstallation>
  <app-modal-item [ref]="ref" [config]="modalConfig"></app-modal-item>
</ng-template> -->