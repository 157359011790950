import { Apollo } from "apollo-angular";
import { Injectable } from "@angular/core";
import { IPaginationInput } from "../pagination/interfaces/paginationInput.interface";
import { ICreateUserByAdminInput, IUpdateUserInput } from "./interfaces/usersInput.interface";

/* GRAPHQL QUERIES */
import GET_USER_BY_ID from './graphql/get-user-by-id.graphql';
import GET_USERS from './graphql/get-users.graphql';
/* GRAPHQL MUTATION */
import CREATE_USER_BY_ADMIN from './graphql/create-user-by-admin.graphql';
import UPDATE_USER_BY_ADMIN from './graphql/update-user-by-admin.graphql';
import REMOVE_USER_BY_ADMIN from './graphql/remove-user-by-admin.graphql';
import GET_USERS_BY_COMPARE from './graphql/get-users-by-compare.graphql';
import GET_REC_USERS_BY_COMPARE from './graphql/get-rec-users-by-compare.graphql';
import SORT_USERS from './graphql/sort-users.graphql';
import COUNT_USERS from './graphql/count-users.graphql';

/**
 * User queries service
 */
@Injectable({
  providedIn: "root"
})
export class UserQueriesService {

  /** Pagination base for table */ private _pagination: IPaginationInput = { page: 1, limit: 20 };
  /** The search string type by the user to use to filter the table result */ private _queryString: string = null; 
  /** The order to user to sort the table result*/ private _order: {key: string, direction: string} = null ;

  /** GETTERS AND SETTERS */
  /** pagination getter */ public get pagination(): IPaginationInput { return this._pagination; }
  /** queryString getter */ public get queryString(): string { return this._queryString; }
  /** order getter */ public get order(): {key: string, direction: string} { return this._order; }
  /** queryString setter */ public set queryString(queryString: string) { this._queryString = queryString; }
  /** pagination setter */ public set pagination(pagination: IPaginationInput) { this._pagination = pagination; }
  /** order setter */ public set order(order: {key: string, direction: string}) { this._order = order; }

  /**
   * constructor method
   * @param _apollo
   */
  constructor(private _apollo: Apollo) { }

  /**
   * Get an user by id
   * @param id 
   * @returns 
   */
  public getUserById(id: number) {
    return this._apollo.query({
      query: GET_USER_BY_ID,
      variables: { id },
      fetchPolicy: "network-only"
    });
  }

  /**
   * Get users by pagination
   * @param pagination
   * @returns 
   */
  public users() {

    return this._apollo.watchQuery({
      query: GET_USERS,
      variables: { 
        search: this.queryString,
        order: this.order,
        pagination: this.pagination
      },
      fetchPolicy: 'cache-and-network'
    });
  }

  /**
   * search a user
   * @param pagination the pagination to apply
   * @param queryString the query string
   * @returns the found user
   */
  public searchUsers(pagination: IPaginationInput, queryString?: string) {
    let variables = { pagination };
    if (queryString) variables['search'] = queryString;

    return this._apollo.query({
      query: GET_USERS,
      variables,
      fetchPolicy: "network-only"
    });
  }

  /**
   * Get installation with a compare string
   * @param compareString 
   * @returns 
   */
  public async getRecUsersByCompare(compareString?: string) {
    return await this._apollo.query({
      query: GET_REC_USERS_BY_COMPARE,
      variables: { compareString },
      fetchPolicy: "network-only"
    }).toPromise();
  }

  /**
   * Create an user
   * @param user 
   * @returns 
   */
  public createUserByAdmin(user: ICreateUserByAdminInput) {
    return this._apollo.mutate({
      mutation: CREATE_USER_BY_ADMIN,
      variables: { user },
      fetchPolicy: "no-cache",
      refetchQueries: [
        { query: COUNT_USERS },
        {
          query: GET_USERS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

  /**
   * Update an user
   * @param user 
   * @returns 
   */
  public updateUserByAdmin(user: IUpdateUserInput) {
    return this._apollo.mutate({
      mutation: UPDATE_USER_BY_ADMIN,
      variables: { user },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: GET_USERS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

  /**
 * count dashboar current user
 * @returns
 */
  public countUsers() {
    return this._apollo.watchQuery({
      query: COUNT_USERS,
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Delete an user
   * @param mail 
   * @returns 
   */
  public deleteUser(mail: string) {
    return this._apollo.mutate({
      mutation: REMOVE_USER_BY_ADMIN,
      variables: { mail },
      fetchPolicy: "no-cache",
      refetchQueries: [
        { query: COUNT_USERS },
        {
          query: GET_USERS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

}
