import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { ISelectOption } from 'src/app/libs/input/facades';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { EnumGraphType } from 'src/app/libs/widgets/facades/enums';
import { ConsumptionQueriesService } from 'src/app/queries/consumption/consumption-queries.service';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { IPaginationInput } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { TranslationService } from '../../../translation/services/translation.service';
import { MyOverlayRef } from '../../overlay/myoverlay-ref';
import { EnumPeriod } from '../facades/enums/period.enum';
import { ICreateEditGraphWidget } from '../facades/interfaces/createWidget.interface';
import { GraphConfigService } from '../facades/services/graph-config.service';

/**
 * Config for graph creation form (modal)
 */
@Component({
  selector: 'app-cer-graph-config',
  templateUrl: './cer-graph-config.component.html',
  styleUrls: ['./cer-graph-config.component.scss']
})
export class CerGraphConfigComponent implements OnInit {
  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** Datas from edit modal */ @Input() public editDatas: any;
  /** FormGroup of graph */ public graphFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;
  /** Installation to select */ public installationsToSelect: ISearchSelectItem[] = [];
  /** Subject to listen for search with compare string */ public searchInstallationsSubject = new Subject<string>();
  /** Pagination used on search installations */ public pagination: IPaginationInput = { page: 1, limit: 20 };
  /** getter for controls of graph form group */ get formControls() { return this.graphFormGroup.controls; }
  /** Pagination used on table */ public paginationInput: IPaginationInput = { page: 1, limit: 100 };
  /** Icon for closing modal */ public faTimes: IconDefinition = faTimes;
  /** the _installationIds of the widget */ private _installationIds: number[] = [];
  /** the _consumptionIds of the widget */ private _consumptionIds: number[] = [];
  /** the installationSelected of the widget */ public installationSelected: any = [];
  /** the consumptionSelected of the widget */ public consumptionSelected: any = [];
  
  /**
   * constructor
   * @param _translationSrv translation service
   * @param _fb the form builder
   * @param _errorMessageSrv the error message service
   * @param _installationQueriesSrv the installation queries service
   * @param _consumptionQueriesSrv the consumption queries service
   * @param graphConfigSrv the graph config service
   */
  constructor(
    private _translationSrv: TranslationService,
    private _fb: FormBuilder,
    protected _errorMessageSrv: ErrorMessageService,
    private _installationQueriesSrv: InstallationQueriesService,
    private _consumptionQueriesSrv: ConsumptionQueriesService,
    public graphConfigSrv: GraphConfigService
  ) {
    this.inputErrorsLabelMap = new Map<string, string>([
      ["title", this._translationSrv.getInstant("installation_power-input")],
      ["period", this._translationSrv.getInstant("installation_power-input")],
      ["cols", this._translationSrv.getInstant("installation_power-input")],
      ["rows", this._translationSrv.getInstant("installation_power-input")],
      ["installationItem", this._translationSrv.getInstant("installation_power-input")],
      ["consumptionItem", this._translationSrv.getInstant("installation_power-input")],
    ]);
  }

  /**
   * On Init method
   */
  ngOnInit(): void {
    this.initFormGroup();
  }

  /**
   * Init form group of graph config
   */
  public initFormGroup(): void {
    const presetDatas = this.editDatas && this.editDatas.widgetDataConfig ? this.editDatas.widgetDataConfig : null;
    this.graphFormGroup = this._fb.group({
      title: [presetDatas ? presetDatas.title : "", Validators.required],
      period: [presetDatas ? presetDatas.period : {}, Validators.required],
      cols: [this.editDatas && this.editDatas.cols != null ? this.editDatas.cols : 4, Validators.required],
      rows: [this.editDatas && this.editDatas.rows != null ? this.editDatas.rows : 4, Validators.required],
      installationItem: [[], Validators.required],
      consumptionItem: [[], Validators.required]
    });

    if (presetDatas) {
      this._presetInstallationsDatas(presetDatas.installationsIds);
      this._presetConsumptionsDatas(presetDatas.consumptionsIds);
    }

    this._initFormValueChanged();
  }

  /**
   * Init the listener of the form
   */
  private _initFormValueChanged(){
    this.graphFormGroup.get("installationItem").valueChanges.subscribe(res => {
      this._installationIds = res? res.map(item => item.data.id) : [];
      this.installationSelected = res? res : [];
    });

    this.graphFormGroup.get("consumptionItem").valueChanges.subscribe(res => {
      this._consumptionIds = res? res.map(item => item.data.id) : [];
      this.consumptionSelected = res? res : [];
    });
  }

  /**
   * preset the installations datas
   * @param installationsIds installation ids
   */
  private _presetInstallationsDatas(installationsIds: number[]){
    this._installationQueriesSrv.getInstallationsByIds(installationsIds).subscribe((res: any) => {
      const { getInstallationsByIds } = res.data;
      if (getInstallationsByIds) {
        this.installationSelected = getInstallationsByIds.map(installation => {
          return {
            id: installation.id,
            label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
            data: installation
          }
        })
        this.graphFormGroup.get("installationItem").patchValue(this.installationSelected);
      }
    }, error => {
      console.log(error);
    })
  }

  /**
   * preset the consumptions datas
   * @param consumptionsIds consumptions ids
   */
  private _presetConsumptionsDatas(consumptionsIds: number[]){
    this._consumptionQueriesSrv.getConsumptionsByIds(consumptionsIds).subscribe((res: any) => {
      const { getConsumptionsByIds } = res.data;
      if (getConsumptionsByIds) {
      
        this.consumptionSelected = getConsumptionsByIds.map(consumption => {
          return {
            id: consumption.id,
            label: `${consumption.name} (${this._translationSrv.getInstant("serialNumber")}: ${consumption.serialNumber})`,
            data: consumption
          }
        });
        this.graphFormGroup.get("consumptionItem").patchValue(this.consumptionSelected)
      }
    }, error => {
      console.log(error);
    })
  }

  /**
   * Validate form and do query to get computed datas from form values
   * @returns 
   */
  public validateForm(duplicate: boolean = false) {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.graphFormGroup, this.inputErrorsLabelMap);

    if (this.graphFormGroup.invalid) {
      console.log("graph-config.component.ts: graphFormGroup is invalid.");
      return;
    }

    const graphToCreate: ICreateEditGraphWidget = {
      cols: this.graphFormGroup.value.cols,
      rows: this.graphFormGroup.value.rows,
      widgetDataConfig: {
        type: EnumGraphType.AREA,
        title: this.graphFormGroup.get("title").value ? this.graphFormGroup.get("title").value : "Default title of graphic",
        period: this.graphFormGroup.get("period").value,
        installationsIds: this._installationIds,
        consumptionsIds: this._consumptionIds
      }
    }
    if (this.editDatas && this.editDatas.id && !duplicate) graphToCreate['id'] = this.editDatas.id;
    this.close(graphToCreate);
  }

  /**
   * Close the modal and send a value to listeners
   * @param value 
   */
  public close(value: any) {
    this.ref.close(value);
  }

  /**
   * Search an installation based on search params (q) 
   * @param q the search string to find the installation
   * @param idsSelected the installation ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the installation results
   */
  public searchInstallations = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._installationQueriesSrv.searchInstallations({ page: 1, limit: 1000 }, q).toPromise();
    let installations: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getInstallations.installations && (<any>resultQuery.data).getInstallations.installations)
      installations = (<any>resultQuery.data).getInstallations.installations

    installations = installations.map((installation: any) => {
      return {
        id: installation.id,
        label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
        data: installation
      };
    })
    return installations;
  }

  /**
   * Search a consumption profile based on search params (q) 
   * @param q the search string to find the consumption
   * @param idsSelected the consumption ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the consumption results
   */
  public searchConsumptions = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._consumptionQueriesSrv.searchConsumptions({ page: 1, limit: 1000 }, q).toPromise();
    let consumptions: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getConsumptions.consumptions && (<any>resultQuery.data).getConsumptions.consumptions)
      consumptions = (<any>resultQuery.data).getConsumptions.consumptions

    consumptions = consumptions.map((consumption: any) => {
      return {
        id: consumption.id,
        label: `${consumption.name} (${this._translationSrv.getInstant("serialNumber")}: ${consumption.serialNumber})`,
        data: consumption
      };
    })
    return consumptions;
  }

}