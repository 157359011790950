import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { USER_TOKEN } from "../pages/auth/login/login.component";

/**
 * JWT Interceptor that check if authorization taken is passed in headers of the request
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  /**
   * Constructor method
   * @param _injector 
   * @param _authSrv 
   */
    public constructor(
        private _injector: Injector,
        private _authSrv: AuthService
    ) { }

    /**
     * Interceptor method called on every method
     * @param req 
     * @param next 
     * @returns 
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       return next.handle(req).pipe(map( event => {
            if (event instanceof HttpResponse) {
              const newToken = event.headers.get('authorization');
              if (newToken !== null && newToken !== "null") {
                localStorage.setItem(USER_TOKEN, newToken);
                this._authSrv.userLogged.next({
                  ...this._authSrv.userLogged.value,
                  token: newToken
                })
              }
            }
            return event;
        }));
    }
}
