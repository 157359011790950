import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEditUsersComponent } from './add-edit-users/add-edit-users.component';
import { ListUsersComponent } from './list-users/list-users.component';

const routes: Routes = [
  {
    path: 'users',
    component: ListUsersComponent,
    children: [
      { path: 'add', component: AddEditUsersComponent },
      { path: 'detail/:id', component: AddEditUsersComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
