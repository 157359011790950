<div class="date-picker__calendar" [ngStyle]="{'position': relative ? 'relative' : 'absolute'}">
        <div class="date-picker__calendar_header">
            <!-- <i class="fas fa-angle-left" (click) = "changeNavMonth(-1)"></i> -->
            <fa-icon [icon]="faChevronLeft" (click) = "changeNavMonth(-1)"></fa-icon>
            <div class="month">{{navigationDate.format('MMMM YYYY')}}</div>
            <!-- <i class="fas fa-angle-right" (click) = "changeNavMonth(1)"></i> -->
            <fa-icon [icon]="faChevronRight" (click) = "changeNavMonth(1)"></fa-icon>
        </div>
        <div class="date-picker__calendar_content">
            <div class="days">
                <div class="days__item" *ngFor="let headerDay of weekDaysHeaderArr">
                        {{headerDay}}
                </div>
            </div>
            <div class="date">
                <div class="date__item" *ngFor="let day of calendarDays"
                [ngClass]="{
                    'date__item_today': day.today, 
                    'date__item_disabled': day.disabled,
                    'date__item_selected' : day.selected
                  }"
                  (click) = "selectDay(day)">
                        {{day.value?day.value : ' '}}
                </div>
            </div>
        </div>
    </div>
