import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { TYPE_BUTTON } from 'src/app/libs/buttons/facades/enums/type-button.enum';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { AuthenticateQueriesService } from 'src/app/queries/authenticate/authenticate-queries.service';
import { AuthService } from 'src/app/services/auth.service';

/**
 * Recover page component
 */
@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  /** true when a verification of information is launched */ public isSubmitted: boolean = false;
  /** true when an user clicks on "I have already a verification code" button */ public verificationCodeSubmitted: boolean = false;
  /** FormGroup of user to login */ public recoverFormGroup: FormGroup;
  /** The list of filter to apply */ TYPE_BUTTON: typeof TYPE_BUTTON = TYPE_BUTTON;

  
  /** Error if there is one */ public error: any = {
    message: "",
    isError: false
  }

  /**
   * constructor
   * @param translationSrv translation service
   * @param _fb the form builder
   * @param _router the router
   * @param _authQueriesSrv the authenticate queries service
   * @param _authSrv the authenticate service
   * @param _alertSrv the alert service
   * @param _errorMessageSrv the error message service
   * @param _translationSrv the translation service
   */
  constructor(
    public translationSrv: TranslationService,
    private _fb: FormBuilder,
    private _router: Router, 
    private _authQueriesSrv: AuthenticateQueriesService,
    private _authSrv: AuthService,
    private _alertSrv: AlertService,
    private _errorMessageSrv: ErrorMessageService,
    private _translationSrv: TranslationService 
  ) { }

  /**
   * Used to get controls of recover form group
   */
  get formControls() { return this.recoverFormGroup.controls; }

  /**
   * On init method.
   */
  ngOnInit(): void {
    this.recoverFormGroup = this._fb.group({
      mail: ["", Validators.required],
      verificationCode: ["", Validators.required],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  /**
   * Called to navigate to another page
   * @param page URL
   */
   goTo(page: string){
    this._router.navigate([page]);
  }

  /**
   * Validate form
   * @returns 
   */
  validateForm() {
    this.isSubmitted = true;
    if (this.recoverFormGroup.invalid) {
      this.error.message = this.translationSrv.getInstant("missing-field");
      this.error.isError = true;
      return;
    } else if (this.recoverFormGroup.value.password !== this.recoverFormGroup.value.confirmPassword) {
      this.error.message = this.translationSrv.getInstant("password-match");
      this.error.isError = true;
      return;
    }

    this._authQueriesSrv.recoverPassword(this.recoverFormGroup.value.mail, this.recoverFormGroup.value.password, this.recoverFormGroup.value.verificationCode)
    .subscribe(result => {
      if (result.errors && result.errors.length > 0) {
        console.log('error gql', result.errors);
        this._alertSrv.danger(this._translationSrv.getInstant("errorPasswordNotReseted"));
      } else if (result.message) {
        this.error.message = result.message;
        this.error.isError = true;
      } else {
        const data: any = <any>result.data;
        if (data && data.recoverPassword) {
          localStorage.setItem("ideta_modulec_userId", data.recoverPassword.id);
          localStorage.setItem("ideta_modulec_userMail", data.recoverPassword.mail);
          localStorage.setItem("ideta_modulec_userRole", data.recoverPassword.role);
          localStorage.setItem("ideta_modulec_firstname", data.recoverPassword.lastName);
          localStorage.setItem("ideta_modulec_lastname", data.recoverPassword.name);
          localStorage.setItem("idetaTokenModuleC", data.recoverPassword.token);
          this.error.message = "";
          this.error.isError = false;
          this._authSrv.userLogged.next({
            token: data.recoverPassword.token,
            id: data.recoverPassword.id,
            mail: data.recoverPassword.mail,
            role: data.recoverPassword.role,
            name: data.recoverPassword.name,
            lastName: data.recoverPassword.lastName,
            avatar: data.recoverPassword.avatar
          })
          this.goTo("/dashboard");
          this._alertSrv.success(this._translationSrv.getInstant("successPasswordChanged"));
        }
      }
    })
  }

  /**
   * Open more options for the form
   * @param sendEmail 
   */
  sendCode(sendEmail: boolean) {
    if(!sendEmail) {this.verificationCodeSubmitted = true;}
    if (sendEmail === true && this.recoverFormGroup.value.mail !== "") {
      this.verificationCodeSubmitted = true;
      
      this._authQueriesSrv.sendMailCode(this.recoverFormGroup.value.mail)
      .subscribe(result => {
        if(!result.data.sendMailCode) {
          return false
        } else return true
      })
    } 
  }
}
