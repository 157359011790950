<div class="companies-add-edit">
    <div class="side-panel" id="edit-box">
        <h1 class="side-panel__header">
            {{ titleKey | translate}}
        </h1>
        <div class="side-panel__content">
            <div class="grid grid_col_1" class="box">
                <h4 class="title">{{ 'consumptionPalamedeSearchTitle' | translate }}</h4>
                <div class="grid grid_col_2">
                    <div>
                        <app-label>{{ "consumptionSectorLabel" | translate }}</app-label>
                        <app-radio-input id="sector"
                          [group]="formGroup" formControlName="sector" [name]="'sector'"
                          [options]="sectorOptions" [isInline]="true"
                          [canEdit]=canEdit
                          ></app-radio-input>
                      </div>
                    <div>
                        <app-label>{{ "addConsumptionPalamedeFormLabel" | translate }}</app-label>
                        <app-search-select
                            [query]="searchPalademeConsumptionProfiles"
                            formControlName="consumptionItem"
                            [group]="formGroup"
                            [name]="'consumptionItem'"
                            id="consumptionItem"
                            [selected]="selectedConsumptions"
                            [maxSelected]="1"
                            [currentItem]="currentConsumption"
                            [resetSelected]="resetSelected"
                            [canEdit]=canEdit
                            [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.name }"> 
                        </app-search-select>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.consumptionItem " class="invalid-label">
                            {{ inputErrorsLabel.consumptionItem.msg | translate }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="grid grid_col_1" class="box gap_top">
                <h4 class="title">{{ 'generalInfosTitle' | translate }}</h4>
                <div class="grid grid_col_2">
                    <div>
                        <app-label>{{ "name" | translate }} *</app-label>
                        <app-text-input
                            formControlName="name"
                            [group]="formGroup"
                            [name]="'name'"
                            id="name"
                            [ngClass]="{'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.name}"> </app-text-input>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.name" class="invalid-label">
                            {{ inputErrorsLabel.name.msg | translate }}
                        </div>
                    </div>
                    <div>
                        <app-label>{{ "serialNumber" | translate}} *</app-label>
                        <app-text-input
                            formControlName="serialNumber"
                            [group]="formGroup"
                            [name]="'serialNumber'"
                            id="serialNumber"
                            [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.serialNumber}"></app-text-input>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.serialNumber" class="invalid-label">
                            {{ inputErrorsLabel.serialNumber.msg | translate}}
                        </div>
                    </div>
                </div>
            </div>

            <app-linked-users *ngIf="canEdit" (usersChanged)="linkedUsersChanged($event)" [selectedUsers]="formattedSelectedUsers" [canEdit]=canEdit></app-linked-users>

            <div *ngIf="error !== ''" class="grid grid_col_1" style="margin-bottom: 2em;">
                <div>
                    <app-label>{{ "errorLabel" | translate}} *</app-label>
                    <h3>{{error}}</h3>
                </div>
            </div>

            <div class="box gap_top" *ngIf="consumptionId !== null">
                <h4 class="title">{{ 'alarm-title' | translate }}</h4>
                <app-label>{{ "contactEmails" | translate}} *</app-label>
                <app-text-input
                    formControlName="serialNumber"
                    [group]="formGroup"
                    [name]="'alarmMail'"
                    id="alarmMail"
                    [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.alarmMail}"></app-text-input>
                <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.alarmMail" class="invalid-label">
                    {{ inputErrorsLabel.alarmMail.msg | translate}}
                </div>
                <div class="gap_top">
                    
                    <div class="converter">
                        <div [ngClass]="{'disable': formGroup.get('alarm').value === false}" (click)="activateAlarm()"></div>
                        <app-checkbox-input
                            style="z-index: 1000; cursor: pointer"
                            [label]="'Alarme de consommation à 0'"
                            [name]="'alarm'"
                            [group]="formGroup">
                        </app-checkbox-input>

                        <app-select-input
                            *ngIf="formGroup.get('alarm').value === true"
                            class="converter_selector"
                            [options]="hoursOptions"
                            [autoSelectFirst]="true"
                            [group]="formGroup"
                            [name]="'beginRange'"
                            id="beginRange">
                        </app-select-input>

                        <app-select-input 
                            *ngIf="formGroup.get('alarm').value === true"
                            class="converter_selector"
                            [options]="hoursOptions"
                            [autoSelectFirst]="true"
                            [group]="formGroup"
                            [name]="'endRange'"
                            id="endRange">
                        </app-select-input>
                        <span [ngClass]="{'border-disabled': formGroup.get('alarm').value === false, 'border-enabled': formGroup.get('alarm').value === true}" class="border"></span>
                    </div>
                
                </div>
            </div>

            <div class="grid grid_col_2" style="padding: 2rem;">
                <app-button [action]="__TYPE_BUTTON.DELETE" (click)="goTo(pagesEnum.MAIN)">{{ "cancel" | translate}}
                </app-button>
                <app-button *ngIf="canEdit" [action]="__TYPE_BUTTON.VALID" (click)="validateForm()">{{ "save" | translate}}</app-button>
            </div>
        </div>
    </div>
</div>
