/**
 * list of graph colors
 */
export const GraphBackgroundColor: string[] = [
    'rgba(79, 146, 56, 0.2)',
    'rgba(232, 163, 0, 0.2)',
    'rgba(243, 223, 39, 0.2)',
    'rgba(245, 53, 102, 0.2)',
    'rgba(83, 139, 215, 0.2)',
    'rgba(181, 192, 28, 0.2)',
    'rgba(36, 200, 93, 0.2)',
    'rgba(253, 132, 59, 0.2)',
    'rgba(180, 100, 153, 0.2)',
    'rgba(169, 237, 138, 0.2)',
    'rgba(98, 55, 161, 0.2)',
    'rgba(185, 15, 108, 0.2)',
    'rgba(167, 114, 117, 0.2)',
    'rgba(49, 5, 8, 0.2)',
    'rgba(94, 227, 205, 0.2)',
    'rgba(88, 236, 173, 0.2)',
    'rgba(136, 117, 9, 0.2)',
    'rgba(173, 233, 186, 0.2)',
    'rgba(216, 169, 0, 0.2)',
    'rgba(208, 154, 133, 0.2)',
    'rgba(233, 239, 163, 0.2)',
    'rgba(198, 162, 24, 0.2)',
    'rgba(80, 63, 168, 0.2)',
    'rgba(79, 119, 214, 0.2)',
    'rgba(96, 152, 241, 0.2)',
    'rgba(81, 65, 64, 0.2)',
    'rgba(16, 26, 247, 0.2)',
    'rgba(239, 59, 101, 0.2)',
    'rgba(128, 178, 53, 0.2)',
    'rgba(177, 55, 81, 0.2)',
];

/**
 * list of background graph colors
 */
export const GraphBorderColor: string[] = [
    'rgba(79, 146, 56, 1)',
    'rgba(232, 163, 0, 1)',
    'rgba(243, 223, 39, 1)',
    'rgba(245, 53, 102, 1)',
    'rgba(83, 139, 215, 1)',
    'rgba(181, 192, 28, 1)',
    'rgba(36, 200, 93, 1)',
    'rgba(253, 132, 59, 1)',
    'rgba(180, 100, 153, 1)',
    'rgba(169, 237, 138, 1)',
    'rgba(98, 55, 161, 1)',
    'rgba(185, 15, 108, 1)',
    'rgba(167, 114, 117, 1)',
    'rgba(49, 5, 8, 1)',
    'rgba(94, 227, 205, 1)',
    'rgba(88, 236, 173, 1)',
    'rgba(136, 117, 9, 1)',
    'rgba(173, 233, 186, 1)',
    'rgba(216, 169, 0, 1)',
    'rgba(208, 154, 133, 1)',
    'rgba(233, 239, 163, 1)',
    'rgba(198, 162, 24, 1)',
    'rgba(80, 63, 168, 1)',
    'rgba(79, 119, 214, 1)',
    'rgba(96, 152, 241, 1)',
    'rgba(81, 65, 64, 1)',
    'rgba(16, 26, 247, 1)',
    'rgba(239, 59, 101, 1)',
    'rgba(128, 178, 53, 1)',
    'rgba(177, 55, 81, 1)',
];

/** color of the prediction (or prevision) graph */ export const PredictionColor: string = "rgba(0, 0, 0, 1)";
/** background color of the prediction (or prevision) graph */ export const PredictionBackgroundColor: string = "rgba(0, 0, 0, 0.5)";
/** color of the PTC graph */ export const PTCColor: string = "rgba(128, 128, 128, 1)";
/** background color of the PTC graph */ export const PTCBackgroundColor: string = "rgba(128, 128, 128, 0.2)";
