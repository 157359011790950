<h4 class="converters-list__header">{{ "convertersTitle" | translate }}</h4>
<div class="converters-list">
  <div *ngIf="converters && converters.length > 0">
    <ng-container *ngFor="let converter of converters; let index = index;">
      <div [ngClass]="{'converter-even': index%2 == 0, 'converter-odd': index%2 != 0}">
        <div class="grid grid_col_4 converter-box__header">
          <div class="grid__child_col_1">{{ "converterNameLabel" | translate }} : {{ converter.name }}</div>
          <div class="grid__child_col_2">{{ "converterIdLabel" | translate }} : {{ converter.id }}</div>
          <div class="grid__child_col_1">{{ "converterPowerLabel" | translate }} : {{ converter.power }} kWc</div>
        </div>
        <div class="converter-box__content">

          <div class="converters-list__content grid grid_col_4">
            <div class="label-gap-top">
              <app-label>{{ "converterBrandLabel" | translate }}</app-label>
              <app-text-input [group]="convertersFormGroup" [name]="converter.id + '_ups_brand'" [id]="converter.id + '_ups_brand'"
                [ngClass]="{ 'invalid-input': false }" ></app-text-input>
            </div>
            <div class="label-gap-top">
              <app-label>{{ "ups_model" | translate }}</app-label>
              <app-text-input [group]="convertersFormGroup" [name]="converter.id + '_ups_model'" [id]="converter.id + '_ups_model'"
                [ngClass]="{ 'invalid-input': false }" ></app-text-input>
            </div>
            <div class="label-gap-top">
              <app-label>{{ "converterSerieNumberLabel" | translate }}</app-label>
              <app-text-input [group]="convertersFormGroup" [name]="converter.id + '_ups_serial'" [id]="converter.id + '_ups_serial'"
                [ngClass]="{ 'invalid-input': isSubmitted && convertersFormGroup.get([converter.id + '_ups_serial']).errors }" ></app-text-input>
              <div *ngIf="isSubmitted && convertersFormGroup.get([converter.id + '_ups_serial']).errors" class="invalid-label">
                {{ "general_requiredInputError-txt" | translate }}
              </div>
            </div>
            <div>
              <app-label>{{ "converterKvaPower" | translate }}</app-label>
              <!-- <app-text-input [group]="convertersFormGroup" [name]="converter.id + '_ups_kvaPower'" [id]="converter.id + '_ups_kvaPower'"  -->
                <!-- [ngClass]="{ 'invalid-input': false }" ></app-text-input> -->
              <app-number-input [group]="convertersFormGroup" [name]="converter.id + '_ups_kvaPower'" [id]="converter.id + '_ups_kvaPower'" [ngClass]="{ 'invalid-input': false }" [step]="0.1" [min]="0"></app-number-input>
            </div>
          </div>

          <div class="sub-container-top">{{ "panels" | translate }}</div>
          <div class="sub-container">
            <div class="converters-list__content grid grid_col_3" style="padding-top: 5px; align-items: flex-start;">

              <div>
                <app-label>{{ "panelBrandLabel" | translate }}</app-label>
                <app-text-input [group]="convertersFormGroup" [name]="converter.id + '_panelBrandLabel'" [id]="converter.id + '_panelBrandLabel'"
                  [ngClass]="{ 'invalid-input': false }" >
                </app-text-input>
              </div>
  
              <div>
                <app-label>{{ "panelModelLabel" | translate }}</app-label>
                <app-text-input [group]="convertersFormGroup" [name]="converter.id + '_panelModelLabel'" [id]="converter.id + '_panelModelLabel'"
                  [ngClass]="{ 'invalid-input': false }" >
                </app-text-input>
              </div>
  
              <div>
                <app-label>{{ "panelPowerLabel" | translate }}</app-label>
                <app-text-input [group]="convertersFormGroup" [name]="converter.id + '_panelPowerLabel'" [id]="converter.id + '_panelPowerLabel'"
                  [ngClass]="{ 'invalid-input': false }" >
                </app-text-input>
              </div>
  
            </div>
            <div class="converters-list__content grid grid_col_4  label-gap-top" style="padding-top: 5px; padding-bottom: 10px;">
  
              <div>
                <app-label>{{ "panelQtyLabel" | translate }}</app-label>
                <app-text-input [group]="convertersFormGroup" [name]="converter.id + '_panelQtyLabel'" [id]="converter.id + '_panelQtyLabel'"
                  [ngClass]="{ 'invalid-input': false }" >
                </app-text-input>
              </div>
              <div>
                <app-label>{{ "orientation" | translate }}</app-label>
                <app-number-input [group]="convertersFormGroup" [name]="converter.id + '_orientation'" [id]="converter.id + '_orientation'" [ngClass]="{ 'invalid-input': false }" [step]="1" [min]="0" [max]="360"></app-number-input>
              </div>
  
              <div>
                <app-label>{{ "inclination" | translate }}</app-label>
                <app-number-input [group]="convertersFormGroup" [name]="converter.id + '_inclination'" [id]="converter.id + '_inclination'" [ngClass]="{ 'invalid-input': false }" [step]="1" [min]="0" [max]="360"></app-number-input>
              </div>
  
              <div>
                <app-label>{{ "panelTempCoeff" | translate }} *</app-label>
                <app-number-input [group]="convertersFormGroup" [name]="converter.id + '_panelTempCoeff'" [id]="converter.id + '_panelTempCoeff'" [ngClass]="{ 'invalid-input': isSubmitted && convertersFormGroup.get([converter.id + '_panelTempCoeff']).errors }" [step]="0.1"></app-number-input>
                <div *ngIf="isSubmitted && convertersFormGroup.get([converter.id + '_panelTempCoeff']).errors" class="invalid-label">
                  {{ "general_requiredInputError-txt" | translate }}
                </div>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
