import { Component, ElementRef, EventEmitter, Input, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import moment from 'moment';
import { AInputs } from '../facades';
import { ErrorMessageService } from '../facades/services/error-message.service';

/**
 * Component that display a calendar input
 * Basic Usage 
 * <app-calendar-input></app-calendar-input>
 */
 @Component({
  selector: 'app-calendar-input',
  templateUrl: './calendar-input.component.html',
  styleUrls: ['./calendar-input.component.scss']
})
export class CalendarInputComponent extends AInputs {
  /** boolean true if the user can edit the installation otherwise false */ @Input() public canEdit: boolean = true
  /** Date for min paramater on calendar */ @Input() public minDate: Date | null;
  /** Date for max paramater on calendar */ @Input() public maxDate: Date | null;
  /** Type of the date input */ @Input() type: string = "date"
  /** The current date value to preset on the input */ @Input() value: string = null;
  /** If the input take all the place available */ @Input() fullSize: boolean = true;

  /** Event triggered when page is changed */ @Output() public onDateChanges: EventEmitter<string> = new EventEmitter<string>();

  /** Date FORMATED for min paramater on calendar (ex: 2018-05-05) */ public minDateFmt: string;
  /** Date FORMATED for max paramater on calendar (ex: 2018-05-05) */ public maxDateFmt: string;

  /** Selected date value (ex: 2018-05-05) */ public selectedValue: string;
  /** calendar view */ @ViewChild('myInput', { static: true }) myInput: ElementRef<HTMLInputElement>;

  /**
    * Constructor method
    * @param {Renderer2} renderer Custom rendering
    * @param {ErrorMessageService} _errorMessageSrv Service of error messages
    */
  constructor(public renderer: Renderer2, protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);         
  }

  /**
    * On Init method.
    * Set min and max parameter with formated format.
    */
  ngOnInit() {
    this.minDateFmt = this.minDate ? this.minDate.toISOString().slice(0, 10) : "";
    this.maxDateFmt = this.maxDate ? this.maxDate.toISOString().slice(0, 10) : "";
    this.selectedValue = this.value? moment(this.value).format('yyyy-MM-DD') : "";
  }

  /**
   * on change
   * @param changes the changes
   */
  ngOnChanges(changes: SimpleChanges){
    this.selectedValue = moment(this.value).format('yyyy-MM-DD');
  }

  /**
   * Called when there is changes on date.
   * @param e event with date changes.
   */
  dateChanges(e) {
    if (e.srcElement.value) {
      this.onDateChanges.emit(e.srcElement.value)
      this.selectedValue = e.srcElement.value;
    }
  }

  /**
   * Set selectedValue to date parameter value
   * @param date date to set as selected (ex: 2018-05-05)
   */
  setNewSelectedDate(date: string) {
    this.selectedValue = date;
  }

  /**
   * Called to reset every value of component.
   */
  reset() {
    this.selectedValue = "";
    this.minDateFmt = "";
    this.maxDateFmt = "";
  }

  /**
   * test function
   */
  public test(){
    (<any>this.myInput.nativeElement).datepicker("show");
  }
}

