import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CounterComponent } from './counter/counter.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CounterRangeComponent } from './counter-range/counter-range.component';
import { CounterSelectTopComponent } from './counter-select-top/counter-select-top.component';
import { CounterSelectInComponent } from './counter-select-in/counter-select-in.component';
import { CounterIconComponent } from './counter-icon/counter-icon.component';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [
    CounterComponent,
    CounterRangeComponent,
    CounterSelectTopComponent,
    CounterSelectInComponent,
    CounterIconComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    InputModule
  ],
  exports: [
    CounterComponent
  ]
})
export class CounterModule { }
