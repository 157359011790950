import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './recover/recover.component';
import { ButtonsModule } from 'src/app/libs/buttons/buttons.module';
import { InputModule } from 'src/app/libs/input/input.module';
import { TranslationModule } from 'src/app/libs/translation/translation.module';


@NgModule({
  declarations: [
    LoginComponent,
    RecoverComponent,
    RecoverComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    InputModule,
    ButtonsModule,
    TranslationModule
  ]
})
export class AuthModule { }
