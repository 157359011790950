export enum FILES_BLOCK_ACTIONS_ENUM {
    DELETE = "Delete",
    ZOOM = "Zoom",
};

export enum STATUS_ALERTS {
    SUCCESS = "SUCCESS",
    INFO = "INFO",
    WARNING = "WARNING",
    DANGER = "DANGER",
    DEFAULT = "DEFAULT",
}