import { Component, Input, ViewChildren, QueryList, ElementRef, Renderer2, OnChanges, SimpleChanges, OnInit } from "@angular/core";
import { AInputs } from "../facades";
import { ISelectOption } from "../facades/interfaces/selectOption.interface";
import { ErrorMessageService } from "../facades/services/error-message.service";

/**
 * Input radio component
 */
@Component({
  selector: "app-radio-input",
  templateUrl: "./radio-input.component.html",
  styleUrls: ["./radio-input.component.scss"]
})
export class RadioInputComponent extends AInputs implements OnInit, OnChanges {
  /** Option of radio */ @Input() public options: ISelectOption[];
  /** true if inline option is selected */ @Input() public isInline: boolean = false;
  /** true if in grid option is selected */  @Input() public isGrid: boolean = false;
  /** Elementref of input */ @ViewChildren("input") protected inputRadioReference: QueryList<ElementRef>;
  /** Selected value if there is one */ public selectedValue: string | number;
  /** boolean true if the user can edit the installation otherwise false */ @Input() public canEdit: boolean = true

  /**
   * Constructor method
   * @param renderer 
   * @param _errorMessageSrv 
   * @param _elementRef 
   */
  constructor(protected renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService,
              protected _elementRef: ElementRef) {
    super(renderer, _errorMessageSrv);
  }

  /**
   * On init method
   */
  ngOnInit(): void {
    if (this.isInline) {
      this.renderer.addClass(this._elementRef.nativeElement, "radio-inline");
    }
    if (this.isGrid) {
      this.renderer.addClass(this._elementRef.nativeElement, "radio-grid");
    }
    this.options.forEach(opt => {
      if (!this.canEdit)
        opt.disable = true
      else
        opt.disable = false
    })
  }

  /**
   * On changes method
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.group && changes.options && this.options && this.canEdit) {
      if (this.group.get(this.name).value !== null && this.group.get(this.name).value !== undefined) {
        this.selectedValue = this.group.get(this.name).value;
      } else {
        this.selectedValue = this.options[0].value;
        this.group.get(this.name).patchValue(this.options[0].value);
        this.group.get(this.name).markAsTouched();
      }
    }
    this.validationCheck();
  }

  /**
   * Called when a radio is selected
   * @param checkboxValue 
   * @param disable 
   */
  public selectCheckbox(checkboxValue, disable = false) {
    if(!disable && this.canEdit){
      this.group.get(this.name).patchValue(checkboxValue);
      this.group.get(this.name).markAsTouched();
    }
  }

  /**
   * Validate form group
   */
  protected validationCheck() {
    this.group.get(this.name).valueChanges.subscribe(result => {
      this.selectedValue = result;
      this.inputRadioReference.toArray().forEach(element => {
        if (!this.group.get(this.name).errors) {
        this.renderer.removeClass(element.nativeElement, "radio__error");
        } else {
          this.renderer.addClass(element.nativeElement, "radio__error");
        }
      });
    });
  }
}
