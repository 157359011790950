<div class="modal-card">
    <section class="modal-card-head">
        <ng-container *ngIf="config.title">
            <div>{{config.title}}</div>
            <fa-icon class="modal-card-head__close" [icon]="faTimes" (click)="close('close')"></fa-icon>
        </ng-container>
    </section>
    <section *ngIf="config.body" class="modal-card-body">
        <div [innerHtml]="config.body"></div>
    </section>
    <footer class="modal-card-foot">
        <div *ngIf="config.buttons">
            <ng-container *ngFor="let button of config.buttons">
                <button (click)="close(button.response)" type="button" class="button" [ngStyle]="{'background-color': button.bgColor, 'color': button.messageColor}">{{button.message}}</button>
            </ng-container>
        </div>
    </footer>
</div>
