<div *ngIf="isDisplayed">
    <div class="box gap_top"> <!-- Email de contacts -->
        <h4 class="title">{{ "contactEmails" | translate }}</h4>
        <div *ngIf="alarmFirstFormGroup && alarmFirstFormGroup.get('contactEmails')">
            <app-label>{{ "addContactEmails" | translate }}</app-label>
            <app-text-input formControlName="contactEmails" [group]="alarmFirstFormGroup" [name]="'contactEmails'" id="contactEmails"
            [ngClass]="{ 'invalid-input': isSubmitted && alarmFirstControls.contactEmails.errors}"></app-text-input>
        </div>
    </div>
    <div class="box gap_top"> <!-- Alarme de production 0 -->
        <h4 class="title">{{ "alarmsType0" | translate }}</h4>
        <div class="grid grid_col_2">
            <div>
                <ng-container *ngFor="let converter of converters; let i = index">
                    <div class="converter" *ngIf="alarmFirstFormGroup && alarmFirstFormGroup.get('converter_' + converter.id) && i % 2 === 0">
                        <div [ngClass]="{'disable': alarmFirstFormGroup.get('converter_' + converter.id).value === false}" (click)="activateAlarm(converter)"></div>
                        <app-checkbox-input
                            style="z-index: 1000; cursor: pointer"
                            [label]="converter.name"
                            [name]="'converter_' + converter.id"
                            [group]="alarmFirstFormGroup">
                        </app-checkbox-input>

                        <app-select-input
                            *ngIf="alarmFirstFormGroup.get('converter_' + converter.id).value === true"
                            class="converter_selector"
                            [options]="hoursOptions" 
                            [autoSelectFirst]="true"
                            [group]="alarmFirstFormGroup" 
                            [name]="'beginRange_' + converter.id" 
                            id="beginRange"
                            [disable]="!canEdit">
                        </app-select-input>

                        <app-select-input 
                            *ngIf="alarmFirstFormGroup.get('converter_' + converter.id).value === true"
                            class="converter_selector"
                            [options]="hoursOptions" 
                            [autoSelectFirst]="true"
                            [group]="alarmFirstFormGroup" 
                            [name]="'endRange_' + converter.id" 
                            id="endRange"
                            [disable]="!canEdit">
                        </app-select-input>
                        <span [ngClass]="{'border-disabled': alarmFirstFormGroup.get('converter_' + converter.id).value === false, 'border-enabled': alarmFirstFormGroup.get('converter_' + converter.id).value === true}" class="border"></span>
                    </div>
                </ng-container>
            </div>
            <div>
                <ng-container *ngFor="let converter of converters; let i = index">
                    <div class="converter" *ngIf="alarmFirstFormGroup && alarmFirstFormGroup.get('converter_' + converter.id) && i % 2 === 1">
                        <div [ngClass]="{'disable': alarmFirstFormGroup.get('converter_' + converter.id).value === false}" (click)="activateAlarm(converter)"></div>
                        <app-checkbox-input
                            style="z-index: 1000; cursor: pointer"
                            [label]="converter.name"
                            [name]="'converter_' + converter.id"
                            [group]="alarmFirstFormGroup">
                        </app-checkbox-input>

                        <app-select-input
                            *ngIf="alarmFirstFormGroup.get('converter_' + converter.id).value === true"
                            class="converter_selector"
                            [options]="hoursOptions" 
                            [autoSelectFirst]="true"
                            [group]="alarmFirstFormGroup" 
                            [name]="'beginRange_' + converter.id" 
                            id="beginRange"
                            [disable]="!canEdit">
                        </app-select-input>

                        <app-select-input 
                            *ngIf="alarmFirstFormGroup.get('converter_' + converter.id).value === true"
                            class="converter_selector"
                            [options]="hoursOptions" 
                            [autoSelectFirst]="true"
                            [group]="alarmFirstFormGroup" 
                            [name]="'endRange_' + converter.id" 
                            id="endRange"
                            [disable]="!canEdit">
                        </app-select-input>
                        <span [ngClass]="{'border-disabled': alarmFirstFormGroup.get('converter_' + converter.id).value === false, 'border-enabled': alarmFirstFormGroup.get('converter_' + converter.id).value === true}" class="border"></span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="converters && converters.length > 1" class="box gap_top"> <!-- Alarme delta kw/kwCrete -->
        <h4 class="title">{{ "alarmsType1" | translate }}</h4>
        <div class="add-alarms" *ngIf="alarmSecondFormGroup && alarmSecondFormGroup.get('percentageDiff')">
            <div class="add-alarms_col">
                <div class="grid" [ngClass]="{'grid_col_1': converters && converters.length === 1, 'grid_col_2': converters && converters.length === 2, 'grid_col_3': converters && converters.length === 3, 'grid_col_4': converters && converters.length >= 4}">
                    <div *ngIf="alarmSecondFormGroup">
                        <ng-container *ngFor="let converter of converters; let i = index">
                            <div [ngClass]="{'gap_top': i >= 4}" class="converterCheckbox" *ngIf="alarmSecondFormGroup && alarmSecondFormGroup.get('converter_' + converter.id) && i % 4 === 0">
                                <app-checkbox-input
                                    [label]="converter.name"
                                    [name]="'converter_' + converter.id"
                                    [group]="alarmSecondFormGroup">
                                </app-checkbox-input>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="alarmSecondFormGroup">
                        <ng-container *ngFor="let converter of converters; let i = index">
                            <div [ngClass]="{'gap_top': i >= 4}" class="converterCheckbox" *ngIf="alarmSecondFormGroup && alarmSecondFormGroup.get('converter_' + converter.id) && i % 4 === 1">
                                <app-checkbox-input
                                    [label]="converter.name"
                                    [name]="'converter_' + converter.id"
                                    [group]="alarmSecondFormGroup">
                                </app-checkbox-input>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="alarmSecondFormGroup">
                        <ng-container *ngFor="let converter of converters; let i = index">
                            <div [ngClass]="{'gap_top': i >= 4}" class="converterCheckbox" *ngIf="alarmSecondFormGroup && alarmSecondFormGroup.get('converter_' + converter.id) && i % 4 === 2">
                                <app-checkbox-input
                                    [label]="converter.name"
                                    [name]="'converter_' + converter.id"
                                    [group]="alarmSecondFormGroup">
                                </app-checkbox-input>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="alarmSecondFormGroup">
                        <ng-container *ngFor="let converter of converters; let i = index">
                            <div [ngClass]="{'gap_top': i >= 4}" class="converterCheckbox" *ngIf="alarmSecondFormGroup && alarmSecondFormGroup.get('converter_' + converter.id) && i % 4 === 3">
                                <app-checkbox-input
                                    [label]="converter.name"
                                    [name]="'converter_' + converter.id"
                                    [group]="alarmSecondFormGroup">
                                </app-checkbox-input>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="grid grid_col_3 gap_top">
                    <div class="add-alarms__beginHour">
                        <app-label>{{ "addAlarmBeginHourFormLabel" | translate }}</app-label>
                        <app-select-input
                            [options]="hoursOptions" 
                            [autoSelectFirst]="true"
                            [group]="alarmSecondFormGroup" 
                            name="beginHour" 
                            id="beginHour"
                            [disable]="!canEdit">
                        </app-select-input>
                    </div>
                    <div class="add-alarms__endHour">
                        <app-label>{{ "addAlarmEndHourFormLabel" | translate }}</app-label>
                        <app-select-input 
                            [options]="hoursOptions" 
                            [autoSelectFirst]="true"
                            [group]="alarmSecondFormGroup" 
                            name="endHour" 
                            id="endHour"
                            [disable]="!canEdit">
                        </app-select-input>
                    </div>
                    <div class="add-alarms__percentageDiff">
                        <app-label>{{ "addAlarmPercentageDiffFormLabel" | translate }}</app-label>
                        <app-number-input [min]="0" [step]="0.1" formControlName="percentageDiff" [group]="alarmSecondFormGroup" [name]="'percentageDiff'" id="percentageDiff"
                        [ngClass]="{ 'invalid-input': isSubmitted && alarmSecondControls.percentageDiff.errors}">%</app-number-input>
                    </div>
                </div>
            </div>
            <div *ngIf="canEdit" class="add-alarms__validate_icon">
                <fa-icon [icon]="faPlusCircle" (click)="addAlarm()"></fa-icon>
            </div>
        </div>
    
        <div *ngIf="alarmsType1.length > 0" class="grid grid_col_1" style="margin-top: 2em">
            <app-label>{{ "addAlarmTableLabel" | translate }}</app-label>
            <app-table style="margin-top: -2em"
                [columns]="columns"
                [contents]="contents"
                [smallPadding]="true"
                [withPagination]="false"
                [paginationResult]="paginationResult"
                (tableAction)="tableActionClicked($event)"
                (pageChangeEmitter)="onPageChange($event)"
                [canEdit]=canEdit
                [canShow]="false">
            </app-table>
        </div>
    </div>
</div>

<ng-template #tplDeleteAlarm>
    <app-modal-item [ref]="ref" [config]="modalConfig"></app-modal-item>
</ng-template>
