<div class="dashboard-add-edit">
  <div class="side-panel" id="edit-box">
    <h1 class="side-panel__header">
      {{ titleKey | translate }}
    </h1>
    <div class="side-panel__content">
      <div class="grid grid_col_1" class="box">
        <div class="grid grid_col_4">
          <h4 class="title grid__child_col_3">{{ "generalInfosTitle" | translate }}</h4>
          <div class="grid__child_col_1">
            <app-button [action]="__TYPE_BUTTON.DARK_PRIMARY" (click)="duplicateThisDashboard()">{{
              "Dupliquer" | translate
            }}</app-button>
          </div>
        </div>
        <div class="grid grid_col_1">
          <div>
            <app-label>{{ "dashboard-name-label" | translate }} *</app-label>
            <app-text-input
              formControlName="name"
              [group]="dashboardFormGroup"
              [name]="'name'"
              id="name"
              [ngClass]="{
                'invalid-input':  isSubmitted && inputErrorsLabel && inputErrorsLabel.name
              }"
            ></app-text-input>
            <div
              *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.name"
              class="invalid-label"
            >
              {{ inputErrorsLabel.name.msg | translate }}
            </div>
          </div>
        </div>
        <div class="grid grid_col_1 gap_top">
          <div>
            <app-label
              >{{ "dashboard-description-label" | translate }} *</app-label
            >
            <app-text-input
              [group]="dashboardFormGroup"
              [name]="'descr'"
              id="descr"
              [ngClass]="{
                'invalid-input':  isSubmitted && inputErrorsLabel && inputErrorsLabel.descr
              }"
            ></app-text-input>
            <div
              *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.descr"
              class="invalid-label"
            >
              {{ inputErrorsLabel.descr.msg | translate }}
            </div>
          </div>
        </div>
        <div class="grid grid_col_1 gap_top">
          <app-checkbox-input
            formControlName="isDefault"
            [label]="'dashboard-isDefault-label' | translate"
            [name]="'isDefault'"
            [group]="dashboardFormGroup"
          ></app-checkbox-input>
        </div>
        <div *ngIf="error !== ''" class="grid grid_col_1">
          <div>
            <app-label>{{ "errorLabel" | translate }} *</app-label>
            <h3>{{ error }}</h3>
          </div>
        </div>
      </div>
      <div class="grid grid_col_2" style="padding: 2rem">
        <app-button
          [action]="__TYPE_BUTTON.DELETE"
          (click)="goTo(pagesEnum.MAIN)"
          >{{ "cancel" | translate }}</app-button
        >
        <app-button [action]="__TYPE_BUTTON.VALID" (click)="validateForm()">{{
          "save" | translate
        }}</app-button>
      </div>
    </div>
  </div>
</div>
