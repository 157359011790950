import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import UPDATE_ISACTIVE from "./graphql/update-is-active.graphql";
import AUTHENTICATE_USER from "./graphql/authenticate-user.graphql";
import RECOVER_PASSWORD from "./graphql/recover-password.graphql";
import SEND_CODE from "./graphql/send-reset-password-code.graphql"

/**
 * Authenticate queries service
 */
@Injectable({
    providedIn: "root"
  })
  export class AuthenticateQueriesService {
  
    /**
     * constructor method
     * @param _apollo
     */
    constructor(private _apollo: Apollo) {}
  
    /**
     * Update field isActive of an user
     * @param mail 
     * @param isActive 
     * @returns 
     */
    public updateIsActiveUser (mail: string, isActive: boolean) {
      return this._apollo.mutate({
        mutation: UPDATE_ISACTIVE,
        variables: { mail, isActive },
        fetchPolicy: "no-cache"
      });
    }

    /**
     * Authenticate an user with his mail and password
     * @param mail 
     * @param password 
     * @returns user info if he's logged and error if not
     */
    public authenticateUser (mail: string, password: string) {
      return this._apollo.mutate({
        mutation: AUTHENTICATE_USER,
        variables: { mail, password },
        fetchPolicy: "no-cache"
      }).pipe(
        catchError((err: any) => {
          return [err];
        })
      );
    }

    /**
     * Recover password of an user based on verificationCode
     * @param mail 
     * @param password 
     * @param verificationCode 
     * @returns 
     */
    public recoverPassword (mail: string, password: string, verificationCode: string) {
      return this._apollo.mutate({
        mutation: RECOVER_PASSWORD,
        variables: { mail, password, verificationCode },
        fetchPolicy: "no-cache"
      }).pipe(
        catchError((err: any) => {
          return [err];
        })
      );
    }

    /**
     * Send mail with the verificationCode
     * @param mail
     * @returns bool
     */
    public sendMailCode (mail: string) {
      return this._apollo.mutate({
        mutation: SEND_CODE,
        variables: { mail },
        fetchPolicy: "no-cache"
      }).pipe(
        catchError((err: any) => {
          return [err];
        })
      );
    }
}
  