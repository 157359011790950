import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPlusCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { IInstallation } from 'src/app/queries/installation/interfaces/installations.interface';
import { IPaginationInput, IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { ISelectOption } from '../../input/facades';
import { IModalItemConfig } from '../../modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from '../../modal/overlay/myoverlay-ref';
import { OverlayService } from '../../modal/overlay/overlay.service';
import { ISearchSelectItem } from '../../search-select/facades/interfaces/searchSelectItem.interface';
import { EnumTableAction, ITableAction, ITableColumn } from '../../table/facades';
import { TranslationService } from '../../translation/services/translation.service';
import { AlarmType, CronJobConfig } from '../facades/alarms.interface';

/**
 * alarm component
 */
@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss']
})
export class AlarmsComponent implements OnInit {

  /** boolean true if the user can edit the installation otherwise false */ canEdit: boolean = localStorage.getItem("ideta_modulec_userRole") ? localStorage.getItem("ideta_modulec_userRole") !== "USER" : false

  /** FormGroup of first type alarms */ public alarmFirstFormGroup: FormGroup;
  /** getter for controls of first type alarms form group */ get alarmFirstControls() { return this.alarmFirstFormGroup.controls; }

  /** FormGroup of second type alarms */ public alarmSecondFormGroup: FormGroup;
  /** getter for controls of second type alarms form group */ get alarmSecondControls() { return this.alarmSecondFormGroup.controls; }

  /** boolean that is passed to true when the installation is submit **/ @Input("isSubmitted") public isSubmitted: boolean = false;
  /** Emit new alarms list input when there is a change */ @Output("alarmsListChanged") public alarmsListChanged: EventEmitter<any> = new EventEmitter();
  /** Emit new validity status when there is a change */ @Output("isValid") public isValid: EventEmitter<boolean> = new EventEmitter();
  /** this subject will be triggerd by the add-edit-installation component when the save button is pressed */ @Input("validitySubject") public validitySubject: Subject<void> = new Subject<void>()
  /** installationId input */ @Input() public installationId: number = null;
  /** isDisplayed input */ @Input() public isDisplayed: boolean = false;
  /** converters input */ @Input() public converters: any = null;
  
  /** Add button icon */ public faPlusCircle: IconDefinition = faPlusCircle;
  /** Role translated to display on form */ public hoursOptions: ISelectOption[] = [];
  /** Variable to send to search & select to reset selected item */ public resetSelected = new Subject<boolean>();
  /** alarm input */ @Input("alarms") public alarms: CronJobConfig[] = [];
  
  /** type 1 alarm list */ public alarmsType1: CronJobConfig[] = [];
  /** Modal configuration */ public modalConfig: IModalItemConfig = {};
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
  /** Template of modal */ @ViewChild('tplDeleteAlarm', { static: true }) modalTemplate: TemplateRef<any>;
  /** Alarm to delete */ public alarmToDelete: any;
  /** Pagination base for table */ public pagination: IPaginationInput = { page: 1, limit: 1 };
  /** pagination result for table */
  public paginationResult: IPaginationResult = {
    ...this.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1,
  };
  /** Array of columns for table */
  public columns: ITableColumn[] = [];

  /**
   * content getter
   */
  public get contents() {
    return this.alarmsType1.map((alarm: CronJobConfig) => {
      let convertersIds = "";
      if (alarm && alarm.palamedeIds && alarm.palamedeIds.length > 0) {
        alarm.palamedeIds.forEach((id, index) => {
          const converter = this.converters.find(convert => convert.id === id);
          if (index !== 0 && converter && converter.name) {
            convertersIds += (", " + converter.name);
          } else {
            convertersIds += converter.name;
          }
        });
      }
      return {
        convertersIds: convertersIds,
        beginHour: alarm.beginHour + "h00",
        endHour: alarm.endHour + "h00",
        percentageDiff: alarm.percentageDiff + "%",
      }
    })
  }

  /**
   * constructor
   * @param _translationSrv the translation service
   * @param _fb the form builder
   * @param _installationSrv the installation service
   * @param _installationQueriesSrv the installation queries service
   * @param _alertSrv the alert service
   * @param _overlayService the overlay service
   */
  constructor(
    private _translationSrv: TranslationService,
    private _fb: FormBuilder,
    private _installationSrv: InstallationQueriesService,
    private _installationQueriesSrv: InstallationQueriesService,
    private _alertSrv: AlertService,
    private _overlayService: OverlayService,
  ) { }

  /**
   * on Init
   */
  ngOnInit(): void {
    this.buildTable();
    if (this.installationId) {
      this.getInstallation(this.installationId);
    }
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this.hoursOptions.push({
          label: "0" + i + ":00",
          value: i
        })
      } else {
        this.hoursOptions.push({
          label: i + ":00",
          value: i
        })
      }
    }
    this.validitySubject.subscribe(() => {
      let haveAlarm = false;
      let haveError = false;

      if (this.alarmsType1 && this.alarmsType1.length > 0) {
        haveAlarm = true;
      }
      if (this.converters && this.converters.length > 0) {
        this.converters.forEach(converter => {
          if (this.alarmFirstFormGroup && this.alarmFirstFormGroup.get("converter_" + converter.id) && this.alarmFirstFormGroup.get("converter_" + converter.id).value) {
            if (this.alarmFirstFormGroup.get("beginRange_" + converter.id).value >= this.alarmFirstFormGroup.get("endRange_" + converter.id).value) haveError = true;
            haveAlarm = true;
          }
        });
      }
      if (haveError) this._alertSrv.danger(this._translationSrv.getInstant("alarmBeginRangeAfterEnd"));
      this.isValid.emit(haveError ? false : haveAlarm ? this.alarmFirstFormGroup.valid : true);
    })
  }

  /**
   * build the table content based on the user role
   */
  buildTable() {
    this.columns = [
      { key: 'convertersIds', type: 'text', title: "Converteurs", clickable: false },
      { key: 'beginHour', type: 'text', title: "Début", clickable: false },
      { key: 'endHour', type: 'text', title: "Fin", clickable: false },
      { key: 'percentageDiff', type: 'text', title: "Delta", clickable: false },
    ];
    if (this.canEdit) {
      this.columns.push(
        { key: 'actions', type: 'action', title: 'Actions' }
      )
    }
  }

  /**
   * trigger when the add button is clicked
   * @param changes the change
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.converters) {
      this.createAlarmsForm();
    }
    if (changes && changes.alarms && changes.alarms.currentValue && changes.alarms.currentValue.length > 0) {
      changes.alarms.currentValue.forEach(element => {
        if (element && element.type === AlarmType.PERCENTAGE_DIFF)
          this.alarmsType1.push(element);
      });
    }
  }

  /**
   * Get the installation for the edit mode
   * @param installationId {number} The id of the selected installation
   * @return {void}
   */
   public getInstallation(installationId: number): void {
    this._installationSrv.getInstallationById(installationId).subscribe(async result => {
      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      } else {
        const data: any = <any>result.data;
        if (data && data.installation) {
          if (data.installation.installationPalamede && data.installation.installationPalamede.palamedeConverters) {
            this.converters = data.installation.installationPalamede.palamedeConverters;
          }
          if (this.alarms && this.alarms.length > 0) {
            this.alarms.forEach(element => {
              if (element && element.type === AlarmType.PERCENTAGE_DIFF)
                this.alarmsType1.push(element);
            });
          }
          this.createAlarmsForm();
        }
      }
    })
  }

  /**
   * Create converter list
  */
  private createAlarmsForm(){
    if(this.converters && this.converters.length > 0){
      const objFirst = {};
      const objSecond = {};
      let email = "";
      this.converters.forEach(convert => {
        if (!convert || !convert.id) {
          console.log("no convert", convert);
          return;
        }

        const alarmConvert = this.findConverterInAlarms(convert.id)
        objFirst["converter_" + convert.id] = [alarmConvert ? true:false, Validators.required]
        objFirst["beginRange_" + convert.id] = [alarmConvert && alarmConvert.beginHour !== null && alarmConvert.beginHour !== undefined ? alarmConvert.beginHour : 12, Validators.required];
        objFirst["endRange_" + convert.id] = [alarmConvert && alarmConvert.endHour !== null && alarmConvert.endHour !== undefined ? alarmConvert.endHour : 14, Validators.required];
        objSecond["converter_" + convert.id] = [false, Validators.required];
      });
      if (this.alarms && this.alarms.length > 0) {
        this.alarms.forEach(alarm => {
          if (email === "" && alarm.mail) {
            email = alarm.mail;
          }
        });
      }
      objFirst["contactEmails"] = [email, Validators.required];
      objSecond["contactEmails"] = [email, Validators.required];

      objSecond["beginHour"] = [12, Validators.required];
      objSecond["endHour"] = [14, Validators.required];
      objSecond["percentageDiff"] = [0, Validators.required];

      this.alarmFirstFormGroup = this._fb.group(objFirst);
      this.alarmSecondFormGroup = this._fb.group(objSecond);

      // disable editing if the user acces role is USER
      if (!this.canEdit) {
        this.alarmFirstFormGroup.disable()
        this.alarmSecondFormGroup.disable()
      }

      if (this.alarmFirstFormGroup) {
        this.alarmsListChanged.emit([this.alarmFirstFormGroup.value, this.alarmsType1]);
        this.alarmFirstFormGroup.valueChanges.subscribe(res => {
          this.alarmsListChanged.emit([this.alarmFirstFormGroup.value, this.alarmsType1]);        
        })
      }
      if (this.alarmFirstFormGroup && this.alarmFirstFormGroup.get("contactEmails") && this.alarmSecondFormGroup && this.alarmSecondFormGroup.get("contactEmails")) {
        this.alarmFirstFormGroup.get("contactEmails").valueChanges.subscribe(email => {
          this.alarmSecondFormGroup.get("contactEmails").patchValue(email);
        })
      }
      if (this.alarmSecondFormGroup) {
        this.alarmSecondFormGroup.valueChanges.subscribe(res => {
          this.alarmsListChanged.emit([this.alarmFirstFormGroup.value, this.alarmsType1]);        
        })
      }
      this.alarmsListChanged.emit([this.alarmFirstFormGroup.value, this.alarmsType1]);
    }
  }

  /**
   * find the converter in the alarms
   * @param id alarm id
   * @returns the alarm
   */
  private findConverterInAlarms(id: string): CronJobConfig {
    let foundAlarm = null;
    if (this.alarms && this.alarms.length > 0) {
      this.alarms.forEach(alarm => {
        if (alarm.type === AlarmType.EQUAL_TO_ZERO) {
          const res = alarm.palamedeIds.find(element => element === id) ? true : false;
          if (res) foundAlarm = alarm;
        }
      });
    }
    return foundAlarm;
  }

  /**
   * Add user to the list of selected users
   * @returns
   */
   public addAlarm() {
    if (!this.alarmSecondFormGroup || this.alarmSecondFormGroup.invalid) { this._alertSrv.danger(this._translationSrv.getInstant("alarmRequireEmail")); console.log("alarmSecondFormGroup is invalid"); return; }
    if (this.getAllConvertersSelected().length < 2) { this._alertSrv.danger(this._translationSrv.getInstant("alarmRequireTwoConvertersMin")); return; }
    if (this.alarmSecondFormGroup.get("beginHour").value >= this.alarmSecondFormGroup.get("endHour").value) { this._alertSrv.danger(this._translationSrv.getInstant("alarmBeginRangeAfterEnd")); return; }

    this.alarmsType1.push({
      type: AlarmType.PERCENTAGE_DIFF,
      installIds: [this.installationId],
      beginHour: this.alarmSecondFormGroup.get("beginHour").value,
      endHour: this.alarmSecondFormGroup.get("endHour").value,
      palamedeIds: this.getAllConvertersSelected(),
      percentageDiff: this.alarmSecondFormGroup.get("percentageDiff").value,
      mail: this.alarmSecondFormGroup.get("contactEmails").value
    })

    this.alarmSecondFormGroup.get("beginHour").patchValue(9);
    this.alarmSecondFormGroup.get("endHour").patchValue(16);
    this.alarmSecondFormGroup.get("percentageDiff").patchValue(0);
    this.converters.forEach(converter => {
      this.alarmSecondFormGroup.get("converter_" + converter.id).patchValue(false);
    })
  }

  /**
   * get all the selected converter
   * @returns the list of selected converters
   */
  getAllConvertersSelected(): string[] {
    let tmp = [];
    this.converters.forEach(converter => {
      if (converter && converter.id && this.alarmSecondFormGroup && this.alarmSecondFormGroup.get("converter_" + converter.id) && this.alarmSecondFormGroup.get("converter_" + converter.id).value)
        tmp.push(converter.id);
    });
    return tmp;
  }

  /**
   * Handle the action triggered by a user click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   */
   public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.alarmToDelete = event.content;
        this.open(this.modalTemplate);
        break;
      case EnumTableAction.EDIT:
      case EnumTableAction.BOOLEAN:
      case EnumTableAction.SORT:
      default:
        break;
    }
  }

  /**
   * Open modal with content
   * @param content
   */
   public open(content: TemplateRef<any>) {
    this.modalConfig = {
      title: this._translationSrv.getInstant('modalDeleteConsumption'),
      body: `${this._translationSrv.getInstant('modalDeleteAlarmType1', { firstId: this.alarmToDelete.firstId, secondId: this.alarmToDelete.secondId, })}`,
      buttons: [
        {
          message: this._translationSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translationSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res.data.value === true) {

        // Search the index of the element to remove
        const index = this.alarmsType1.findIndex(item => item === this.alarmToDelete);

        // Remove the selected user from the table contents
        this.alarmsType1.splice(index, 1);

        this.alarmsListChanged.emit([this.alarmFirstFormGroup.value, this.alarmsType1]);
      }
    });
  }
  /**
   * Called by table child when page is changed
   * @param event
   */
   public onPageChange(event: number): void {
    this.pagination.page = event;
  }

  /**
   * Search a palamede installation based on search params (q)
   * @param q the search string to find the installation
   * @param idsSelected the installation ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the installation results
   */
   public searchConverters = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._installationQueriesSrv.searchInstallations({ page: 1, limit: 1000 }, q).toPromise();
    let installations: any[] = [];
    let res = [];
    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getInstallations.installations && (<any>resultQuery.data).getInstallations.installations)
      res = (<any>resultQuery.data).getInstallations.installations
    
    res.forEach(installation => {
      if (installation.id === this.installationId) {
        let palamede = installation.installationPalamede;
        if (palamede && palamede.palamedeConverters) {
          palamede.palamedeConverters.forEach(converter => {
            let obj = {
              id: converter.id,
              label: `${converter.name} (${converter.id})`,
              data: converter
            }
            if (installations.indexOf(obj) === -1) installations.push(obj);
          });
        }
      }
    });
    return installations;
  }

  /**
   * activate alarm on a specific converter
   * @param converter the converter
   */
  public activateAlarm(converter: any) {
    if (this.alarmFirstFormGroup && this.alarmFirstFormGroup.get("converter_" + converter.id)) {
      this.alarmFirstFormGroup.get("converter_" + converter.id).patchValue(true);
    }
  }
}
