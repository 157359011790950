<div class="res-add-edit">
    <div class="side-panel" id="edit-box">
        <h1 class="side-panel__header">
            {{ titleKey | translate }}
        </h1>
        <div class="side-panel__content">
            <div class="grid grid_col_1" class="box">
                <h4 class="title">{{ 'generalInfosTitle' | translate }}</h4>
                <div class="grid grid_col_2 gap_top">
                    <div>
                        <app-label>{{ "name" | translate}} *</app-label>
                        <app-text-input formControlName="name" [group]="recFormGroup" [name]="'name'" id="name"
                        [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.name }"></app-text-input>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.name" class="invalid-label">
                            {{ inputErrorsLabel.name.msg | translate }}
                          </div>
                    </div>
                    <div>
                        <app-label>{{ "Email" | translate}} *</app-label>
                        <app-text-input formControlName="mail" [group]="recFormGroup" [name]="'mail'" id="mail"
                        [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.mail }"></app-text-input>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.mail" class="invalid-label">
                            {{ inputErrorsLabel.name.msg | translate }}
                          </div>
                    </div>
                </div>
            </div>
            <div class="box gap_top">
                <h4 class="title">{{ "energySplitting" | translate }}</h4>
                <div class="grid grid_col_2 gap_top">
                    <div>
                        <app-label>{{ "cerRepartitionMode" | translate}} *</app-label>
                        <app-select-input [options]="CerType" [autoSelectFirst]="true" [group]="selectCerForm" name="cerType" id="cerType"
                        [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.cerType }">{{selectedCerType}}</app-select-input>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.cerType" class="invalid-label">
                            {{ inputErrorsLabel.name.msg | translate }}
                          </div>
                    </div>
                    <div *ngIf="selectedCerType == 'ACC'">
                        <app-label>{{ "repartition" | translate}} *</app-label>
                        <app-select-input [options]="splittingKey" [autoSelectFirst]="true" [group]="selectCerForm" name="splittingKey" id="splittingKey"
                        [ngClass]="{ 'invalid-input': isSubmitted && inputErrorsLabel && inputErrorsLabel.splittingKey }">{{selectedsplittingKey}}</app-select-input>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.splittingKey" class="invalid-label">
                            {{ inputErrorsLabel.name.msg | translate }}
                          </div>
                    </div>
                </div>
            </div>

            <app-linked-installations (installationsChanged)="linkedInstallationsChanged($event)" [selectedInstallations]="formattedSelectedInstallations" ></app-linked-installations>
            <app-linked-consumptions (consumptionsChanged)="linkedConsumptionsChanged($event)" [selectedConsumptions]="formattedSelectedConsumptions" [allowNewConsumption]="allowNewConsumption"></app-linked-consumptions>

            <div class="box gap_top" *ngIf="selectedCerType == 'ACC' && selectedsplittingKey === 'fix'">
                <h4 class="title">{{ "energySplittingPerUser-title" | translate }}</h4>
                <div class="grid grid_col_2 gap_top">
                    <app-label>{{ "name" | translate}}</app-label>
                    <app-label>{{ "repartition" | translate}} *</app-label>
                </div>
                <div *ngFor="let elem of formattedSelectedConsumptions">
                    <div class="grid grid_col_2 gap_top">
                        <p>{{elem.name}}</p>
                        <app-number-input [min]=0 [step]=1 [group]="selectCerForm" [name]="elem.id + '_percent'" [id]="elem.id + '_percent'"></app-number-input>
                    </div>
                </div>
                <p>Total: {{repartitionTotal}}</p>
            </div>

            <div *ngIf="showValidationMessage" class="invalid-input-box">
                {{"atLeastOneInstallAndOneConsumptionRequired" | translate }}
            </div>

            <div class="grid grid_col_2" style="padding: 2rem;">
                <app-button [action]="__TYPE_BUTTON.DELETE" (click)="goTo('/rec')">Annuler</app-button>
                <app-button [action]="__TYPE_BUTTON.VALID" (click)="validateForm()">Sauvegarder</app-button>
            </div>
        </div>
    </div>
</div>