import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  faCaretDown,
  faTimes,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AInputs } from '../../input/facades';
import { ErrorMessageService } from '../../input/facades/services/error-message.service';
import { ISearchSelectItem } from '../facades/interfaces/searchSelectItem.interface';

/**
 * Search select component
 * Usage :
 * <app-search-select [query]="query" formControlName="name" [group]="group" [name]="'name'" id="id"></app-search-select>
 */
@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
})
export class SearchSelectComponent extends AInputs implements OnInit {
  /** FormGroup of users */ public searchFormGroup: FormGroup;
  /** Font Awesome Icon for log-out */ public faDropdown: IconDefinition = faCaretDown;
  /** Font Awesome Icon for log-out */ public faTimes: IconDefinition = faTimes;

  /** resetSelected */ @Input() public resetSelected: Subject<boolean>;
  /** Query used to get items */ @Input() public query: ( q?: string, idsSelected?: string[] ) => Promise<any>;
  /** querySubject */ @Input() public querySubject: Subject<any>;
  /** Options used as items */ @Input() public options: ISearchSelectItem[] = [];
  /** Selected options from input */ @Input() public selected: ISearchSelectItem[] = [];
  /** currentItem */ @Input() currentItem: ISearchSelectItem = null;
  /** Max items that can be selected */ @Input() public maxSelected: number;
  /** Event triggered when compare string is changed */ @Output() public onCompareChanged: EventEmitter<string> = new EventEmitter<string>();
  /** boolean true if the user can edit the installation otherwise false */ @Input() public canEdit: boolean = true

  /** Selected options */ public optionsSelected: ISearchSelectItem[] = [];
  /** Options displayed using compare string as constraint */ public optionsDisplayed: ISearchSelectItem[];
  /** true if there is no option that match with the compare string as constraint */ public noOptions: boolean = false;
  /** true if options menu is open */ public isOpen: boolean = false;
  /** string of comparison */ public compareString: string = '';
  /** selected item */ public currentSelected: ISearchSelectItem = null;

  /** get the selected items */
  get idsSelected(): string[] {
    if (this.selected && this.selected.length > 0 && this.selected[0]) {
      const result = this.selected.map((item) => {
        return item.id;
      });
      return result;
    } else {
      return null;
    }
  }

  /**
   * Used to get controls of searchFormGroup
   */
  get formControls() {
    return this.searchFormGroup.controls;
  }

  /**
   * constructor
   * @param renderer the renderer
   * @param _errorMessageSrv the error message service
   * @param _fb the form builder
   */
  constructor(
    public renderer: Renderer2,
    protected _errorMessageSrv: ErrorMessageService,
    private _fb: FormBuilder,
  ) {
    super(renderer, _errorMessageSrv);
  }

  /**
   * On init method.
   */
  ngOnInit(): void {
    this.searchFormGroup = this._fb.group({
      searchName: [null, Validators.required],
    });
    if (!this.canEdit)
      this.searchFormGroup.disable()

    this.optionsDisplayed = this.options;
    this.optionsSelected = <ISearchSelectItem[]>this.selected.map((elem) => {
      return {
        id: elem.id,
        label: elem.label,
        data: elem.data,
      };
    });

    this.searchFormGroup.valueChanges.pipe(debounceTime(500)).subscribe(async (res) => {
      this.compareString = res.searchName;
      if(this.query) this.getElements(res.searchName);
      else this.onCompareChanged.next(res.searchName);

    });
    if (this.resetSelected) {
      this.resetSelected.subscribe((res) => {
        this.optionsSelected = [];
        this.searchFormGroup.get("searchName").patchValue("");
        this.optionsDisplayed = []
        this.currentItem = null;
        this.getElements("");
      });
    }

    if(this.maxSelected && this.maxSelected == 1 && this.group.get(this.name) && this.group.get(this.name).value) this.currentItem = this.group.get(this.name).value[0];
    else this.currentItem = null;

    this.getElements('');
  }

  /**
   * After view init method.
   */
  ngAfterViewInit() {
    this.getElements('');
  }

  /**
   * Use query (passed as Input) to get items
   * @param compareString the string to compare
   */
  public async getElements(compareString: string) {
    if (this.query) {
      this.optionsDisplayed = await this.query(
        compareString,
        this.idsSelected
      ).then((res) => {
        if (res && res.length > 0) {
          this.noOptions = false;
          const newArray: ISearchSelectItem[] = this.buildOptions(res);
          if (newArray.length) {
            return newArray.map((elem: ISearchSelectItem) => {
              return {
                id: elem.id,
                label: elem.label,
                data: elem.data,
              };
            });
          } else {
            return [];
          }
        } else {
          this.noOptions = true;
          return [];
        }
      });
    } else {
      this.reduceOptions(compareString);
    }
  }
  
  /**
   * Build options
   * @param res list of options
   * @returns the selected options
   */
  public buildOptions(res: ISearchSelectItem[]): ISearchSelectItem[] {
    let resultArray: ISearchSelectItem[] = [];
    res.forEach((elem) => {
      if (!this.arrayContains(this.selected, elem)) {
        resultArray.push(elem);
      }
    });
    return resultArray;
  }

  /**
   * check if an element is on optionsSelected array
   * @param optionsSelected
   * @param elem
   * @returns
   */
  public arrayContains(
    optionsSelected: ISearchSelectItem[],
    elem: ISearchSelectItem
  ): boolean {
    let contains: boolean = false;
    optionsSelected.forEach((option) => {
      if (option.id === elem.id) {
        contains = true;
      }
    });
    return contains;
  }

  /**
   * On changes method.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.options && changes.options.currentValue) {
      this.optionsDisplayed = changes.options.currentValue;
    }
    if (changes && changes.selected) {
      this.selected = changes.selected.currentValue;
      this.optionsSelected = <ISearchSelectItem[]>this.selected.map((elem) => {
        return {
          id: elem.id,
          label: elem.label,
          data: elem.data,
        };
      });
      this.getElements('');

      if(this.maxSelected && this.maxSelected == 1 && this.group.get(this.name) && this.group.get(this.name).value) this.currentItem = this.group.get(this.name).value[0];
      else this.currentItem = null;
    }

    if (changes && changes.currentItem && changes.currentItem.currentValue) {
      this.optionsSelected.push(changes.currentItem.currentValue);
    }
  }

  /**
   * Used if query isn't passed as parameter. In this case, you must pass options as input.
   * This method reduce options based on compare string as constraint
   * @param compareBase the string to compare
   * @returns
   */
  public reduceOptions(compareBase: string) {
    if (!this.options) {
      return;
    }
    let finalsOptions: ISearchSelectItem[] = this.options.filter((option) =>
      option.label
        .trim()
        .toLowerCase()
        .includes(compareBase.trim().toLowerCase())
    );

    this.optionsDisplayed = finalsOptions;
    this.noOptions = finalsOptions.length === 0 ? true : false;
  }

  /**
   * toogle dropdown
   * Called when menu is opened
   * @param state
   */
  public toggleDropdown(state: boolean = null) {
    if ( this.maxSelected && this.maxSelected != 1 && this.optionsSelected && this.maxSelected && this.optionsSelected.length === this.maxSelected )
      return;

    if (state !== null) this.isOpen = state;
    else this.isOpen = !this.isOpen;

    if (this.isOpen === false) {
      this.searchFormGroup.get('searchName').patchValue('');
    }
  }

  /**
   * Called when click outside the select search input
   * and close the dropdown
   */
  public closeDropdown(): void {
    this.isOpen = false;
  }

  /**
   * Called when an item is clicked
   * @param item
   * @returns
   */
  public onItemClick(item: ISearchSelectItem) {
    if (!this.group.get(this.name)) {
      console.log('group get name is null');
      return;
    }

    let newArray: ISearchSelectItem[] = this.optionsSelected.map((elem) => {
      return elem;
    });
    if((this.maxSelected &&  this.maxSelected == 1)) {
      this.currentItem = item;
      this.group.get(this.name).patchValue([this.currentItem]);
      this.optionsSelected.push(item);
      if (this.optionsSelected.length >= this.maxSelected) this.isOpen = false;
      else this.getElements(this.compareString);
    } else {
      if (
        (this.maxSelected && newArray !== null && (newArray.length < this.maxSelected)) || !this.maxSelected) {
        newArray.push(item);
        this.optionsSelected.push(item);
        this.group.get(this.name).patchValue(newArray);
        if (this.optionsSelected.length === this.maxSelected) this.isOpen = false;
        else this.getElements(this.compareString);
      }
    }
  }

  /**
   * Delete an element when the cross is clicked
   * @param item item
   */
  public deleteSelectedItem(item: ISearchSelectItem) {
    this.optionsSelected.splice(this.optionsSelected.indexOf(item), 1);
    let newArray: ISearchSelectItem[] = this.group.get(this.name).value;
    let foundIndex = newArray.findIndex(elem => elem.id == item.id);
    newArray.splice(foundIndex, 1);
    this.currentItem = null;
    this.group.get(this.name).patchValue(newArray);
    this.getElements(this.compareString);
  }

  /**
   * check if there is undefined data in the array
   * @param array the array to check
   * @returns true if there is undefined data in the array
   */
  public checkForUndefined(array: any[]): boolean {
    if (!array) return false;
    for (let i = 0; i < array.length; i++) {
      if (array[i] === undefined) {
        return true;
      }
    }
    return false;
  }
}
