<gridster-item [item]="item" [class]="'widget-card ' + gridsterIgnoreContentClass + ' ' + getWidgetColor(item)">
    <div class="widget-card__header">
        <h1 class="widget-card__header-title">{{item.widgetDataConfig.title}}</h1>
        <fa-icon [icon]="faPen" class="widget-card__icon widget-card__icon--edit" (click)="editWidget()"></fa-icon>
        <fa-icon [icon]="faTimesCircle" class="widget-card__icon widget-card__icon--remove" (click)="closeCard($event)"></fa-icon>
        <fa-icon [icon]="faArrowsAlt" [class]="'widget-card__icon widget-card__icon--move ' + gridsterHandleClass"></fa-icon>
    </div>
    <div class="widget-card__content">
        <ng-container [ngSwitch]="item.widget">
            <!-- <ng-container *ngSwitchCase="__EnumWidgetType.TABLE" ><app-table-widget></app-table-widget></ng-container> -->
            <ng-container *ngSwitchCase="__EnumWidgetType.COUNT" ><app-counter-widget [config]="item.widgetDataConfig" [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-counter-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.COUNT_CONSUMPTION" ><app-counter-consumption-widget [config]="item.widgetDataConfig" [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-counter-consumption-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.ECONOMY" ><app-economy-widget [config]="item.widgetDataConfig" [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-economy-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.INSTALLATION_GRAPH" ><app-production-graph-widget [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-production-graph-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.PRODUCTION_HISTORY_GRAPH" ><app-production-history-graph-widget [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-production-history-graph-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.CONSUMPTION_GRAPH" ><app-consumption-graph-widget [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-consumption-graph-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.CER_GRAPH" ><app-cer-graph-widget [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-cer-graph-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.AUTO_CONSUMPTION_GRAPH" ><app-auto-consumption-graph-widget [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-auto-consumption-graph-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.DIOXYDE_GRAPH" ><app-dioxyde-graph-widget [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-dioxyde-graph-widget></ng-container>
            <ng-container *ngSwitchCase="__EnumWidgetType.KW_BY_KWCRETE" ><app-kwPerKwcrete-graph-widget [graphConfig]="item.widgetDataConfig" [id]="item.id"></app-kwPerKwcrete-graph-widget></ng-container>
        </ng-container>
    </div>
</gridster-item>
