import { animate, style, transition, trigger } from '@angular/animations';

/**
 * Animation fade in / fade out for enter and leave event on a modal
 */
export const MODAL_IN_ANIMATION = 
trigger('modalInAnimation', [
    transition(':enter', [
      // start with the content positioned off the right of the screen, 
      style({
          backgroundColor: 'rgba(0, 0, 0, 0)',
          opacity: 0
      }),

      // animation and styles at end of transition
      animate('.25s ease-in-out', style({
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          opacity: 1
      }))
  ]),

  // route 'leave' transition
  transition(':leave', [
      // animation and styles at end of transition
      animate('.25s ease-in-out', style({
        backgroundColor: 'rgba(0, 0, 0, 0)',
        opacity: 0
      }))
  ])
]) 