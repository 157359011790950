import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { IPaginationInput } from '../pagination/interfaces/paginationInput.interface';
import { ICreateDashboardInput, IUpdateDashboardInput } from 'src/app/queries/dashboard/interfaces/dashboardsInput.interface';

/* GRAPHQL QUERIES */
import GET_DASBOARDS_BY_ID_CURRENT_USER from './graphql/get-dashboard-by-id-current-user.graphql';
import COUNT_DASBOARDS_BY_ID_CURRENT_USER from './graphql/count-dashboard_user.graphql';
import CREATE_DASHBOARD from './graphql/create-dashboard.graphql';
import DUPLICATE_DASHBOARD from './graphql/duplicate-dashboard.graphql';
import REMOVE_DASHBOARD from './graphql/remove-dashboard.graphql';
import GET_DASHBOARD from './graphql/get-dashboard.graphql';
import UPTADE_DASHBOARD from './graphql/update-dashboard.graphql';
import CHECK_DASHBOARDS from './graphql/check-dashboards.graphql';
import CHECK_USERS_DASHBOARDS from './graphql/check-users-dashboards.graphql';

/**
 * Dashboard queries service
 */
@Injectable({
  providedIn: 'root',
})
export class DashboardQueriesService {

  /** the pagination */ private _pagination: IPaginationInput = { page: 1, limit: 20 }; /** Pagination base for table */
  /** the query string */ private _queryString: string = null; /** The search string type by the user to use to filter the table result */ 
  /** the order */ private _order: {key: string, direction: string} = null ;

  /** pagination getter */ public get pagination(): IPaginationInput { return this._pagination; }
  /** queryString getter */ public get queryString(): string { return this._queryString; }
  /** order getter */ public get order(): {key: string, direction: string} { return this._order; }
  /** queryString setter */ public set queryString(queryString: string) { this._queryString = queryString; }
  /** pagination setter */ public set pagination(pagination: IPaginationInput) { this._pagination = pagination; }
  /** order setter */ public set order(order: {key: string, direction: string}) { this._order = order; }


  /**
   * constructor method
   * @param _apollo
   */
  constructor(private _apollo: Apollo) {}



  /**
   * Get an dashboard by current user
   * @param pagination
   * @returns
   */
  public getDashboardByIdCurrentUser() {
    return this._apollo.watchQuery({
      query: GET_DASBOARDS_BY_ID_CURRENT_USER,
      variables: {
        search: this.queryString,
        order: this.order,
        pagination: this.pagination
      },
      fetchPolicy: 'network-only',
    });
  }

  /**
   * Get dashboard by id
   * @param id
   * @returns
   */
  public getDashboard(id: number) {
    return this._apollo.query({
      query: GET_DASHBOARD,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
  }

  /**
   * Creation an dashboard
   * @param install
   * @returns
   */
  public createDashboard(dashboard: ICreateDashboardInput) {
    return this._apollo.mutate({
      mutation: CREATE_DASHBOARD,
      variables: { dashboard },
      fetchPolicy: 'no-cache',
      refetchQueries: [
        { query: COUNT_DASBOARDS_BY_ID_CURRENT_USER },
        {
          query: GET_DASBOARDS_BY_ID_CURRENT_USER,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        },
      ],
    });
  }


    /**
   * Creation an dashboard
   * @param install
   * @returns
   */
     public duplicateDashboard(id: number) {
      return this._apollo.mutate({
        mutation: DUPLICATE_DASHBOARD,
        variables: { id },
        fetchPolicy: 'no-cache',
        refetchQueries: [
          { query: COUNT_DASBOARDS_BY_ID_CURRENT_USER },
          {
            query: GET_DASBOARDS_BY_ID_CURRENT_USER,
            variables: {
              search: this.queryString,
              order: this.order,
              pagination: this.pagination
            },
          },
        ],
      });
    }

  /**
   * check all the user dashboard validity
   * @returns
   */
    public checkDashboardsValidity(userId: number) {
      return this._apollo.mutate({
        mutation: CHECK_DASHBOARDS,
        variables: { userId },
        fetchPolicy: 'no-cache',
      });
    }


  /**
   * check all the user dashboard validity
   * @returns
   */
     public checkMultipleUsersDashboardsValidity(usersIds: number[]) {
      return this._apollo.mutate({
        mutation: CHECK_USERS_DASHBOARDS,
        variables: { usersIds },
        fetchPolicy: 'no-cache',
      });
    }

  /**
   * Update dashboard based on column key
   * @param compareString
   * @returns
   */
  public updateDashboard(updateDashboardInput: IUpdateDashboardInput) {
    return this._apollo.mutate({
      mutation: UPTADE_DASHBOARD,
      variables: { updateDashboardInput },
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: GET_DASBOARDS_BY_ID_CURRENT_USER,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        },
      ],
    });
  }

  /**
   * Delete an dashboard
   * @param mail
   * @returns
   */
  public deleteDashboard(id: Number) {
    return this._apollo.mutate({
      mutation: REMOVE_DASHBOARD,
      variables: { id },
      fetchPolicy: 'no-cache',
      refetchQueries: [
        { query: COUNT_DASBOARDS_BY_ID_CURRENT_USER },
        {
          query: GET_DASBOARDS_BY_ID_CURRENT_USER,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        },
      ],
    });
  }

  /**
   * count dashboar current user
   * @returns
   */
  public countByCurrentUser() {
    return this._apollo.watchQuery({
      query: COUNT_DASBOARDS_BY_ID_CURRENT_USER,
      fetchPolicy: 'network-only',
    });
  }
}
