import { EnumPeriod } from "src/app/libs/modal/widgets-config/facades/enums/period.enum";
import { ICreateCountWidget, ICreateEditGraphWidget } from "src/app/libs/modal/widgets-config/facades/interfaces/createWidget.interface";
import { EnumGraphType, EnumWidgetColor, EnumWidgetType } from "../../facades/enums";

/** the default widget config */ export const defaultConfig = { dragEnabled: true, resizeEnabled: true, hasContent: true };

/**
 * widget configs
 */
export  const WIDGET_BOARD_CONFIG_MAP = new Map([
    [EnumWidgetType.INSTALLATION_GRAPH, (params: ICreateEditGraphWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.INSTALLATION_GRAPH,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: {
          ...params.widgetDataConfig,
          type: params.widgetDataConfig.period === EnumPeriod.DAY? EnumGraphType.AREA : EnumGraphType.STACKEDBAR,
        }
      }
      return config
    }],
    [EnumWidgetType.PRODUCTION_HISTORY_GRAPH, (params: ICreateEditGraphWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.PRODUCTION_HISTORY_GRAPH,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: {
          ...params.widgetDataConfig,
          type: EnumGraphType.BAR,
        }
      }
      return config
    }],
    [EnumWidgetType.CONSUMPTION_GRAPH, (params: ICreateEditGraphWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.CONSUMPTION_GRAPH,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: {
          ...params.widgetDataConfig,
          type: params.widgetDataConfig.period === EnumPeriod.DAY? EnumGraphType.AREA : EnumGraphType.STACKEDBAR,
        }
      }
      return config
    }],
    [EnumWidgetType.CER_GRAPH, (params: ICreateEditGraphWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.CER_GRAPH,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: {
          ...params.widgetDataConfig,
          // type: params.widgetDataConfig.period === EnumPeriod.DAY? EnumGraphType.AREA : EnumGraphType.STACKEDBAR,
        }
      }
      return config
    }],
    [EnumWidgetType.AUTO_CONSUMPTION_GRAPH, (params: ICreateEditGraphWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.AUTO_CONSUMPTION_GRAPH,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: {
          ...params.widgetDataConfig,
          type: params.widgetDataConfig.period === EnumPeriod.DAY? EnumGraphType.AREA : EnumGraphType.STACKEDBAR,
        }
      }
      return config
    }],
    [EnumWidgetType.DIOXYDE_GRAPH, (params: ICreateEditGraphWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.DIOXYDE_GRAPH,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: {
          ...params.widgetDataConfig,
          type: params.widgetDataConfig.period === EnumPeriod.DAY? EnumGraphType.AREA : EnumGraphType.STACKEDBAR,
        }
      }
      return config
    }],
    [EnumWidgetType.COUNT, (params: ICreateCountWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.COUNT,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: params.widgetDataConfig
      }
      return config
    }],
    [EnumWidgetType.COUNT_CONSUMPTION, (params: ICreateCountWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.COUNT_CONSUMPTION,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: params.widgetDataConfig
      }
      return config
    }],
    [EnumWidgetType.ECONOMY, (params: ICreateCountWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.ECONOMY,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: params.widgetDataConfig
      }
      return config
    }],
    [EnumWidgetType.KW_BY_KWCRETE, (params: ICreateEditGraphWidget) => {
      const config = {
        ...defaultConfig,
        cols: params.cols,
        rows: params.rows,
        widget: EnumWidgetType.KW_BY_KWCRETE,
        color: EnumWidgetColor.WHITE,
        widgetDataConfig: {
          ...params.widgetDataConfig,
          type: params.widgetDataConfig.period === EnumPeriod.DAY? EnumGraphType.AREA : EnumGraphType.STACKEDBAR,
        }
      }
      return config
    }],
    // [
    //   EnumWidgetType.Test,
    //   {
    //     ...defaultConfig,
    //     cols: 1,
    //     rows: 2,
    //     widget: EnumWidgetType.Test,
    //     color: EnumWidgetColor.INFO,
    //   },
    // ],
    // [
    //   EnumWidgetType.GRAPH,
    //   {
    //     ...defaultConfig,
    //     cols: 3,
    //     rows: 4,
    //     widget: EnumWidgetType.GRAPH,
    //     color: EnumWidgetColor.WHITE,
    //     widgetData: {
    //       type: EnumGraphType.BAR,
    //       title: 'Test de titre depuis config',
    //       labels: this.mockLabels,
    //       datas: [this.mockData1],
    //     },
    //   },
    // ],
    // [
    //   EnumWidgetType.COUNT,
    //   {
    //     ...defaultConfig,
    //     cols: 1,
    //     rows: 2,
    //     widget: EnumWidgetType.COUNT,
    //     color: EnumWidgetColor.SUCCESS,
    //   },
    // ],
  ]);
