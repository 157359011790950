import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { ISelectOption } from 'src/app/libs/input/facades';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { IPaginationInput } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { TranslationService } from '../../../translation/services/translation.service';
import { MyOverlayRef } from '../../overlay/myoverlay-ref';
import { ICreateCountWidget } from '../facades/interfaces/createWidget.interface';

/**
 * Config component to create Count Widget
 */
@Component({
  selector: 'app-economy-config',
  templateUrl: './economy-config.component.html',
  styleUrls: ['./economy-config.component.scss']
})
export class EconomyConfigComponent implements OnInit {

  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** Datas from edit modal */ @Input() public editDatas: any;
  /** FormGroup of count */ public economyFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;
  /** Options of the select top */ periodOptions: ISelectOption[] = [];
  /** Installation to select */ public installationsToSelect: ISearchSelectItem[] = [];
  /** Subject to listen for search with compare string */ public searchInstallationsSubject = new Subject<string>();
  /** Pagination used on search installations */ public pagination: IPaginationInput = { page: 1, limit: 20 };
  /** getter for controls of count form group */ get formControls() { return this.economyFormGroup.controls; }
  /** Pagination used on table */ public paginationInput: IPaginationInput = { page: 1, limit: 100 };
  /** Icon for closing modal */ public faTimes: IconDefinition = faTimes;
  /** selected installation */ public installationSelected: any = [];
  /**
   * Constructor method
   */
  constructor(
    private _translationSrv: TranslationService,
    private _fb: FormBuilder,
    protected _errorMessageSrv: ErrorMessageService,
    private _installationQueriesSrv: InstallationQueriesService,
  ) {
    this.inputErrorsLabelMap = new Map<string, string>([
      ["title", this._translationSrv.getInstant("installation_power-input")],
      ["period", this._translationSrv.getInstant("installation_power-input")],
      ["cols", this._translationSrv.getInstant("installation_power-input")],
      ["rows", this._translationSrv.getInstant("installation_power-input")],
      ["offset", this._translationSrv.getInstant("installation_power-input")],
      ["kwPrice", this._translationSrv.getInstant("installation_power-input")],
      ["installationItem", this._translationSrv.getInstant("installation_power-input")],
    ]);
  }

  /**
   * On Init method
   */
  ngOnInit(): void {
    this.initFormGroup();
  }

  /**
   * Init form group of count config
   */
  public initFormGroup(): void {
    var d = new Date();
    d.setHours(23,59,59,59);

    const presetDatas = this.editDatas && this.editDatas.widgetDataConfig? this.editDatas.widgetDataConfig : null;
    this.economyFormGroup = this._fb.group({
      title: [presetDatas? presetDatas.title : "", Validators.required],
      period: [presetDatas? presetDatas.date : d.getTime(), Validators.required],
      periodHour: [presetDatas? presetDatas.hour : null, Validators.required],
      cols: [this.editDatas && this.editDatas.cols != null? this.editDatas.cols : 2, Validators.required],
      rows: [this.editDatas && this.editDatas.rows != null? this.editDatas.rows : 2, Validators.required],
      counterNumber: [presetDatas? presetDatas.counterNumber : null],
      kwPrice: [presetDatas? presetDatas.kwPrice : null, Validators.required],
      offset: [presetDatas? presetDatas.offset : null],
      installationItem: [[], Validators.required],
    });

    if (presetDatas) {
      this._presetInstallationsDatas(presetDatas.installationsIds)
    }

    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        this.periodOptions.push({
          label: "0" + i + ":00",
          value: i * 3600000
        })
      } else {
        this.periodOptions.push({
          label: i + ":00",
          value: i * 3600000
        })
      }
    }
    this.instanciatelistener()
  }

  /**
   * preset installation datas
   * @param installationsIds installation ids
   */
  private _presetInstallationsDatas(installationsIds: number[]){
    this._installationQueriesSrv.getInstallationsByIds(installationsIds).subscribe((res: any) => {
      const { getInstallationsByIds } = res.data;
      if (getInstallationsByIds) {
        this.installationSelected = getInstallationsByIds.map(installation => {
          return {
            id: installation.id,
            label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
            data: installation
          }
        })
        this.economyFormGroup.get("installationItem").patchValue(this.installationSelected);
      }
    }, error => {
      console.log(error);
    })
  }


  /**
   * validate form
   * @param duplicate duplicate if true create a new widget with same settings
   * @returns void
   */
  public validateForm(duplicate: boolean = false) {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.economyFormGroup, this.inputErrorsLabelMap);

    if (this.economyFormGroup.invalid) {
      console.log("count-config.component.ts: countFormGroup is invalid.");
      return;
    }
    const economyToCreate: ICreateCountWidget = {
      cols: this.economyFormGroup.value.cols,
      rows: this.economyFormGroup.value.rows,
      widgetDataConfig: {
        title: this.economyFormGroup.get("title").value ? this.economyFormGroup.get("title").value : "Default title of count",
        finalTimestamp: this.economyFormGroup.get("period").value + this.economyFormGroup.get("periodHour").value - 86399000,
        date: this.economyFormGroup.get("period").value,
        hour: this.economyFormGroup.get("periodHour").value,
        installationsIds: this.installationSelected.map(elem => elem.id),
        counterNumber: this.economyFormGroup.get("counterNumber").value,
        kwPrice: this.economyFormGroup.get("kwPrice").value,
        offset: this.economyFormGroup.get("offset").value ? this.economyFormGroup.get("offset").value : 0
      }
    }
    if(this.editDatas && this.editDatas.id && !duplicate) economyToCreate['id'] = this.editDatas.id;
    this.close(economyToCreate);
  }

  /**
   * Close the modal and send a value to listeners
   * @param value
   */
  public close(value: any) {
    this.ref.close(value);
  }

  /**
   * instanciate listener
   */
  private instanciatelistener() {
    this.economyFormGroup.get("installationItem").valueChanges.subscribe(res => {
      this.installationSelected = res && res[0]? res : [];
    });
  }

  /**
   * Search a palamede installation based on search params (q)
   * @param q the search string to find the installation
   * @param idsSelected the installation ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the installation results
   */
  public searchInstallations = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._installationQueriesSrv.searchInstallations({ page: 1, limit: 1000 }, q).toPromise();
    let installations: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getInstallations.installations && (<any>resultQuery.data).getInstallations.installations)
      installations = (<any>resultQuery.data).getInstallations.installations

    installations = installations.map((installation: any) => {
      return {
        id: installation.id,
        label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
        data: installation
      };
    })
    return installations;
  }
}
