export enum AlarmType { 
    EQUAL_TO_ZERO = 0,
    PERCENTAGE_DIFF = 1,
}

/**
 * Interface for an alarm
 */
export interface CronJobConfig {
  /** the type of the alarm */ type: AlarmType;
  /** the begin hour of the alarm */ beginHour: number
  /** the end hours of the alarm */ endHour: number
  /** one string with all mail separated by , */ mail: string;

  /** only used for type AlarmType.PERCENTAGE_DIFF */ palamedeIds?: string[];
  /** only used for type AlarmType.PERCENTAGE_DIFF */ percentageDiff?: number;

  /** only used for type AlarmType.EQUAL_TO_ZERO */ installIds?: number[];
}