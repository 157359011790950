<div class="loader" *ngIf="loading">
    <div class="loader-item"></div>
</div>

<div class="counter">
    <div class="grid grid_col_2">
        <div class="since-date">{{sinceDate}}</div>
        <div *ngIf="counterNumber !== ''" class="counter-number">{{counterNumber}}</div>
    </div>
    <div class="counter-infos">{{count}}</div>
</div>

<div>
    <div class="graph-informations">{{installInfos.join(", ")}}</div>
</div>

