<input
    #myInput
    id="dateId"
    class="input input-date"
    [class.input-date-auto]="!fullSize"
    [type]="type"
    [name]="name"
    [id]="id"
    [attr.disabled]="!canEdit ? '' : null"
    max="{{maxDateFmt}}"
    min="{{minDateFmt}}"
    (change)="dateChanges($event)"
    value="{{selectedValue}}"
/>
