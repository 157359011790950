<button class="button_date-change" (click)="changeDate('prev')">
    <fa-icon [icon]="faChevronLeft"></fa-icon>
</button>
<div class="widget_date">
    <app-date-input class="grid__child_col_1" [group]="formGroup" name="currentDate" [range]="periodUnitMap.get(graphConfig.period)"></app-date-input>
</div>
<button class="button_date-change" (click)="changeDate('next')">
    <fa-icon [icon]="faChevronRight"></fa-icon>
</button>
<div style="width: 100%; height: 85%; position: relative;">
    <!-- <canvas id="testGraph" baseChart [datasets]="chartData" [labels]="chartLabels" [options]="chartOptions"
        [colors]="chartColors" [legend]="chartLegend" [chartType]="chartType" [plugins]="chartPlugins" [legend]="true">
    </canvas> -->
    <canvas #myCanvas></canvas>
</div>

<div *ngIf="information" class="graph-informations">
    <div>
       {{"totalKwhByKwcOnPerdiod" | translate}}: {{information.productionTotal? information.productionTotal.toFixed(2) : (0).toFixed(0)}}
    </div>
</div>

<div class="loader" *ngIf="loading">
    <div class="loader-item"></div>
</div>