<div class="box gap_top">
    <h4 class="title">{{ "usersTitle" | translate }}</h4>
    <div class="grid grid_col_2">
        <div *ngIf="canEdit" class="add-users__select">
            <app-label>{{ "addUserFormLabel" | translate }}</app-label>
            <app-search-select [group]="formGroup" formControlName="userItem"
                [query]="searchUsers"
                [name]="'userItem'"
                id="userItem"
                [selected]="selectedForSearch"
                [maxSelected]="1"
                [resetSelected]="resetSelected"></app-search-select>
            <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.userItem" class="invalid-label">
                {{ inputErrorsLabel.userItem.msg | translate }}
            </div>
        </div>
        <div *ngIf="canEdit" class="add-users__validate">
            <div class="add-users__validate_icon">
                <fa-icon [icon]="faPlusCircle" (click)="addUser()"></fa-icon>
            </div>
        </div>
    </div>

    <div *ngIf="selectedUsers.length > 0" class="grid grid_col_1" style="margin-top: 2em">
        <app-label>{{ "addUserTableLabel" | translate }}</app-label>
        <app-table style="margin-top: -2em"
            [columns]="columns"
            [contents]="contents"
            [smallPadding]="true"
            [withPagination]="false"
            [paginationResult]="paginationResult"
            (tableAction)="tableActionClicked($event)"
            (pageChangeEmitter)="onPageChange($event)"
            [canEdit]=canEdit>
        </app-table>
    </div>
</div>

<ng-template #tplDeleteUserToLink>
    <app-modal-item [ref]="ref" [config]="modalConfig"></app-modal-item>
</ng-template>
