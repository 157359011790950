import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ICreateRec } from "src/app/pages/rec/facades/interfaces/createRec.interface";
import { IUpdateRecInput } from "src/app/pages/rec/facades/interfaces/updateRec.interface";
import { IPaginationInput } from "../pagination/interfaces/paginationInput.interface";
import { CREATE_REC, DELETE_REC, GET_REC, GET_RECS, GET_RECS_BY_COMPARE, UPDATE_REC, COUNT_RECS, GET_USER_RECS } from "./rec.queries";

/**
 * User queries service
 */
@Injectable({
  providedIn: "root"
})
export class RecQueriesService {

  /** Pagination base for table */ private _pagination: IPaginationInput = { page: 1, limit: 20 };
  /** The search string type by the user to use to filter the table result */ private _queryString: string = null;
  /** The order to user to sort the table result*/ private _order: {key: string, direction: string} = null ;

  /** GETTERS AND SETTERS */
  public get pagination(): IPaginationInput { return this._pagination; }
  public set pagination(pagination: IPaginationInput) { this._pagination = pagination; }
  public get queryString(): string { return this._queryString; }
  public set queryString(queryString: string) { this._queryString = queryString; }
  public get order(): {key: string, direction: string} { return this._order; }
  public set order(order: {key: string, direction: string}) { this._order = order; }

  /**
   * constructor method
   * @param _apollo
   */
  constructor(private _apollo: Apollo) { }


  /**
   * Search a consumption
   * @param name
   * @returns
   */
    public searchUserRecs(pagination?: IPaginationInput, queryString?: string) {
    let variables = { pagination };
    if (queryString) variables['search'] = queryString;
    return this._apollo.query({
      query: GET_USER_RECS,
      variables,
      fetchPolicy: "network-only"
    })
  }

  /**
   * Search a consumption
   * @param name
   * @returns
   */
     public searchRecs(pagination?: IPaginationInput, queryString?: string) {
      let variables = { pagination };
      if (queryString) variables['search'] = queryString;

      return this._apollo.query({
        query: GET_RECS,
        variables,
        fetchPolicy: "network-only"
      })
    }

  /**
   * Get every rec
   * @param pagination
   * @returns
   */
  public getRecs() {
    return this._apollo.watchQuery({
      query: GET_RECS,
      variables: {
        search: this.queryString,
        order: this.order,
        pagination: this.pagination
      },
      fetchPolicy: 'cache-and-network',
    });
  }


  /**
   * Get every rec
   * @param id
   * @returns
   */
  public getRec(id: number) {
    return this._apollo.query({
      query: GET_REC,
      variables: { id },
      fetchPolicy: "network-only"
    });
  }

  /**
 * Get recs with a compare string
 * @param name
 * @returns
 */
  public async getRecsByCompare(pagination: IPaginationInput, compareString?: string) {
    return await this._apollo.query({
      query: GET_RECS_BY_COMPARE,
      variables: { pagination, compareString },
      fetchPolicy: "network-only"
    }).toPromise();
  }

  /**
   * Create a rec
   * @param rec
   * @returns
   */
  public createRec(rec: ICreateRec) {
    return this._apollo.mutate({
      mutation: CREATE_REC,
      variables: { createRecInput: rec },
      fetchPolicy: "no-cache",
      refetchQueries: [
        { query: COUNT_RECS },
        {
          query: GET_RECS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        },
      ],
    });
  }

  /**
   * Update a rec
   * @param rec
   * @returns
   */
  public updateRec(recToUpdate: IUpdateRecInput) {
    return this._apollo.mutate({
      mutation: UPDATE_REC,
      variables: { updateRecInput: recToUpdate },
      fetchPolicy: 'no-cache',
      refetchQueries: [
        {
          query: GET_RECS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        },
      ],
    });
  }

  /**
   * Delete a rec
   * @param rec
   * @returns
   */
  public deleteRec(id: number) {
    return this._apollo.mutate({
      mutation: DELETE_REC,
      variables: { id },
      fetchPolicy: 'no-cache',
      refetchQueries: [
        { query: COUNT_RECS },
        {
          query: GET_RECS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        },
      ],
    });
  }

  /**
  * count dashboar current user
  * @returns
  */
  public countRecs() {
    return this._apollo.watchQuery({
      query: COUNT_RECS,
      fetchPolicy: 'network-only',
    });
  }

}
