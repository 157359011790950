import { Component, ViewChild, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { STATUS_ALERTS, FILES_BLOCK_ACTIONS_ENUM } from "./enum/imgImputEnum";
import { TranslationService } from '../../translation/services/translation.service';
import { AlertService } from "ngx-alerts";
import { faPlus, IconDefinition } from "@fortawesome/free-solid-svg-icons";

/**
 * Component for the image input
 */
@Component({
  selector: "app-img-input",
  templateUrl: "./img-input.component.html",
  styleUrls: ["./img-input.component.scss"]
})
export class ImgInputComponent {

  /** true if the input should be disable */ @Input() public disabled = false;
  /** the accedpted image type */ @Input() public acceptedType: string = "image/*"
  /** true if the input shoukd be hidden */ @Input() public hiddenInput = false;
  /** an array of file in the input */ @Input() files: File[] = [];
  /** true if the input is requiered*/ @Input() required: boolean = false;
  /** true if the file contain an error*/ @Input() isFileError: boolean = false;
  /** triggerd when a file is added or removed */ @Output() public sendImg = new EventEmitter<any>();
  /** view where the images will be displayed */ @ViewChild("imgInput", {static: false}) public imgInput: ElementRef;
  /** Font awesome icon for delete */ public faPlus: IconDefinition = faPlus;
  /** action enum */ public FILES_BLOCK_ACTIONS_ENUM = FILES_BLOCK_ACTIONS_ENUM;

  /**
   * constructor
   * @param _alertSrv the alert service
   * @param _translateSrv the translation service
   */
  constructor(private _alertSrv: AlertService,
              private _translateSrv: TranslationService) {}

  /**
   * choose a file
   * @param event the event
   */
  public chooseFile(event: Event) {
    if(event) event.stopPropagation();
    if (!this.disabled) {
      this.imgInput.nativeElement.click();
    }
  }

  /**
   * Add the file in the filesList and read it for preview
   * @param evt the event
   */
  public set(evt: Event) {
    let file = (evt.target as HTMLInputElement).files[0];
    if (file) {
      if (this.acceptedType === "image/*" && file.type.indexOf("image/") === -1) {
        this._alertSrv.danger(
          this._translateSrv.getInstant("errorLabel") + 
          this._translateSrv.getInstant("general_fileNeedToBePicture-txt")
        )
      } else {
        // Read file for preview
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          file["previewUrl"] = reader.result;
          this.sendImg.emit(file);
          this.imgInput.nativeElement.value = "";
        };
      }
    }
  }

  /**
   * on drop
   * @param ev the event
   */
  public onDrop(ev: any) {
    ev.preventDefault();
    if (!!ev.dataTransfer.files[0]) { this.set(ev.dataTransfer.files[0]); }
  }

  /**
   * avoid
   * @param ev the event
   */
  public avoid(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
  }
}
