import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text-input/text-input.component'
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component'
import { RadioInputComponent } from './radio-input/radio-input.component'
import { SearchInputComponent } from './search-input/search-input.component'
import { LabelComponent } from '../label/label.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMessageService } from './facades/services/error-message.service';
import { SharedDirectivesModule } from '../directives/directives.modules';
import { NumberInputComponent } from './number-input/number-input.component';
import { CalendarInputComponent } from './calendar-input/calendar-input.component';
import { ButtonInputComponent } from './button-input/button-input.component';
import { TranslationModule } from '../translation/translation.module';
import { DateInputComponent } from './date-input/date-input.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ImgInputComponent } from './img-input/img-input.component';
import { ImgBlockComponent } from './img-block/img-block.component';
import { DateInputManualComponent } from './date-input-manual/date-input-manual.component';

@NgModule({
  declarations: [
    TextInputComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    SearchInputComponent,
    LabelComponent,
    SelectInputComponent,
    TextareaInputComponent,
    PasswordInputComponent,
    NumberInputComponent,
    CalendarInputComponent,
    ButtonInputComponent,
    DateInputComponent,
    DatePickerComponent,
    ImgInputComponent,
    ImgBlockComponent,
    DateInputManualComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    TranslationModule,
    FontAwesomeModule
  ],
  exports : [
    TextInputComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    SearchInputComponent,
    LabelComponent,
    SelectInputComponent,
    TextareaInputComponent,
    PasswordInputComponent,
    NumberInputComponent,
    CalendarInputComponent,
    ButtonInputComponent,
    DateInputComponent,
    DatePickerComponent,
    ImgInputComponent,
    ImgBlockComponent,
    DateInputManualComponent
  ],
  providers: [
    ErrorMessageService
  ]
})
export class InputModule { }
