import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { AInputs } from '../facades';
import { ErrorMessageService } from '../facades/services/error-message.service';

/**
 * textarea input component
 */
@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss']
})
export class TextareaInputComponent extends AInputs {

  /** Rows size related to the textarea */    @Input() public rows: number;
  /** Column size related to the textarea */  @Input() public cols: number;

  /**
    * Constructor method
    * @param {Renderer2} renderer Custom rendering
    * @param {ErrorMessageService} _errorMessageSrv Service of error messages
    */
  constructor(public renderer: Renderer2, protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
  }
}
