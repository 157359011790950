import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { EnumTableAction, ITableAction, ITableColumn } from 'src/app/libs/table/facades';
import { ISearchConfig } from 'src/app/libs/table/facades/interfaces/tableSearchConfig.interface';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { IPaginationInput, IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { ConsumptionQueriesService } from 'src/app/queries/consumption/consumption-queries.service';
import { AlertService } from 'ngx-alerts';
import { CONSUMPTION_PAGES_ENUM } from '../facades/enums/consumptionsPages.enum';

/**
 * Consumptions list component
 * @class ConsumptionsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-consumptions',
  templateUrl: './consumptions.component.html',
  styleUrls: ['./consumptions.component.scss'],
})
export class ConsumptionsComponent implements OnInit {

  /** boolean true if the user can edit the installation otherwise false */ canEdit: boolean = localStorage.getItem("ideta_modulec_userRole") ? localStorage.getItem("ideta_modulec_userRole") !== "USER" : false
  /** Template of modal */ @ViewChild('tplDeleteConsumption', { static: true }) modalTemplate: TemplateRef<any>;
  
  /** The list of consumptions pages */ pagesEnum: typeof CONSUMPTION_PAGES_ENUM = CONSUMPTION_PAGES_ENUM;

  /** Array of columns for table */ public columns: ITableColumn[] = [];
  /** Content to display on table */ public content: any[] = [];
  /** Pagination result base used on table */ public paginationResult: IPaginationResult = {
    ...this._consumptionsSrv.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1,
  };

  /** Consumption saved when deleting it */ public consumptionToDelete: any = {};
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
  /** Modal configuration */ public modalConfig: IModalItemConfig = {};
  /** Configuration of search input */ public searchConfig: ISearchConfig = { searchPlaceholderKey: "searchPlaceholderConsumptions" }
  /**
   * Creates an instance of ConsumptionsComponent.
   * @memberof ConsumptionsComponent
   */
  constructor(
    /** Router */ private _router: Router,
    /** Activated route */ private _activedRoute: ActivatedRoute,
    /** Consumptions service */ private _consumptionsSrv: ConsumptionQueriesService,
    /** Translation service */ private _translateSrv: TranslationService,
    /** Overlay service */ private _overlayService: OverlayService,
    /** Alert service */ private _alertSrv: AlertService

  ) {
  }

  /**
   * Called on init
   * @memberof ConsumptionsComponent
   */
  ngOnInit(): void {
    this.buildTable();
    // this.getConsumptions(); // onSearch('') is used in order to reset the filter when page is loaded
    this.onSearch('');
  }


  /**
   * build the table content based on the user role
   */
  buildTable() {
    this.columns = [
      { key: 'name', type: 'text', title: this._translateSrv.getInstant("consumptionTable_name-label"), sortable: true },
      { key: 'serialNumber', type: 'text', title: this._translateSrv.getInstant("consumptionTable_serialNumber-label"), sortable: true }
    ];
    if (this.canEdit) {
      this.columns.push(
        { key: 'consumptionPalamede.id', type: 'text', title: this._translateSrv.getInstant("consumptionTable_palamedeId-label"), sortable: false },
        { key: 'consumptionPalamede.name', type: 'text', title: this._translateSrv.getInstant("consumptionTable_palamedeName-label"), sortable: false },
        { key: 'actions', type: 'action', title: 'Actions' },
      )
    }
  }

  /**
   * Called to navigate to another page
   * @param page URL
   * @param content content to pass to page
   * @memberof ConsumptionsComponent
   */
  goTo(page: string, content?: any) {
    this._router.navigate([page], {
      relativeTo: this._activedRoute,
      state: content,
    });
  }

  /**
   * Handle the action triggered by a user click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   * @memberof ConsumptionsComponent
   */
  public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.consumptionToDelete = event.content;
        this.open(this.modalTemplate)
        break;
      case EnumTableAction.EDIT:
        this.goTo(this.pagesEnum.DETAIL + `/${event.content.id}`, event);
        break;
      case EnumTableAction.SHOW:
        break;
      case EnumTableAction.SORT:
        if (event && event.content && event.content.key) {
          this._consumptionsSrv.order = {
            key: event.content.key,
            direction: this._consumptionsSrv.order && this._consumptionsSrv.order.key === event.content.key ? (
              this._consumptionsSrv.order.direction === "ASC" ? "DESC" : "ASC") :
              "ASC"
          }
          this.getConsumptions();
        }
        break;
      default:
        break;
    }
  }

  /**
   * Open modal with content
   * @param content 
   */
  public open(content: TemplateRef<any>) {
    this.modalConfig = {
      title: this._translateSrv.getInstant('modalDeleteConsumption'),
      body: `${this._translateSrv.getInstant('modalDeleteConsumptionBody', { name: this.consumptionToDelete.name, serialNumber: this.consumptionToDelete.serialNumber })}`,
      buttons: [
        {
          message: this._translateSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translateSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res.data.value === true) {
        this.onDelete();
      }
    });
  }

  /**
   * get the list of all the consumptions
   * @memberof ConsumptionsComponent
   */
  public getConsumptions(): void {
    this._consumptionsSrv.getConsumptions().valueChanges.subscribe((result) => {
      if (result.errors && result.errors.length > 0) {
        console.log('error', result.errors);
        throw result.errors;
      } else {
        const data: any = <any>result.data;
        if (data && data.getConsumptions && data.getConsumptions.consumptions) {
          this.content = data.getConsumptions.consumptions.map(
            (consumption) => {
              // if palamede link is broken, object is undefined so we'll complete it with an error message
              if (!consumption.consumptionPalamede) {
                consumption.consumptionPalamede = {
                  id: consumption.consumptionPalamedeId,
                  name: '-- ID Palamede introuvable ! --'
                }
              }
              return consumption;
            }
          );
        }
        if (data && data.getConsumptions && data.getConsumptions.pagination) {
          this.paginationResult = data.getConsumptions.pagination;
        }
      }
    });
  }

  /**
   * Handle the action triggered by user click on delete button
   * @param {boolean} event event from button trigger
   * @memberof ConsumptionsComponent
   */
  public onDelete(): void {
   
    this._consumptionsSrv.removeConsumption(this.consumptionToDelete.id).subscribe((result) => {
      if (result.errors && result.errors.length > 0) {
        this._alertSrv.danger(this._translateSrv.getInstant("errorDeleteConsumption"));
        console.log('error', result.errors)
      } else {
        this._alertSrv.success(this._translateSrv.getInstant("successDeleteConsumption"));
        // Verify if we need to change pagination in case we delete the last element of the page
        if ((this.content.length - 1) == 0) {
          if (this.paginationResult.hasPrevious) this._consumptionsSrv.pagination.page = this.paginationResult.page - 1;
          else this._consumptionsSrv.pagination.page = 1;
        }
        this.getConsumptions();
      }
    });
  }

  /**
   * Handle the action triggered to change the page to display
   * @param {number} event
   * @memberof ConsumptionsComponent
   */
  public onPageChange(event: number): void {
    this._consumptionsSrv.pagination = { ...this._consumptionsSrv.pagination, page: event }
    this.getConsumptions();
  }

  /**
   * onSearch callback handle when user type in the search field to filter the list
   * @param {string} q the search param type by the user to filter the list
   * @returns {void}
   */
  public onSearch(q: string): void {
    this._consumptionsSrv.pagination = { ...this._consumptionsSrv.pagination, page: 1 }
    this._consumptionsSrv.queryString = q;
    this.getConsumptions();
  }
}
