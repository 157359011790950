import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountConfigComponent } from './count-config/count-config.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslationModule } from '../../translation/translation.module';
import { InputModule } from '../../input/input.module';
import { SearchSelectModule } from '../../search-select/search-select.module';
import { GraphConfigService } from './facades/services/graph-config.service';
import { ProductionGraphConfigComponent } from './production-graph-config/production-graph-config.component';
import { ConsumptionGraphConfigComponent } from './consumption-graph-config/consumption-graph-config.component';
import { ProductionHistoryGraphConfigComponent } from './production-history-graph-config/production-history-graph-config.component';
import { CerGraphConfigComponent } from './cer-graph-config/cer-graph-config.component';
import { AutoConsumptionGraphConfigComponent } from './auto-consumption-graph-config/auto-consumption-graph-config.component';
import { DioxydeGraphConfig } from './dioxyde-graph-config/dioxyde-graph-config.component';
import { EconomyConfigComponent } from './economy-config/economy-config.component';
import { KwPerKwcreteGraphConfigComponent } from './kwPerKwcrete-graph-config/kwPerKwcrete-graph-config.component';
import { CountConsumptionConfigComponent } from './count-consumption-config/count-consumption-config.component';

/**list of componenent to include in the module */
const components = [
  ProductionGraphConfigComponent,
  ProductionHistoryGraphConfigComponent,
  ConsumptionGraphConfigComponent,
  CountConfigComponent,
  CountConsumptionConfigComponent,
  CerGraphConfigComponent,
  EconomyConfigComponent,
  AutoConsumptionGraphConfigComponent,
  DioxydeGraphConfig,
  KwPerKwcreteGraphConfigComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslationModule,
    InputModule,
    SearchSelectModule
  ],
  exports: [...components],
  providers: [
    GraphConfigService
  ]
})
export class WidgetsConfigModule { }
