import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'ngx-alerts';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { TranslationService } from 'src/app/shared/Services/translation.service';
import { ISelectOption } from '../../input/facades';
import { ErrorMessageService } from '../../input/facades/services/error-message.service';
import { MyOverlayRef } from '../../modal/overlay/myoverlay-ref';
import { ISearchSelectItem } from '../../search-select/facades/interfaces/searchSelectItem.interface';
import { FILE_TYPES_ENUM } from '../facades/enum/fileTypes.enum';
import { EXPORT_TYPES_ENUM } from '../facades/enum/exportTypes.enum';
import { ConsumptionQueriesService } from 'src/app/queries/consumption/consumption-queries.service';
import moment from 'moment';

/**
 * export config component
 */
@Component({
  selector: 'app-export-config',
  templateUrl: './export-config.component.html',
  styleUrls: ['./export-config.component.scss']
})
export class ExportConfigComponent implements OnInit {

  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** FormGroup of export */ public exportFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;
  /** getter for controls of count form group */ get formControls() { return this.exportFormGroup.controls; }
  /** selected installation */ public installationSelected: any = [];
  
  /** Role translated to display on form */ public fileTypesProductionOptions: ISelectOption[] = [
    { value: FILE_TYPES_ENUM.CSV, label: "CSV" },
    { value: FILE_TYPES_ENUM.PDF, label: "PDF" },
  ];
  /** Role translated to display on form */ public fileTypesConsumptionOptions: ISelectOption[] = [
    { value: FILE_TYPES_ENUM.CSV, label: "CSV" },
  ];
  /** Role translated to display on form */ public typeExportOptions: ISelectOption[] = [
    { value: EXPORT_TYPES_ENUM.PRODUCTION, label: this._translationSrv.getInstant("productionLabel") },
    { value: EXPORT_TYPES_ENUM.CONSUMPTION, label: this._translationSrv.getInstant("consumptionLabel") },
  ];
  /** enum to use in html file */ fileTypeEnum: typeof FILE_TYPES_ENUM = FILE_TYPES_ENUM;
  /** enum to use in html file */ typeExportEnum: typeof EXPORT_TYPES_ENUM = EXPORT_TYPES_ENUM;

  /**
   * constructor
   * @param _installationQueriesSrv the installation queries service
   * @param _consumptionsQueriesSrv the consumptions queries service
   * @param _translationSrv the translation service
   * @param _fb the form builder
   * @param _errorMessageSrv the error message service
   * @param _alertSrv the alert service
   */
  constructor(
    private _installationQueriesSrv: InstallationQueriesService,
    private _consumptionsQueriesSrv: ConsumptionQueriesService,
    private _translationSrv: TranslationService,
    private _fb: FormBuilder,
    protected _errorMessageSrv: ErrorMessageService,
    private _alertSrv: AlertService,
  ) { 
    this.inputErrorsLabelMap = new Map<string, string>([
      ["title", this._translationSrv.getInstant("installation_power-input")],
      ["periodStart", this._translationSrv.getInstant("installation_power-input")],
      ["periodEnd", this._translationSrv.getInstant("installation_power-input")],
      ["chosenItems", this._translationSrv.getInstant("installation_power-input")],
      ["fileType", this._translationSrv.getInstant("installation_power-input")],
    ]);
  }

  /**
   * init the component
   */
  ngOnInit(): void {
    this.initFormGroup();
    this.presetFileName();
  }

  /**
   * init the form group
   */
  public initFormGroup(): void {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // yesterday.toUTCString()

    this.exportFormGroup = this._fb.group({
      typeExport: [null, Validators.required],
      title: ["", Validators.required],
      periodStart: [yesterday, Validators.required],
      periodEnd: [Date.now(), Validators.required],
      chosenItems: [[], Validators.required],
      fileType: [null, Validators.required],
    });
    this.instanciatelistener();
  }

  /**
   * validate the form
   * @returns void
   */
  validateForm() {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.exportFormGroup, this.inputErrorsLabelMap);

    if (this.exportFormGroup.value.periodStart > this.exportFormGroup.value.periodEnd) this._alertSrv.danger(this._translationSrv.getInstant("periodStartMustBeBeforeEnd"))

    if (this.exportFormGroup.invalid) {
      console.log("export-config.component.ts: exportFormGroup is invalid.");
      return;
    }
    const exportDatas: any = {
      typeExport: this.exportFormGroup.value.typeExport,
      title: this.exportFormGroup.value.title,
      periodStart: typeof this.exportFormGroup.value.periodStart === "number" ? this.exportFormGroup.value.periodStart : Date.parse(this.exportFormGroup.value.periodStart.toUTCString()),
      chosenItems: this.exportFormGroup.value.chosenItems,
      fileType: this.exportFormGroup.value.fileType,
    }

    if (this.exportFormGroup.value.fileType === FILE_TYPES_ENUM.CSV)
      exportDatas.periodEnd = this.exportFormGroup.value.periodEnd

    this.close(exportDatas);
  }

  /**
   * Close the modal and send a value to listeners
   * @param value
   */
  public close(value: any) {
    if (!this.ref) return;
    this.ref.close(value);
  }

  /**
   * instanciate listener
   */
  private instanciatelistener() {
    this.exportFormGroup.get("chosenItems").valueChanges.subscribe(res => {
      this.installationSelected = res && res[0]? res : [];
      console.log("file type : ", this.exportFormGroup.get("fileType").value)
      this.presetFileName()
    });

    this.exportFormGroup.get("typeExport").valueChanges.subscribe(res => {
      if (res && res === EXPORT_TYPES_ENUM.CONSUMPTION)
        this.exportFormGroup.get("fileType").patchValue(FILE_TYPES_ENUM.CSV);
      this.presetFileName()
    });

    this.exportFormGroup.get("periodStart").valueChanges.subscribe(res => {
      this.presetFileName()
    });
  }

  /**
   * preset the file name
   */
  private presetFileName() {
    const typeExport = this.exportFormGroup.get("typeExport").value;
    let date = moment(this.exportFormGroup.get("periodStart").value)
    let valueDate = date.format("DD-MM-YYYY");

    let nameToExport = "(";
    this.installationSelected.forEach(element => {
      nameToExport += element.data.name;
      if (element !== this.installationSelected[this.installationSelected.length - 1]) nameToExport += ", ";
    });
    nameToExport += ")";
    let fileName = "";
    if (typeExport === EXPORT_TYPES_ENUM.PRODUCTION) {
      let exportTypeName = this._translationSrv.getInstant("prodExport");
      fileName = `${exportTypeName}`;
      fileName += nameToExport.length > 2 ? nameToExport : "";
      fileName += `-${valueDate}`;
    } else {
      let exportTypeName = this._translationSrv.getInstant("ConsumptionExport");
      fileName = `${exportTypeName}`;
      fileName += nameToExport.length > 2 ? nameToExport : "";
      fileName += `-${valueDate}`;
    }

    this.exportFormGroup.get("title").patchValue(fileName);
    console.log("presetFileName : ", fileName);
    console.log("this.installationSelected : ", this.installationSelected);
  }

  /**
   * Search a palamede installation based on search params (q)
   * @param q the search string to find the installation
   * @param idsSelected the installation ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the installation results
   */
   public searchInstallations = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._installationQueriesSrv.searchInstallations({ page: 1, limit: 1000 }, q).toPromise();
    let installations: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getInstallations.installations && (<any>resultQuery.data).getInstallations.installations)
      installations = (<any>resultQuery.data).getInstallations.installations

    installations = installations.map((installation: any) => {
      return {
        id: installation.id,
        label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
        data: installation
      };
    })
    return installations;
  }

  /**
   * Search a palamede installation based on search params (q)
   * @param q the search string to find the installation
   * @param idsSelected the installation ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the installation results
   */
   public searchConsumptions = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._consumptionsQueriesSrv.searchConsumptions({ page: 1, limit: 1000 }, q).toPromise();
    let consumptions: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getConsumptions.consumptions && (<any>resultQuery.data).getConsumptions.consumptions)
      consumptions = (<any>resultQuery.data).getConsumptions.consumptions

      consumptions = consumptions.map((consumption: any) => {
      return {
        id: consumption.id,
        label: `${consumption.name} (${this._translationSrv.getInstant("serialNumber")}: ${consumption.serialNumber})`,
        data: consumption
      };
    })
    return consumptions;
  }

}
