import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { faTimes, faTimesCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { ICommentConfig } from '../../interfaces/comment.interface';


/**
 * modal comment component
 */
@Component({
  selector: 'app-modal-comment',
  templateUrl: './modal-comment.component.html',
  styleUrls: ['./modal-comment.component.scss']
})
export class ModalCommentComponent implements OnInit {
  /** true if the modal should be displayed */ @Input() public display: boolean = false;
  /** Array of all comments */ @Input() public comment: ICommentConfig;
  /** Translate key for validate button (edit or add) */ public validateTranslateKey = "addComment"
  /** True if we are in edit mode */ public isEdit: boolean = false;
  /** FormGroup of comments */ public commentFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** Font Awesome Icon for closing button */ public faTimes: IconDefinition = faTimes;
  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** Config of the modal (header, body, footer) */ @Input() public config: IModalItemConfig;
  /** Array of File of the comment */ public fileArray: File[] = [];


  /**
   * constructor
   * @param _fb the form builder
   */
  constructor(
    private _fb: FormBuilder,
  ) { }

  /**
   * onInit 
   */
  ngOnInit(): void {
    if (this.comment) {
      this.isEdit = true;
      this.validateTranslateKey = "editComment"
    }
    this.initFormGroup();
  }

  /**
   * Init form group of comments adding
   */
   public initFormGroup(): void {
    this.commentFormGroup = this._fb.group({
      id: [this.comment ? this.comment.id : ""],
      title: [this.comment ? this.comment.title : ""],
      description: [this.comment ? this.comment.description : ""],
      files: [this.comment ? this.comment.files : ""]
    });
  }

  /**
   * Close the modal and send a value to listeners
   * @param value
   */
   close(value: any) {
    this.ref.close(value);
  }

  /**
   * Validate form of comment creation
   */
  validateForm(): void {
    this.isSubmitted = true;
    let item = {
      ...this.commentFormGroup.value,
    }
    if (!this.isEdit) {
      delete item.id;
    }
    this.close({
      type: this.isEdit ? "edit" : "add",
      value: item,
      valid: true
    });
    this.commentFormGroup.reset();
  }

  /**
   * this function remove a file that have been added
   * @param file the file that should be removed
   */
  private removeFile(file: File)
  {
    this.fileArray = this.fileArray.filter((obj) => obj != file)
    this.commentFormGroup.get('files').patchValue(this.fileArray);
  }

  /**
   * this function will be called by one of the app-img-block containing an image
   * @param event the event that contain {action: Delete or Zoom, file: the File that have triggerd the event}
   * @returns nothing
   */
  onFileAction(event: any)
  {
    if (event && event.action && event.file)
    {
      switch(event.action) {
        case "Delete":
          return this.removeFile(event.file);
        case "Zoom": // todo: display the image in full screen
          return;
      }
    }
  }
  /**
   * this function will be triggerd when the user add a new image
   * @param file the file that have been added
   */
  onNewFile(file: any) {
    if (file) {
      this.fileArray.push(file)
      this.commentFormGroup.get('files').patchValue(this.fileArray);
    }
  }
}
