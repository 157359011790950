import { EventEmitter, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlusCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { EnumTableAction, ITableAction, ITableColumn } from 'src/app/libs/table/facades';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { IPaginationInput, IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { IInstallation } from 'src/app/queries/installation/interfaces/installations.interface';

/**
 * linked installation
 */
@Component({
  selector: 'app-linked-installations',
  templateUrl: './linked-installations.component.html',
  styleUrls: ['./linked-installations.component.scss']
})
export class LinkedInstallationsComponent implements OnInit {
  /** FormGroup for add a linked installation */ public formGroup: FormGroup;
  /** Add button icon */ public faPlusCircle: IconDefinition = faPlusCircle;
  /** list of installations selected for remove from the select search */ public selectedForSearch: ISearchSelectItem[] = [];
  /** List of installations selected to display for the table */ @Input() public selectedInstallations: IInstallation[] = [];
  /** EventEmitter when the array of installations linked changed */ @Output("installationsChanged") public installationsChanged: EventEmitter<IInstallation[]> = new EventEmitter();

  /** Variable to send to search & select to reset selected item */ public resetSelected = new Subject<boolean>();
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** Map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** Label of inputs errors */ public inputErrorsLabel: any;
  /** Installation to delete */ public installationToDelete: any;
  /** Modal configuration */ public modalConfig: IModalItemConfig = {};
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
  /** Template of modal */ @ViewChild('tplDeleteInstallationToLink', { static: true }) modalTemplate: TemplateRef<any>;
  /** Pagination base for table */ public pagination: IPaginationInput = { page: 1, limit: 1 };
  /** pagination result for table */
  public paginationResult: IPaginationResult = {
    ...this.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1,
  };
  /** Array of columns for table */
  public columns: ITableColumn[] = [
    { key: 'name', type: 'text', title: this._translationSrv.getInstant("fullName"), clickable: false },
    { key: 'serialNumber', type: 'text', title: this._translationSrv.getInstant("serialNumber"), clickable: false },
    { key: 'actions', type: 'action', title: this._translationSrv.getInstant('actions') },
  ];

  /** previous length */ private _previousSelectedLength: number = 0;


  /**
   * constructor
   * @param _router the router
   * @param _activedRoute the activated route
   * @param _fb the form builder
   * @param _installationsQueriesSrv the installation queries service
   * @param _translationSrv the translation service
   * @param _overlayService the overlay service
   * @param _alertSrv the alert service
   */
  constructor(private _router: Router,
              private _activedRoute: ActivatedRoute,
              private _fb: FormBuilder,
              private _installationsQueriesSrv: InstallationQueriesService,
              private _translationSrv: TranslationService,
              private _overlayService: OverlayService,
              private _alertSrv: AlertService) {}

  /**
   * on init
   */
  ngOnInit() {
    this.initFormGroup();
  }

  /**
   * on check
  */
  ngDoCheck(){
    if(this.selectedInstallations && this.selectedInstallations.length !== this._previousSelectedLength){
      this._previousSelectedLength = this.selectedInstallations.length;
      this.buildSelectedForSearch();
    }
  }

  /**
   * init form group
   */
  public initFormGroup(): void {
    this.formGroup = this._fb.group({
      installationItem: [null, Validators.required],
    })
  }

  /**search installation */
  public searchInstallations = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._installationsQueriesSrv.searchInstallations({ page: 1, limit: 1000 }, q).toPromise();
    let installations: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getInstallations.installations && (<any>resultQuery.data).getInstallations.installations)
      installations = (<any>resultQuery.data).getInstallations.installations

    installations = installations.map((installation: any) => {
      return {
        id: installation.id,
        label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
        data: installation
      };
    })
    return installations;
  }

  /**
   * Add installation to the list of selected installations
   * @returns 
   */
  public addInstallation() {
    if(!this.formGroup || !this.formGroup.get("installationItem").value || !this.formGroup.get("installationItem").value[0]) {
      this._alertSrv.danger(this._translationSrv.getInstant("installationRequiredToLink_error"));
      return;
    }
    this.selectedInstallations.push(this.formGroup.get("installationItem").value[0].data)
    this.buildSelectedForSearch();
    this.formGroup.get("installationItem").setValue([]);
    this.resetSelected.next(true);
    this.installationsChanged.emit(this.selectedInstallations);
  }

  /**
   * Handle the action triggered by a installation click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   */
  public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.installationToDelete = event.content;
        this.open(this.modalTemplate);
        break;
      case EnumTableAction.EDIT:
        this.goTo(`/installations/detail/${event.content.id}`);
        break;
      case EnumTableAction.BOOLEAN:
        // this.updateActiveInstallation(event.content);
        break;
      case EnumTableAction.SORT:
        if (event && event.content && event.content.key) {
          // this.pagination.page = 1;
          // this.sortedBy = event.content.key;
          // this.sortInstallations(event.content.key)
        }
        break;
      default:
        break;
    }
  }

  /**
   * Called by table child when page is changed
   * @param event 
   */
  public onPageChange(event: number): void {
    this.pagination.page = event;
  }

  /**
   * Open modal with content
   * @param content 
   */
  public open(content: TemplateRef<any>) {
    this.modalConfig = {
      title: this._translationSrv.getInstant('modalDeleteInstallation'),
      body: `${this._translationSrv.getInstant('modalDeleteInstallationBody', { name: this.installationToDelete.name, serialNumber: this.installationToDelete.serialNumber })}`,
      buttons: [
        {
          message: this._translationSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translationSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res.data.value === true) {

        // Search the index of the element to remove
        const index = this.selectedInstallations.findIndex(item => item.id === this.installationToDelete.id);

        // Need to use a tempArray because of reference of the searchSelectedInstallations array need to change
        let tempArray = this.selectedForSearch.map(selected => { return selected })
        tempArray.splice(index, 1);
        this.selectedForSearch = tempArray.map(selected => { return selected });

        // Remove the selected installation from the table contents
        this.selectedInstallations.splice(index, 1);

        this.installationsChanged.emit(this.selectedInstallations);
      }
    });
  }

  /**
   * content getter
   */
  public get contents() {
    return this.selectedInstallations.map((installation: IInstallation) => {
      return {
        name: installation.name,
        serialNumber: installation.serialNumber,
        id: installation.id
      }
    })
  }

  /**
   * build the selected for search
   */
  public buildSelectedForSearch() {
    let result: ISearchSelectItem[] = [];
    if (this.selectedInstallations.length > 0) {
      result = this.selectedInstallations.map((elem: IInstallation) => {
        return {
          id: elem.id,
          label: elem.serialNumber ,
          data: elem
        }
      })
    }
    this.selectedForSearch = result;
  }

  /**
   * Called to navigate to another page
   * @param page URL
   * @param content content to pass to page
   * @memberof InstallationsComponent
   */
  goTo(page: string, content?: any) {
    this._router.navigate([page], {
      relativeTo: this._activedRoute,
      state: content,
    });
  }

}
