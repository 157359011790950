import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { ISelectOption } from 'src/app/libs/input/facades';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { EnumGraphType } from 'src/app/libs/widgets/facades/enums';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { IPaginationInput } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { TranslationService } from '../../../translation/services/translation.service';
import { MyOverlayRef } from '../../overlay/myoverlay-ref';
import { EnumPeriod } from '../facades/enums/period.enum';
import { ICreateEditGraphWidget } from '../facades/interfaces/createWidget.interface';

/**
 * Config for graph creation form (modal)
 */
@Component({
  selector: 'app-production-graph-config',
  templateUrl: './production-graph-config.component.html',
  styleUrls: ['./production-graph-config.component.scss']
})
export class ProductionGraphConfigComponent implements OnInit {
  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** Datas from edit modal */ @Input() public editDatas: any;
  /** FormGroup of graph */ public graphFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;
  /** list of current installation converters */ public currentInstallationConverters: any[] = null;
  /** Options of the select top */ periodOptions: ISelectOption[] = [
    { label: this._translationSrv.getInstant("perDay"), value: EnumPeriod.DAY },
    { label: this._translationSrv.getInstant("perWeek"), value: EnumPeriod.WEEK },
    { label: this._translationSrv.getInstant("perMonth"), value: EnumPeriod.MONTH },
    { label: this._translationSrv.getInstant("perYear"), value: EnumPeriod.YEAR },
  ];
  /** periode enum */ public __EnumPeriod = EnumPeriod;
  /** stack options */ stackedOptions: ISelectOption[] = [
    {label: this._translationSrv.getInstant("nonStacked"), value: false},
    {label: this._translationSrv.getInstant("stacked"), value: true},
  ]
  /** Installation to select */ public installationsToSelect: ISearchSelectItem[] = [];
  /** Subject to listen for search with compare string */ public searchInstallationsSubject = new Subject<string>();
  /** Pagination used on search installations */ public pagination: IPaginationInput = { page: 1, limit: 20 };
  /** getter for controls of graph form group */ get formControls() { return this.graphFormGroup.controls; }
  /** Pagination used on table */ public paginationInput: IPaginationInput = { page: 1, limit: 100 };
  /** Icon for closing modal */ public faTimes: IconDefinition = faTimes;
  /** selected installation */ public installationSelected: any = [];

  /**
   * constructor
   * @param _translationSrv the translation service
   * @param _fb the form builder
   * @param _errorMessageSrv the error message service
   * @param _installationQueriesSrv the installation queries service
   */
  constructor(
    private _translationSrv: TranslationService,
    private _fb: FormBuilder,
    protected _errorMessageSrv: ErrorMessageService,
    private _installationQueriesSrv: InstallationQueriesService,
  ) {
    this.inputErrorsLabelMap = new Map<string, string>([
      ["title", this._translationSrv.getInstant("installation_power-input")],
      ["period", this._translationSrv.getInstant("installation_power-input")],
      ["cols", this._translationSrv.getInstant("installation_power-input")],
      ["rows", this._translationSrv.getInstant("installation_power-input")],
      ["installationItem", this._translationSrv.getInstant("installation_power-input")],
    ]);
  }

  /**
   * On Init method
   */
  ngOnInit(): void {
    this.initFormGroup();
  }

  /**
   * Init form group of graph config
   */
  public initFormGroup(): void {
    const presetDatas = this.editDatas && this.editDatas.widgetDataConfig? this.editDatas.widgetDataConfig : null;
    this.graphFormGroup = this._fb.group({
      title: [presetDatas? presetDatas.title : "", Validators.required],
      period: [presetDatas? presetDatas.period : {}, Validators.required],
      cols: [this.editDatas && this.editDatas.cols != null? this.editDatas.cols : 4, Validators.required],
      rows: [this.editDatas && this.editDatas.rows != null? this.editDatas.rows : 4, Validators.required],
      installationItem: [null, Validators.required],
      stacked: [presetDatas? presetDatas.stacked : false],
      showSunHour: [presetDatas? presetDatas.showSunHour : false],
    });

    if(presetDatas){
      this._installationQueriesSrv.getInstallationsByIds(presetDatas.installationId).subscribe((res: any) => {
        if(res && res.data && res.data.getInstallationsByIds){
          this.installationSelected = []
          res.data.getInstallationsByIds.forEach((installation: any) => {
            this.installationSelected.push({
              id: installation.id,
              label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
              data: installation
            });
          });
          this.graphFormGroup.get("installationItem").patchValue(this.installationSelected)
        }
      }, error => {
        console.log(error);
      })
    }

    this.graphFormGroup.get("installationItem").valueChanges.subscribe(res => {
      if (res && res[0] && res[0].data) {
        this.installationSelected = res
        const installationPalamede = res[0].data.installationPalamede;
        if (installationPalamede && installationPalamede.palamedeConverters && installationPalamede.palamedeConverters !== this.currentInstallationConverters) {
          this.currentInstallationConverters = installationPalamede.palamedeConverters;
          this.currentInstallationConverters.forEach(converter => {
            this.graphFormGroup.addControl('converter_' + converter.id, new FormControl(true, Validators.required))
          });
        }
      } else {
        if (this.currentInstallationConverters) {
          this.currentInstallationConverters.forEach(converter => {
            this.graphFormGroup.removeControl('converter_' + converter.id)
          });
          this.currentInstallationConverters = null;
        }
      }
    })
  }

  /**
   * Validate form and do query to get computed datas from form values
   * @returns
   */
  public validateForm(duplicate: boolean = false) {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.graphFormGroup, this.inputErrorsLabelMap);

    if (this.graphFormGroup.invalid) {
      console.log("graph-config.component.ts: graphFormGroup is invalid.");
      return;
    }

    const graphToCreate: ICreateEditGraphWidget = {
      cols: this.graphFormGroup.value.cols,
      rows: this.graphFormGroup.value.rows,
      widgetDataConfig: {
        type: EnumGraphType.AREA,
        title: this.graphFormGroup.get("title").value ? this.graphFormGroup.get("title").value : "Default title of graphic",
        period: this.graphFormGroup.get("period").value,
        installationId: this.installationSelected.map(item => item.id),
        stacked: this.graphFormGroup.get("stacked").value,
        showSunHour: this.graphFormGroup.get("showSunHour").value && this.installationSelected.length < 2 && this.graphFormGroup.get("period").value == EnumPeriod.DAY,
      }
    }
    if(this.editDatas && this.editDatas.id && !duplicate) graphToCreate['id'] = this.editDatas.id;
      this.close(graphToCreate);
  }

  /**
   * Close the modal and send a value to listeners
   * @param value
   */
  public close(value: any) {
    this.ref.close(value);
  }

  /**
   * Search a palamede installation based on search params (q)
   * @param q the search string to find the installation
   * @param idsSelected the installation ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the installation results
   */
  public searchInstallations = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._installationQueriesSrv.searchInstallations({ page: 1, limit: 1000 }, q).toPromise();
    let installations: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getInstallations.installations && (<any>resultQuery.data).getInstallations.installations)
      installations = (<any>resultQuery.data).getInstallations.installations

    installations = installations.map((installation: any) => {
      return {
        id: installation.id,
        label: `${installation.name} (${this._translationSrv.getInstant("serialNumber")}: ${installation.serialNumber})`,
        data: installation
      };
    })
    return installations;
  }
}
