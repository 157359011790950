<div class="modal-card" id="count-config" style="height:600px;width: 52vw;">
    <section class="modal-card-head">
        <div>{{ 'exportConfig' | translate }}</div>
    </section>
    <section class="modal-card-body" style="min-height: 30rem;">
        <div>
            <div class="grid grid_col_1">
                <div>
                    <app-label>{{ "typeExport" | translate }} *</app-label>
                    <app-radio-input 
                    formControlName="typeExport" 
                    [group]="exportFormGroup" 
                    [name]="'typeExport'" 
                    id="typeExport"
                    [options]="typeExportOptions" 
                    [isInline]="true"></app-radio-input>
                </div>
            </div>
            <div *ngIf="exportFormGroup && exportFormGroup.value.typeExport === typeExportEnum.PRODUCTION" class="grid grid_col_1 gap_top">
                <div>
                    <app-label>{{ "chooseInstallation" | translate}} *</app-label> 
                    <app-search-select [group]="exportFormGroup" formControlName="chosenItems" 
                        [query]="searchInstallations"
                        [selected]="installationSelected"
                        [name]="'chosenItems'"
                        id="chosenItems" ></app-search-select>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.chosenItems" class="invalid-label">
                        {{ inputErrorsLabel.chosenItems.msg | translate}}
                    </div>
                </div>
            </div>
            <div *ngIf="exportFormGroup && exportFormGroup.value.typeExport === typeExportEnum.CONSUMPTION" class="grid grid_col_1 gap_top">
                <div>
                    <app-label>{{ "chooseConsumption" | translate}} *</app-label> 
                    <app-search-select [group]="exportFormGroup" formControlName="chosenItems" 
                        [query]="searchConsumptions"
                        [selected]="installationSelected"
                        [name]="'chosenItems'"
                        id="chosenItems" ></app-search-select>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.chosenItems" class="invalid-label">
                        {{ inputErrorsLabel.chosenItems.msg | translate}}
                    </div>
                </div>
            </div>
            <div class="grid grid_col_2 gap_top">
                <div>
                    <app-label>{{ "fileName" | translate}} *</app-label>
                    <app-text-input formControlName="title" [group]="exportFormGroup" [name]="'title'" id="title" [ngClass]="{ 'invalid-input': isSubmitted && formControls.title.errors}"></app-text-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.title" class="invalid-label">
                        {{ inputErrorsLabel.title.msg | translate}}
                    </div>
                </div>
                <div>
                    <app-label>{{ "fileType" | translate }} *</app-label>
                    <app-radio-input 
                      formControlName="fileType" 
                      [group]="exportFormGroup" 
                      [name]="'fileType'" 
                      id="fileType"
                      [options]="exportFormGroup && exportFormGroup.value.typeExport === typeExportEnum.PRODUCTION ? fileTypesProductionOptions : fileTypesConsumptionOptions" 
                      [isInline]="true"></app-radio-input>
                </div>
            </div>
            <div class="grid grid_col_4 gap_top">
                <div>
                    <app-label>{{ (exportFormGroup && exportFormGroup.value.fileType === fileTypeEnum.CSV ? "startDate" : "date") | translate}} *</app-label>
                    <app-date-input-manual formControlName="periodStart" [group]="exportFormGroup" [name]="'periodStart'" id="periodStart" [ngClass]="{ 'invalid-input': isSubmitted && formControls.periodStart.errors}"></app-date-input-manual>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.periodStart" class="invalid-label">
                        {{ inputErrorsLabel.periodStart.msg | translate}}
                    </div>
                </div>
                <div *ngIf="exportFormGroup && exportFormGroup.value.fileType === fileTypeEnum.CSV">
                    <app-label>{{ "endDate" | translate}} *</app-label>
                    <app-date-input-manual formControlName="periodEnd" [group]="exportFormGroup" [name]="'periodEnd'" id="periodEnd" [ngClass]="{ 'invalid-input': isSubmitted && formControls.periodEnd.errors}"></app-date-input-manual>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.periodEnd" class="invalid-label">
                        {{ inputErrorsLabel.periodEnd.msg | translate}}
                    </div>
                </div>
            </div>
        </div>
    </section>
    <footer class="modal-card-foot">
        <div>
            <button class="button button_valid" (click)="validateForm()" type="button">{{ "save" | translate }}</button>
            <button class="button button_delete" (click)="close(false)" type="button">{{ "cancel" | translate }}</button>
            <!-- <app-button (click)="close()" type="button" class="button" [ngStyle]="{'background-color': button.bgColor, 'color': button.messageColor}">{{button.message}}</app-button> -->
        </div>
    </footer>
</div>