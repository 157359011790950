<div class="modal-card" id="count-config">
    <section class="modal-card-head">
        <div>{{ 'widgetConfig' | translate }}</div>
        <fa-icon class="modal-card-head__close" [icon]="faTimes" (click)="close(false)"></fa-icon>
    </section>
    <section class="modal-card-body" style="min-height: 30rem;">
        <div>
            <div class="grid grid_col_1">
                <div>
                    <app-label>{{ "chooseInstallation" | translate}} *</app-label> 
                    <app-search-select [group]="countFormGroup" formControlName="installationItem" 
                        [query]="searchInstallations"
                        [selected]="installationSelected"
                        [name]="'installationItem'"
                        id="installationItem" ></app-search-select>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.installationItem" class="invalid-label">
                        {{ inputErrorsLabel.installationItem.msg | translate}}
                    </div>
                </div>
            </div>
            <div class="grid grid_col_2 gap_top">
                <div>
                    <app-label>{{ "title" | translate}} *</app-label>
                    <app-text-input formControlName="title" [group]="countFormGroup" [name]="'title'" id="title" [ngClass]="{ 'invalid-input': isSubmitted && formControls.title.errors}"></app-text-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.title" class="invalid-label">
                        {{ inputErrorsLabel.title.msg | translate}}
                    </div>
                </div>
                <div class="grid grid_col_2">
                    <div>
                        <app-label>{{ "startDateCount" | translate}} *</app-label>
                        <app-date-input formControlName="period" [group]="countFormGroup" [name]="'period'" id="period" [ngClass]="{ 'invalid-input': isSubmitted && formControls.period.errors}"></app-date-input>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.period" class="invalid-label">
                            {{ inputErrorsLabel.period.msg | translate}}
                        </div>
                    </div>
                    <div>
                        <app-label>{{ "startHourCount" | translate}}</app-label>
                        <app-select-input [options]="periodOptions" [autoSelectFirst]="true" formControlName="periodHour" [group]="countFormGroup" [name]="'periodHour'" id="periodHour" [ngClass]="{ 'invalid-input': isSubmitted && formControls.periodHour.errors}"></app-select-input>
                        <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.periodHour" class="invalid-label">
                            {{ inputErrorsLabel.periodHour.msg | translate}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid grid_col_2 gap_top">
                <div>
                    <app-label>{{ "cols" | translate}} *</app-label>
                    <app-number-input [min]="2" [step]="1" formControlName="cols" [group]="countFormGroup" [name]="'cols'" id="cols" [ngClass]="{ 'invalid-input': isSubmitted && formControls.cols.errors}"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.cols" class="invalid-label">
                        {{ inputErrorsLabel.cols.msg | translate}}
                    </div>
                </div>
                <div>
                    <app-label>{{ "rows" | translate}} *</app-label>
                    <app-number-input [min]="2" [step]="1" formControlName="rows" [group]="countFormGroup" [name]="'rows'" id="rows" [ngClass]="{ 'invalid-input': isSubmitted && formControls.rows.errors}"></app-number-input>
                    <div *ngIf="isSubmitted && inputErrorsLabel && inputErrorsLabel.rows" class="invalid-label">
                        {{ inputErrorsLabel.rows.msg | translate}}
                    </div>
                </div>
            </div>


            <div class="grid grid_col_2 gap_top">
                <div>
                    <app-label>{{ "offset" | translate}} ({{ "WillBeAddToTheProduction" | translate}} en {{ "kWh" | translate}})</app-label>
                    <app-number-input [min]=0 formControlName="counterNumber" [group]="countFormGroup" [name]="'offset'" id="offset"></app-number-input>
                </div>
                <div>
                    <app-label>{{ "counter-number" | translate}}</app-label>
                    <app-number-input formControlName="counterNumber" [group]="countFormGroup" [name]="'counterNumber'" id="counterNumber"></app-number-input>
                </div>
            </div>
            <app-label>{{ "correction-coeff" | translate}}</app-label>
            <app-text-input formControlName="correctionMultiplier" [group]="countFormGroup" [name]="'correctionMultiplier'" id="correctionMultiplier"></app-text-input>

            <!-- <ng-container *ngIf="currentInstallationConverters" >
                <app-checkbox-input *ngFor="let converter of currentInstallationConverters" [label]="converter.name" [group]="countFormGroup" [name]="'converter_' + converter.id"></app-checkbox-input>
            </ng-container> -->
        </div>
    </section>
    <footer class="modal-card-foot">
        <div>
            <button class="button button_valid" (click)="validateForm()" type="button">{{ "save" | translate }}</button>
            <button class="button button_delete" (click)="close(false)" type="button">{{ "cancel" | translate }}</button>
            <button *ngIf="this.editDatas && this.editDatas.id" class="button button_primary" (click)="validateForm(true)" type="button">{{ "Duplicate" | translate }}</button>
        </div>
    </footer>
</div>