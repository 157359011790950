import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SLIDE_IN_OUT_ADD_ANIMATION } from 'src/app/libs/animations/slideInOutAddEdit.animation';
import { TYPE_BUTTON } from 'src/app/libs/buttons/facades/enums/type-button.enum';
import {
  ICreateDashboardInput,
  IUpdateDashboardInput,
} from 'src/app/queries/dashboard/interfaces/dashboardsInput.interface';
import { DASHBOARD_PAGES_ENUM } from '../facades/enums/dashboardPages.enum';
import { DashboardQueriesService } from 'src/app/queries/dashboard/dashboard-queries.service';

import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { AlertService } from 'ngx-alerts';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';

/**
 * Display a page for add an user
 * Basic usage
 * <app-add-dashboard></app-add-dashboard>
 */
@Component({
  selector: 'add-edit-add-dashboard',
  templateUrl: './add-edit-dashboard.component.html',
  styleUrls: ['./add-edit-dashboard.component.scss'],
  animations: [SLIDE_IN_OUT_ADD_ANIMATION],
  host: { '[@slideInOutAddAnimation]': '' },
})
export class AddEditDashboardsComponent implements OnInit {
  /** The list of dashboard pages */pagesEnum: typeof DASHBOARD_PAGES_ENUM = DASHBOARD_PAGES_ENUM; 
  /** Enum of button types */ public __TYPE_BUTTON = TYPE_BUTTON; 
  /** FormGroup of dashboard */ public dashboardFormGroup: FormGroup; 
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false; 
  /** Id of dashboard to edit */ public dashboardId: number; 
  /** Error if there is one */ public error: string = ''; 
  /** User to edit */ public dashboard: IUpdateDashboardInput; 
  /** Key of title (add/edit) */ public titleKey: string = 'add-dashboard-title'; 
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;

  /** on press escape */ @HostListener('document:keyup.escape', ['$event']) onKeyUpHandler(event: KeyboardEvent) {
    this.goTo(this.pagesEnum.MAIN);
  }

  /**
   * constructor
   * @param _router the router
   * @param _activatedRoute the activated route
   * @param _fb the form builder
   * @param _dashboardSrv the dashboard service
   * @param _translationSrv the translation service
   * @param _alertSrv the alert service
   * @param _errorMessageSrv the error message service
   */
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _dashboardSrv: DashboardQueriesService,
    private _translationSrv: TranslationService,
    private _alertSrv: AlertService,
    private _errorMessageSrv: ErrorMessageService
  ) {
    this.inputErrorsLabelMap = new Map<string, string>([
      ['name', this._translationSrv.getInstant('dashboard_name-input')],
      ['descr', this._translationSrv.getInstant('dashboard_description-input')],
    ]);
  }

  /** get the form control */ get formControls() {return this.dashboardFormGroup.controls;}

  /**
   * Called on init
   */
  ngOnInit(): void {
    this.initFormGroup();

    this._activatedRoute.params.subscribe((param) => {
      if (param && param['id']) {
        this.dashboardId = JSON.parse(param['id']);
        this.getCurrentDashboard();
        this.titleKey = 'edit-dashboard-title';
      }
    });
  }

  /**
   * Initiate the dashboard form group
   */
  public initFormGroup(): void {
    this.dashboardFormGroup = this._fb.group({
      name: ['', Validators.required],
      descr: ['', Validators.required],
      isDefault: [false, Validators.nullValidator],
    });
  }

  /**
   * get the current dashboard
   */
  private getCurrentDashboard() {
    this._dashboardSrv
      .getDashboard(this.dashboardId)
      .subscribe(async (result) => {
        if (result.errors && result.errors.length > 0) {
          console.log('error', result.errors);
          throw result.errors;
        } else {
          const data: any = <any>result.data;
          if (data && data.getDashboard) {
            this.dashboardFormGroup.patchValue(data.getDashboard);
          }
        }
      });
  }

  /**
   * Called when a button is clicked
   * @param event
   */
  onClick(event) {
    var target = event.target || event.srcElement || event.currentTarget;
    if (!document.getElementById('edit-box').contains(target)) {
      this.goTo(this.pagesEnum.MAIN);
    }
  }

  /**
   * Called to navigate to another page
   * @param page URL
   */
  goTo(page: string) {
    this._router.navigate([page], { relativeTo: this._activatedRoute });
  }

  /**
   * Validate form of dashboard creation
   */
  validateForm(): void {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(
      this.dashboardFormGroup,
      this.inputErrorsLabelMap
    );

    if (this.dashboardFormGroup.invalid) {
      console.log('dashboardFormGroup is invalid.');
      return;
    }

    let dashboardToSave: any = {
      name: this.dashboardFormGroup.value.name,
      descr: this.dashboardFormGroup.value.descr,
      isDefault: this.dashboardFormGroup.value.isDefault,
    };

    if (this.dashboardId) {
      dashboardToSave.id = this.dashboardId;
      this.editDashboard(dashboardToSave);
    } else this.createDashboard(dashboardToSave);
  }

  /**
   * edit the dashboard the data to save
   * @param dashboardToSave 
   */
  public editDashboard(dashboardToSave: IUpdateDashboardInput) {
    try {
      this._dashboardSrv
        .updateDashboard(dashboardToSave)
        .subscribe((result) => {
          if (result.errors && result.errors.length > 0) {
            console.error('error', result.errors);
            this.error = result.errors.toString();
            this._alertSrv.danger(
              this._translationSrv.getInstant('errorUpdateDashboard')
            );
          } else {
            this._alertSrv.success(
              this._translationSrv.getInstant('successUpdateDashboard')
            );
            this.goTo(this.pagesEnum.MAIN);
          }
        });
    } catch (error) {
      console.log('error dans le catch', error);
    }
  }

  /**
   * create a dashboard
   * @param dashboardToSave the dashboard to create
   */
  public createDashboard(dashboardToSave: ICreateDashboardInput) {
    try {
      this._dashboardSrv
        .createDashboard(dashboardToSave)
        .subscribe((result) => {
          if (result.errors && result.errors.length > 0) {
            console.error('error', result.errors);
            this.error = result.errors.toString();
            this._alertSrv.danger(
              this._translationSrv.getInstant('errorAddDashboard')
            );
          } else {
            this._alertSrv.success(
              this._translationSrv.getInstant('successAddDashboard')
            );
            this.goTo(this.pagesEnum.MAIN);
          }
        });
    } catch (error) {
      console.log('error dans le catch', error);
    }
  }

  /**
   * duplicate dashboard
   */
  public duplicateThisDashboard() {
    this._dashboardSrv.duplicateDashboard(this.dashboardId).subscribe(res => {
      
      if (res && res.data) {
        let data: any = res.data;
        if (data.duplicateDashboard > -1) {
          this.goTo(this.pagesEnum.MAIN);
        }
      }
    });
  }
}
