import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from "@angular/core";
import { faTrashAlt, IconDefinition } from "@fortawesome/free-solid-svg-icons";

/**
 * enum of img block action
 */
enum FILES_BLOCK_ACTIONS_ENUM {
  DELETE = "Delete",
  ZOOM = "Zoom",
};

/**
 * the ig block componenent
 */
@Component({
  selector: "app-img-block",
  templateUrl: "./img-block.component.html",
  styleUrls: ["./img-block.component.scss"]
})
export class ImgBlockComponent {

    /** true if the input is disable */ @Input() public disabled = false;
    /** true if the input is hidden */ @Input() public hiddenAction = false;
    /** array of file */ @Input() file: any;
    /** true if the input should overlay */ @Input() overlay: boolean = true;
    /** triggerd when  */ @Output() public fileTouched = new EventEmitter<any>();
    /** enum of img block action */ public FILES_BLOCK_ACTIONS_ENUM = FILES_BLOCK_ACTIONS_ENUM;
    /** Font awesome icon for delete */ public faTrashAlt: IconDefinition = faTrashAlt;

    /**
     * constructor
     */
    constructor() {}

    /**
     * triggerd when a file is touched
     * @param action the action
     */
    public touchFile(action) {
      this.fileTouched.emit({action: action, file: this.file});
    }
}
