
<div class="upload-img__input" 
    [ngClass]="{'upload-img__input_disable': disabled, 'upload-img__input_error': isFileError}"
    (drop)="onDrop($event)" 
    (dragover)="avoid($event)" 
    (dragleave)="avoid($event)" 
    (click)="chooseFile($event)">
    <!-- <i class="fas fa-plus"></i> -->
    <fa-icon class="upload-img__icon" [icon]="faPlus" (click)="chooseFile($event)"></fa-icon>
    <input #imgInput class="upload-files__input" type="file" hidden (change)="set($event)" [accept]='acceptedType'>
</div>

