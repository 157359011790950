import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ICreateConsumptionInput, IUpdateConsumptionInput } from "./interfaces/consumptions.interface";
import { IPaginationInput } from "../pagination/interfaces/paginationInput.interface";


import GET_CONSUMPTIONS from "./graphql/get-consumptions.graphql";
import CREATE_CONSUMPTION from "./graphql/create-consumption.graphql";
import UPDATE_CONSUMPTION from "./graphql/update-consumption.graphql";
import COUNT_CONSUMPTIONS from "./graphql/count-consumption.graphql";
import REMOVE_CONSUMPTION from "./graphql/remove-consumption.graphql";
import GET_CONSUMPTION_BY_ID from "./graphql/get-consumption-by-id.graphql";
import GET_CONSUMPTIONS_BY_IDS from "./graphql/get-consumptions-by-ids.graphql";
import GET_FORMATTED_DATA_FOR_CONSUMPTION from "./graphql/get-formatted-data-for-consumption.graphql";
import GET_CSV_DATAS from "./graphql/get-csv-data.graphql";
import GET_CONSUMPTION_COUNTER_DATAS from "./graphql/get-counter-concumption-datas.graphql";

import { IDateRange } from '../../libs/filters/facades/interfaces/dateRange.interface';

/**
 * Consumption queries service
 */
@Injectable({
  providedIn: "root"
})
export class ConsumptionQueriesService {

  /** the pagination */ private _pagination: IPaginationInput = { page: 1, limit: 20 }; /** Pagination base for table */
  /** the query string */ private _queryString: string = null; /** The search string type by the user to use to filter the table result */
  /** the order (asc/desc)*/ private _order: {key: string, direction: string} = null ;

  /** pagination getter */ public get pagination(): IPaginationInput { return this._pagination; }
  /** queryString getter */ public get queryString(): string { return this._queryString; }
  /** order getter */ public get order(): {key: string, direction: string} { return this._order; }
  /** queryString setter */ public set queryString(queryString: string) { this._queryString = queryString; }
  /** pagination setter */ public set pagination(pagination: IPaginationInput) { this._pagination = pagination; }
  /** order setter */ public set order(order: {key: string, direction: string}) { this._order = order; }

  /**
   * constructor
   * @param _apollo apollo client
   */
  constructor(private _apollo: Apollo) { }

  /**
   * Get an consumption by id
   * @param id
   * @returns
   */
  public getConsumptionById(id: number) {
    return this._apollo.query({
      query: GET_CONSUMPTION_BY_ID,
      variables: { id },
      fetchPolicy: "network-only"
    });
  }

  /**
   * Get an consumption by ids
   * @param ids
   * @returns
   */
  public getConsumptionsByIds(ids: number[]) {
    return this._apollo.query({
      query: GET_CONSUMPTIONS_BY_IDS,
      variables: { ids },
      fetchPolicy: "network-only"
    });
  }

  /**
   * Get consumption counter formatted data
   * @param consumption counter counter
   * @returns
   */
   public getCounterConsumptionFormattedDatas(consumptionIds: number[], range: any) {
    return this._apollo.query({
      query: GET_CONSUMPTION_COUNTER_DATAS,
      variables: { 
        consumptionIds: consumptionIds,
        range: range,
       },
      fetchPolicy: "network-only"
    });
  }

  /**
   * get csv consumption csv data
   * @param ids array of consumption ids
   * @param range time range
   * @returns the csv data
   */
  public getCsvDatas(ids: number[], range: any) {
    return this._apollo.query({
      query: GET_CSV_DATAS,
      variables: {
        ids: ids,
        range: range,
      },
      fetchPolicy: "network-only"
    })
  }

  /**
   * Search a consumption
   * @param name
   * @returns
   */
  public searchConsumptions(pagination?: IPaginationInput, queryString?: string) {
    let variables = { pagination };
    if (queryString) variables['search'] = queryString;

    return this._apollo.query({
      query: GET_CONSUMPTIONS,
      variables,
      fetchPolicy: "network-only"
    })
  }

  /**
   * Get consumptions by pagination
   * @param pagination
   * @returns
   */
  public getConsumptions() {
    return this._apollo.watchQuery({
      query: GET_CONSUMPTIONS,
      variables: {
        search: this.queryString,
        order: this.order,
        pagination: this.pagination
      },
      fetchPolicy: "network-only"
    });
  }


  /**
   * Creation an consumption
   * @param install
   * @returns
   */
  public createConsumption(createConsumptionInput: ICreateConsumptionInput) {
    return this._apollo.mutate({
      mutation: CREATE_CONSUMPTION,
      variables: { createConsumptionInput },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: COUNT_CONSUMPTIONS
        },
        {
          query: GET_CONSUMPTIONS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

  /**
   * Update an consumption
   * @param install
   * @returns
   */
  public updateConsumption(updateConsumptionInput: IUpdateConsumptionInput) {
    return this._apollo.mutate({
      mutation: UPDATE_CONSUMPTION,
      variables: { updateConsumptionInput },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: GET_CONSUMPTIONS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

  /**
   * Remove an consumption
   * @param id
   * @returns
   */
  public removeConsumption(id: Number) {
    return this._apollo.mutate({
      mutation: REMOVE_CONSUMPTION,
      variables: { id },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: COUNT_CONSUMPTIONS
        },
        {
          query: GET_CONSUMPTIONS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

  /**
  * Count number of consumption profils
  * @returns
  */
  public countConsumptions() {
    return this._apollo.watchQuery({
      query: COUNT_CONSUMPTIONS,
      fetchPolicy: 'network-only',
    });
  }

  /**
   * get data for consumption widget
   * @param id the consumption id
   * @param range the time range
   * @returns void
   */
  public getFormattedDataForConsumption(id: number, range) {
    return this._apollo.query({
      query: GET_FORMATTED_DATA_FOR_CONSUMPTION,
      variables: {
        id,
        range
      },
      fetchPolicy: "network-only"
    });
  }
}
