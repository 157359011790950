import { Subject } from "rxjs";
import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';

/**
 * Interface of closed event
 */
export interface OverlayCloseEvent<R> {
    /** type of closing */ type: 'backdropClick' | 'close',
    /** data returned */ data: R
}

/**
 * Overlay reference with subject that is triggered when modal is closed
 */
export class MyOverlayRef<R = any, T = any> {
    /** Subject triggered when modal is closed */ public afterClosed = new Subject<OverlayCloseEvent<R>>();
    
    /**
     * Constructor method
     * @param overlay overlay
     * @param content content
     * @param data data
     */
    constructor(
        public overlay: OverlayRef,
        public content: string | TemplateRef<any> | Type<any>,
        public data: T) 
    {
      overlay.backdropClick().subscribe(() => this._close('backdropClick', null));
    }
    
    /**
     * Close modal with data as result and 'close' type of event
     * @param data data
     */
    close(data?: R) {
      this._close('close', data);
    }

    /**
     * Method that close the modal and emit on subject afterClosed
     * @param type type
     * @param data data
     */
    private _close(type: 'backdropClick' | 'close', data: R) {
      this.overlay.detach();
      this.afterClosed.next({
        type,
        data
      });
      this.afterClosed.complete();
    }
}