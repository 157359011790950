import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DashboardQueriesService } from '../../../queries/dashboard/dashboard-queries.service';
import { EnumTableAction } from 'src/app/libs/table/facades/enums/tableAction.enum';
import { ITableAction } from 'src/app/libs/table/facades/interfaces/tableAction.interface';
import { ITableColumn } from 'src/app/libs/table/facades/interfaces/tableColumn.interface';
import { DASHBOARD_PAGES_ENUM } from '../../dashboard/facades/enums/dashboardPages.enum';
import { IPaginationInput, IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { ISearchConfig } from 'src/app/libs/table/facades/interfaces/tableSearchConfig.interface';
import { AlertService } from 'ngx-alerts';

/**
 * Display a list of dashboards
 * Basic usage
 * <app-list-dashboards [content]="dashboardsArray"></app-list-dashboards>
 */
@Component({
  selector: 'app-list-dashboards',
  templateUrl: './list-dashboard.component.html',
  styleUrls: ['./list-dashboard.component.scss'],
})
export class ListDashboardComponent implements OnInit {
  /** Template of modal to validate deleting of an dashboard */ @ViewChild('tplDeleteDashboard', { static: true }) modalTemplate: TemplateRef<any>;
  /** The list of Dashboards pages */ pagesEnum: typeof DASHBOARD_PAGES_ENUM = DASHBOARD_PAGES_ENUM; 
  /** Array of columns for table */ public columns: ITableColumn[] = [
    { key: 'name', type: 'text', title: 'dashboard-tableHeader-name', sortable: true },
    { key: 'descr', type: 'text', title: 'dashboard-tableHeader-description', sortable: true },
    { key: 'isDefault', type: 'boolean', title: 'dashboard-tableHeader-default', sortable: true },
    { key: 'actions', type: 'action', title: 'dashboard-tableHeader-actions' },
  ];
  /** Content to display on table */ public content: any[] = []; 

  /** Pagination result base for table */
  public paginationResult: IPaginationResult = {
    ...this._dashboardQueriesSrv.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1,
  };
  /** Save of dashboard to delete */ public dashboardToDelete: any = {}; 
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null; 
  /** Modal configuration */ public modalConfig: IModalItemConfig = {}; 
  /** Configuration of search input */ public searchConfig: ISearchConfig = { searchPlaceholderKey: 'dashboard-search-placeholder-list-label' }; 

  /**
   * constructor
   * @param _router the router
   * @param _activedRoute the actived route
   * @param _dashboardQueriesSrv the dashboard queries service
   * @param _translateSrv the translation service
   * @param _overlayService the overlay service
   * @param _alertSrv the alert service
   */
  constructor(
    private _router: Router,
    private _activedRoute: ActivatedRoute,
    private _dashboardQueriesSrv: DashboardQueriesService,
    private _translateSrv: TranslationService,
    private _overlayService: OverlayService,
    private _alertSrv: AlertService
  ) { }

  /**
   * Called on init
   * @returns {void}
   */
  ngOnInit(): void {
    // this.getDashboards(); // onSearch('') is used in order to reset the filter when page is loaded
    this.onSearch('');
  }

  /**
   * Called to navigate to another page
   * @param page URL
   * @param content content to pass to page
   * @returns {void}
   */
  goTo(page: string): void {
    this._router.navigate([page], {
      relativeTo: this._activedRoute,
    });
  }

  /**
   * Handle the action triggered by a dashboard click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   */
  public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.dashboardToDelete = event.content;
        this.open(this.modalTemplate);
        break;
      case EnumTableAction.EDIT:
        this.goTo(this.pagesEnum.EDIT + `/${event.content.id}`);
        break;
      case EnumTableAction.SHOW:
        this.goTo(this.pagesEnum.SHOW + `/${event.content.id}`);
        break;
      case EnumTableAction.SORT:
        if (event && event.content && event.content.key) {
          this._dashboardQueriesSrv.order = {
            key: event.content.key,
            direction: this._dashboardQueriesSrv.order && this._dashboardQueriesSrv.order.key === event.content.key ? (
              this._dashboardQueriesSrv.order.direction === "ASC" ? "DESC" : "ASC") :
              "ASC"
          }
          this.getDashboards();
        }
        break;
      default:
        break;
    }
  }

  /**
   * Open modal using content
   * @param {TempleRef<any>} content: the template to open in the modal 
   * @returns {void}
   */
  public open(content: TemplateRef<any>): void {
    this.modalConfig = {
      title: this._translateSrv.getInstant('modalDeleteDashboard'),
      body: `${this._translateSrv.getInstant('modalDeleteDashboardBody', { name: this.dashboardToDelete.name })}`,
      buttons: [
        {
          message: this._translateSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translateSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res.data.value === true) {
        this.onDelete();
      }
    });
  }

  /**
   * Called by table child when page is changed
   * @param {number} event The page used to get the result
   * @returns {void}
   */
  public onPageChange(event: number): void {
    this._dashboardQueriesSrv.pagination = { ...this._dashboardQueriesSrv.pagination, page: event }
    this.getDashboards();
  }

  /**
   * Called when an dashboard is delete.
   * @returns {void}
   */
  public onDelete(): void {
    this._dashboardQueriesSrv
      .deleteDashboard(this.dashboardToDelete.id)
      .subscribe((result) => {
        if (result.errors && result.errors.length > 0) {
          console.log('error', result.errors);
          this._alertSrv.danger(this._translateSrv.getInstant("errorDeleteDashboard"));
        } else {
           // Verify if we need to change pagination in case we delete the last element of the page
           if ((this.content.length - 1) == 0) {
            if (this.paginationResult.hasPrevious) this._dashboardQueriesSrv.pagination.page = this.paginationResult.page - 1;
            else this._dashboardQueriesSrv.pagination.page = 1;
          }
          this._alertSrv.success(this._translateSrv.getInstant("successDeleteDashboard"));
        }
      });
  }

  /**
   * Query getting dashboards using pagination of table
   * @returns {void}
   */
  public getDashboards(): void {
    this._dashboardQueriesSrv.getDashboardByIdCurrentUser().valueChanges.subscribe((result) => {
      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      } else {
        const data: any = <any>result.data;
        if (data?.getDashboardsByCurrentUser?.dashboards) {
          this.content = data.getDashboardsByCurrentUser.dashboards.map(
            (dashboard) => {
              return {
                id: dashboard.id,
                name: dashboard.name,
                items: dashboard.items,
                isDefault: dashboard.isDefault,
                descr: dashboard.descr,
                createdAt: dashboard.createdAt,
                updatedAt: dashboard.updatedAt,
              };
            }
          );
        }

        if (data && data.getDashboardsByCurrentUser && data.getDashboardsByCurrentUser.pagination) {
          this.paginationResult = data.getDashboardsByCurrentUser.pagination;
        }
      }
    });
  }

  /**
   * onSearch callback handle when user type in the search field to filter the list
   * @param {string} q the search param type by the user to filter the list
   * @returns {void}
   */
  public onSearch(q: string): void {
    this._dashboardQueriesSrv.pagination = { ...this._dashboardQueriesSrv.pagination, page: 1 }
    this._dashboardQueriesSrv.queryString = q;
    this.getDashboards();
  }


}
