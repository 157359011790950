import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { ISelectOption } from 'src/app/libs/input/facades';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { EnumGraphType } from 'src/app/libs/widgets/facades/enums';
import { ConsumptionQueriesService } from 'src/app/queries/consumption/consumption-queries.service';
import { IPaginationInput } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { TranslationService } from '../../../translation/services/translation.service';
import { MyOverlayRef } from '../../overlay/myoverlay-ref';
import { ICreateEditGraphWidget } from '../facades/interfaces/createWidget.interface';
import { GraphConfigService } from '../facades/services/graph-config.service';
import { RecQueriesService } from '../../../../queries/rec/rec-queries.service';


/**
 * possible auto-consumption type
 */
enum EnumAutoConsumptionType {
  ACC = "ACC",
  ACI = "ACI",
  PROSUMER = "PROSUMER",
}
/**
 * Config for graph creation form (modal)
 */
@Component({
  selector: 'app-auto-consumption-graph-config',
  templateUrl: './auto-consumption-graph-config.component.html',
  styleUrls: ['./auto-consumption-graph-config.component.scss']
})
export class AutoConsumptionGraphConfigComponent implements OnInit {
  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** Datas from edit modal */ @Input() public editDatas: any;
  /** FormGroup of graph */ public graphFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;
  /** Pagination used on search installations */ public pagination: IPaginationInput = { page: 1, limit: 20 };
  /** getter for controls of graph form group */ get formControls() { return this.graphFormGroup.controls; }
  /** Pagination used on table */ public paginationInput: IPaginationInput = { page: 1, limit: 100 };
  /** Icon for closing modal */ public faTimes: IconDefinition = faTimes;

  /** array of the currently selected consumption id */ private _consumptionIds: number[] = [];
  /** array of the currently selected consumption  */ public consumptionSelected: any = [];

  /** the currently selected cer (it's an array to handle the search-select component) */ public cerSelected: any[] = [];
  /** id of the selected cer */ private _cerId: number;

  /** enum of auto consumption type */ public __EnumAutoConsumptionType = EnumAutoConsumptionType;

  /** contain all the consumption of the selected cer */ private allCerConsumption: any[] = []
  /** should auto reset */ public shouldResetSelected: Subject<boolean> = new Subject<boolean>()

/**
 *  auto consumption type options
 */
  public autoConsumptionTypeOptions: ISelectOption[] = [
    { label: this._translationSrv.getInstant("ACC"), value: EnumAutoConsumptionType.ACC },
    { label: this._translationSrv.getInstant("ACI"), value: EnumAutoConsumptionType.ACI },
    { label: this._translationSrv.getInstant("PROSUMER"), value: EnumAutoConsumptionType.PROSUMER },
  ];

 
  /**
   * constructor
   * @param _translationSrv the translation service
   * @param _fb the form builder
   * @param _errorMessageSrv the error message service
   * @param _consumptionQueriesSrv the consumption queries service
   * @param graphConfigSrv the graph config service
   * @param _recSrv the rec queries service
   */
  constructor(
    private _translationSrv: TranslationService,
    private _fb: FormBuilder,
    protected _errorMessageSrv: ErrorMessageService,
    private _consumptionQueriesSrv: ConsumptionQueriesService,
    public graphConfigSrv: GraphConfigService,
    private _recSrv: RecQueriesService
  ) {
    this.inputErrorsLabelMap = new Map<string, string>([
      ["title", this._translationSrv.getInstant("installation_power-input")],
      ["period", this._translationSrv.getInstant("installation_power-input")],
      ["cols", this._translationSrv.getInstant("installation_power-input")],
      ["rows", this._translationSrv.getInstant("installation_power-input")],
      ["cerItem", this._translationSrv.getInstant("installation_power-input")],
      ["repartition", this._translationSrv.getInstant("installation_power-input")],
      ["consumptionItem", this._translationSrv.getInstant("installation_power-input")],
    ]);
  }

  /**
   * On Init method
   */
  ngOnInit(): void {
    this.initFormGroup();
  }

  /**
   * Init form group of graph config
   */
  public initFormGroup(): void {
    const presetDatas = this.editDatas && this.editDatas.widgetDataConfig ? this.editDatas.widgetDataConfig : null;
    this.graphFormGroup = this._fb.group({
      title: [presetDatas ? presetDatas.title : "", Validators.required],
      period: [presetDatas ? presetDatas.period : {}, Validators.required],
      cols: [this.editDatas && this.editDatas.cols != null ? this.editDatas.cols : 4, Validators.required],
      rows: [this.editDatas && this.editDatas.rows != null ? this.editDatas.rows : 4, Validators.required],
      cerItem: [null, Validators.required],
      consumptionItem: [[], Validators.required]
    });

    if (presetDatas) {
      this._presetCerDatas(presetDatas.cer);
      this._presetConsumptionsDatas(presetDatas.consumptionsIds);
    }

    this._initFormValueChanged();
  }

  /**
   * instanciate ValueChanged listener of the form groupe
   */
  private _initFormValueChanged(){
    this.graphFormGroup.get("cerItem").valueChanges.subscribe(async (res) => {
      // check is the selectedCer is the same as before
      if (res && res[0] && res[0].id && res[0].id === this._cerId) return
      // update the cerID and the selected cer
      this._cerId = res && res.length > 0 && res[0] && res[0].id? res[0].id : -1;
      this.cerSelected[0] = res[0]? res[0] : [];

      // reset the consumption because the consumption should be based on the cer
      this._consumptionIds = []
      this.consumptionSelected = [];

      if (this._cerId === -1) return;
      const cerData = await this.getCerData(this._cerId)
      this.allCerConsumption = cerData.consumptions.map((elem: any) => { return elem.id });
    });

    this.graphFormGroup.get("consumptionItem").valueChanges.subscribe(res => {

      if (this.consumptionSelected && this.consumptionSelected.length === 0) this._consumptionIds = []

      // verify if the option "all consumption is picked"
      let isAllSelected: boolean = false
      res.forEach(elem => {
        if (elem && elem.data == null && elem.id == null) {
          isAllSelected = true;
        }
      });


      // if -> user have selected all profil | else -> user have selected specific profil
      if (isAllSelected) {
        if (res === this.consumptionSelected) {return}
        this._consumptionIds = this.allCerConsumption
        this.consumptionSelected = [{
          id: null,
          label: this._translationSrv.getInstant("EveryConsumptionProfil"),
          data: null
        }];
        this.graphFormGroup.get("consumptionItem").patchValue(this.consumptionSelected)
      }
      else {
        this._consumptionIds = res? res.map(item => item.data.id) : [];
        this.consumptionSelected = res? res : [];
      }
    });
  }

    /**
   * fill the field cer with the cer passed as parameter
   * @param cer the data to set in the cer field
   */
  private async _presetCerDatas(cer: any){
    if (!cer) return
    this.cerSelected[0] = {
      id: cer.id,
      label: cer.name,
      data: cer
    };
    this._cerId = cer.id
    this.graphFormGroup.get("cerItem").patchValue(this.cerSelected)
    this.allCerConsumption = cer.recs_consumptions.map((elem: any) => { return elem.consumptionId });

  }

  /**
   * fill the field consumption with the array passed as parameter
   * @param consumptionsIds the data to set in the consumption field
   */
  private _presetConsumptionsDatas(consumptionsIds: number[]){
    this._consumptionQueriesSrv.getConsumptionsByIds(consumptionsIds).subscribe((res: any) => {
      const { getConsumptionsByIds } = res.data;
      if (getConsumptionsByIds) {
        this.consumptionSelected = getConsumptionsByIds.map(consumption => {
          return {
            id: consumption.id,
            label: `${consumption.name} (${this._translationSrv.getInstant("serialNumber")}: ${consumption.serialNumber})`,
            data: consumption
          }
        });
        this.graphFormGroup.get("consumptionItem").patchValue(this.consumptionSelected)
      }
    }, error => {
      console.error(error);
    })
  }

  /**
   * return an object that contain an object
   * {autoConsumptionType: string, }
   *
   * @param cerID the id of the used cer
   */
  private async getCerData(cerID: number): Promise<any> {
    return new Promise((resolve) => {
      this._recSrv.getRec(cerID).subscribe(res => {
        let data = <any>(res.data)
        resolve(data.getRec);
      })
    })
  }

  /**
   * Validate form and do query to get computed datas from form values
   * @returns
   */
  public async validateForm(duplicate: boolean = false) {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.graphFormGroup, this.inputErrorsLabelMap);

    if (this.graphFormGroup.invalid) {
      console.error("graph-config.component.ts: graphFormGroup is invalid.");
      return;
    }

    const cerData = await this.getCerData(this._cerId)

    const graphToCreate: ICreateEditGraphWidget = {
      cols: this.graphFormGroup.value.cols,
      rows: this.graphFormGroup.value.rows,
      widgetDataConfig: {
        type: EnumGraphType.AREA,
        title: this.graphFormGroup.get("title").value ? this.graphFormGroup.get("title").value : "Default title of graphic",
        period: this.graphFormGroup.get("period").value,
        consumptionsIds: this._consumptionIds,
        cer: cerData
      }
    }
    if (this.editDatas && this.editDatas.id && !duplicate) graphToCreate['id'] = this.editDatas.id;
      this.close(graphToCreate);
  }

  /**
   * Close the modal and send a value to listeners
   * @param value
   */
  public close(value: any) {
    this.ref.close(value);
  }

  /**
  * Search a Cer based on search params (q)
  * @param q the search string to find the cer
  * @param idsSelected the cer ids already select to remove them from the results
  * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the cer results
  */
  public searchCer = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._recSrv.searchUserRecs({ page: 1, limit: 1000 }, q).toPromise();
    let recs: any[] = [];
    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getUserRecs && (<any>resultQuery.data).getUserRecs.recs) {
      recs = (<any>resultQuery.data).getUserRecs.recs
      recs = recs.map((rec: any) => {
        return {
          id: rec.id,
          label: rec.name,
          data: rec
        };
      })
    }
    return recs;
  }

  /**
   * Search a consumption profile based on search params (q)
   * @param q the search string to find the consumption
   * @param idsSelected the consumption ids already select to remove them from the results
   * @returns {Promise<ISearchSelectItem[]} Return the promise for getting the consumption results
   */
  public searchConsumptions = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._consumptionQueriesSrv.searchConsumptions({ page: 1, limit: 1000 }, q).toPromise();
    let consumptions: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getConsumptions.consumptions && (<any>resultQuery.data).getConsumptions.consumptions)
      consumptions = (<any>resultQuery.data).getConsumptions.consumptions

      if (!this._cerId || this._cerId === -1) return null
      const cerData = await this.getCerData(this._cerId);
      if (!cerData) return null

      let result = []
      
    if (this._consumptionIds !== this.allCerConsumption) {
      consumptions.forEach((consumption: any) => {

        cerData.consumptions.forEach(element => {
          if (element.id === consumption.id) {
            result.push( {
              id: consumption.id,
              label: `${consumption.name} (${this._translationSrv.getInstant("serialNumber")}: ${consumption.serialNumber})`,
              data: consumption
            });
          }
        });
      })
    }
    if (localStorage.getItem("ideta_modulec_userRole") !== "USER" && result.length > 0 && this._consumptionIds.length < this.allCerConsumption.length) {
      result.push ({
        id: null,
        label: this._translationSrv.getInstant("EveryConsumptionProfil"),
        data: null
      })
    }
    return result;
  }

}
