import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Component used to display the user avatar
 * 
 * @example 
 * Basic Usage
 * <app-profile-picture imgUrl="imgUrl"></app-profile-picture>
 * 
 * Usage with redirection on click
 * <app-profile-picture imgUrl="imgUrl" redirectUrl="/redirectUrl"></app-profile-picture>
 */
@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent {

  /** The img url used to display the user avatar */ @Input('imgUrl') imgUrl: string = '';
  /** The redirect url used on avatar click */ @Input('redirectUrl') redirectUrl: string = '';

  /**
   * Constructor method
   * @param {Router} _router Angular Router
   */
  constructor(private _router: Router) { }

  /**
   * Go to the current user profile page
   * @returns {void}
   */
  public goToProfile(): void {
    if(this.redirectUrl && this.redirectUrl != ''){
      this._router.navigate([this.redirectUrl]);
    }
  }

}
