import { EventEmitter, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlusCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { EnumTableAction, ITableAction, ITableColumn } from 'src/app/libs/table/facades';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { IPaginationInput, IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { ConsumptionQueriesService } from 'src/app/queries/consumption/consumption-queries.service';
import { IConsumption } from 'src/app/queries/consumption/interfaces/consumptions.interface';

/**
 * linked to consumption list
 */
@Component({
  selector: 'app-linked-consumptions',
  templateUrl: './linked-consumptions.component.html',
  styleUrls: ['./linked-consumptions.component.scss']
})
export class LinkedConsumptionsComponent implements OnInit {
  /** FormGroup for add a linked consumption */ public formGroup: FormGroup;
  /** Add button icon */ public faPlusCircle: IconDefinition = faPlusCircle;
  /** list of consumptions selected for remove from the select search */ public selectedForSearch: ISearchSelectItem[] = [];
  /** List of consumptions selected to display for the table */ @Input() public selectedConsumptions: IConsumption[] = [];
  /** allow adding new consumption */ @Input() public allowNewConsumption: boolean = true;
  /** EventEmitter when the array of consumptions linked changed */ @Output("consumptionsChanged") public consumptionsChanged: EventEmitter<IConsumption[]> = new EventEmitter();

  /** Variable to send to search & select to reset selected item */ public resetSelected = new Subject<boolean>();
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** Map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** Label of inputs errors */ public inputErrorsLabel: any;
  /** Consumption to delete */ public consumptionToDelete: any;
  /** Modal configuration */ public modalConfig: IModalItemConfig = {};
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
  /** Template of modal */ @ViewChild('tplDeleteConsumptionToLink', { static: true }) modalTemplate: TemplateRef<any>;
  /** Pagination base for table */ public pagination: IPaginationInput = { page: 1, limit: 1 };
  /** pagination result for table */
  public paginationResult: IPaginationResult = {
    ...this.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1,
  };
  /** Array of columns for table */
  public columns: ITableColumn[] = [
    { key: 'name', type: 'text', title: this._translationSrv.getInstant("fullName"), clickable: false },
    { key: 'serialNumber', type: 'text', title: this._translationSrv.getInstant("serialNumber"), clickable: false },
    { key: 'actions', type: 'action', title: this._translationSrv.getInstant('actions') },
  ];

  /** previous selected length */ private _previousSelectedLength: number = 0;

  /**
   * constructor
   * @param _router the router
   * @param _activedRoute the activated route
   * @param _fb the form builder
   * @param _consumptionsQueriesSrv the consumptions queries service
   * @param _translationSrv the translation service
   * @param _overlayService the overlay service
   * @param _alertSrv the alert service
   */
  constructor(private _router: Router,
              private _activedRoute: ActivatedRoute,
              private _fb: FormBuilder,
              private _consumptionsQueriesSrv: ConsumptionQueriesService,
              private _translationSrv: TranslationService,
              private _overlayService: OverlayService,
              private _alertSrv: AlertService) {}

  /**
   * on init
   */
  ngOnInit() {
    this.initFormGroup();
  }

  /**
   * check the selected consumptions length
   */
  ngDoCheck(){
    if(this.selectedConsumptions && this.selectedConsumptions.length !== this._previousSelectedLength){
      this._previousSelectedLength = this.selectedConsumptions.length;
      this.buildSelectedForSearch();
    }
  }

  /**
   * init the form group
   */
  public initFormGroup(): void {
    this.formGroup = this._fb.group({
      consumptionItem: [null, Validators.required],
    })
  }

  /**
   * search consumptions
   * @param q the query
   * @param idsSelected the selected ids 
   * @returns void
   */
  public searchConsumptions = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._consumptionsQueriesSrv.searchConsumptions({ page: 1, limit: 1000 }, q).toPromise();
    let consumptions: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getConsumptions.consumptions && (<any>resultQuery.data).getConsumptions.consumptions)
      consumptions = (<any>resultQuery.data).getConsumptions.consumptions

    consumptions = consumptions.map((consumption: any) => {
      return {
        id: consumption.id,
        label: `${consumption.name} (${this._translationSrv.getInstant("serialNumber")}: ${consumption.serialNumber})`,
        data: consumption
      };
    })
    return consumptions;
  }

  /**
   * Add consumption to the list of selected consumptions
   * @returns 
   */
  public addConsumption() {
    if(!this.formGroup || !this.formGroup.get("consumptionItem").value || !this.formGroup.get("consumptionItem").value[0]) {
      this._alertSrv.danger(this._translationSrv.getInstant("consumptionRequiredToLink_error"));
      return;
    }
    this.selectedConsumptions.push(this.formGroup.get("consumptionItem").value[0].data)
    this.buildSelectedForSearch();
    this.formGroup.get("consumptionItem").setValue([]);
    this.resetSelected.next(true);
    this.consumptionsChanged.emit(this.selectedConsumptions);
  }

  /**
   * Handle the action triggered by a consumption click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   */
  public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.consumptionToDelete = event.content;
        this.open(this.modalTemplate);
        break;
      case EnumTableAction.EDIT:
        this.goTo(`/consumptions/detail/${event.content.id}`);
        break;
      case EnumTableAction.BOOLEAN:
        // this.updateActiveConsumption(event.content);
        break;
      case EnumTableAction.SORT:
        if (event && event.content && event.content.key) {
          // this.pagination.page = 1;
          // this.sortedBy = event.content.key;
          // this.sortConsumptions(event.content.key)
        }
        break;
      default:
        break;
    }
  }

  /**
   * Called by table child when page is changed
   * @param event 
   */
  public onPageChange(event: number): void {
    this.pagination.page = event;
  }

  /**
   * Open modal with content
   * @param content 
   */
  public open(content: TemplateRef<any>) {
    this.modalConfig = {
      title: this._translationSrv.getInstant('modalDeleteConsumption'),
      body: `${this._translationSrv.getInstant('modalDeleteConsumptionBody', { name: this.consumptionToDelete.name, serialNumber: this.consumptionToDelete.serialNumber })}`,
      buttons: [
        {
          message: this._translationSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translationSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res.data.value === true) {

        // Search the index of the element to remove
        const index = this.selectedConsumptions.findIndex(item => item.id === this.consumptionToDelete.id);

        // Need to use a tempArray because of reference of the searchSelectedConsumptions array need to change
        let tempArray = this.selectedForSearch.map(selected => { return selected })
        tempArray.splice(index, 1);
        this.selectedForSearch = tempArray.map(selected => { return selected });

        // Remove the selected consumption from the table contents
        this.selectedConsumptions.splice(index, 1);

        this.consumptionsChanged.emit(this.selectedConsumptions);
      }
    });
  }

  /**
   * content getter
   */
  public get contents() {
    return this.selectedConsumptions.map((consumption: IConsumption) => {
      return {
        name: consumption.name,
        serialNumber: consumption.serialNumber,
        id: consumption.id
      }
    })
  }


  /**
   * Build the selected consumptions for search
   */
  public buildSelectedForSearch() {
    let result: ISearchSelectItem[] = [];
    if (this.selectedConsumptions.length > 0) {
      result = this.selectedConsumptions.map((elem: IConsumption) => {
        return {
          id: elem.id,
          label: elem.serialNumber,
          data: elem
        }
      })
    }
    this.selectedForSearch = result;
  }

  /**
   * Called to navigate to another page
   * @param page URL
   * @param content content to pass to page
   * @memberof ConsumptionsComponent
   */
  goTo(page: string, content?: any) {
    this._router.navigate([page], {
      relativeTo: this._activedRoute,
      state: content,
    });
  }

}
