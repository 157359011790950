import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MyOverlayRef } from '../../modal/overlay/myoverlay-ref';
import { OverlayService } from '../../modal/overlay/overlay.service';
import { TranslationService } from '../../translation/services/translation.service';
import * as XLSX from 'xlsx';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { EnumPeriod } from '../../modal/widgets-config/facades/enums/period.enum';
import { DateTime } from 'luxon';
import { saveAs } from 'file-saver';
import { FILE_TYPES_ENUM } from '../facades/enum/fileTypes.enum';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

import { ConsumptionQueriesService } from 'src/app/queries/consumption/consumption-queries.service';
import { EXPORT_TYPES_ENUM } from '../facades/enum/exportTypes.enum';
import { AlertService } from 'ngx-alerts';

/**
 * export component
 */
@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  /** Template of modal */ @ViewChild('tplExportData', { static: true }) modalExportConfig: TemplateRef<any>;
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;

  /**
   * constructor
   * @param _translationSrv the translation service
   * @param _overlayService the overlay service
   * @param _alertSrv the alert service
   * @param _installationQueriesSrv the installation queries service
   * @param _consumptionsQueriesSrv the consumption queries service
   */
  constructor(
    private _translationSrv: TranslationService,
    private _overlayService: OverlayService,
    private _alertSrv: AlertService,
    private _installationQueriesSrv: InstallationQueriesService,
    private _consumptionsQueriesSrv: ConsumptionQueriesService,
  ) { }

  /** ngOnInit */
  ngOnInit(): void {
  }

  /**
   * export data of production
   */
  onExportClick() {
    this.open(this.modalExportConfig);
  }

  /**
   * Open modal with content
   * @param content
   */
   public open(content: TemplateRef<any>) {

    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res && res.data && res.data !== false && res.data.chosenItems && res.data.periodStart && res.data.title && res.data.fileType) {
        this._alertSrv.success(this._translationSrv.getInstant('export-started'));
        const title = res.data.title
        const ids = res.data.chosenItems.map((item: any) => {return item.id});

        if (!ids) return;

        if (res.data.typeExport === EXPORT_TYPES_ENUM.PRODUCTION) {
          switch (res.data.fileType) {
            case FILE_TYPES_ENUM.PDF:
              window.open(`${environment.API_URL_CONTROLLER}installations/installationExport.pdf?date=${res.data.periodStart}&installationsIds=[${ids.toString()}]&name=${title}`, '_blank').focus();
              break;
            case FILE_TYPES_ENUM.CSV:
              if (!res.data.periodEnd) return;
              let adaptedRange = {
                gte: DateTime.fromMillis(res.data.periodStart).startOf("day").toMillis(),
                lte: DateTime.fromMillis(res.data.periodEnd).startOf("day").toMillis(),
                period: EnumPeriod.DAY
              }
              this._installationQueriesSrv.getCsvDatas(ids, adaptedRange).subscribe(res => {
                this.exportDataProduction((<any>res.data).installationCsvDatas, title, adaptedRange);
              })
              break;
            default:
              break;
          }
        } else if (res.data.typeExport === EXPORT_TYPES_ENUM.CONSUMPTION && res.data.fileType === FILE_TYPES_ENUM.CSV) {
          if (!res.data.periodEnd) return;
          let adaptedRange = {
            gte: DateTime.fromMillis(res.data.periodStart).startOf("day").toMillis(),
            lte: DateTime.fromMillis(res.data.periodEnd).startOf("day").toMillis(),
            period: EnumPeriod.DAY
          }
          this._consumptionsQueriesSrv.getCsvDatas(ids, adaptedRange).subscribe(res => {
            this.exportDataConsumption((<any>res.data).getConsumptionCsvDatas, title, adaptedRange);
          })
        }
      }
    });
  }
  
  /**
   * replace all occurence of the parameter find by the parameter replace in the parameter str
   * @param str the string where you want to replace
   * @param find the caracter to find
   * @param replace the charater that si going to replace the previous one
   * @returns the string with the replaced caracter
   */
  private replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  /**
   * converte an array of objects to a map
   * @param array the array that you want to convert
   * @returns the converted map
   */
  private arrayToMap(array) {
    return new Map(array.map(i => [i.date, i.value]));
  }

  /**
   * add 15 min to the timestamp passed as parameter
   * @param previoustimestamp the previous timestamp
   * @returns the new timestamp
   */
  private getNextTimestamp(previoustimestamp: number) {
    return previoustimestamp += (1000 * 60 * 15);
  }

  /**
   * export to csv the data of production
   * @param dataset dataset of production
   * @param title csv title
   * @param range the time range
   */
  public exportDataProduction(dataset, title:string, range) {
    /* starting from this data */

    let currentTimestamp = range.gte

    var out = [];
    if (dataset.length > 0) {

      let mapArray: Map<number, number>[] = dataset.map(elem => {
        return this.arrayToMap(elem.data)
      });

      let dateTranslate = this._translationSrv.getInstant('date');
      let hourTranslate = this._translationSrv.getInstant('startHourCount');

      while (currentTimestamp < range.lte) {
        let obj = {};
        obj[dateTranslate] = DateTime.fromMillis(currentTimestamp).toFormat('dd/MM/yyyy')
        obj[hourTranslate] = DateTime.fromMillis(currentTimestamp).toFormat('HH:mm')
        let total = 0;
        dataset.forEach((converter,idx: number) => {
          const value = mapArray[idx] && mapArray[idx].has(currentTimestamp) ? mapArray[idx].get(currentTimestamp) * 1000 : 0;
          obj[`${converter.installationName}_${converter.converterPalamedeName}(Wh)`] = value
          if (!converter.converterPalamedeName.includes('_PTC'))
            total += value
        })
        obj["total"] = total;
        out.push(obj);
        currentTimestamp = this.getNextTimestamp(currentTimestamp);
      };

      /* generate a worksheet */
      var ws = XLSX.utils.json_to_sheet(out, {header:[dateTranslate,hourTranslate]});

      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Ideta Export");

      let csv = XLSX.utils.sheet_to_csv(ws);
      csv = this.replaceAll(csv, ',', ';');
      this.downloadCsv(csv, title);
    }
  }

    /**
   * export to csv the data of consumption
   * @param dataset dataset of consumption
   * @param title csv title
   * @param range the time range
   */
  public exportDataConsumption(dataset, title:string, range) {
    /* starting from this data */
    var out = [];
    let currentTimestamp = range.gte
    if (dataset.length > 0) {


      let mapArray: Map<number, number>[] = dataset.map(elem => {
        return this.arrayToMap(elem.data)
      });

      let longest;
      let test = 0;
      dataset.forEach(element => {
        if (element.data.length > test) {
          test = element.data.length;
          longest = element;
        }
      })

      let dateTranslate = this._translationSrv.getInstant('date');
      let hourTranslate = this._translationSrv.getInstant('startHourCount');

      while (currentTimestamp < range.lte) {
        let obj = {};
        obj[dateTranslate] = DateTime.fromMillis(currentTimestamp).toFormat('dd/MM/yyyy')
        obj[hourTranslate] = DateTime.fromMillis(currentTimestamp).toFormat('HH:mm')
        let total = 0;
        dataset.forEach((consumption,idx: number) => {
          const value = mapArray[idx] && mapArray[idx].has(currentTimestamp) ? mapArray[idx].get(currentTimestamp) * 1000 : 0;
          obj[`${consumption.consumptionName}_${consumption.consumptionSerial}(Wh)`] = value
          total += value
        })
        obj["total"] = total;
        out.push(obj);
        currentTimestamp = this.getNextTimestamp(currentTimestamp);
      };

      /* generate a worksheet */
      var ws = XLSX.utils.json_to_sheet(out, {header:[dateTranslate,hourTranslate]});

      /* add to workbook */
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Ideta Export");

      let csv = XLSX.utils.sheet_to_csv(ws);
      csv = this.replaceAll(csv, ',', ';');
      this.downloadCsv(csv, title);
    }
  }

  /**
   * download csv file
   * @param csv the csv file to download
   */
  public downloadCsv(csv, title: string) {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${title}.csv`);
  }
}
