import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ListDashboardComponent } from './list-dashboard/list-dashboard.component';
import { AddEditDashboardsComponent } from './add-edit-dashboard/add-edit-dashboard.component';
import { DashboardRoutingModule } from './dashboard.routing.module';
import { WidgetsModule } from 'src/app/libs/widgets/widgets.module';
import { TranslationModule } from 'src/app/libs/translation/translation.module';
import { TableModule } from 'src/app/libs/table/table.module';
import { ButtonsModule } from 'src/app/libs/buttons/buttons.module';
import { InputModule } from 'src/app/libs/input/input.module';
import { SearchSelectModule } from 'src/app/libs/search-select/search-select.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'src/app/libs/modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    WidgetsModule,
    TranslationModule,
    TableModule,
    ButtonsModule,
    InputModule,
    SearchSelectModule,
    FontAwesomeModule,
    ModalModule,
  ],
  declarations: [
    DashboardComponent,
    ListDashboardComponent,
    AddEditDashboardsComponent
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
