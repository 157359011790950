import { USER_ROLES_ENUM } from "../enums/usersRoles.enum";

/**
 * Translation of role by key to string name
 */
export const ROLES_TRANSLATION_KEY_MAP = new Map([
    [USER_ROLES_ENUM.USER, "user"],
    [USER_ROLES_ENUM.ADMIN, "admin"],
    [USER_ROLES_ENUM.SUPER_ADMIN, "superAdmin"]
])

