<ng-container *ngFor="let option of options; let i = index;">  
    <div class="radio" (click)="selectCheckbox(option.value, option.disable)">
      <input #input type="radio" 
        [name]="name" 
        [id]="id+'_'+i" 
        [value]="option.value"
        [checked]="selectedValue != null && selectedValue == option.value"
        [attr.disabled]="option.disable ? '' : null"
        [attr.required]="require ? '' : null"
       >
      <label [for]="id" class="radio-label">{{ option.label | translate }}</label>
    </div>
  </ng-container>