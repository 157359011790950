import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { EnumGraphType, GRAPH_TYPE_BY_ENUM } from 'src/app/libs/widgets/facades/enums';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { DateTime, DurationObject } from "luxon";
import { EnumPeriod } from '../../modal/widgets-config/facades/enums/period.enum';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { GraphBackgroundColor, GraphBorderColor } from '../facades/constants/graph-color.constant';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendarInputComponent } from '../../input/calendar-input/calendar-input.component';
import { GraphConfigService } from '../../modal/widgets-config/facades/services/graph-config.service';
import { RecQueriesService } from 'src/app/queries/rec/rec-queries.service';

/**
 * register the chart.js
 */
Chart.register(...registerables);

/**
 * auto consumption graph widget component
 */
@Component({
  selector: 'app-auto-consumption-graph-widget',
  templateUrl: './auto-consumption-graph-widget.component.html',
  styleUrls: ['./auto-consumption-graph-widget.component.scss']
})
export class AutoConsumptionGraphWidget {

  /** Config for graph */ @Input() public graphConfig: any;
  /** id for graph */ @Input() public id: any;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronLeft: IconDefinition = faChevronLeft;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronRight: IconDefinition = faChevronRight;
  /** FormGroup of installation */ public formGroup: FormGroup;

  /** true if the widget is loading */public loading: boolean = false;
  /** canvas view */ @ViewChild('myCanvas', { static: true }) myCanvas: ElementRef<HTMLCanvasElement>;
  /** date picker view */ @ViewChild('inputDate', { static: true }) inputDate: ElementRef<CalendarInputComponent>;

  /** data */ public information: any = null;
  /** the chart */ public myChart: Chart = null;
  /** dataset */ public totalDataset: any = null;
  /** data from backend */ public formattedDatasForGraph: any = null;

  /**
   * construct the graph
   * @param _installationQueriesSrv the installation queries service
   * @param _fb the form builder
   * @param graphConfigSrv the graph config service
   * @param _recSrv the rec queries service
   */
  constructor(private _installationQueriesSrv: InstallationQueriesService,
    private _fb: FormBuilder,
    public graphConfigSrv: GraphConfigService,
    private _recSrv: RecQueriesService
    ) { }



  /**
   * On init method.
   */
  ngOnInit() {
    this.initFormGroup();
    this._initGraphOptions();
    this._getWidgetDatas();
  }

  /**
   * init the form group
   */
  public initFormGroup() {
    this.formGroup = this._fb.group({
      currentDate: [DateTime.now().setLocale("fr").toMillis()],
    });

    this.formGroup.get("currentDate").valueChanges.subscribe(res => {
      this._getWidgetDatas();
    })
  }

  /**
   * init the graph options
   */
  private _initGraphOptions() {
    if (this.myCanvas) {
      const ctx = this.myCanvas.nativeElement.getContext("2d");
      const chartConfig = {
        type: GRAPH_TYPE_BY_ENUM[this.graphConfig.type],
        data: null,
        options: {
          scales: {
            x: {
              stacked: false
            },
            y: {
              beginAtZero: true,
              stacked: false,
              title: {
                display: true,
                text: 'kWh',
                font: {
                  size: 10,
                  lineHeight: 0.5
                }
              }
            }
          },
          plugins: {},
          interaction: {
            intersect: false,
            mode: 'index',
          },
          maintainAspectRatio: false,
        }
      };

      chartConfig.options.plugins['tooltip'] = {
        callbacks: {
          label: function (tooltipItems) {
            return `${tooltipItems.dataset.label}: ${tooltipItems && tooltipItems.parsed && tooltipItems.parsed.y? tooltipItems.parsed.y.toFixed(2) : '0'} kWh`;
          },
        }
      }

      this.myChart = new Chart(ctx, chartConfig)
    }
  }

  /**
   * get cer data
   * @param cerID the cer id 
   * @returns void
   */
     private async getCerData(cerID: number): Promise<any> {
      return new Promise((resolve) => {
        this._recSrv.getRec(cerID).subscribe(res => {
          let data = <any>(res.data)
          resolve(data.getRec);
        })
      })
    }

  /**
   * get the widget datas
   * @returns void
   */
  private async _getWidgetDatas() {
    let getAutoConsumptionDatas = [];
    let autoConsumptionLabels = [];
    const range = this.graphConfigSrv.calculateRange(this.graphConfig.period, this.formGroup.get("currentDate").value);
    const cer = await this.getCerData(this.graphConfig.cer.id)
    if (!cer) return;

    let repartitionPercentagePerConsumption = this.graphConfig.consumptionsIds.map((id: number) => {
      // iterate over all the consumption and if the repartion repartition is "fix" try to get from the cer the repartition percentage
      for (const consumption of cer.recs_consumptions)
      {
        if (consumption.consumptionId === id) { // if the id match return the percentage of this
          return consumption.percentage
        }
      }
      return -1 // return -1 if the percentage isn't found
    })

    this.loading = true;
    this._installationQueriesSrv.getAutoConsumptionGraphDatas(cer.consumptions.map((elem: any) => { return elem.id }), cer.installations.map((elem: any) => { return elem.id }), this.graphConfig.consumptionsIds, repartitionPercentagePerConsumption ,cer.repartitionKey, cer.repartitionMode, range).subscribe((resultDatas: any) => {
      this.loading = false;
      if (resultDatas && resultDatas.data) {
        const { getAutoConsumptionGraph } = resultDatas.data;
        if(getAutoConsumptionGraph) {
          const label = getAutoConsumptionGraph.label;
          let graphDatas = getAutoConsumptionGraph.data;

          this.information = getAutoConsumptionGraph.information;

          getAutoConsumptionDatas = graphDatas ? graphDatas : [];
          autoConsumptionLabels = label ? label : [];

          const blueColor = 'rgba(54, 162, 235, 0.7)';
          const redColor = 'rgba(128,0,0,0.7)';
          const greenColor = 'rgba(12, 146, 0, 0.7)';
          const borderBlueColor = 'rgba(54, 162, 235, 1)';
          const borderRedColor = 'rgba(128,0,0,1)';
          const borderGreenColor = 'rgba(12, 146, 0, 1)';

          this.formattedDatasForGraph = getAutoConsumptionDatas.map((item, index) => {
            return {
              ...item,
              backgroundColor:  item.key === "production"? redColor : (item.key === "autoconsumption"? greenColor : blueColor),
              borderColor: item.key === "production"? borderRedColor : (item.key === "autoconsumption"? borderGreenColor : borderBlueColor),
              pointRadius: 1,
              borderWidth: 1,
              order: item.key === "production"? 3 : (item.key === "autoconsumption"? 1 : 2),
              type: this.graphConfig.period === EnumPeriod.DAY? 'line' : 'bar',
              fill: true
            }
          });

          this.myChart.data.datasets = this.formattedDatasForGraph;
          this.myChart.data.labels = autoConsumptionLabels;
        }
        this.myChart.update();

      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    })
  }

  /**
   * change the date
   * @param orientation next or prev
   */
  public changeDate(orientation: string = "next") {
    const currentDate = DateTime.fromMillis(this.formGroup.get("currentDate").value);
    if (orientation == "next") this.formGroup.get("currentDate").patchValue(currentDate.plus({ [this.graphConfig.period]: 1 }).toMillis());
    else this.formGroup.get("currentDate").patchValue(currentDate.minus({ [this.graphConfig.period]: 1 }).toMillis());
    this._getWidgetDatas();
  }
}
