<div class="recover_page">
  <div class="recover_page__form">
    <img
      src="../../../../assets/img/login/login_logo.png"
      alt="Logo IDETA"
      class="recover_page__form-logo"
    />
    <p>{{ "ideta_description" | translate }}</p>

    <app-label>{{ "email" | translate }}</app-label>
    <app-text-input
      [placeholder]="'placeholder_mail' | translate"
      formControlName="mail"
      [group]="recoverFormGroup"
      [name]="'mail'"
      id="mail"
      [ngClass]="{ 'invalid-input': isSubmitted && formControls.mail.errors }"
    ></app-text-input>
    <ng-container *ngIf="!verificationCodeSubmitted">
      <app-button
        [action]="TYPE_BUTTON.DARK_PRIMARY"
        size="small"
        (click)="sendCode(true)"
        >Envoi du code de vérification</app-button
      >
      <a (click)="sendCode(false)">J'ai déjà mon code de vérification.</a>
    </ng-container>

    <ng-container *ngIf="verificationCodeSubmitted">
      <app-label>{{ "verification-code" | translate }}</app-label>
      <app-text-input
        [placeholder]="'placeholder_verification-code' | translate"
        formControlName="verificationCode"
        [group]="recoverFormGroup"
        [name]="'verificationCode'"
        id="verificationCode"
        [ngClass]="{
          'invalid-input': isSubmitted && formControls.verificationCode.errors
        }"
      ></app-text-input>
      <app-label>{{ "new-password" | translate }}</app-label>
      <app-text-input
        [placeholder]="'placeholder_password' | translate"
        formControlName="password"
        [group]="recoverFormGroup"
        [name]="'password'"
        id="password"
        [ngClass]="{
          'invalid-input': isSubmitted && formControls.password.errors
        }"
        [isPassword]="true"
      ></app-text-input>
      <app-label>{{ "confirm-new-password" | translate }}</app-label>
      <app-text-input
        [placeholder]="'placeholder_confirm-password' | translate"
        formControlName="confirmPassword"
        [group]="recoverFormGroup"
        [name]="'confirmPassword'"
        id="confirmPassword"
        [ngClass]="{
          'invalid-input': isSubmitted && formControls.confirmPassword.errors
        }"
        [isPassword]="true"
      ></app-text-input>
      <h5 *ngIf="error.isError">{{ error.message | translate }}</h5>
      <app-button
        [action]="TYPE_BUTTON.DARK_PRIMARY"
        size="regular"
        (click)="validateForm()"
        >Récupération</app-button
      >
    </ng-container>

    <p>
      Vous avez déjà un compte ?
      <a href="/login">Se connecter.</a>
    </p>
  </div>
  <div class="recover_page__bg"></div>
</div>
