<div>
    <div #input class="select"
    [ngClass]="{'select__disable': disable}" 
    [formGroup]="group" (appClickOutside)="close()">
        <div class="select__content " [ngClass]="{'select__content_isOpen': !isClosed}" (click)="open()" >
            <div class="select__content_text">{{valueSelect && valueSelect.label? ( valueSelect.label.toString() | translate ) : "" }}</div>
            <i class="fas fa-chevron-down select__content_icon"></i>
            <input type="text" [formControlName]="name" [attr.name]="name" style="display:none">
        </div>
        <div 
        class="select__options"
        [ngClass]="{'select__options_active': !isClosed}">
            <div *ngFor="let option of options" class="select__options_item" [ngClass]="{'selected':  valueSelect && option.value === valueSelect.value}" (click)="close(option)">
                {{ option.label? ( option.label.toString() | translate ) : ""}}
            </div>
        </div>
    </div>
</div>
