<div class="box gap_top" style="position: relative;">
  <h4 class="title">{{ titleNote | translate }}</h4>
  <div class="grid">
    <div class="add-comment__select">
      <div *ngIf="canEdit" class="add-comment__button">
        <fa-icon [icon]="faPlusCircle" (click)="commentActionClicked()"></fa-icon>
      </div>

      <div class="listComment row" *ngFor="let comment of commentList; let i = index;">
        <div *ngIf="canEdit" class="row__cell_actions" style="float: right;">
          <div *ngIf="!comment.deleted_at; else commentDeleted" class="note-delete">
            <fa-icon class="row__cell_actions--delete delete_comment" [icon]="faTrashAlt" (click)="commentDeleteClicked(comment)"></fa-icon>
          </div>
          <ng-template #commentDeleted class="note-undo">
            <div class="note-undo">
              <fa-icon class="row__cell_actions--revert revert_comment" [icon]="faUndo" (click)="commentDeleteClicked(comment)"></fa-icon>
            </div>
          </ng-template>
        </div>
        <div [ngClass]="{'tempDeleted': comment.deleted_at }" class="note-block" style="margin-right: 2em;">
          <div class="note-block__header">
            <div class="paraTitle">{{comment.title}}</div>
            <div class="dateComment">{{ "created_atComment" | translate}} {{comment.created_at}}</div>
          </div>
          <div class="note-block__content">
            <div class="paraComment">{{comment.description}}</div>
            <div class="upload-img__container">
              <ng-container *ngFor="let url of imgsUrl.get(comment)">
                <div class="upload-img__container_picture">
                  <img src="{{url}}" (click)="openImg(url)">
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #tplAddEditComment>
  <app-modal-comment [ref]="ref" [config]="modalConfig" [comment]="commentToOpen"></app-modal-comment>
</ng-template>

<ng-template #tplDeleteComment>
  <app-modal-item [ref]="ref" [config]="modalConfigDelete"></app-modal-item>
</ng-template>
