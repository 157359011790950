import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoutIconComponent } from './logout-icon/logout-icon.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    LogoutIconComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FontAwesomeModule,
  ],
  exports: [
    LogoutIconComponent
  ]
})
export class AuthenticateModule { }
