import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstallationsComponent } from './installations/installations.component';
import { InstallationsRoutingModule } from './installations.routing.module';
import { TranslationModule } from 'src/app/libs/translation/translation.module';
import { InputModule } from 'src/app/libs/input/input.module';
import { ButtonsModule } from 'src/app/libs/buttons/buttons.module';
import { TableModule } from 'src/app/libs/table/table.module';
import { AddEditInstallationsComponent } from './add-edit-installations/add-edit-installations.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'src/app/libs/modal/modal.module';
import { SearchSelectModule } from 'src/app/libs/search-select/search-select.module';
import { ConvertersListComponent } from './converters-list/converters-list.component';
import { LinkedModule } from '../linked/linked.module';
import { AlarmsModule } from 'src/app/libs/alarms/alarms.module';

@NgModule({
  imports: [
    CommonModule,
    InstallationsRoutingModule,
    TranslationModule,
    InputModule,
    ButtonsModule,
    TableModule,
    FontAwesomeModule,
    ModalModule,
    SearchSelectModule,
    LinkedModule,
    AlarmsModule
  ],
  declarations: [
    InstallationsComponent,
    AddEditInstallationsComponent,
    ConvertersListComponent
  ],
  exports: [
    InstallationsComponent,
    AddEditInstallationsComponent
  ],
})
export class InstallationsModule {}
