import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
// import { Color, Label } from 'ng2-charts';
import { EnumGraphType } from 'src/app/libs/widgets/facades/enums';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { DateTime, DurationObject } from "luxon";
import { EnumPeriod } from '../../modal/widgets-config/facades/enums/period.enum';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GraphBackgroundColor, GraphBorderColor } from '../facades/constants/graph-color.constant';
import { FormGroup } from '@angular/forms';
import { CalendarInputComponent } from '../../input/calendar-input/calendar-input.component';

Chart.register(...registerables);
/**
 * Component that display a bar
 * Basic Usage 
 * <app-graph-widget [graphConfig]="item.widgetData"></app-graph-widget>
 */
@Component({
  selector: 'app-production-history-graph-widget',
  templateUrl: './production-history-graph-widget.component.html',
  styleUrls: ['./production-history-graph-widget.component.scss']
})
export class ProductionHistoryGraphWidgetComponent {

  /** Config for graph */ @Input() public graphConfig: any;
  /** id for graph */ @Input() public id: any;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronLeft: IconDefinition = faChevronLeft;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronRight: IconDefinition = faChevronRight;
  /** FormGroup of installation */ public formGroup: FormGroup;

  /** true if the widget is loading */public loading: boolean = false;
  /** canva view */ @ViewChild('myCanvas', { static: true }) myCanvas: ElementRef<HTMLCanvasElement>;
  /** input date view */ @ViewChild('inputDate', { static: true }) inputDate: ElementRef<CalendarInputComponent>;

  /** Enum to est type by enumType for graph */ private typeByEnum = {
    [EnumGraphType.AREA]: 'line',
    [EnumGraphType.MIXED]: 'line',
    [EnumGraphType.BAR]: 'bar',
    [EnumGraphType.STACKEDBAR]: 'bar'
  }

  /** current start date */ public currentStartDate: DateTime = null;
  /** current end date */ public currentEndDate: DateTime = null;

  /** period unit map */ public periodUnitMap: Map<string, keyof DurationObject> = null;
  /** enum period */ public __EnumPeriod = EnumPeriod;
  /** data */ public information: any = null;

  /** the chart */ public myChart: Chart = null;
  /** dataset */ public totalDataset: any = null;
  /** data from backend */ public formattedDatasForGraph: any = null;

  /**
   * Constructor methos.
   */
  constructor(private _installationQueriesSrv: InstallationQueriesService) {
    this.periodUnitMap = new Map<string, keyof DurationObject>([
      [EnumPeriod.YEAR, "year"],
    ]);
  }


  /**
   * On init method.
   */
  ngOnInit() {
    // this.initFormGroup();
    this._initGraphOptions();
    this._getWidgetDatas();
  }

  /**
   * Init graph options
   */
  private _initGraphOptions() {
    if (this.myCanvas) {
      const ctx = this.myCanvas.nativeElement.getContext("2d");
      const chartConfig = {
        type: this.typeByEnum[this.graphConfig.type],
        data: null,
        options: {
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: 'kWh',
                font: {
                  size: 10,
                  lineHeight: 0.5
                }
              }
            }
          },
          plugins: {},
          interaction: {
            intersect: false,
            mode: 'index',
          },
          maintainAspectRatio: false,
        }
      };

      chartConfig.options.plugins['tooltip'] = {
        callbacks: {
          label: function (tooltipItems) {
            return `${tooltipItems.dataset.label}: ${tooltipItems.parsed.y.toFixed(2)} kWh`;
          },
        }
      }
      this.myChart = new Chart(ctx, chartConfig)
    }
  }

  /**
   * get widget datas
   */
  private _getWidgetDatas() {
    let convertersFormattedData = [];
    let convertersLabels = [];

    this.loading = true;
    this._installationQueriesSrv.getInstallationsProductionHistory(this.graphConfig.installationId).subscribe((resultDatas: any) => {
      this.loading = false;
      if (resultDatas && resultDatas.data && resultDatas.data.getInstallationsProductionHistory) {

        let productionDatas = resultDatas.data.getInstallationsProductionHistory.data;
        const label = resultDatas.data.getInstallationsProductionHistory.label;
        this.information = resultDatas.data.getInstallationsProductionHistory.information
        for (const [key, value] of Object.entries(this.information)) {
          this.information[key] = parseFloat(<string>value).toFixed(2);
        }
        this.totalDataset = {
          label: "Total",
          data: []
        }

        productionDatas.forEach(element => {
          if (element.data) {
            element.data.forEach((element, index) => {
              this.totalDataset.data[index] = this.totalDataset.data[index] != null ? this.totalDataset.data[index] + element : element
            });
          }
        });

        convertersFormattedData = productionDatas ? productionDatas : [];
        convertersLabels = label ? label : [];


        this.formattedDatasForGraph = convertersFormattedData.map((item, index) => {
          return {
            ...item,
            backgroundColor: GraphBackgroundColor[index],
            borderColor: GraphBorderColor[index],
            pointRadius: 1,
            borderWidth: 1,

            fill: true
          }
        });
        if(this.graphConfig.type == EnumGraphType.AREA){
          this.formattedDatasForGraph.push({
            ...this.totalDataset,
            backgroundColor: GraphBackgroundColor[this.formattedDatasForGraph.length],
            borderColor: GraphBorderColor[this.formattedDatasForGraph.length],
            pointRadius: 1,
            borderWidth: 1,

            fill: true
          })
        }
        this.myChart.data.datasets = this.formattedDatasForGraph;
        this.myChart.data.labels = convertersLabels;
        this.myChart.update();

      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    })
  }

}
