import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PaginationModule } from '../pagination/pagination.module';
import { InputModule } from '../input/input.module';
import { TranslationModule } from '../translation/translation.module';

@NgModule({
  declarations: [TableComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    PaginationModule,
    InputModule,
    TranslationModule
  ],
  exports : [ TableComponent]
})
export class TableModule { }
