import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TYPE_BUTTON } from 'src/app/libs/buttons/facades/enums/type-button.enum';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { AuthenticateQueriesService } from 'src/app/queries/authenticate/authenticate-queries.service';
import { DashboardQueriesService } from 'src/app/queries/dashboard/dashboard-queries.service';
import { IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { AuthService } from 'src/app/services/auth.service';

/** id of the user key for localStorage */
export const USER_ID =
  'ideta_modulec_userId'; 

/** mail of the user key for localStorage */
export const USER_MAIL =
  'ideta_modulec_userMail';

/** role of the user key for localStorage */
export const USER_ROLE =
  'ideta_modulec_userRole';

/** firstname of the user key for localStorage */
export const USER_FIRSTNAME =
  'ideta_modulec_firstname';

/** lastname of the user key for localStorage */
export const USER_LASTNAME =
  'ideta_modulec_lastname'; 

/** token of the user key for localStorage */
export const USER_TOKEN =
  'idetaTokenModuleC';

/** avatar of the user key for localStorage */
export const USER_AVATAR = 
  'ideta_modulec_avatar';

/**
 * Login page component
 */
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  /** Pagination result base for table */
  public paginationResult: IPaginationResult = {
    ...this._dashboardQueriesSrv.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1,
  };

  /** true when validate button is clicked */
  public isSubmitted: boolean =
    false;

  /** FormGroup of user to login */
  public loginFormGroup: FormGroup;

  /** The list of filter to apply */
  TYPE_BUTTON: typeof TYPE_BUTTON = TYPE_BUTTON;

  /** Error if there is one */ public error: any = {
    message: '',
    isError: false,
  };

  /**
   * constructor
   * @param translationSrv translation service
   * @param _fb form builder
   * @param _router router
   * @param _authQueriesSrv auth queries service 
   * @param _authSrv auth service
   * @param _translateSrv translation service
   * @param _dashboardQueriesSrv dashboard queries service
   */
  constructor(
    public translationSrv: TranslationService,
    private _fb: FormBuilder,
    private _router: Router,
    private _authQueriesSrv: AuthenticateQueriesService,
    private _authSrv: AuthService,
    private _translateSrv: TranslationService,
    private _dashboardQueriesSrv: DashboardQueriesService,
  ) {}

  /**
   * Used to get controls of login form group
   */
  get formControls() {
    return this.loginFormGroup.controls;
  }

  /**
   * On init method.
   */
  ngOnInit(): void {
    this.loginFormGroup = this._fb.group({
      mail: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  /**
   * Called to navigate to another page
   * @param page URL
   */
  goTo(page: string) {
    this._router.navigate([page]);
  }


  /**
   * if the user have a default dashboard, we redirect to it otherwise we redirect to the dashboard list
   */
  goToDefaultDashboard() {
    this._dashboardQueriesSrv.getDashboardByIdCurrentUser().valueChanges.subscribe((result) => {
      if (result.errors && result.errors.length > 0) {
        console.log('error', result.errors);
        throw result.errors;
      } else {
        const data: any = <any>result.data;
        if (data?.getDashboardsByCurrentUser?.dashboards) {
          let dashboards = data?.getDashboardsByCurrentUser?.dashboards
          if (dashboards.length > 0) { // if there more than one dashboard, we redirect look if there is a default dashboard
            for(let i = 0; i < dashboards.length; i++) {
              if (dashboards[i].isDefault) {
                this._router.navigate([`/dashboard/show/${dashboards[i].id}`]);
                return;
              }
            }
          } else {
            this.goTo('/dashboard');
          }
        }

        if (data && data.getDashboardsByCurrentUser && data.getDashboardsByCurrentUser.pagination) {
          this.paginationResult = data.getDashboardsByCurrentUser.pagination;
        }
      }
    });
  }


  /**
   * Validate form
   * @returns
   */
  validateForm() {
    this.isSubmitted = true;
    if (this.loginFormGroup.invalid) {
      this.error.message = this.translationSrv.getInstant('missing-field');
      this.error.isError = true;
      return;
    }

    try {
      this._authQueriesSrv
        .authenticateUser(
          this.loginFormGroup.value.mail,
          this.loginFormGroup.value.password
        )
        .subscribe((result) => {
          if (result.errors && result.errors.length > 0) {
            throw result.errors;
          } else if (result.message) {
            this.error.message = this._translateSrv.getInstant("loginError");
            this.error.isError = true;
          } else {
            const data: any = <any>result.data;
            if (data && data.authenticate) {
              localStorage.setItem(USER_ID, data.authenticate.id);
              localStorage.setItem(USER_MAIL, data.authenticate.mail);
              localStorage.setItem(USER_ROLE, data.authenticate.role);
              localStorage.setItem(USER_FIRSTNAME, data.authenticate.name);
              localStorage.setItem(USER_LASTNAME, data.authenticate.lastName);
              localStorage.setItem(USER_TOKEN, data.authenticate.token);
              localStorage.setItem(USER_AVATAR, data.authenticate.avatar);
              this.error.message = '';
              this.error.isError = false;
              this._authSrv.userLogged.next({
                id: data.authenticate.id,
                mail: data.authenticate.mail,
                role: data.authenticate.role,
                name: data.authenticate.name,
                lastName: data.authenticate.lastName,
                token: data.authenticate.token,
                avatar: data.authenticate.avatar
              });
              this.goToDefaultDashboard()
              this.goTo('/dashboard');
            }
          }
        });
    } catch (e) {
      console.log('error dans le catch: ', e);
    }
  }
}
