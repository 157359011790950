import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { EnumGraphType, GRAPH_TYPE_BY_ENUM } from 'src/app/libs/widgets/facades/enums';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { DateTime, DurationObject } from "luxon";
import { EnumPeriod } from '../../modal/widgets-config/facades/enums/period.enum';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { GraphBackgroundColor, GraphBorderColor } from '../facades/constants/graph-color.constant';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendarInputComponent } from '../../input/calendar-input/calendar-input.component';
import { GraphConfigService } from '../../modal/widgets-config/facades/services/graph-config.service';
import moment from 'moment';
import { TranslationService } from '../../translation/services/translation.service';

Chart.register(...registerables);

/**
 * cer-graph-widget
 */
@Component({
  selector: 'app-cer-graph-widget',
  templateUrl: './cer-graph-widget.component.html',
  styleUrls: ['./cer-graph-widget.component.scss']
})
export class CerGraphWidgetComponent {

  /** Config for graph */ @Input() public graphConfig: any;
  /** id for graph */ @Input() public id: any;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronLeft: IconDefinition = faChevronLeft;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronRight: IconDefinition = faChevronRight;
  /** FormGroup of installation */ public formGroup: FormGroup;

  /** true if the loader should be displayed */ public loading: boolean = false;
  /** the graph view */ @ViewChild('myCanvas', { static: true }) myCanvas: ElementRef<HTMLCanvasElement>;
  /** the input date view */ @ViewChild('inputDate', { static: true }) inputDate: ElementRef<CalendarInputComponent>;

  /** the Chart of the graph */ public myChart: Chart = null;
  /** the totalDataset of the graph */ public totalDataset: any = null;
  /** the formattedDatasForGraph of the graph */ public formattedDatasForGraph: any = null;
  /** the information of the graph */ public information: any = null;

  /**
   * constructor
   * @param _installationQueriesSrv installation queries service
   * @param _fb the form builder
   * @param graphConfigSrv the graph config service
   */
  constructor(private _installationQueriesSrv: InstallationQueriesService,
    private _fb: FormBuilder,
    public graphConfigSrv: GraphConfigService) { }

  /**
   * On init method.
   */
  ngOnInit() {
    this.initFormGroup();
    this._initGraphOptions();
    this._getWidgetDatas();
  }

  /**
   * Init the form group
   */
  public initFormGroup() {
    this.formGroup = this._fb.group({
      currentDate: [DateTime.now().setLocale("fr").toMillis()],
    });

    this.formGroup.get("currentDate").valueChanges.subscribe(res => {
      this._getWidgetDatas();
    })
  }

  /**
   * Init the graph options
   */
  private _initGraphOptions() {
    if (this.myCanvas) {
      const ctx = this.myCanvas.nativeElement.getContext("2d");
      const chartConfig = {
        type: GRAPH_TYPE_BY_ENUM[this.graphConfig.type],
        data: null,
        options: {
          scales: {
            x: {
              // stacked: this.graphConfig.type === EnumGraphType.STACKEDBAR ? true : false,
              stacked: true
            },
            y: {
              beginAtZero: true,
              // stacked: this.graphConfig.type === EnumGraphType.STACKEDBAR ? true : false,
              stacked: true,
              title: {
                display: true,
                text: 'kWh',
                font: {
                  size: 10,
                  lineHeight: 0.5
                }
              }
            }
          },
          plugins: {},
          interaction: {
            intersect: false,
            mode: 'index',
          },
          maintainAspectRatio: false,
        }
      };

      chartConfig.options.plugins['tooltip'] = {
        callbacks: {
          label: function (tooltipItems) {
            return `${tooltipItems.dataset.label}: ${tooltipItems.parsed.y.toFixed(2)} kWh`;
          },
        }
      }
      if (this.graphConfig.type == EnumGraphType.STACKEDBAR) {
        chartConfig.options.plugins['tooltip'].callbacks['footer'] =
          (tooltipItems) => {
            let sum = 0;
            tooltipItems.forEach(function (tooltipItem) {
              sum += tooltipItem.parsed.y;
            });
            return 'Total: ' + sum.toFixed(2) + ' kWh';
          }
      }
      this.myChart = new Chart(ctx, chartConfig)
    }
  }

  /**
   * Get the widget datas
   */
  private _getWidgetDatas() {
    let cerFormattedDatas = [];
    let cerLabels = [];
    const range = this.graphConfigSrv.calculateRange(this.graphConfig.period, this.formGroup.get("currentDate").value);
    this.loading = true;
    this._installationQueriesSrv.getCerFormattedDatas(this.graphConfig.installationsIds, this.graphConfig.consumptionsIds, range).subscribe((resultDatas: any) => {
      this.loading = false;
      if (resultDatas && resultDatas.data) {
        const { getCerFormattedDatas } = resultDatas.data;
        if(getCerFormattedDatas) {
          const label = getCerFormattedDatas.label;
          let graphDatas = getCerFormattedDatas.data;
          this.information = getCerFormattedDatas.information;

          cerFormattedDatas = graphDatas ? graphDatas : [];
          cerLabels = label ? label : [];

          this.formattedDatasForGraph = cerFormattedDatas.map((item, index) => {
            return {
              ...item,
              backgroundColor:  item.label === "Production"? "transparent" : GraphBackgroundColor[index],
              borderColor: GraphBorderColor[index],
              pointRadius: 1,
              borderWidth: item.label === "Production"? 2 : 1,
              stack: item.label === "Production"? "production" : "consommation",
              type: item.label === "Production"? 'line' : 'bar',
              fill: true
            }
          });

          this.myChart.data.datasets = this.formattedDatasForGraph;
          this.myChart.data.labels = cerLabels;
        }

        this.myChart.update();

      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    })
  }

  /**
   * change the date
   * @param orientation next or prev
   */
  public changeDate(orientation: string = "next") {
    const currentDate = DateTime.fromMillis(this.formGroup.get("currentDate").value);
    if (orientation == "next") this.formGroup.get("currentDate").patchValue(currentDate.plus({ [this.graphConfig.period]: 1 }).toMillis());
    else this.formGroup.get("currentDate").patchValue(currentDate.minus({ [this.graphConfig.period]: 1 }).toMillis());
    this._getWidgetDatas();
  }

  /**
   * return the formated date
   * @param range the range
   * @returns the formated date
   */
  getFormattedDate(range: any){
    let date = moment(this.formGroup.get("currentDate").value)

    switch(range){
      case "date":
        return date.format("DD-MM-YYYY");
      case "month":
        return date.format("MMMM YYYY");
      case "year":
        return date.format("YYYY");
      case "week":
        return `Semaine ${date.format("WW")} (${date.startOf('isoWeek').format('DD-MM-YYYY')} - ${date.endOf('isoWeek').format('DD-MM-YYYY')})`;
      default:
        return date.format("DD-MM-YYYY");
    }
  }
}
