import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { EnumGraphType } from 'src/app/libs/widgets/facades/enums';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { DateTime, DurationObject } from "luxon";
import { EnumPeriod } from '../../modal/widgets-config/facades/enums/period.enum';
import { faChevronLeft, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { GraphBackgroundColor, GraphBorderColor, PredictionBackgroundColor, PredictionColor, PTCBackgroundColor, PTCColor } from '../facades/constants/graph-color.constant';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CalendarInputComponent } from '../../input/calendar-input/calendar-input.component';

Chart.register(...registerables);
/**
 * Component that display a bar
 * Basic Usage 
 * <app-graph-widget [graphConfig]="item.widgetData"></app-graph-widget>
 */
@Component({
  selector: 'app-production-graph-widget',
  templateUrl: './production-graph-widget.component.html',
  styleUrls: ['./production-graph-widget.component.scss']
})
export class ProductionGraphWidgetComponent {

  /** Config for graph */ @Input() public graphConfig: any;
  /** id for graph */ @Input() public id: any;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronLeft: IconDefinition = faChevronLeft;
  /** The FontAwesome Icon for dragging the widget on the board */ public faChevronRight: IconDefinition = faChevronRight;
  /** FormGroup of installation */ public formGroup: FormGroup;

  /** true if the widget is loading */public loading: boolean = false;
  /** canva view */ @ViewChild('myCanvas', { static: true }) myCanvas: ElementRef<HTMLCanvasElement>;
  /** input date view */ @ViewChild('inputDate', { static: true }) inputDate: ElementRef<CalendarInputComponent>;

  /** Enum to est type by enumType for graph */ private typeByEnum = {
    [EnumGraphType.AREA]: 'line',
    [EnumGraphType.MIXED]: 'line',
    [EnumGraphType.BAR]: 'bar',
    [EnumGraphType.STACKEDBAR]: 'bar'
  }
  /** data of the ptc */ PTCDatas: any[] = [];
  /** data of the prevision */ previsionDatas: any[] = [];

  /** current start date */ public currentStartDate: DateTime = null;
  /** current end date */ public currentEndDate: DateTime = null;

  /** period unit map */ public periodUnitMap: Map<string, keyof DurationObject> = null;
  /** enum period */ public __EnumPeriod = EnumPeriod;
  /** data */ public information: any = null;

  
  /** the chart */ public myChart: Chart = null;
  /** dataset */ public totalDataset: any = null;
  /** data from backend */ public formattedDatasForGraph: any = null;

  /**
   * constructor
   * @param _installationQueriesSrv the installation queries service
   * @param _fb the form builder
   */
  constructor(
    private _installationQueriesSrv: InstallationQueriesService,
    private _fb: FormBuilder,
    ) {
    this.periodUnitMap = new Map<string, keyof DurationObject>([
      [EnumPeriod.DAY, "day"],
      [EnumPeriod.WEEK, "week"],
      [EnumPeriod.MONTH, "month"],
      [EnumPeriod.YEAR, "year"]
    ]);
  }



  /**
   * On init method.
   */
  ngOnInit() {
    this.initFormGroup();
    this._initGraphOptions();
    this._getWidgetDatas();
  }

  /**
   * init the form group
   */
  public initFormGroup() {
    this.formGroup = this._fb.group({
      currentDate: [DateTime.now().setLocale("fr").toMillis()],
    });

    this.formGroup.get("currentDate").valueChanges.subscribe(res => {
      this._getWidgetDatas();
    })
  }

  /**
   * init the graph options
   */
  private _initGraphOptions() {
    if (this.myCanvas) {
      const ctx = this.myCanvas.nativeElement.getContext("2d");
      const chartConfig = {
        type: this.typeByEnum[this.graphConfig.type],
        data: null,
        options: {
          scales: {
            x: {
              stacked: this.graphConfig.type === EnumGraphType.STACKEDBAR ? true : (this.graphConfig.stacked ? true : false),
              // stacked: true
            },
            y: {
              beginAtZero: true,
              stacked: this.graphConfig.type === EnumGraphType.STACKEDBAR ? true : (this.graphConfig.stacked ? true : false),
              // stacked: true,
              title: {
                display: true,
                text: 'kWh',
                font: {
                  size: 10,
                  lineHeight: 0.5
                }
              }
            }
          },
          plugins: {
            legend: {
              onClick: (e, a, b) => {
                this.onLegendClick(e, a, b);
              }
            }
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          maintainAspectRatio: false,
        }
      };

      chartConfig.options.plugins['tooltip'] = {
        callbacks: {
          label: function (tooltipItems) {
            return `${tooltipItems.dataset.label}: ${tooltipItems.parsed.y.toFixed(2)} kWh`;
          },
        }
      }
      if (this.graphConfig.type == EnumGraphType.STACKEDBAR) {
        chartConfig.options.plugins['tooltip'].callbacks['footer'] =
          (tooltipItems) => {
            let sum = 0;
            tooltipItems.forEach(function (tooltipItem) {
              if (tooltipItem.dataset.label !== "PTC" && tooltipItem.dataset.label !== "Prevision")
                sum += tooltipItem.parsed.y;
            });
            return 'Production total: ' + sum.toFixed(2) + ' kWh';
          }
      }
      this.myChart = new Chart(ctx, chartConfig)
    }
  }

  /**
   * reduce the data to only the date where the sun is up
   * @param datas the data
   * @returns adapted datas
   */
  private reduceToSunHours(datas: any) {
    let ptc = datas.data.filter(d => d.label === "PTC");
    let hours = this.getSunIdx(ptc[0]);
    if (hours.sunRise !== -1 && hours.sunset !== -1) {
      for (let i = 0 ;i < datas.data.length; i++) {
        datas.data[i].data = datas.data[i].data.slice(hours.sunRise, hours.sunset);
      }
    }
    datas.label = datas.label.slice(hours.sunRise, hours.sunset);
    return datas
  }

  /**
   * get widget datas
   */
  private _getWidgetDatas() {
    let convertersFormattedData = [];
    let convertersLabels = [];

    const range = this._calculateRange(this.graphConfig.period);
    console.log("this.graphConfig", this.graphConfig);
    this.loading = true;
    this._installationQueriesSrv.getConvertersFormattedDataForInstallation(this.graphConfig.installationId, range).subscribe((resultDatas: any) => {
      this.PTCDatas = [];
      this.loading = false;
      if (resultDatas && resultDatas.data) {
        let { getConvertersFormattedDataForInstallation } = resultDatas.data;
        if (this.graphConfig.showSunHour && this.graphConfig.showSunHour === true) {
          getConvertersFormattedDataForInstallation = this.reduceToSunHours(getConvertersFormattedDataForInstallation);
        }
        let productionDatas = getConvertersFormattedDataForInstallation.data;
        const label = getConvertersFormattedDataForInstallation.label;
        this.information = getConvertersFormattedDataForInstallation.information;

        if (this.graphConfig.type === EnumGraphType.STACKEDBAR || !this.graphConfig.stacked) {
          this.totalDataset = {
            label: "Total",
            data: []
          }

          productionDatas.forEach(element => {
            if (element.data && element.label != "PTC") {
              if (element.label.includes("PTC_")) {
                this.PTCDatas.push(element);
              } else {
                element.data.forEach((element, index) => {
                  this.totalDataset.data[index] = this.totalDataset.data[index] != null ? this.totalDataset.data[index] + element : element
                });
              }
            }
          });
        }

        convertersFormattedData = productionDatas ? productionDatas : [];
        convertersLabels = label ? label : [];


        this.formattedDatasForGraph = []
        convertersFormattedData.filter(item => item.label != "Prevision" && item.label != "PTC").forEach((item, index) => {
          if (item.label.includes("PTC_")) {
            this.PTCDatas.push(item);
          } else {
          this.formattedDatasForGraph.push({
            ...item,
            backgroundColor: item.label === "Prevision"? 'transparent' : GraphBackgroundColor[index],
            borderColor: GraphBorderColor[index],
            pointRadius: 1,
            stack: item.label == "PTC" ? "PTC" : item.label === "Prevision"? 'prediction' : 'test',
            fill: (item.label == "PTC" && this.graphConfig.period == EnumPeriod.DAY)? false : true,
            index: 10,
            borderWidth: (item.label =="PTC" && this.graphConfig.period == EnumPeriod.DAY) ? 2 : item.label =="PTC" ? {top: 2, bottom: 0, left: 0, right: 0} : 1,
          })
        }
        });
        let predictionItem = convertersFormattedData.find(item => item.label == "Prevision");
        if (predictionItem) { this.previsionDatas = predictionItem.data; }
        if(predictionItem && this.graphConfig.period != EnumPeriod.DAY){
          this.formattedDatasForGraph.push({
            ...predictionItem,
            backgroundColor: this.graphConfig.period != EnumPeriod.YEAR? "transparent" : PredictionBackgroundColor,
            borderColor: PredictionColor,
            pointRadius: 0,
            type: this.graphConfig.period == EnumPeriod.YEAR? 'bar' : 'line',
            fill: this.graphConfig.period == EnumPeriod.DAY? false : true,
            index: 10,
            borderWidth: this.graphConfig.period == EnumPeriod.YEAR? {top: 1, bottom: 0, left: 0, right: 0} : 2
          })
        }
        if (this.graphConfig.type == EnumGraphType.AREA && !this.graphConfig.stacked) {
          this.formattedDatasForGraph.push({
            ...this.totalDataset,
            backgroundColor: GraphBackgroundColor[this.formattedDatasForGraph.length],
            borderColor: GraphBorderColor[this.formattedDatasForGraph.length],
            pointRadius: 1,
            borderWidth: 1,
            stack: "Test",
            fill: true
          })
        }
        let ptcItem = convertersFormattedData.find(item => item.label == "PTC");
        if(ptcItem){
          this.formattedDatasForGraph.push({
            ...ptcItem,
            backgroundColor: PTCBackgroundColor,
            hidden: this.graphConfig.period == EnumPeriod.DAY ? false : true,
            borderColor: PTCColor,
            pointRadius: 1,
            stack: "PTC",
            fill: false,
            index: 10,
            borderWidth: this.graphConfig.period == EnumPeriod.DAY? 2 : {top: 1, bottom: 0, left: 0, right: 0},
          })
        }
        this.myChart.data.datasets = this.formattedDatasForGraph;
        this.myChart.data.labels = convertersLabels;
        console.log("chart update");
        this.myChart.update();
      }
    }, (error) => {
      this.loading = false;
      console.log(error);
    })
  }


  /**
   * compute the PTC to only display the ptc of the displayed converteur
   * @param displayedConverteur the legend list of the displayed converteur
   * @returns 
   */
  private _updatePTCData(displayedConverteur) {

    // find dataset with label PTC
    let ptcDataset = this.myChart.data.datasets.find(item => item.label == "PTC");

    if (displayedConverteur.length <= 0) {
      ptcDataset.data.fill(0);
      this.myChart.update();
      return;
    } 

    // remove the previous PTC dataset
    let removalIndex = this.myChart.data.datasets.indexOf(ptcDataset); //Locate index of ds1
    if(removalIndex >= 0) { //make sure this element exists in the array
      this.myChart.data.datasets.splice(removalIndex, 1);
    }

    // add the new PTC dataset
    let newPtc = {label: "PTC", data: []}
    displayedConverteur.forEach((element) => {
      let converteurPtcData = this.PTCDatas.find(item => item.label == `PTC_${element.text}`);
      if (!converteurPtcData) { return; }
      for (let i =0; i < converteurPtcData.data.length; i++) {
        newPtc.data[i] = newPtc.data[i] ? newPtc.data[i] + converteurPtcData.data[i] : converteurPtcData.data[i];
      }
    })

    const newDataset: any = {
      ...newPtc,
      backgroundColor: PTCBackgroundColor,
      borderColor: PTCColor,
      pointRadius: 1,
      stack: "PTC",
      fill: false,
      index: 10,
      borderWidth: this.graphConfig.period == EnumPeriod.DAY? 2 : {top: 1, bottom: 0, left: 0, right: 0},
    }

    // add the new PTC dataset
    this.myChart.data.datasets.push(newDataset)
    this.myChart.update();
  }

  /**
   * update the rpediction with the displayed converter
   * @param displayedConverteur list of displayed converter
   * @returns void
   */
  private _updatePrediction(displayedConverteur) {

    let previsionDataset = this.myChart.data.datasets.find(item => item.label == "Prevision");

    if (displayedConverteur.length <= 0) {
      previsionDataset.data.fill(0);
      this.myChart.update();
      return;
    } 

    let totalelement = 0;
    this.myChart.data.datasets.forEach(item => {
      if (item.label != "Prevision" && item.label != "PTC" && item.label != "Total") totalelement++;
    });

    // remove the previous prevision dataset
    let removalIndex = this.myChart.data.datasets.indexOf(previsionDataset); //Locate index of ds1
    if(removalIndex >= 0) { //make sure this element exists in the array
      this.myChart.data.datasets.splice(removalIndex, 1);
    }

    let ratio = displayedConverteur.length / totalelement;

    // add the new PTC dataset
    let newPrevision = {label: "Prevision", data: []}
      for (let i =0; i < this.previsionDatas.length; i++) {
        newPrevision.data[i] = newPrevision.data[i] ? newPrevision.data[i] + (this.previsionDatas[i] * ratio) : this.previsionDatas[i] * ratio;
      }

    const newDataset: any = {
      ...newPrevision,
      backgroundColor: this.graphConfig.period != EnumPeriod.YEAR? "transparent" : PredictionBackgroundColor,
      borderColor: PredictionColor,
      pointRadius: 0,
      type: this.graphConfig.period == EnumPeriod.YEAR? 'bar' : 'line',
      fill: this.graphConfig.period == EnumPeriod.DAY? false : true,
      index: 10,
      borderWidth: this.graphConfig.period == EnumPeriod.YEAR? {top: 1, bottom: 0, left: 0, right: 0} : 2
    }

    // add the new Prevision dataset
    this.myChart.data.datasets.push(newDataset)
    this.myChart.update();
  }



  /**
   * this. function will be called when user click on a legend
   * @param e click event
   * @param legendItem the clicked item
   * @param legend all the item
   * @returns void
   */
  private onLegendClick(e, legendItem, legend) {
    // hide/show datasets
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    if (ci.isDatasetVisible(index)) {
        ci.hide(index);
        legendItem.hidden = true;
    } else {
        ci.show(index);
        legendItem.hidden = false;
    }
    // if (!legend.legendItems.find(item => item.text == "PTC")) return; // check if PTC is present to avoid computing it if the PTC isn't displayed;
    if (legendItem.text == "Total") return; // check id the user press a convertor to only update the PTC if it's changed
    // if the PTC is present; check which converteur is display and update the ptc to only display the corresponding ptc
    let displayedConverteur = [];
    let isPTCHidden = false;
    let isPrevisionHidden = false;
    legend.legendItems.forEach(item => {
      if (item.text === "PTC" && item.hidden) { isPTCHidden = true }
      if (item.text === "Prevision" && item.hidden) { isPrevisionHidden = true }
      if (item.text !== "PTC" && item.text !== "Total" && item.text !== "Prevision" && !item.hidden) {
        displayedConverteur.push(item);
      }
    })
    if (!isPTCHidden)
      this._updatePTCData(displayedConverteur);

    if (!isPrevisionHidden && this.graphConfig.period !== EnumPeriod.DAY)
      this._updatePrediction(displayedConverteur);
  }



  /**
   * compte the range of the graph based on the date selected by the user
   * @param period the periode selected by the user
   * @returns void
   */
  private _calculateRange(period: string) {

    let result = null
    this.currentStartDate = DateTime.fromMillis(this.formGroup.get("currentDate").value).setLocale("fr").startOf(this.periodUnitMap.get(this.graphConfig.period));
    this.currentEndDate = DateTime.fromMillis(this.formGroup.get("currentDate").value).setLocale("fr").endOf(this.periodUnitMap.get(this.graphConfig.period));
    result = {
      gte: this.currentStartDate.toMillis(),
      lte: this.currentEndDate.toMillis(),
      period
    }
    return result;
  }

  /**
   * get the idx of sunset and sunrise in the ptc array
   * @param ptc the ptc dataset
   * @returns an object with the idx of sunset and sunrise
   */
  private getSunIdx(ptc: any): {sunRise: number, sunset: number} {
    let res = {sunRise: -1, sunset: -1};
    if (!ptc.data || ptc.data.length <= 0) return res
    for (let i = 0; i < ptc.data.length; i++) {
      if (<number>(ptc.data[i]).toFixed(2) > 0) {
        res.sunRise = i;
        break;
      }
    }
    for (let i = ptc.data.length - 1; i >= 0; i--) {
      if (<number>(ptc.data[i]).toFixed(2) > 0) {
        res.sunset = i;
        break;
      }
    }
    if (res.sunRise > 0) res.sunRise--;
    if (res.sunset < ptc.data.length - 1) { res.sunset+=2; }
      
    return res;
  }
  
  /**
   * trigger when the user change the date
   * @param orientation next or prev
   */
  public changeDate(orientation: string = "next") {
    const currentDate = DateTime.fromMillis(this.formGroup.get("currentDate").value);
    if (orientation == "next") this.formGroup.get("currentDate").patchValue(currentDate.plus({ [this.graphConfig.period]: 1 }).toMillis());
    else this.formGroup.get("currentDate").patchValue(currentDate.minus({ [this.graphConfig.period]: 1 }).toMillis());
    this._getWidgetDatas();
  }
}
