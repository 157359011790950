<div class="box gap_top">
    <h4 class="title">{{ 'titleFiles' | translate }}</h4>
    <div class="container-pictures">
        <div *ngIf=canEdit>
            <app-label>{{ 'addFiles' | translate }}</app-label>
            <app-img-input (sendImg)="onNewFile($event)" [acceptedType]="'*'"></app-img-input>
        </div>
        <div *ngFor="let file of filesList">
            <app-label>{{file.name && file.name.slice(0, 15)}}</app-label>
            <app-img-block [hiddenAction]=!canEdit [file]=file (fileTouched)=onFileAction($event) >
            </app-img-block>
        </div>
      </div>
</div>
