import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlarmsComponent } from './alarms/alarms.component';
import { InputModule } from '../input/input.module';
import { TranslationModule } from '../translation/translation.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { TableModule } from '../table/table.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from '../modal/modal.module';
import { SearchSelectModule } from '../search-select/search-select.module';



@NgModule({
  declarations: [
    AlarmsComponent,
  ],
  imports: [
    CommonModule,
    InputModule,
    TranslationModule,
    ButtonsModule,
    FontAwesomeModule,
    TableModule,
    ModalModule,
    SearchSelectModule
  ],
  exports: [
    AlarmsComponent,
  ]
})
export class AlarmsModule { }
