import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListRecComponent } from './list-rec/list-rec.component';
import { RecRoutingModule } from './rec.routing.module';
import { TableModule } from 'src/app/libs/table/table.module';
import { TranslationModule } from 'src/app/libs/translation/translation.module';
import { ButtonsModule } from 'src/app/libs/buttons/buttons.module';
import { AddEditRecComponent } from './add-edit-rec/add-edit-rec.component';
import { TabTitleService } from '../../libs/shared/services/tabTitle.service';
import { InputModule } from 'src/app/libs/input/input.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchSelectModule } from 'src/app/libs/search-select/search-select.module';
import { UserQueriesService } from 'src/app/queries/user/user-queries.service';
import { ModalModule } from 'src/app/libs/modal/modal.module';
import { LinkedModule } from '../linked/linked.module';

@NgModule({
  declarations: [
    ListRecComponent,
    AddEditRecComponent,
  ],
  imports: [
    CommonModule,
    TranslationModule,
    RecRoutingModule,
    TableModule,
    ButtonsModule,
    InputModule,
    FontAwesomeModule,
    SearchSelectModule,
    ModalModule,
    LinkedModule
  ],
  providers: [
    TabTitleService,
    UserQueriesService
  ]
})
export class RecModule { }
