import { Input, OnInit, ViewChild, ElementRef, Renderer2, SimpleChanges, OnChanges, AfterViewInit, Directive } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ErrorMessageService } from "./services/error-message.service";

/**
 * Base class for all input components
 */
@Directive()
export abstract class AInputs implements OnInit, OnChanges, AfterViewInit {
    /** the name of the input */ @Input() public name: string;
    /** the id of the input */ @Input() public id: string;
    /** the group of the input */ @Input() public group: FormGroup;
    /** true if the input should be disable */ @Input() public disable = false;
    /** true if the input should be autofocused */ @Input() public autofocus = false;
    /** true if the input should be requiered */ @Input() public require = false;
    /** the error class */ protected errorClass = "input__error";

    /** the view where the input will be placed */ @ViewChild("input", {static: true}) protected inputReference: ElementRef;
    /** the error message */ public errorMessage = "";

    /**
     * constructor
     * @param renderer the renderer
     * @param _errorMessageSrv the error message service
     */
    public constructor( protected renderer: Renderer2,
                        protected _errorMessageSrv: ErrorMessageService) { }

    /** ngOnInit */
    ngOnInit() {}

    /**
     * trigger on changes
     * @param changes the changement
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disable && this.group) {
            if (this.disable) {
                this.group.get(this.name).disable();
            } else {
                this.group.get(this.name).enable();
            }
        }
        if (changes.group) {
            this.validationCheck();
        }
    }

    /**
     * trigger after view init to autofocus if the input should be autofocused
     */
    ngAfterViewInit() {
        if (this.autofocus) {
            setTimeout(() => { this.inputReference.nativeElement.focus(); }, 400);
        }
    }

    /**
     * check if the input is valid
     */
    protected validationCheck() {
        this.group.get(this.name).valueChanges.subscribe(result => {
            let msg = "";
            if (this.inputReference && this.inputReference.nativeElement) {
                if (!this.group.get(this.name).errors ) {
                    this.renderer.removeClass(this.inputReference.nativeElement, this.errorClass);
                } else {
                    this.renderer.addClass(this.inputReference.nativeElement, this.errorClass);
                }
            }
            this.errorMessage = msg.length > 0 ? msg : null;
        });
    }
}
