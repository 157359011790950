import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetCardComponent } from './widget-card/widget-card.component';
import { GridsterModule } from 'angular-gridster2';
import { WidgetBoardComponent } from './widget-board/widget-board.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableWidgetComponent } from './table-widget/table-widget.component';
import { TableModule } from '../table/table.module';
import { InputModule } from '../input/input.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { CounterWidgetComponent } from './counter-widget/counter-widget.component';
import { CounterModule } from '../counter/counter.module';

// import { ChartsModule } from 'ng2-charts';
import { WidgetsConfigModule } from '../modal/widgets-config/widgets-config.module';
import { TranslationModule } from '../translation/translation.module';
import { ProductionGraphWidgetComponent } from './production-graph-widget/production-graph-widget.component';
import { KwPerKwcreteGraphWidgetComponent } from './kwPerKwcrete-graph-widget/kwPerKwcrete-graph-widget.component';
import { ConsumptionGraphWidgetComponent } from './consumption-graph-widget/consumption-graph-widget.component';
import { ProductionHistoryGraphWidgetComponent } from './production-history-graph-widget/production-history-graph-widget.component';
import { CerGraphWidgetComponent } from './cer-graph-widget/cer-graph-widget.component';
import { AutoConsumptionGraphWidget } from './auto-consumption-graph-widget/auto-consumption-graph-widget.component';
import { DioxydeGraphWidgetComponent } from './dioxyde-graph-widget/dioxyde-graph-widget.component';
import { EconomyWidgetComponent } from './economy-widget/economy-widget.component';
import { ModalItemComponent } from '../modal/modal-item/modal-item.component';
import { ModalModule } from '../modal/modal.module';
import { CounterConsumptionWidgetComponent } from './counter-consumption-widget/counter-consumption-widget.component';

@NgModule({
  declarations: [
    WidgetCardComponent,
    WidgetBoardComponent,
    TableWidgetComponent,
    CounterWidgetComponent,
    CounterConsumptionWidgetComponent,
    EconomyWidgetComponent,
    ProductionGraphWidgetComponent,
    ProductionHistoryGraphWidgetComponent,
    ConsumptionGraphWidgetComponent,
    CerGraphWidgetComponent,
    AutoConsumptionGraphWidget,
    DioxydeGraphWidgetComponent,
    KwPerKwcreteGraphWidgetComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    GridsterModule,
    FontAwesomeModule,
    TableModule,
    // ChartsModule,
    ButtonsModule,
    CounterModule,
    WidgetsConfigModule,
    ModalModule,
    InputModule,
  ],
  exports: [
    WidgetCardComponent,
    WidgetBoardComponent,
    CounterWidgetComponent
  ]
})
export class WidgetsModule { }
