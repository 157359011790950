import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { ListUsersComponent } from './list-users/list-users.component';
import { TableModule } from 'src/app/libs/table/table.module';
import { ButtonsModule } from 'src/app/libs/buttons/buttons.module';
import { TranslationModule } from 'src/app/libs/translation/translation.module';
import { InputModule } from 'src/app/libs/input/input.module';
import { SearchSelectModule } from 'src/app/libs/search-select/search-select.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'src/app/libs/modal/modal.module';
import { AddEditUsersComponent } from './add-edit-users/add-edit-users.component';
import { LinkedModule } from '../linked/linked.module';


@NgModule({
  declarations: [
    ListUsersComponent, 
    AddEditUsersComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    TableModule,
    ButtonsModule,
    TranslationModule,
    InputModule,
    SearchSelectModule,
    FontAwesomeModule,
    ModalModule,
    LinkedModule
  ],
  exports: [

  ]
})
export class UsersModule {}
