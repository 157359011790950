import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { ICreateInstallationInput, IUpdateInstallationInput } from "./interfaces/installations.interface";
import { IPaginationInput } from "../pagination/interfaces/paginationInput.interface";

import UPLOAD_IMAGE from "./graphql/upload-image.graphql";

import GET_INSTALLATIONS from "./graphql/get-installations.graphql";
import GET_INSTALLATION_BY_ID from "./graphql/get-installation-by-id.graphql";
import GET_INSTALLATIONS_BY_IDS from "./graphql/get-installation-by-ids.graphql";
import GET_INSTALLATION_BY_NAME from "./graphql/get-installation-by-name.graphql";
import CREATE_INSTALLATION from "./graphql/create-installation.graphql";
import UPDATE_INSTALLATION from "./graphql/update-installation.graphql";
import REMOVE_INSTALLATION from "./graphql/remove-installation.graphql";
import COUNT_INSTALLATIONS from "./graphql/count-installation.graphql";
import GET_CONVERTERS_FORMATTED_DATA_FOR_INSTALLATION from "./graphql/get-converters-formatted-data-for-installation.graphql";
import GET_KW_BY_KWCRETE_BY_CONVERTERS from "./graphql/get-kw-by-kwCrete-by-converters.graphql";
import GET_INSTALLATION_BY_PALEMEDE_ID from "./graphql/get-installation-by-palamede-id.graphql";
import GET_INSTALLATIONS_PRODUCTION_HISTORY from "./graphql/get-installations-production-history.graphql";
import GET_CER_FORMATTED_DATAS from "./graphql/get-cer-formatted-datas.graphql";
import GET_COUNTER_FORMATTED_DATAS from "./graphql/get-counter-formatted-datas.graphql";
import GET_AUTO_CONSUMPTION_GRAPH_DATAS from "./graphql/get-auto-consumption-graph-datas.graphql";
import REMOVE_INSTALLATION_NOTE from "./graphql/remove-installation-note.graphql";
import GET_WIDGET_CO2 from "./graphql/get-widget-co2.graphql";
import GET_COORDINATE from "./graphql/get-coordinate.graphql";
import GET_CSV_DATAS from "./graphql/get-csv-datas.graphql";

/**
 * Installation queries service
 */
@Injectable({
  providedIn: "root"
})
export class InstallationQueriesService {

  /** Pagination base for table */ private _pagination: IPaginationInput = { page: 1, limit: 20 }; 
  /** The search string type by the user to use to filter the table result */ private _queryString: string = null; 
  /** the order */ private _order: {key: string, direction: string} = null ;

  /** pagination getter */ public get pagination(): IPaginationInput { return this._pagination; }
  /** queryString getter */ public get queryString(): string { return this._queryString; }
  /** order getter */ public get order(): {key: string, direction: string} { return this._order; }
  /** pagination setter */ public set queryString(queryString: string) { this._queryString = queryString; }
  /** queryString setter */ public set pagination(pagination: IPaginationInput) { this._pagination = pagination; }
  /** order setter */ public set order(order: {key: string, direction: string}) { this._order = order; }

  /**
   * constructor
   * @param _apollo the apollo service
   */
  constructor(private _apollo: Apollo) { }


  /**
  * Update an installation
  * @param install
  * @returns
  */
  public uploadImage(file: any) {
    return this._apollo.mutate({
      mutation: UPLOAD_IMAGE,
      variables: { file },
      fetchPolicy: "no-cache",
    });
  }
  /**
   * Get an installation by id
   * @param id
   * @returns
   */
  public getInstallationById(id: number) {
    return this._apollo.query({
      query: GET_INSTALLATION_BY_ID,
      variables: { id },
      fetchPolicy: "no-cache"
    });
  }

    /**
   * Get an installation by id
   * @param id the installation id
   * @returns
   */
     public getInstallationsByIds(ids: number[]) {
      return this._apollo.query({
        query: GET_INSTALLATIONS_BY_IDS,
        variables: { ids },
        fetchPolicy: "network-only"
      });
    }

  /**
   * Get installation with a compare string
   * @param name installation name
   * @returns
   */
  public async getInstallationsByName(name?: string) {
    return await this._apollo.query({
      query: GET_INSTALLATION_BY_NAME,
      variables: { name },
      fetchPolicy: "network-only"
    }).toPromise();
  }


  /**
   * Search an installation
   * @param name installation name
   * @returns
   */
   public searchInstallations(pagination?: IPaginationInput, queryString?: string) {
    let variables = { pagination };
    if (queryString) variables['search'] = queryString;

    return this._apollo.query({
      query: GET_INSTALLATIONS,
      variables,
      fetchPolicy: "no-cache"
    })
  }

  /**
   * Get installations by pagination
   * @param pagination the pagination to apply
   * @returns
   */
  public getInstallations() {

    return this._apollo.watchQuery({
      query: GET_INSTALLATIONS,
      variables: {
        search: this.queryString,
        order: this.order,
        pagination: this.pagination
      },
      fetchPolicy: 'cache-and-network',
    });
  }

  /**
   * get cer graph datas
   * @param installationsIds the installations ids
   * @param consumptionsIds the consumptions ids
   * @param range the time range
   * @returns void
   */
  public getCerFormattedDatas(installationsIds: number[], consumptionsIds: number[], range){
    return this._apollo.query({
      query: GET_CER_FORMATTED_DATAS,
      variables: {
        installationsIds,
        consumptionsIds,
        range
      },
      fetchPolicy: "network-only"
    });
  }

  /**
   * get counter graph datas
   * @param installationsIds the installations ids 
   * @param range the time range
   * @returns void
   */
  public getCounterFormattedDatas(installationsIds: number[], range){
    return this._apollo.query({
      query: GET_COUNTER_FORMATTED_DATAS,
      variables: {
        installationsIds,
        range
      },
      fetchPolicy: "network-only"
    });
  }

  /**
   * get auto consumption graph datas
   * @param allCerConsumptionsIds cer consumptions ids
   * @param installationsIds installations ids
   * @param consumptionsIds consumptions ids
   * @param repartitionPercentage repartition percentage
   * @param repartitionKey repartition key
   * @param autoConsumptionType the auto consumption type
   * @param range the time range
   * @returns void
   */
  public getAutoConsumptionGraphDatas(allCerConsumptionsIds: number[],installationsIds: number[], consumptionsIds: number[], repartitionPercentage: number[] ,repartitionKey: any, autoConsumptionType: any, range){
    return this._apollo.query({
      query: GET_AUTO_CONSUMPTION_GRAPH_DATAS,
      variables: {
        allCerConsumptionsIds,
        installationsIds,
        consumptionsIds,
        repartitionPercentage,
        range,
        repartitionKey,
        autoConsumptionType
      },
      fetchPolicy: "network-only"
    });
  }

  /**
   * get the production graph datas
   * @param ids converter ids
   * @param range the time range
   * @returns void
   */
  public getConvertersFormattedDataForInstallation(ids: number[], range) {
    return this._apollo.query({
      query: GET_CONVERTERS_FORMATTED_DATA_FOR_INSTALLATION,
      variables: {
        ids,
        range
      },
      fetchPolicy: "no-cache"
    });
  }

  /**
   * get kw by kwc graph datas
   * @param palamedeConvertersIds palamede converter ids
   * @param range the time range
   * @returns void
   */
  public getKwByKwCreteForConverters(palamedeConvertersIds: string[], range) {
    return this._apollo.query({
      query: GET_KW_BY_KWCRETE_BY_CONVERTERS,
      variables: {
        palamedeConvertersIds,
        range
      },
      fetchPolicy: "network-only"
    });
  }

  /**
   * production history graph datas
   * @param id production id
   * @returns void
   */
  public getInstallationsProductionHistory(id: number) {
    return this._apollo.query({
      query: GET_INSTALLATIONS_PRODUCTION_HISTORY,
      variables: {
        id
      },
      fetchPolicy: "network-only"
    });
  }

  /**
   * Get installations by pagination
   * @param pagination
   * @returns
   */
   public getInstallationByPalamedeId(id: string) {
    return this._apollo.query({
      query: GET_INSTALLATION_BY_PALEMEDE_ID,
      variables: { id },
      fetchPolicy: "network-only"
    });
  }

  /**
   * Creation an installation
   * @param install
   * @returns
   */
  public createInstallation(install: ICreateInstallationInput) {
    return this._apollo.mutate({
      mutation: CREATE_INSTALLATION,
      variables: { install },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: COUNT_INSTALLATIONS
        },
        {
          query: GET_INSTALLATIONS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

  /**
   * Update an installation
   * @param install
   * @returns
   */
  public updateInstallation(install: IUpdateInstallationInput) {
    return this._apollo.mutate({
      mutation: UPDATE_INSTALLATION,
      variables: { install },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: GET_INSTALLATIONS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

  /**
   * Remove an installation
   * @param id
   * @returns
   */
  public removeInstallation(id: Number) {
    return this._apollo.mutate({
      mutation: REMOVE_INSTALLATION,
      variables: { id },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: COUNT_INSTALLATIONS
        },
        {
          query: GET_INSTALLATIONS,
          variables: {
            search: this.queryString,
            order: this.order,
            pagination: this.pagination
          },
        }
      ]
    });
  }

  /**
   * remove a note from an installation
   * @param id of the note
   * @returns
   */
    public removeInstallationNote(id: string) {
      return this._apollo.mutate({
      mutation: REMOVE_INSTALLATION_NOTE,
      variables: { id },
      fetchPolicy: "no-cache"
    });
    }

  /**
  * count dashboar current user
  * @returns
  */
  public countInstallations() {
    return this._apollo.watchQuery({
      query: COUNT_INSTALLATIONS,
      fetchPolicy: 'network-only',
    });
  }

  /**
   * get co2 widget datas
   * @param installationsIds the installations ids 
   * @param range the time range
   * @param co2Level the co2 level
   * @returns void
   */
  public getWidgetCo2(installationsIds: number[], range, co2Level: number) {
    return this._apollo.query({
      query: GET_WIDGET_CO2,
      variables: {
        installationIds: installationsIds,
        range,
        co2Level: co2Level
      },
      fetchPolicy: "network-only"
    })

  }

  /**
   * get coordinates of installations
   * @param addresse the installation address
   * @returns void
   */
  public getCoordinate(addresse: string){
    return this._apollo.query({
      query: GET_COORDINATE,
      variables: {
        addresse: addresse,
      },
      fetchPolicy: "network-only"
    })

  }

  /**
   * get csv export datas
   * @param ids the installations ids
   * @param range the time range
   * @returns void
   */
  public getCsvDatas(ids: number[], range: any) {
    return this._apollo.query({
      query: GET_CSV_DATAS,
      variables: {
        ids: ids,
        range: range,
      },
      fetchPolicy: "network-only"
    })
  }
}
