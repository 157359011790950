import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlusCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { SLIDE_IN_OUT_ADD_ANIMATION } from 'src/app/libs/animations/slideInOutAddEdit.animation';
import { TYPE_BUTTON } from 'src/app/libs/buttons/facades/enums/type-button.enum';
import { ISelectOption } from 'src/app/libs/input/facades';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { ITableColumn } from 'src/app/libs/table/facades';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { IInstallation } from 'src/app/queries/installation/interfaces/installations.interface';
import { ICreateUserByAdminInput, IUpdateUserInput } from 'src/app/queries/user/interfaces/usersInput.interface';
import { UserQueriesService } from 'src/app/queries/user/user-queries.service';
import { USER_PAGES_ENUM } from '../facades/enums/usersPages.enum';
import { USER_ROLES_ENUM } from '../facades/enums/usersRoles.enum';
import { IInstallationToCreate } from '../facades/interfaces/installationToCreate.interface';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { AlertService } from 'ngx-alerts';
import { IConsumption } from 'src/app/queries/consumption/interfaces/consumptions.interface';
import { environment } from '../../../../environments/environment';
import { DashboardQueriesService } from '../../../queries/dashboard/dashboard-queries.service';

/**
 * Display a page for add an user
 * Basic usage 
 * <app-add-users></app-add-users>
 */
@Component({
  selector: 'app-add-edit-users',
  templateUrl: 'add-edit-users.component.html',
  styleUrls: ['./add-edit-users.component.scss'],
  animations: [SLIDE_IN_OUT_ADD_ANIMATION],
  host: { '[@slideInOutAddAnimation]': '' }
})
export class AddEditUsersComponent implements OnInit {
  /** initial file */ public initialFile: any;
  /** The list of users pages */ pagesEnum: typeof USER_PAGES_ENUM = USER_PAGES_ENUM;
  /** reset selected */ public resetSelected = new Subject<boolean>();
  /** Enum of button types */ public __TYPE_BUTTON = TYPE_BUTTON;
  /** add button icon */ public faPlusCircle: IconDefinition = faPlusCircle;
  /** Template of modal */ @ViewChild('tplDeleteInstallation', { static: true }) modalTemplate: TemplateRef<any>;
  /** Modal configuration */ public modalConfig: IModalItemConfig = {};
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
  /** FormGroup of users */ public usersFormGroup: FormGroup;
  /** FormGroup of installations of the user */ public installationsFormGroup: FormGroup;
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
    /** Key of title (add/edit) */ public titleKey: string = "add-users-title";
  /** list of installation to create */ public installationsToCreate: IInstallationToCreate[] = [];
  /** Role translated to display on form */ public rolesOptions: ISelectOption[] = [
    { value: USER_ROLES_ENUM.USER, label: this._translationSrv.getInstant("user") },
    { value: USER_ROLES_ENUM.ADMIN, label: this._translationSrv.getInstant("admin") },
  ];
  /** Id of user to edit (if it is an edit) */ public userId: number = null;
  /** Error if there is one */ public error: string = '';
  /** Role of user logged in */ private role: USER_ROLES_ENUM;
  /** Array of selected installations */ public selectedInstallations: IInstallation[] = [];
  /** Array of columns for table */ public columns: ITableColumn[] = [
    { key: 'serialNumber', type: 'text', title: 'Numéro de serie', clickable: false, },
    { key: 'place', type: 'text', title: 'Lieu', clickable: false },
    { key: 'name', type: 'text', title: "Nom", clickable: false },
    { key: 'actions', type: 'action', title: 'Actions' },
  ];
  /** Array of selected installations */ public formattedSelectedInstallations: IInstallation[] = [];
  /** Array of selected consumptions */ public formattedSelectedConsumptions: IConsumption[] = [];

  /** File of the avatar */ public file: File | any;

  /** Content to display on table */ public get contents() {
    return this.installationsToCreate
      .map((install: any) => {
        return {
          serialNumber: install.installation.id,
          place: install.installation.street_number + " " + install.installation.street_name + ", " + install.installation.locality + " (" + install.installation.postal_code + "), " + install.installation.country,
          // power: install.installation.power,
          name: install.name,
          data: install.installation
        }
      })
  }
  /**list of install to delete  */ public installToDelete: any;
  /** selected elem */ public selectedArray: ISearchSelectItem[] = [];
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;


  /** escape key listener */
  @HostListener('document:keyup.escape', ['$event']) onKeyUpHandler(event: KeyboardEvent) {
    this.goTo(this.pagesEnum.MAIN);
  }

  /**
   * constructor
   * @param _router the router
   * @param _activatedRoute the activated route
   * @param _fb the form builder
   * @param _userSrv the user service
   * @param _alertSrv the alert service
   * @param _errorMessageSrv the error message service
   * @param _translationSrv the translation service
   * @param _dashboardSrv the dashboard query service
   */
  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _fb: FormBuilder,
    private _userSrv: UserQueriesService,
    private _alertSrv: AlertService,
    private _errorMessageSrv: ErrorMessageService,
    private _translationSrv: TranslationService,
    private _dashboardSrv: DashboardQueriesService,
    ) {
    this.inputErrorsLabelMap = new Map<string, string>([
      ["name", this._translationSrv.getInstant("user_name-input")],
      ["mail", this._translationSrv.getInstant("user_mail-input")],
      ["lastName", this._translationSrv.getInstant("user_lastName-input")],
      ["phone", this._translationSrv.getInstant("user_phone-input")]
    ]);

    this.role = <USER_ROLES_ENUM>localStorage.getItem("role");
  }

  /** getter for controls of installations form group */ get formControls() { return this.usersFormGroup.controls; }

  /**
   * Called on init
   */
  ngOnInit(): void {
    this.initFormGroup();
    this._activatedRoute.params.subscribe(param => {
      if (param && param['id']) {
        this.userId = JSON.parse(param['id']);
        this.getUser(this.userId);
        this.titleKey = "edit-users-title"
      }
    });
  }

  /**
   * Called to navigate to another page
   * @param page URL
   */
  goTo(page: string) {
    this._router.navigate([page], { relativeTo: this._activatedRoute });
  }

  /**
   * Initiate the users form group
   */
  public initFormGroup(): void {
    this.usersFormGroup = this._fb.group({
      name: ["", Validators.required],
      lastName: ["", Validators.required],
      mail: ["", Validators.required],
      phone: ["", Validators.required],
      role: [null, Validators.required],
      installations: [],
      avatar: null,
    });
  }

  /**
   * get user by id
   * @param userId the user id
   */
  public getUser(userId: number): void {
    this._userSrv.getUserById(this.userId).subscribe(async (result) => {
      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      } else {
        const data: any = <any>result.data;
        console.log("data", data)

        if (data.user.installations) {
          this.formattedSelectedInstallations = data.user.installations.map((item: IInstallation) => {
            return { ...item }
          })
        }

        if(data.user.consumptions){
          this.formattedSelectedConsumptions = data.user.consumptions.map((item: IConsumption) => {
            return { ...item };
          })
        }

        if (data && data.user) {
          this.usersFormGroup.patchValue({
            name: data.user.name,
            lastName: data.user.lastName,
            mail: data.user.mail,
            phone: data.user.phone,
            role: data.user.role
          })
          if (data.user.avatar) {
            this.usersFormGroup.get("avatar").patchValue(data.user.avatar);
            this.file = {
              name: 'avatar'
            }
            this.file.previewUrl = await this.getUserAvatar(data.user.avatar)
            this.initialFile = data.user.avatar;
          }
        }
      }
    });
  }

  /**
   * get user avatar
   * @param file the file to get
   * @returns 
   */
  public async getUserAvatar(file: any): Promise<string> {
    let myInit: RequestInit =
    {
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      mode: 'cors',
      cache: 'default'
    };

    if (!file) {
      return null;
    };

    return new Promise(async (resolve) => {
      const filepath = environment.API_URL_CONTROLLER + "getFile/" + "avatar/" + file.substr(environment.UPLOAD_AVATAR_IMAGE_PATH.length);

      console.log("final filePath", filepath)
      await fetch(filepath, myInit)
      .then(function(response) {
        if (!response || !response.url) {
          console.error("can't find url of file")
        }
        resolve(response.url)
      })
    })
  }


  /**
   * Validate form of users creation
   */
  validateForm(): void {
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.usersFormGroup, this.inputErrorsLabelMap);
    if (this.usersFormGroup.invalid) return console.log("add-users.component.ts: usersFormGroup is invalid.");

    const userToSave: ICreateUserByAdminInput = {
      name: this.usersFormGroup.value.name,
      lastName: this.usersFormGroup.value.lastName,
      mail: this.usersFormGroup.value.mail,
      phone: this.usersFormGroup.value.phone,
      role: this.usersFormGroup.value.role,
      installationsIds: this.formattedSelectedInstallations.map(install => {
        return install.id
      }),
      consumptionsIds: this.formattedSelectedConsumptions.map(consumption => {
        return consumption.id
      }),
    }
    if (this.file && this.file.size) {
      userToSave.avatar = this.file;
    } else if (this.file && this.userId) {
      userToSave.deleteAvatar = false;
    } else if (this.userId) {
      userToSave.deleteAvatar = true;
    }

    if (this.userId) {
      (<IUpdateUserInput>userToSave).id = this.userId;
      this.editUser(<IUpdateUserInput>userToSave);
    } else this.addUser(userToSave);
  }

  /**
   * Add user query
   * @param userToCreate 
   */
  private addUser(userToCreate: ICreateUserByAdminInput) {
    try {
      this._userSrv.createUserByAdmin(userToCreate).subscribe(result => {
        if (result.errors && result.errors.length > 0) {
          this.error = result.errors.toString();
          this._alertSrv.danger(this._translationSrv.getInstant("errorAddUser"));
        } else {
          const data: any = <any>result.data;
          if (data && data.createUserByAdmin && data.createUserByAdmin) {
            this._dashboardSrv.checkDashboardsValidity(data.createUserByAdmin.id).subscribe((tmp) => {});
            this._alertSrv.success(this._translationSrv.getInstant("addUserTableLabel"));
            this.goTo(this.pagesEnum.MAIN);
          }
        }
      });
    } catch (error) {
      console.log("error dans le catch", error);
    }
  }

  /**
   * Edit user query
   * @param userToUpdate 
   */
  private editUser(userToUpdate: IUpdateUserInput) {
    try {
      console.log("edit")
      this._userSrv.updateUserByAdmin(userToUpdate).subscribe((result) => {
        if (result.errors && result.errors.length > 0) {
          // console.log('error', result.errors)
          this.error = result.errors.toString();
          this._alertSrv.danger(this._translationSrv.getInstant("errorEditUser"));
        } else {
          const data: any = <any>result.data;
          if (data && data.updateUser) {
            console.log("USER ID SENT: ", data.updateUser.id);
            this._dashboardSrv.checkDashboardsValidity(data.updateUser.id).subscribe((tmp) => {});
            this._alertSrv.success(this._translationSrv.getInstant("successEditUser"));
            this.goTo(this.pagesEnum.MAIN);
          }
        }
      });
    } catch (error) {
      console.log('error dans le catch', error);
    }
  }

  /**
   * trigger on installation change
   * @param linkedInstallations the linked installations
   */
  public linkedInstallationsChanged(linkedInstallations: IInstallation[]){
    this.formattedSelectedInstallations = linkedInstallations;
  }

  /**
   * trigger on consumption change
   * @param linkedConsumptions the linked consumptions
   */
  public linkedConsumptionsChanged(linkedConsumptions: IConsumption[]){
    this.formattedSelectedConsumptions = linkedConsumptions;
  }

/**
 * this function remove a file that have been added
 * @param file the file that should be removed
 */
  private removeFile(file: File)
  {
    if (this.file)
      this.file = null;
    this.usersFormGroup.get('avatar').patchValue(null);
  }

  /**
   * this function will be called by one of the app-img-block containing an image
   * @param event the event that contain {action: Delete or Zoom, file: the File that have triggerd the event}
   * @returns nothing
   */
   onFileAction(event: any)
   {
     if (event && event.action && event.file)
     {
       switch(event.action) {
         case "Delete":
           return this.removeFile(event.file);
         case "Zoom": // todo: display the image in full screen
           return;
       }
     }
   }

  /**
   * this function will be triggerd when the user add a new image for profile avatar
   * @param file the file that have been added
   */
   onNewFile(file: any) {
    if (file) {
      this.file = file;
      this.usersFormGroup.get('avatar').patchValue(this.file);
    }
  }
}
