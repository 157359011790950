<div [formGroup]="group" class="container-input">
    <input
        #input
        [class]="class"
        [autocomplete]="autocomplete"
        type="{{isPassword === true ? 'password' : 'text'}}"
        [name]="name"
        [id]="id"
        [formControlName]="name"
        [attr.disabled]="disable ? '' : null"
        [placeholder]="placeholder">
</div>
