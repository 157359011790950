<div id="list-rec">
    <div class="page-header">
        <h1 class="page-header__title">{{ "dashboard-list-title" | translate }}</h1>
        <app-button class="page-header__actions" (click)="goTo(pagesEnum.ADD)">{{ "add" | translate }}
        </app-button>
    </div>
    <app-table 
        [paginationResult]="paginationResult" 
        [columns]="columns" 
        [contents]="content" 
        [searchConfig]="searchConfig" 
        (tableAction)="tableActionClicked($event)" 
        (onSearch)="onSearch($event)"
        (pageChangeEmitter)="onPageChange($event)"></app-table>
</div>
<router-outlet></router-outlet>


<ng-template #tplDeleteDashboard>
    <app-modal-item [ref]="ref" [config]="modalConfig"></app-modal-item>
</ng-template>