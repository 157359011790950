import { EventEmitter, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlusCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'ngx-alerts';
import { Subject } from 'rxjs';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { ISearchSelectItem } from 'src/app/libs/search-select/facades/interfaces/searchSelectItem.interface';
import { EnumTableAction, ITableAction, ITableColumn } from 'src/app/libs/table/facades';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { IPaginationInput, IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { UserQueriesService } from 'src/app/queries/user/user-queries.service';
import { IUser } from '../../users/facades/interfaces/user.interface';

/**
 * linked users component
 */
@Component({
  selector: 'app-linked-users',
  templateUrl: './linked-users.component.html',
  styleUrls: ['./linked-users.component.scss']
})
export class LinkedUsersComponent implements OnInit {
  /** FormGroup for add a linked user */ public formGroup: FormGroup;
  /** Add button icon */ public faPlusCircle: IconDefinition = faPlusCircle;
  /** list of users selected for remove from the select search */ public selectedForSearch: ISearchSelectItem[] = [];
  /** List of users selected to display for the table */ @Input() public selectedUsers: IUser[] = [];
  /** EventEmitter when the array of users linked changed */ @Output("usersChanged") public usersChanged: EventEmitter<IUser[]> = new EventEmitter();
  /** Variable to send to search & select to reset selected item */ public resetSelected = new Subject<boolean>();
  /** Changed when submit button is pressed */ public isSubmitted: boolean = false;
  /** Map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** Label of inputs errors */ public inputErrorsLabel: any;
  /** User to delete */ public userToDelete: any;
  /** boolean true if the user can edit the installation otherwise false */ @Input() public canEdit: boolean = true
  /** Modal configuration */ public modalConfig: IModalItemConfig = {};
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
  /** Template of modal */ @ViewChild('tplDeleteUserToLink', { static: true }) modalTemplate: TemplateRef<any>;
  /** Pagination base for table */ public pagination: IPaginationInput = { page: 1, limit: 1 };
  /** pagination result for table */
  public paginationResult: IPaginationResult = {
    ...this.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1,
  };
  /** Array of columns for table */
  public columns: ITableColumn[] = [
    { key: 'fullName', type: 'text', title: "Nom Complet", clickable: false },
    { key: 'mail', type: 'text', title: "Mail", clickable: false },
    { key: 'phone', type: 'text', title: "Numéro de téléphone", clickable: false },
    { key: 'actions', type: 'action', title: 'Actions' },
  ];

  /** previous selected length */ private _previousSelectedLength: number = 0;


  /**
   * constructor
   * @param _router the router
   * @param _activedRoute the activated route
   * @param _fb the form builder
   * @param _usersQueriesSrv the user queries service
   * @param _translationSrv the translation service
   * @param _overlayService the overlay service
   * @param _alertSrv the alert service
   */
  constructor(private _router: Router,
              private _activedRoute: ActivatedRoute,
              private _fb: FormBuilder,
              private _usersQueriesSrv: UserQueriesService,
              private _translationSrv: TranslationService,
              private _overlayService: OverlayService,
              private _alertSrv: AlertService) {}

  /**
   * init the component
   */
  ngOnInit() {
    this.initFormGroup();
  }

  /**
   * check if the user is selected
   */
  ngDoCheck(){
    if(this.selectedUsers && this.selectedUsers.length !== this._previousSelectedLength){
      this._previousSelectedLength = this.selectedUsers.length;
      this.buildSelectedForSearch();
    }
  }

  /**
   * init the form group
   */
  public initFormGroup(): void {
    this.formGroup = this._fb.group({
      userItem: [null, Validators.required],
    })
  }

  /**
   * search user
   * @param q 
   * @param idsSelected 
   * @returns void
   */
  public searchUsers = async (q?: string, idsSelected: string[] = null): Promise<ISearchSelectItem[]> => {
    const resultQuery = await this._usersQueriesSrv.searchUsers({ page: 1, limit: 1000 }, q).toPromise();
    let users: any[] = [];

    if (resultQuery && resultQuery.data && (<any>resultQuery.data).getUsers.users && (<any>resultQuery.data).getUsers.users)
      users = (<any>resultQuery.data).getUsers.users

    users = users.map((user: any) => {
      return {
        id: user.id,
        label: `${user.name} ${user.lastName} (${user.mail})`,
        data: user
      };
    })
    return users;
  }

  /**
   * Add user to the list of selected users
   * @returns void
   */
  public addUser() {
    if(!this.formGroup || !this.formGroup.get("userItem").value || !this.formGroup.get("userItem").value[0]) {
      this._alertSrv.danger(this._translationSrv.getInstant("userRequiredToLink_error"));
      return;
    }
    this.selectedUsers.push(this.formGroup.get("userItem").value[0].data)
    this.buildSelectedForSearch();
    this.formGroup.get("userItem").setValue([]);
    this.resetSelected.next(true);
    this.usersChanged.emit(this.selectedUsers);
  }

  /**
   * Handle the action triggered by a user click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   */
  public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.userToDelete = event.content;
        this.open(this.modalTemplate);
        break;
      case EnumTableAction.EDIT:
        this.goTo(`/users/detail/${event.content.id}`);
        break;
      case EnumTableAction.BOOLEAN:
        // this.updateActiveUser(event.content);
        break;
      case EnumTableAction.SORT:
        if (event && event.content && event.content.key) {
          // this.pagination.page = 1;
          // this.sortedBy = event.content.key;
          // this.sortUsers(event.content.key)
        }
        break;
      default:
        break;
    }
  }

  /**
   * Called by table child when page is changed
   * @param event changement
   */
  public onPageChange(event: number): void {
    this.pagination.page = event;
  }

  /**
   * Open modal with content
   * @param content
   */
  public open(content: TemplateRef<any>) {
    console.log(this.userToDelete);
    this.modalConfig = {
      title: this._translationSrv.getInstant('modalDeleteConsumption'),
      body: `${this._translationSrv.getInstant('modalDeleteConsumptionBody', {name: this.userToDelete.fullName })}`,
      buttons: [
        {
          message: this._translationSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translationSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res.data.value === true) {

        // Search the index of the element to remove
        const index = this.selectedUsers.findIndex(item => item.id === this.userToDelete.id);

        // Need to use a tempArray because of reference of the searchSelectedUsers array need to change
        let tempArray = this.selectedForSearch.map(selected => { return selected })
        tempArray.splice(index, 1);
        this.selectedForSearch = tempArray.map(selected => { return selected });

        // Remove the selected user from the table contents
        this.selectedUsers.splice(index, 1);

        this.usersChanged.emit(this.selectedUsers);
      }
    });
  }

  /**
   * content getter
   */
  public get contents() {
    return this.selectedUsers.map((user: IUser) => {
      return {
        fullName: user.name + ' ' + user.lastName,
        phone: user.phone,
        mail: user.mail,
        id: user.id
      }
    })
  }

  /**
   * built the selected for search
   */
  public buildSelectedForSearch() {
    let result: ISearchSelectItem[] = [];
    if (this.selectedUsers.length > 0) {
      result = this.selectedUsers.map((elem: IUser) => {
        return {
          id: elem.id,
          label: elem.name + ' ' + elem.lastName,
          data: elem
        }
      })
    }
    this.selectedForSearch = result;
  }

  /**
   * Called to navigate to another page
   * @param page URL
   * @param content content to pass to page
   * @memberof InstallationsComponent
   */
  goTo(page: string, content?: any) {
    this._router.navigate([page], {
      relativeTo: this._activedRoute,
      state: content,
    });
  }

}
