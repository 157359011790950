import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Service used for the translation of the application
 */
@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    /** All the available languages */ private _availableLanguages: string[] = ['fr'];

    /**
     * constructor
     * @param _translateSrv the translate service
     */
    constructor(
        /** translate service */ private _translateSrv: TranslateService) { }

    /**
     * Getter for the available languages
     * @return {string[]} the available languages
     */
    public get availableLanguages(): string[] { return this._availableLanguages }

    /**
     * Public function to initialize all necessaries for translations
     * @returns {void}
     */
    public initializeLanguage(): void{
        this._initializeAvailableLanguages();
        this._initializeCurrentLanguage();
    }

    /**
     * Initialize the available languages for translations
     * @returns {void}
     */
    public _initializeAvailableLanguages(): void{
        this._translateSrv.addLangs(this._availableLanguages);
    }

    /**
     * Initialize the current language for translation
     * @returns {void}
     */
    public _initializeCurrentLanguage(): void{
        if(localStorage.getItem('idetaCurrentLanguage'))
            this._translateSrv.use(localStorage.getItem('idetaCurrentLanguage'));
        else localStorage.setItem("idetaCurrentLanguage", this._translateSrv.getDefaultLang());
    }

    /**
     * Get the current language used for the translation
     * @returns {string} the current language
     */
    public getCurrentLanguage(): string{
        return this._translateSrv.currentLang;
    }

    /**
     * Set the current language to use for the translation
     * @param language The language to use for translation
     * @returns {void}
     */
    public setCurrentLanguage(language: string): void{
        this._translateSrv.use(language? language : this._translateSrv.getDefaultLang());
        localStorage.setItem("idetaCurrentLanguage", language? language : this._translateSrv.getDefaultLang());
    }

    /**
     * get the translation from loaded lang of the param key
     * @param {string} key The key to translate
     * @returns {string} the translation of the key
     */
    public getInstant(key: string, interpolateParams?: any): string {
      if (interpolateParams) {
        return this._translateSrv.instant(key, interpolateParams);
      }
      return this._translateSrv.instant(key);
    }

    /**
     * Set the default language for the application
     * @returns {void}
     */
    public setDefaultLang(): void {
        return this._translateSrv.setDefaultLang('fr')
    }


}
