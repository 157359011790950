import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { EnumTableAction, ITableAction, ITableColumn } from 'src/app/libs/table/facades';
import { ISearchConfig } from 'src/app/libs/table/facades/interfaces/tableSearchConfig.interface';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { IPaginationInput, IPaginationResult } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { InstallationQueriesService } from 'src/app/queries/installation/installation-queries.service';
import { INSTALLATION_PAGES_ENUM } from '../facades/enums/installationsPages.enum';
import { IInstallation } from 'src/app/queries/installation/interfaces/installations.interface';
import { AlertService } from 'ngx-alerts';

/**
 * Installations list component
 * @class InstallationsComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-installations',
  templateUrl: './installations.component.html',
  styleUrls: ['./installations.component.scss'],
})
export class InstallationsComponent implements OnInit {

  /** boolean true if the user can edit the installation otherwise false */ canEdit: boolean = localStorage.getItem("ideta_modulec_userRole") ? localStorage.getItem("ideta_modulec_userRole") !== "USER" : false
  /** Template of modal */ @ViewChild('tplDeleteInstallation', { static: true }) modalTemplate: TemplateRef<any>;

  /** The list of installations pages */ pagesEnum: typeof INSTALLATION_PAGES_ENUM =
    INSTALLATION_PAGES_ENUM;

  /** Array of columns for table */ public columns: ITableColumn[] = [];
  /** Content to display on table */ public content: any[] = [];
  /** Pagination result base used on table */   public paginationResult: IPaginationResult = {
    ...this._installationsSrv.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1,
  };

  /** Installation saved when deleting it */ public installationToDelete: any = {};
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null;
  /** Modal configuration */ public modalConfig: IModalItemConfig = {};
  /** Configuration of search input */ public searchConfig: ISearchConfig = { searchPlaceholderKey: "searchPlaceholderInstallations" }

  /**
   * Creates an instance of InstallationsComponent.
   * @memberof InstallationsComponent
   */
  constructor(
    /** Router */ private _router: Router,
    /** Activated route */ private _activedRoute: ActivatedRoute,
    /** Installations service */ private _installationsSrv: InstallationQueriesService,
    /** Translation service */ private _translateSrv: TranslationService,
    /** Overlay service */ private _overlayService: OverlayService,
    /** Alert service */ private _alertSrv: AlertService

  ) {
  }

  /**
   * Called on init
   * @memberof InstallationsComponent
   */
  ngOnInit(): void {
    this.buildTable();
    // this.getInstallations(); // onSearch('') is used in order to reset the filter when page is loaded
    this.onSearch('');
    
  }


  /**
   * build the table content based on the user role
   */
  buildTable() {
    this.columns = [
      { key: 'name', type: 'text', title: this._translateSrv.getInstant('installationTableHeader_name-title'), sortable: true, },
      { key: 'serialNumber', type: 'text', title: this._translateSrv.getInstant('installationTableHeader_serialNumber-title'), sortable: true, },
      { key: 'power', type: 'text', title: this._translateSrv.getInstant('installationTableHeader_power-title'), sortable: true, },
      { key: 'place', type: 'text', title: this._translateSrv.getInstant('installationTableHeader_address-title'), sortable: false },
      { key: 'installationPalamede.name', type: 'text', title: this._translateSrv.getInstant('installationTableHeader_palamedeName-title'), sortable: false },
      { key: 'installationPalamede.id', type: 'text', title: this._translateSrv.getInstant('installationTableHeader_palamedeId-title'), sortable: false },
      { key: 'actions', type: 'action', title: this._translateSrv.getInstant('installationTableHeader_actions-title'), sortable: false },
    ];
  }

  /**
   * Called to navigate to another page
   * @param page URL
   * @param content content to pass to page
   * @memberof InstallationsComponent
   */
  goTo(page: string, content?: any) {
    this._router.navigate([page], {
      relativeTo: this._activedRoute,
      state: content,
    });
  }

  /**
   * Handle the action triggered by a user click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   * @memberof InstallationsComponent
   */
  public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.installationToDelete = event.content;
        this.open(this.modalTemplate)
        break;
      case EnumTableAction.EDIT:
      case EnumTableAction.SHOW:
        this.goTo(this.pagesEnum.DETAIL + `/${event.content.id}`, event);
        break;
      case EnumTableAction.SORT:
        if (event && event.content && event.content.key) {
          this._installationsSrv.order = {
            key: event.content.key,
            direction: this._installationsSrv.order && this._installationsSrv.order.key === event.content.key ? (
              this._installationsSrv.order.direction === "ASC" ? "DESC" : "ASC") :
              "ASC"
          }
          this.getInstallations();
        }
        break;
      default:
        break;
    }
  }

  /**
   * Open modal with content
   * @param content 
   */
  public open(content: TemplateRef<any>) {
    this.modalConfig = {
      title: this._translateSrv.getInstant('modalDeleteInstallation'),
      body: `${this._translateSrv.getInstant('modalDeleteInstallationBody', { name: this.installationToDelete.name, serialNumber: this.installationToDelete.serialNumber })}`,
      buttons: [
        {
          message: this._translateSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translateSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      if (res.data && res.data.value === true) {
        this.onDelete();
      }
    });
  }

  /**
   * get the list of all the installations
   * @memberof InstallationsComponent
   */
  public getInstallations(): void {

    this._installationsSrv.getInstallations().valueChanges.subscribe((result) => {
      if (result) {
        if (result.errors && result.errors.length > 0) {
          console.error('error', result.errors);
          throw result.errors;
        } else {
          const data: any = <any>result.data;
          if (data && data.getInstallations && data.getInstallations.installations) {
            this.content = data.getInstallations.installations.map(
              (installation) => {
                const place = this.getInstallationAddress(installation.installationPalamede);
                // if palamede link is broken, object is undefined so we'll complete it with an error message
                if (!installation.installationPalamede) {
                  installation.installationPalamede = {
                    id: installation.installationPalamedeId,
                    name: '-- ID Palamede introuvable ! --'
                  }
                }
                return {
                  place,
                  ...installation,
                };
              }
            );
          }
          if (
            data &&
            data.getInstallations &&
            data.getInstallations.pagination
          ) {
            this.paginationResult = data.getInstallations.pagination;
          }
        }
      }
    });
  }

    /**
   * Format the installation address in string format based on object
   * @param data {any} the object containing the address information
   * @returns {string} The formatted address for the palamedeInstallation
   */
     public getInstallationAddress(data: any): string {
      // if palamede link is broken, data is undefined so we'll complete it with an error message
      if (!data) return '-- ID Palamede introuvable ! --';
      let formattedAddress = "";
      formattedAddress += data.street_number ? `${data.street_number},` : "";
      formattedAddress += data.street_name ? ` ${data.street_name}` : "";
      formattedAddress += data.postal_code ? ` ${data.postal_code}` : "";
      formattedAddress += data.locality ? ` ${data.locality}` : "";
      formattedAddress += data.country ? ` ${data.country}` : "";
      return formattedAddress;
    }

  /**
   * Handle the action triggered by user click on delete button
   * @param {boolean} event event from button trigger
   * @memberof InstallationsComponent
   */
  public onDelete(): void {
    this._installationsSrv.removeInstallation(this.installationToDelete.id).subscribe((result) => {
      if (result.errors && result.errors.length > 0) {
        this._alertSrv.danger(this._translateSrv.getInstant("errorDeleteInstallation"));
        console.log('error', result.errors)
      } else {
        this._alertSrv.success(this._translateSrv.getInstant("successDeleteInstallation"));
        // Verify if we need to change pagination in case we delete the last element of the page
        if ((this.content.length - 1) == 0) {
          if (this.paginationResult.hasPrevious) this._installationsSrv.pagination.page = this.paginationResult.page - 1;
          else this._installationsSrv.pagination.page = 1;
        }
        this.getInstallations();
      }
    });
  }

  /**
   * Handle the action triggered to change the page to display
   * @param {number} event
   * @memberof InstallationsComponent
   */
  public onPageChange(event: number): void {
    this._installationsSrv.pagination = { ...this._installationsSrv.pagination, page: event }
    this.getInstallations();
  }

  /**
   * onSearch callback handle when user type in the search field to filter the list
   * @param {string} q the search param type by the user to filter the list
   * @returns {void}
   */
  public onSearch(q: string): void {
    this._installationsSrv.pagination = { ...this._installationsSrv.pagination, page: 1 }
    this._installationsSrv.queryString = q;
    this.getInstallations();
  }
}
