import { Component, Input, Renderer2, SimpleChanges, OnChanges, ElementRef } from "@angular/core";
import { AInputs } from "../facades/input";

import { ErrorMessageService } from "../facades/services/error-message.service";

/** checkbox input compnent */
@Component({
  selector: "app-checkbox-input",
  templateUrl: "./checkbox-input.component.html",
  styleUrls: ["./checkbox-input.component.scss"]
})
export class CheckboxInputComponent extends AInputs implements OnChanges {
  /** the label */ @Input() label: string;
  /** true if the checkbox should be small */ @Input() small = false;
  /** validity of the checkbox */ @Input() isCheckboxValid: boolean = true;
  /** true if the checkox is activated*/ @Input() isToggle: boolean = false;
  /** true if the checkbox is disable */ @Input() toggleDisabled: boolean = false;
  /** current value of the checkbox */ public valueCheckbox: boolean;

  /**
   * constructor
   * @param _elRef the element ref
   * @param renderer the renderer
   * @param _errorMessageSrv the error message service
   */
  constructor(private _elRef: ElementRef, public renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
    this.errorClass = "checkbox__error";
  }

    /**
     * triggerd on changement
     * @param changes the changement
     */
    public ngOnChanges(changes: SimpleChanges) {
      super.ngOnChanges(changes);
      if (changes.isCheckboxValid) {
        if ( this.isCheckboxValid) {
          this.renderer.removeClass(this._elRef.nativeElement, this.errorClass);
        } else {
          this.renderer.addClass(this._elRef.nativeElement, this.errorClass);
        }
      }
    }

  /**
   * checkValue
   */
  public checkValue() {
    if (!this.disable) {
      this.group.get(this.name).patchValue( this.group.get(this.name).value ? false : true);
      this.group.get(this.name).markAsTouched();
    }
  }
}

