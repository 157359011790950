import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faTrashAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AlertService } from 'ngx-alerts';
import { SLIDE_IN_OUT_ADD_ANIMATION } from 'src/app/libs/animations/slideInOutAddEdit.animation';
import { TYPE_BUTTON } from 'src/app/libs/buttons/facades/enums/type-button.enum';
import { ISelectOption } from 'src/app/libs/input/facades/interfaces';
import { ErrorMessageService } from 'src/app/libs/input/facades/services/error-message.service';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { IConsumption } from 'src/app/queries/consumption/interfaces/consumptions.interface';
import { IInstallation } from 'src/app/queries/installation/interfaces/installations.interface';
import { RecQueriesService } from 'src/app/queries/rec/rec-queries.service';
import { TabTitleService } from '../../../libs/shared/services/tabTitle.service';
import { REC_PAGES_ENUM } from '../facades/enums/recsPages.enum';
import { ICreateRec } from '../facades/interfaces/createRec.interface';
import { IUpdateRecInput } from '../facades/interfaces/updateRec.interface';

/**
 * Display a page for add a rec
 *
 * Basic usage
 * <app-add-rec></app-add-rec>
 */
@Component({
  selector: 'app-add-edit-rec',
  templateUrl: './add-edit-rec.component.html',
  styleUrls: ['./add-edit-rec.component.scss'],
  animations: [SLIDE_IN_OUT_ADD_ANIMATION],
  host: { '[@slideInOutAddAnimation]': ''}
})
export class AddEditRecComponent implements OnInit {
  /** The list of users pages */ pagesEnum: typeof REC_PAGES_ENUM = REC_PAGES_ENUM;
  /** Enum of button types */ public __TYPE_BUTTON = TYPE_BUTTON;
  /** Font awesome icon for delete */ public faTrashAlt: IconDefinition = faTrashAlt;

  /** FormGroup of REC */ public recFormGroup: FormGroup;
  /** true if rec has installation */ public hasInstallation: boolean = false;
  /** true if form is validate */ public isSubmitted: boolean = false;
  /** Error if there is one */ public error: string = '';
  /** translation key */ public titleKey: string = "add-rec-title";
  /** Array of selected installations */ public formattedSelectedInstallations: IInstallation[] = [];
  /** Array of selected consumptions */ public formattedSelectedConsumptions: IConsumption[] = [];
  /** Id of rec to edit (if it is an edit) */ public recId: number = null;
  /** map of inputs errors */ public inputErrorsLabelMap: Map<string, string>;
  /** label of inputs errors */ public inputErrorsLabel: any;

  /** Form which store CER type selected value */ public selectCerForm: FormGroup;

  /** CER type (ACC/ACI/PROSUMER) */ public CerType: ISelectOption[] = [{label: "ACC", value: "ACC"}, {label: "ACI", value: "ACI"}, {label: "PROSUMER", value: "PROSUMER"}]
  /** the currently selected CER type */ public selectedCerType: string = ""

  /** splitting key (proportionnal/fix) */ public splittingKey: ISelectOption[] = [{label: this._translationSrv.getInstant("repartitionModeProportional"), value: "proportional"}, {label: this._translationSrv.getInstant("repartitionModeFixed"), value: "fix"}]
  /** the currently selescted repartition key */ public selectedsplittingKey: string = ""

  /** true if the user can add a new consumption */ public allowNewConsumption: boolean = true

  /** this value is computed by the function this.computePercentage() */ public repartitionTotal: number = -1

  /** Message to display for installations and consumptions validation */ public showValidationMessage: boolean = false;

  /**
   * excape key listener
   * @param event key up event
   */
  @HostListener('document:keyup.escape', ['$event']) onKeyUpHandler(event: KeyboardEvent) {
    this.goTo(this.pagesEnum.MAIN);
  }
  
  /**
   * constructor
   * @param _router the router 
   * @param _activatedRoute the activated route
   * @param _fb the form builder
   * @param _recQueriesSrv the rec queries service
   * @param _translationSrv the translation service
   * @param _errorMessageSrv the error message service
   * @param _alertSrv the alert service
   */
  constructor(private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _fb: FormBuilder,
              private _recQueriesSrv: RecQueriesService,
              private _translationSrv: TranslationService,
              private _errorMessageSrv: ErrorMessageService,
              private _alertSrv: AlertService,)
  {
    this.inputErrorsLabelMap = new Map<string, string>([
      ["name", this._translationSrv.getInstant("user_name-input")],
      ["mail", this._translationSrv.getInstant("user_mail-input")],
    ]);

  }
  /**
   * form control getter
   */
  get formControls() { return this.recFormGroup.controls; }

  /**
   * Called on init
   */
  ngOnInit(): void {
    this.initFormGroup();
    this._activatedRoute.params.subscribe(param => {
      if (param && param['id']) {
        this.recId = JSON.parse(param['id']);
        this.getRec();
        this.titleKey = "edit-cer-title"
      }
    });

    // will be called when a new CER type is selected
    this.selectCerForm.get('cerType').valueChanges.subscribe(result => {
      if (result) {
        this.selectedCerType = result
        this.updateAllowAddConsumptionStatus()
      }
    })

    // will be called when a new splitting key is selected
    this.selectCerForm.get('splittingKey').valueChanges.subscribe(result => {
      if (result) {
        this.selectedsplittingKey = result
      }
    })

    this.selectCerForm.valueChanges.subscribe(result => {
        this.repartitionTotal = this.computePercentage()
    })
  }

  /**
   * get rec by id
   */
  public getRec(){
    this._recQueriesSrv.getRec(this.recId).subscribe(async result => {
      if (result.errors && result.errors.length > 0) {
        throw result.errors;
      } else {
        const data: any = <any>result.data;
        if (data && data.getRec) {

          if (data.getRec.installations) {
            this.formattedSelectedInstallations = data.getRec.installations.map((item: IInstallation) => {
              return { ...item }
            })
          }

          if(data.getRec.consumptions){
            this.formattedSelectedConsumptions = data.getRec.consumptions.map((item: IConsumption) => {
              return { ...item };
            })
            this.updateCunsumptionFormGroup()
            this.formattedSelectedConsumptions.forEach((elem, idx) => {
              this.selectCerForm.get(elem.id + "_percent").patchValue(data.getRec.recs_consumptions[idx].percentage ? data.getRec.recs_consumptions[idx].percentage : 0);
            })

          }



          this.selectCerForm.patchValue({
            cerType: data.getRec.repartitionMode,
            splittingKey: data.getRec.repartitionKey,
          })

          this.recFormGroup.patchValue({
            name: data.getRec.name,
            mail: data.getRec.mail
          })
        }
      };
    })
  }

  /**
   * init form group
   */
  public updateCunsumptionFormGroup()
  {
    this.formattedSelectedConsumptions.forEach(consumption => {
      this.selectCerForm.addControl(consumption.id + "_percent", new FormControl(Validators.required))
    });
  }

  /**
   * Initiate the REC form group
   */
  public initFormGroup(): void {

    let obj = {
      cerType: [this.CerType, Validators.required],
      splittingKey: [this.splittingKey],
    };

    this.selectCerForm = this._fb.group(obj);

    this.recFormGroup = this._fb.group({
      name: ["", Validators.required],
      mail: ["", Validators.required],
      installations: [],
      users: [],
    });
  }

  /**
   * Called to navigate to another page
   */
  goTo(page: string){
    this._router.navigate([page], {relativeTo: this._activatedRoute});
  }

  /**
   * compute repartition percentage
   * @returns return 100 if it's valid otherwise return the total percentage of repartition of all the
   */
  private computePercentage(): number
  {
    if (this.selectedCerType !== "ACC" || this.selectedsplittingKey !== "fix") return 100
    let total = 0;
    this.formattedSelectedConsumptions.forEach(elem => {
      let tmp = this.selectCerForm.get(elem.id + "_percent")
      if (tmp && tmp.value && !isNaN(tmp.value))
      {
        total += tmp.value;
      }
    })
    return total;
  }

  /**
   * Validate form of REC creation
   */
  validateForm(){
    this.isSubmitted = true;
    this.inputErrorsLabel = this._errorMessageSrv.getFormErrors(this.recFormGroup, this.inputErrorsLabelMap);
    if (this.recFormGroup.invalid ) return console.log("add-rec.component.ts: recFormGroup is invalid.");
    if (this.computePercentage() > 100)
    {
      this._alertSrv.danger(this._translationSrv.getInstant("errorPercentage"));
      return console.log("add-rec.component.ts: recFormGroup is invalid.");
    }
    if (this.selectedCerType !== "ACC" && this.formattedSelectedConsumptions.length > 1)
    {
      this._alertSrv.danger(this._translationSrv.getInstant("errorOnlyOneConsumptionProfil"));
      return console.log("add-rec.component.ts: recFormGroup is invalid.");
    }

    if(!this.formattedSelectedConsumptions || this.formattedSelectedConsumptions.length == 0 || !this.formattedSelectedInstallations || this.formattedSelectedInstallations.length == 0){
      return this.showValidationMessage = true;
    } else this.showValidationMessage = false;


    const recToSave: ICreateRec = {
      name: this.recFormGroup.value.name,
      mail: this.recFormGroup.value.mail,
      consumptionsIds: this.formattedSelectedConsumptions.map(item => item.id),
      installationsIds: this.formattedSelectedInstallations.map(item => item.id),
      percentage: this.formattedSelectedConsumptions.map(item => {
        return this.selectCerForm.get(item.id + "_percent").value
      }),
      repartitionMode: this.selectedCerType,
      repartitionKey: this.selectedCerType !== "ACC" ? "none" : this.selectedsplittingKey
    }

    // if it's an edit else add
    if(this.recId){
      (<IUpdateRecInput><unknown>recToSave).id = this.recId;
      this.editRec(<IUpdateRecInput><unknown>recToSave);
    } else this.addRec(recToSave);


  }

  /**
   * create a new rec
   * @param recToCreate the rec that should be created
   */
  private addRec(recToCreate: ICreateRec){
    try {
      this._recQueriesSrv.createRec(recToCreate).subscribe(result => {
        if (result.errors && result.errors.length > 0) {
          this.error = result.errors.toString();
          this._alertSrv.danger(this._translationSrv.getInstant("errorAddRec"));
        } else {
          const data: any = <any>result.data;
          if (data && data.createRec) {
            this._alertSrv.success(this._translationSrv.getInstant("successAddRec"));
            this.goTo("/rec");
          }
        }
      });
    } catch (error) {
      console.log("error dans le catch", error);
    }
  }

  /**
   * update a rec entity
   * @param recToUpdate the rec that should be updated
   */
  private editRec(recToUpdate: IUpdateRecInput) {

    try {
      this._recQueriesSrv.updateRec(recToUpdate).subscribe(result => {
        if (result.errors && result.errors.length > 0) {
          this.error = result.errors.toString();
          this._alertSrv.danger(this._translationSrv.getInstant("errorEditRec"));
        } else {
          const data: any = <any>result.data;
          if (data && data.updateRec) {
            this._alertSrv.success(this._translationSrv.getInstant("successEditRec"));
            this.goTo("/rec");
          }
        }
      });
    } catch (error) {
      console.log("error dans le catch", error);
    }
  }

  /**
   * triggerd when the user change the linked installation
   * @param linkedInstallations 
   */
  public linkedInstallationsChanged(linkedInstallations: IInstallation[]){
    this.formattedSelectedInstallations = linkedInstallations;
  }

  /**
   * update the acces to change the repartition key
   */
  private updateAllowAddConsumptionStatus() {
    if (this.selectedCerType === "ACI" || this.selectedCerType === "PROSUMER")
    {
      let arrayLength = this.formattedSelectedConsumptions.length
      if (arrayLength >= 1)
      {
        this.allowNewConsumption = false
      } else {
        this.allowNewConsumption = true
      }
    } else {
      this.allowNewConsumption = true
    }
  }


  /**
   * trigger when the linked consumptions changed
   * @param linkedConsumptions the new consumptions
   */
  public linkedConsumptionsChanged(linkedConsumptions: IConsumption[]){
    this.updateAllowAddConsumptionStatus();
    this.formattedSelectedConsumptions = linkedConsumptions;
    this.updateCunsumptionFormGroup()
  }
}
