import { Injectable } from "@angular/core"
import { Apollo } from "apollo-angular";
import { IPaginationInput } from "../pagination/interfaces/paginationInput.interface";
import GET_INSTALLATION_BY_NAME from "./graphql/get-palamede-installations-by-compare.graphql";


// import GET_INSTALLATION_BY_NAME from "./graphql/get-palamede-installations-by-compare.graphql";


/**
 * Installation queries service
 */
@Injectable({
    providedIn: "root"
})
export class InstallationPalamedeQueriesService {
    
    /**
     * constructor method
     * @param _apollo
     */
    constructor(private _apollo: Apollo) {}

    /**
   * Get installation with a compare string
   * @param name 
   * @returns 
   */
  public async getInstallationsPalamedeByCompare(compareString?: string, pagination?: IPaginationInput, filter?: any) {
    return await this._apollo.query({
      query: GET_INSTALLATION_BY_NAME,
      variables: { 
        compareString, 
        pagination,
        filter
      },
      fetchPolicy: "no-cache"
    }).toPromise();
  }
}