import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'src/app/libs/table/table.module';
import { ButtonsModule } from 'src/app/libs/buttons/buttons.module';
import { TranslationModule } from 'src/app/libs/translation/translation.module';
import { InputModule } from 'src/app/libs/input/input.module';
import { SearchSelectModule } from 'src/app/libs/search-select/search-select.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'src/app/libs/modal/modal.module';
import { LinkedUsersComponent } from './linked-users/linked-users.component';
import { LinkedInstallationsComponent } from './linked-installations/linked-installations.component';
import { LinkedConsumptionsComponent } from './linked-consumptions/linked-consumptions.component';
import { LinkedCommentComponent } from './linked-comment/linked-comment.component';
import { ModalCommentComponent } from 'src/app/pages/linked/linked-comment/modal/modal-comment/modal-comment.component';
import { LinkedFilesComponent } from './linked-files/linked-files.component';

@NgModule({
  declarations: [
    LinkedUsersComponent,
    LinkedInstallationsComponent,
    LinkedConsumptionsComponent,
    LinkedCommentComponent,
    ModalCommentComponent,
    LinkedFilesComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    ButtonsModule,
    TranslationModule,
    InputModule,
    SearchSelectModule,
    FontAwesomeModule,
    ModalModule,
  ],
  exports: [
    LinkedUsersComponent,
    LinkedInstallationsComponent,
    LinkedConsumptionsComponent,
    LinkedCommentComponent,
    LinkedFilesComponent,
    ModalCommentComponent,
  ]
})
export class LinkedModule {}
