import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faTimes, faTimesCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { IModalItemConfig } from '../facades/interfaces/modalItemConfig.interface';
import { MyOverlayRef } from '../overlay/myoverlay-ref';

/**
 * Used to configurate a modal
 */
@Component({
  selector: 'app-modal-item',
  templateUrl: './modal-item.component.html',
  styleUrls: ['./modal-item.component.scss']
})
export class ModalItemComponent implements OnInit {

  /** Overlay reference initiate in parent */ @Input() public ref: MyOverlayRef;
  /** Config of the modal (header, body, footer) */ @Input() public config: IModalItemConfig;

  /** Font Awesome Icon for closing button */ public faTimes: IconDefinition = faTimes;

  /**
   * On init method
   */
  ngOnInit() {
    if (this.config.duration) {
      setTimeout(() => {
        this.close("timeout");
      }, this.config.duration);
    }
  }

  /**
   * Close the modal and send a value to listeners
   * @param value
   */
  close(value: string) {
    this.ref.close({
      type: "delete",
      value: value,
      valid: true
    });
  }

}
