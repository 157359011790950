import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserQueriesService } from '../../../queries/user/user-queries.service';
import { EnumTableAction } from 'src/app/libs/table/facades/enums/tableAction.enum';
import { ITableAction } from 'src/app/libs/table/facades/interfaces/tableAction.interface';
import { ITableColumn } from 'src/app/libs/table/facades/interfaces/tableColumn.interface';
import { USER_PAGES_ENUM } from '../facades/enums/usersPages.enum';
import { IPaginationInput, IPaginationResult, } from 'src/app/queries/pagination/interfaces/paginationInput.interface';
import { AuthenticateQueriesService } from 'src/app/queries/authenticate/authenticate-queries.service';
import { USER_ROLES_ENUM } from '../facades/enums/usersRoles.enum';
import { TranslationService } from 'src/app/libs/translation/services/translation.service';
import { OverlayService } from 'src/app/libs/modal/overlay/overlay.service';
import { MyOverlayRef } from 'src/app/libs/modal/overlay/myoverlay-ref';
import { IModalItemConfig } from 'src/app/libs/modal/facades/interfaces/modalItemConfig.interface';
import { ISearchConfig } from 'src/app/libs/table/facades/interfaces/tableSearchConfig.interface';
import { AlertService } from 'ngx-alerts';

/**
 * Display a list of users
 *
 * Basic usage
 * <app-list-users [content]="usersArray"></app-list-users>
 */
@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss'],
})
export class ListUsersComponent implements OnInit {
  /** Template of modal to validate deleting of an user */ @ViewChild('tplDeleteUser', { static: true }) modalTemplate: TemplateRef<any>;
  /** The list of users pages */ pagesEnum: typeof USER_PAGES_ENUM = USER_PAGES_ENUM;
  /** Array of columns for table */ public columns: ITableColumn[] = [
    { key: 'name', type: 'text', title: 'Nom', sortable: true },
    { key: 'lastName', type: 'text', title: 'Prénom', sortable: true },
    { key: 'mail', type: 'text', title: 'Mail', sortable: true },
    { key: 'phone', type: 'text', title: 'Téléphone', sortable: true },
    { key: 'role', type: 'text', title: 'Rôle', sortable: true },
    { key: 'actions', type: 'action', title: 'Actions' },
  ];

  /** Content to display on table */ public content: any[] = [];


  /** Pagination result base for table */
  public paginationResult: IPaginationResult = {
    ...this._userQueriesSrv.pagination,
    hasNext: false,
    hasPrevious: false,
    total: 1,
    totalPage: 1
  };

  /** Save of user to delete */public userToDelete: any = {}; 
  /** Role of user logged */ public role: USER_ROLES_ENUM; 
  /** Overlay reference used to open modal */ public ref: MyOverlayRef = null; 
  /** Modal configuration */ public modalConfig: IModalItemConfig = {} 
  /** Configuration of search input */ public searchConfig: ISearchConfig = { searchPlaceholderKey: "searchPlaceholderUsers", } 

  /**
   * constructor
   * @param _router the router
   * @param _activedRoute the route
   * @param _userQueriesSrv the user queries service
   * @param _translateSrv the translation service
   * @param _overlayService the overlay service
   * @param _alertSrv the alert service
   */
  constructor(
    private _router: Router, /** Router */
    private _activedRoute: ActivatedRoute, /** Activated Route */
    private _userQueriesSrv: UserQueriesService, /** Users queries service */
    private _translateSrv: TranslationService, /** Translate service */
    private _overlayService: OverlayService, /** Overlay service */
    private _alertSrv: AlertService

  ) {
    this.role = <USER_ROLES_ENUM>localStorage.getItem('role');
  }

  /**
   * Called on init
   */
  ngOnInit(): void {
    // this.getUsers() // onSearch('') is used in order to reset the filter when page is loaded
    this.onSearch('');
  }

  /**
   * Called to navigate to another page
   * @param page URL
   * @param content content to pass to page
   */
  goTo(page: string) {
    this._router.navigate([page], {
      relativeTo: this._activedRoute,
    });
  }

  /**
   * Handle the action triggered by a user click on a table component
   * @param {ITableAction} event Event catched from table trigger
   * @returns {void}
   */
  public tableActionClicked(event: ITableAction): void {
    switch (event.action) {
      case EnumTableAction.DELETE:
        this.userToDelete = event.content;
        this.open(this.modalTemplate);
        break;
      case EnumTableAction.EDIT:
        this.goTo(this.pagesEnum.DETAIL + `/${event.content.id}`);
        break;
      case EnumTableAction.SHOW:
        break;
      case EnumTableAction.SORT:
        if (event && event.content && event.content.key) {
          this._userQueriesSrv.order = {
            key: event.content.key,
            direction: this._userQueriesSrv.order && this._userQueriesSrv.order.key === event.content.key ? (
              this._userQueriesSrv.order.direction === "ASC" ? "DESC" : "ASC") :
              "ASC"
          }
          this.getUsers();
        }
        break;
      default:
        break;
    }
  }

  /**
   * Open modal using content
   * @param content
   */
  public open(content: TemplateRef<any>) {
    this.modalConfig = {
      title: this._translateSrv.getInstant('modalDeleteUser'),
      body: `${this._translateSrv.getInstant(
        'modalDeleteUserBody',
        {
          givenName: this.userToDelete.lastName + " " + this.userToDelete.name,
          mail: this.userToDelete.mail
        }
      )}`,
      buttons: [
        {
          message: this._translateSrv.getInstant('delete'),
          bgColor: '#EA5455',
          response: true,
          messageColor: '#ffffff',
        },
        {
          message: this._translateSrv.getInstant('no'),
          response: false,
        },
      ],
    };
    this.ref = this._overlayService.open(content, null);

    this.ref.afterClosed.subscribe((res) => {
      console.log("res", res);
      if (res.data && res.data.value === true) {
        this.onDelete();
      }
    });
  }

  /**
   * Query getting users using pagination of table
   */
  public getUsers(): void {
    this._userQueriesSrv.users().valueChanges.subscribe((result) => {
      if (result.errors && result.errors.length > 0) {
        console.log('error', result.errors);
        throw result.errors;
      } else {
        const data: any = <any>result.data;
        if (data && data.getUsers && data.getUsers.users) {
          this.content = data.getUsers.users.map((user) => {
            return {
              id: user.id,
              name: user.name,
              lastName: user.lastName,
              mail: user.mail,
              phone: user.phone,
              role: this.formatRole(user.role),
              // role: user.role
              roleEnum: user.role,
              isActive: user.login.isActive,
            };
          });
        }
        if (data && data.getUsers && data.getUsers.pagination) {
          this.paginationResult = data.getUsers.pagination;
        }
      }
    });
  }

  /**
   * Used to translate role name to display on table
   * @param role
   * @returns
   */
  public formatRole(role: USER_ROLES_ENUM): string {
    switch (role) {
      case USER_ROLES_ENUM.USER:
        return this._translateSrv.getInstant('user');
      case USER_ROLES_ENUM.ADMIN:
        return this._translateSrv.getInstant('admin');
      case USER_ROLES_ENUM.SUPER_ADMIN:
        return this._translateSrv.getInstant('superAdmin');
    }
  }

  /**
   * Called by table child when page is changed
   * @param event
   */
  public onPageChange(event: number): void {
    this._userQueriesSrv.pagination = { ...this._userQueriesSrv.pagination, page: event }
    this.getUsers();
  }

  /**
   * Called when an user is delete.
   */
  public onDelete(): void {
    this._userQueriesSrv
      .deleteUser(this.userToDelete.mail)
      .subscribe((result) => {
        if (result.errors && result.errors.length > 0) {
          console.log('error', result.errors);
          this._alertSrv.danger(this._translateSrv.getInstant("errorDeleteUser"));
        } else {
          // Verify if we need to change pagination in case we delete the last element of the page
          if ((this.content.length - 1) == 0) {
            if (this.paginationResult.hasPrevious) this._userQueriesSrv.pagination.page = this.paginationResult.page - 1;
            else this._userQueriesSrv.pagination.page = 1;
          }
          this._alertSrv.success(this._translateSrv.getInstant("successDeleteUser"));
        }
      });
  }

  /**
   * onSearch callback handle when user type in the search field to filter the list
   * @param {string} q the search param type by the user to filter the list
   * @returns {void}
   */
  public onSearch(q: string): void {
    this._userQueriesSrv.pagination = { ...this._userQueriesSrv.pagination, page: 1 }
    this._userQueriesSrv.queryString = q;
    this.getUsers();
  }
}
