import {NgModule} from '@angular/core';
import { setContext } from '@apollo/client/link/context';
import {ApolloClientOptions, ApolloLink, InMemoryCache, from} from '@apollo/client/core';
import {onError} from '@apollo/client/link/error';
import {Apollo, APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
 
import {environment} from 'src/environments/environment';
import { JwtInterceptor } from 'src/app/interceptors/jwt.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {createUploadLink} from 'apollo-upload-client';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authenticate/services/authentication.service';
import { GraphQLError } from 'graphql';
// import { onError } from 'apollo-link-error';
 
/** The URL To use to call the GraphQL API */ const uri = environment.API_URL // <-- add the URL of the GraphQL server here
 
/**
 * Create the Apollo Link to send http request
 * @param {HttpLink} httpLink Apollo Link to allow sending single http request per operation
 * @returns {ApolloClientOptions<any>}
 */
 @NgModule({
  providers: [
    // {
    //   provide: APOLLO_OPTIONS,
    //   useFactory: createApollo,
    //   deps: [HttpLink],
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ],
})
export class GraphQLModule{
  constructor(private _router: Router,
              private _apollo: Apollo,
              private _auth: AuthenticationService){

    const uploadLink = <any>createUploadLink({  uri })

    const authLink = new ApolloLink((operation, forward) => {

      const token = localStorage.getItem('idetaTokenModuleC');
      if (token !== null && token !== "null") {
        operation.setContext({
          headers: {
            "Authorization": token ? `Bearer ${token}` : "",
          }
        });
      } else {
        operation.setContext({})
      }

      return forward(operation);
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      let graphErrors: any = graphQLErrors;
      if (graphErrors) {
        graphErrors.map(({ message, locations, path, statusCode }) => {
          let test: GraphQLError
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (statusCode === 401) {
              // this._router.navigate(["/login"]);
              this._auth.logout();
            }
        });
      }
      if (networkError) {
        const errors = (<any>networkError).error && (<any>networkError).error.errors? (<any>networkError).error.errors : [];
        console.error(networkError.message);
        errors.forEach(error => {
          console.error(error);
        });
      }
    });

    this._apollo.create({
      link: from([authLink, errorLink, uploadLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          errorPolicy: "all"
        },
        query: {
          errorPolicy: "all"
        }
      }
    });

  }


  
}
// export function createApollo(httpLink: HttpLink): ApolloClientOptions<any>{
//   const auth = setContext((operation, context) => {
//     const token = localStorage.getItem('idetaTokenModuleC');
//     if (token === null || token === "null") {
//       // console.log("[CreateApollo from GraphQLModule]: token from local storage is null")
//       return {};
//     }else {
//       // console.log("[CreateApollo from GraphQLModule]: token from local storage IS NOT NULL")
//       return {
//         headers: {
//           Authorization: 'Bearer ' + token
//         }
//       };
//     }
//   });
 





//   return {
//     link:  ApolloLink.from([auth, errorLink, uploadLink]),
//     cache: new InMemoryCache(),
//   };
// }
 

