import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { USER_AVATAR, USER_FIRSTNAME, USER_ID, USER_LASTNAME, USER_MAIL, USER_ROLE, USER_TOKEN } from '../pages/auth/login/login.component';
import { USER_ROLES_ENUM } from '../pages/users/facades/enums/usersRoles.enum';
import { IUserLogged } from './facades/interfaces/userLogged.interface';

/**
 * Service for authentification. You can listen on the userLogged subject.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /** Initialization of the subject via */ public userLocalStorage: IUserLogged = {
    mail: localStorage.getItem(USER_MAIL),
    token: localStorage.getItem(USER_TOKEN),
    id: JSON.parse(localStorage.getItem(USER_ID)),
    role: <USER_ROLES_ENUM>localStorage.getItem(USER_ROLE),
    name: localStorage.getItem(USER_FIRSTNAME),
    lastName: localStorage.getItem(USER_LASTNAME),
    avatar: localStorage.getItem(USER_AVATAR)
  }

  /** User logged in subject with value saved */ public userLogged: BehaviorSubject<IUserLogged> = new BehaviorSubject<IUserLogged>(this.userLocalStorage);
}
