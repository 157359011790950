import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { MyOverlayRef } from './myoverlay-ref';
import { OverlayComponent } from './overlay/overlay.component';

/**
 * Overlay service used to open a modal
 */
@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  /**
   * Constructor method
   * @param _overlay 
   * @param _injector 
   */
  constructor(private _overlay: Overlay, private _injector: Injector) {}

  /**
   * Open a modal
   * @param content content of the modal
   * @param data data that will be send as result
   * @returns 
   */
  public open<R = any, T = any>(
    content: string | TemplateRef<any> | Type<any>,
    data: T
  ): MyOverlayRef<R> {
    const configs = new OverlayConfig({
      hasBackdrop: true,
      panelClass: ['modal', 'is-active'],
      backdropClass: 'modal-background'
    });
    
    const overlayRef = this._overlay.create(configs);

    const myOverlayRef = new MyOverlayRef<R, T>(overlayRef, content, data);

    const injector = this.createInjector(myOverlayRef, this._injector);
    overlayRef.attach(new ComponentPortal(OverlayComponent, null, injector));

    return myOverlayRef;
  }

  /**
   * Create injector using overlayRef and injector created before.
   * @param ref
   * @param inj 
   * @returns 
   */
  public createInjector(ref: MyOverlayRef, inj: Injector) {
    return Injector.create({
      parent: inj,
      providers: [
        { provide: MyOverlayRef, useValue: ref }
      ]
    })
  }
}
