import { gql } from "apollo-angular";

/**
 * Query for getting list of users
 */
export const GET_RECS = gql`
 query getRecs ($pagination: PaginationInput!, $search: String, $order: OrderInput){
    getRecs (pagination: $pagination, search: $search, order: $order) {
        recs {
            id
            name
            mail
            installations {
                name
                id
                serialNumber
                power
                status
                error
                installationPalamedeId
                installationPalamede {
                    street_name
                    street_number
                    country
                    locality
                }
            }
            consumptions{
                id
                serialNumber
                name
            }
        }
        pagination {
            total
            limit
            page
            totalPage
            hasNext
            hasPrevious
        }
    } 
 }
 `

/**
* Query for getting list of users
*/
export const GET_RECS_BY_COMPARE = gql`
  query getRecsByCompare ($compareString:String, $pagination: PaginationInput!){
     getRecsByCompare (
        compareString: $compareString
        pagination: $pagination
    ) {
        recs {
            id
            name

            mail
            installations {
                name
                id
                serialNumber
                power
                status
                error
                installationPalamedeId
                installationPalamede {
                    street_name
                    street_number
                    country
                    locality
                }
            }
            consumptions{
                id
                serialNumber
                name
            }
        }
        pagination {
            total
            limit
            page
            totalPage
            hasNext
            hasPrevious
        }
     } 
  }
  `

/**
* Query for getting list of users
*/
export const GET_REC = gql`
  query getRec ($id: Int!){
    getRec (id: $id) {
        id
        name
        mail
        repartitionMode
        repartitionKey
        installations {
            name
            id
            serialNumber
            power
            status
            error
            installationPalamedeId
            installationPalamede {
                street_name
                street_number
                country
                locality
            }
        }
        consumptions{
            id
            serialNumber
            name
        }
        recs_consumptions{
            id
            recId
            consumptionId
            percentage
        }
    } 
}
`

/**
* Mutation to create an user by admin
*/
export const CREATE_REC = gql`
mutation createRec ( $createRecInput: CreateRecInput!) {
    createRec(
        createRecInput: $createRecInput
    ) {
        id
        name
        mail
        repartitionMode
        repartitionKey
    }
}
`

/**
* Mutation to create an user by admin
*/
export const UPDATE_REC = gql`
mutation updateRec ( $updateRecInput: UpdateRecInput!) {
    updateRec(
        updateRecInput: $updateRecInput
    ) {
        id
        name
        mail
        installations {
            name
            id
            serialNumber
            power
            status
            error
            installationPalamedeId
            installationPalamede {
                street_name
                street_number
                country
                locality
            }
        }
        consumptions{
            id
            serialNumber
            name
        }
    }
}
`

/**
* Mutation to create an user by admin
*/
export const DELETE_REC = gql`
mutation deleteRec ( $id: Int!) {
    deleteRec(
        id: $id
    ) {
        id
        name
        mail
    }
}
`
/**
 * Query for getting the number of community
 */
export const COUNT_RECS = gql`
query recCount {
  recCount
}

`

/**
 * Query for getting the rec of a user
 */
export const GET_USER_RECS = gql`
 query getUserRecs ($pagination: PaginationInput!, $search: String, $order: OrderInput){
    getUserRecs (pagination: $pagination, search: $search, order: $order) {
        recs {
            id
            name
            mail
            installations {
                name
                id
                serialNumber
                power
                status
                error
                installationPalamedeId
                installationPalamede {
                    street_name
                    street_number
                    country
                    locality
                }
            }
            consumptions{
                id
                serialNumber
                name
            }
        }
        pagination {
            total
            limit
            page
            totalPage
            hasNext
            hasPrevious
        }
    } 
 }
 `