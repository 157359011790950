import { Component, ElementRef, Input, Renderer2, SimpleChanges } from '@angular/core';
import { AInputs } from '../facades';
import { ISelectOption } from '../facades/interfaces/selectOption.interface';
import { ErrorMessageService } from '../facades/services/error-message.service';

/**
 * Component that display a select input
 * @example
 * Basic Usage 
 * <app-select-input [autoSelectFirst]="true" [options]="options"></app-select-input>
 */
@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent extends AInputs {

  /** List of options related to select options */ @Input() public options: ISelectOption[] = [];
  /** If you want the first option to be chosen automatically */ @Input() public autoSelectFirst = false;

  /** Selected option */ public valueSelect: any = null;
  /** State of closed or not */ public isClosed = true;

  /**
    * Constructor method
    * @param {Renderer2} renderer Custom rendering
    * @param {ErrorMessageService} _errorMessageSrv Service of error messages
    */
  constructor(public renderer: Renderer2,
              protected _errorMessageSrv: ErrorMessageService) {
    super(renderer, _errorMessageSrv);
  }

  /**
    * On Init method.
    * Check all options and add it to the form group.
    */
  ngOnInit() {
    const tmp = this.options.filter((d) => d.value === this.group.get(this.name).value);
    this.valueSelect = tmp[0];
    if (tmp.length === 0 && this.autoSelectFirst && this.options[0]) {
      this.valueSelect = this.options[0];
      this.group.get(this.name).patchValue(this.options[0].value);
    }
  }

  /**
   * On Changes method.
   * @param {SimpleChanges} changes event when there is a change.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.options) {
      const tmp = this.options.filter((d) => d.value === this.group.get(this.name).value);
      this.valueSelect = tmp[0];
    }
    if (changes.group) {
      this.validationCheck();
    }
  }

  /**
   * Shows info and close the select
   */
  public showInfo() { this.close(null); }

  /**
   * Open select options
   */
  public open() {
    if (!this.disable) {
      // this.inputReference.nativeElement.classList.add("select_active");
      this.isClosed = !this.isClosed;
    }
  }

  /**
   * Close select options
   * @param option change selected value
   */
  close(option?: any): void {
    if (option) {
      this.group.get(this.name).patchValue(option.value);
      this.group.get(this.name).markAsTouched();
      this.valueSelect = option;
    }
    // this.inputReference.nativeElement.classList.remove("select_active");
    this.isClosed = true;
  }

  /**
   * Check if the group is valid or not
   */
  protected validationCheck() {
    this.valueSelect = null;
    this.group.get(this.name).valueChanges.subscribe(result => {
      // Use to change the selected value label
      if ((!this.valueSelect && result) || (this.valueSelect && this.valueSelect.value !== result)) {
        this.valueSelect = this.options.find( option => option.value === result);
      }
      this.valueSelect = result ? this.valueSelect : null;
      let msg = "";
      if (!this.group.get(this.name).errors) {
        this.renderer.removeClass(this.inputReference.nativeElement, "select__error");
      } else {
        this.renderer.addClass(this.inputReference.nativeElement, "select__error");
        msg = this._errorMessageSrv.getInputErrors(this.group.get(this.name).errors);
      }
      this.errorMessage = msg.length > 0 ? msg : null;
    });
  }
}
